import React, { useEffect, useState, useRef } from 'react'
import { Container, Row, Col, Button, Modal, Card, Image, Table } from "react-bootstrap";
import { colors, contact, mainImage } from "../configs";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { stringDate, stringFullDate, stringFullTime } from '../Utility/dateTime';
import { useSelector, useDispatch } from "react-redux";
import { findInArray, summary } from '../Utility/function';
import { CSVLink, CSVDownload } from "react-csv";

function Modal_ReportReceipt({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='xl',
  onClick,
  data,
  reportId
}) {
  const { shop } = useSelector((state)=> state.resShop)
  const { cutOff, channel, smartTable, humanResource, smartCategory, POSs } = shop;
  const tableRef = useRef(null);

  const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
  ];


  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      size={size}
    >
      <Modal.Header style={{paddingTop:50,position:'relative'}} >
           
            {/* <div style={{borderRadius:20,backgroundColor:colors.darkPurple,padding:10,color:colors.white,display:'flex',alignItems:'center'}} >
                <i class="bi bi-box-arrow-in-down" style={{paddingRight:10}} ></i>
                Export
                
            </div> */}
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-secondary"
                table="table-to-xls2"
                filename={`บิลทั้งหมด${stringDate(new Date())}`}
                sheet="ใบเสร็จ"
                buttonText='ดาวน์โหลด'
            />
            <CSVLink 
              style={{backgroundColor:colors.softGray,textDecoration:'none',color:colors.white,padding:10,borderRadius:20}}
              filename={"fuck you"}
              data={data.map((item)=>{return({net:item.net,void:item.void,beforeVat:item.beforeVat,serviceChargeAmount:item.serviceChargeAmount,discount:summary(item.discount,'amount'),totalQty:item.totalQty,receiptNumber:item.receiptNumber,vatAmount:item.vatAmount,subTotal:item.subTotal,timestamp:item.timestamp})})}>
                Download me
            </CSVLink>
            <div onClick={onHide}  style={{position:'absolute',top:0,right:15,borderRadius:100,fontSize:36,borderWidth:0,cursor: 'pointer',color:colors.darkGray}} >
                x
            </div>
            
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'transparent',overflow:'auto',maxHeight:'500px'}} >
      
      {/* <CSVDownload data={csvData} target="_blank" /> */}
      {reportId===1
              ?<Row>
                <Table striped bordered hover responsive  variant="light" id="table-to-xls2"  >
                  <thead  >
                    <tr>
                      <th style={{textAlign:'center', width: '10%'}} >No.</th>
                      <th style={{textAlign:'center'}} >เลขที่บิล</th>
                      <th style={{textAlign:'center'}}>POS</th>
                      <th style={{textAlign:'center'}}>ยอดขาย</th>
                      <th style={{textAlign:'center'}}>สถานะ</th>
                      <th style={{textAlign:'center'}}>วัน เวลา</th>
                    </tr>
                  </thead>
                  <tbody  >
                    {data.map((item, index) => (
                      <tr  style={{cursor: 'pointer'}} key={index} >
                        <td>{parseInt(index) + 1}</td>
                        <td >{item.receiptNumber}</td>
                        <td style={{textAlign:'center'}}>{findInArray(POSs,'id',item.posId)?.name||item.posId}</td>
                        <td style={{textAlign:'center'}}>{item.net?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                        <td style={{textAlign:'center'}}>{item.void?'ยกเลิก':'ปกติ'}</td>
                        <td style={{textAlign:'center'}}>{stringFullTime(item.timestamp|| new Date())}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
              :<Row>
                <Table striped bordered hover responsive  variant="light" id="table-to-xls2"  >
                  <thead  >
                    <tr>
                      <th style={{ width: '10%' }}>No.</th>
                      <th style={{ width: '50%' }}>ชื่อ</th>
                      <th style={{ width: '20%' }}>จำนวน</th>
                      <th style={{ width: '20%' }}>ยอดขาย</th>
                    </tr>
                  </thead>
                  <tbody  >
                    {data.map((item, index) => (
                      <tr  style={{cursor: 'pointer'}} key={index} >
                        <td>{parseInt(index) + 1}</td>
                        <td >{item.name}</td>
                        <td style={{textAlign:'center'}}>{item.allQty}</td>
                        <td style={{textAlign:'center'}} data-type="text" >{item.allTotalPrice?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
          }
        {/* <Table   responsive id="table-to-xls2" >
          <thead >
            <tr>
                <th >No.</th>
                <th >เลขที่บิล</th>
                <th>POS</th>
                <th>ยอดขาย</th>
                <th>สถานะ</th>
            </tr>
          </thead>
          <tbody>
              
              {data.map((item, index) => (
                <tr key={index}  style={{cursor: 'pointer'}} >
                <td>{item.no+1}.</td>
                <td  >
                  {String(item.receiptNumber)}
                </td>
                <td style={{textAlign:'center'}}>
                  {item.posId}
                </td>
                <td style={{textAlign:'center'}}>
                  {item.net}
                </td>
                <td style={{textAlign:'center'}}>
                  {item.void?'ยกเลิก':'ปกติ'}
                </td>
              </tr>
              ))}
          </tbody>
        </Table> */}
        
      </Modal.Body>
    </Modal>
  );
}

export default Modal_ReportReceipt;
