import React, { useState, useContext } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import ChartScreen from "./ChartScreen";
import { colors, mainImage } from "../configs";
import Modal_Report from "../modal/Modal_Report";
import { useSelector, useDispatch } from "react-redux";
import { LinkTranslator,  periodFilter, summary } from "../Utility/function";
import { normalSort } from "../Utility/sort";
import { firstDayOfMonth, lastDayOfMonth, stringGetYearMonth } from "../Utility/dateTime";
import { createAllTable, } from "../redux/dailySaleSlice";

function MarketDashboardScreen() {
  const dispatch = useDispatch();
  const [report_Modal, setReport_Modal] = useState(false)
  const { marketSale } = useSelector((state)=> state.dailySale)
  const { renters_Normal } = useSelector((state)=> state.renter)

  function UpperDisplay(){
    return(
      <Row  >
          <Col   lg={6} xl={4}  >
            <Button onClick={()=>{dispatch(createAllTable({type:'day',startDate:new Date(),endDate:new Date()}));setReport_Modal(true)}}  style={{margin:5,padding:10,borderRadius:20,color:'white',width:'100%',position:'relative',backgroundColor:colors.darkPurple,borderWidth:0}} >
              <h4>ยอดขายรายวัน</h4>
              <h1><b>{summary(periodFilter(marketSale,'thisDay'),'net').toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</b></h1>
                <img style={{width:'10%',position:'absolute',top:10,right:10,minWidth:20}} alt='excel' src={mainImage.eye} />
            </Button>
          </Col>
          <Col   lg={6} xl={4} >
            <Button onClick={()=>{dispatch(createAllTable({type:'month',startDate:firstDayOfMonth(),endDate:lastDayOfMonth()}));setReport_Modal(true)}}  style={{margin:5,backgroundColor:colors.purpleRed,padding:10,borderRadius:20,color:'white',position:'relative',width:'100%',borderWidth:0}} >
              <h4>ยอดขายรายเดือน</h4>
              <h1><b>{summary(periodFilter(marketSale,'thisMonth'),'net').toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</b></h1>
              <img  style={{width:'10%',position:'absolute',top:10,right:10,minWidth:20}} alt='excel' src={mainImage.eye} />
            </Button>
          </Col>
          <Col   lg={6} xl={4}  >
            <div style={{margin:5,backgroundColor:colors.purplePeel,paddingLeft:40,borderRadius:30,color:'white'}} >
              <h6>จำนวนออเดอร์ วันนี้: </h6>
              <h1><b>{summary(periodFilter(marketSale,'thisDay'),'orders')}</b></h1>
            </div>
            <div style={{margin:5,backgroundColor:'orange',paddingLeft:40,borderRadius:30,color:'white'}} >
              <h6>จำนวนออเดอร์ เดือนนี้: </h6>
              <h1><b>{summary(periodFilter(marketSale,'thisMonth'),'orders')}</b></h1>
            </div>
          </Col>
      </Row>
    )
  }

  return (
    <div className="background"  >
      <UpperDisplay/>
      <Modal_Report
        show={report_Modal}
        onHide={()=>{setReport_Modal(false)}}
      />
      <Row>
          <Col  lg={8} >
            <div style={{backgroundColor:'white',margin:5,padding:10,borderRadius:20,height:'400px',marginRight:0}} >
              ยอดขายเดือน {stringGetYearMonth(new Date())}
              <ChartScreen/>
            </div>
          </Col>
          <Col  lg={4} >
            <div style={{backgroundColor:'white',margin:5,padding:10,borderRadius:20,height:'400px',marginLeft:0}} >
              <p>Top 10 ร้านค้าขายดีประจำวัน</p>
              {normalSort('net',periodFilter(marketSale,'thisDay')).slice(0,10).map((item,index)=>{
                return(
                  <div key={index} style={{display:'flex'}} >
                    <div style={{paddingRight:10,width:'100%'}} >
                      <h6>{index+1}.{LinkTranslator(renters_Normal,'shopId',item.shopId,'shopName')}</h6>
                    </div>
                    <div style={{display:'flex',justifyContent:'flex-end'}} >
                      <h6>{item.net.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</h6>
                    </div>
                  </div>
                )
              })}
            </div>
          </Col>
      </Row>
        {/* <div className="container" >

        </div> */}
    </div>
  );
}

export default MarketDashboardScreen;
