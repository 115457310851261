import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { bath, colors } from "../configs";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate , Outlet, NavLink } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';


function ShopListDetail() {

    const [data, setData] = useState([])
    const navigate = useNavigate();
    const [page, setPage] = React.useState(2);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        console.log(newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

  return (
    <div className="container" style={{borderRadius:20}} >
        <Row style={{marginLeft:0,marginRight:0,marginTop:0}} >
            <Row>
                <Col lg={3} >
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
                        <img  style={{width:190,borderRadius:100,objectFit:'cover',height:190,border: '4px solid #ddd'}} alt="image" src='https://s359.kapook.com/pagebuilder/9c2ed769-e59e-4cfd-bf48-6ea2b64ec73b.jpg' />
                    </div>
                </Col>
                <Col lg={9} >
                    <Row>
                        <Col lg={4} style={{ paddingLeft: 5,paddingRight: 5 }} >
                            <div style={{borderRadius:20,backgroundColor:colors.darkPurple,color:'white',padding:10,marginBottom:10}} >
                                <h6>ยอดขายประจำวันนี้</h6>
                                <h3><b>4,600 {bath}</b></h3>
                            </div>
                        </Col>
                        <Col lg={4} style={{ paddingLeft: 5,paddingRight: 5 }}  >
                            <div style={{borderRadius:20,backgroundColor:colors.darkPurple,color:'white',padding:10,marginBottom:10}} >
                                <h6>ยอดขายประจำเดือนนี้</h6>
                                <h3><b>4,600 {bath}</b></h3>
                            </div>
                        </Col>
                        <Col lg={4} style={{ paddingLeft: 5,paddingRight: 5 }}    >
                            <Row style={{ marginLeft: 0, marginRight: 0 }} >
                                <Col xs={12} md={6} style={{ paddingLeft: 5,paddingRight: 5 }}    >
                                    <div style={{borderRadius:20,backgroundColor:colors.purplePeel,color:colors.darkPurple,padding:10,marginBottom:10}} >
                                        <h6 style={{margin:0}} >จำนวนออเดอร์ประจำวันนี้</h6>
                                        <h5 style={{margin:0}} ><b>4,600 {bath}</b></h5>
                                    </div>
                                </Col>
                                <Col xs={12} md={6} style={{ paddingLeft: 5,paddingRight: 5 }}   >
                                    <div style={{borderRadius:20,backgroundColor:colors.purplePeel,color:colors.darkPurple,padding:10,marginBottom:10}} >
                                        <h6 style={{margin:0}} >จำนวนออเดอร์ประจำเดือนนี้</h6>
                                        <h5 style={{margin:0}} ><b>4,600 {bath}</b></h5>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}  md={4} style={{textAlign:'center',color:colors.darkGray}}  >
                            <div>
                                <h1><b>21</b></h1>
                                <p>จำนวนวันที่เปิดร้าน</p>
                            </div>
                        </Col>
                        <Col sm={12}  md={4}   style={{textAlign:'center',color:colors.darkGray,display:'flex',alignItems:'center',paddingLeft:0,paddingRight:0,justifyContent:'space-between'}}  >
                            <div className="left-border" style={{height:'50%'}} />
                            <div>
                                <h1><b>6</b></h1>
                                <p>จำนวนวันที่ปิดร้าน</p>
                            </div>
                            <div className="right-border" style={{height:'50%'}} />

                        </Col>
                        <Col sm={12}  md={4} style={{textAlign:'center',color:colors.darkGray,}}  >
                            <h1><b>1</b></h1>
                            <p>จำนวนครั้งผิดนัดชำระ</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col lg={3} className='square border-end border-1 ' style={{paddingLeft:0,color:colors.darkGray}}  >
                    <h2><b>Shop Name</b></h2>
                    <h5 ><b>ชื่อผู้ประกอบการ</b></h5>
                    <div style={{borderTop:`1px solid ${colors.lightGray}`,paddingTop:10,paddingBottom:10,fontSize:12,marginTop:30}} >
                        Tel : 085610-3603
                    </div>
                    <div style={{borderTop:`1px solid ${colors.lightGray}`,paddingTop:10,paddingBottom:10,fontSize:12}} >
                        ที่อยู่
                    </div>
                    <div style={{borderTop:`1px solid ${colors.lightGray}`,paddingTop:10,paddingBottom:10,fontSize:12}} >
                        <p style={{marginBottom:0}} ><b>ประเภทร้านค้า</b></p>
                        ร้านอาหาร
                    </div>
                    <div style={{borderTop:`1px solid ${colors.lightGray}`,paddingTop:10,paddingBottom:10,fontSize:12}} >
                        <p style={{marginBottom:0}} ><b>ประเภทสินค้า</b></p>
                        อาหารตามสั่ง/เครื่องดื่ม
                    </div>
                    <div style={{borderTop:`1px solid ${colors.lightGray}`,paddingTop:10,paddingBottom:10,fontSize:12}} >
                        <p style={{marginBottom:0}} ><b>วันที่เข้าร่วม </b></p>
                        1 มีนาคม 2566
                    </div>
                </Col>
                <Col lg={9} >
                    <Row style={{paddingLeft:20,color:colors.darkGray}} >
                        รายการสินค้า : 12 รายการ
                    </Row>
                    <Row>
                        {Array.from({ length: 20 }).map((_, index) => (
                          <Col sm='4'  lg='2'  style={{padding:10}} >
                              <Button variant="light"  style={{borderRadius:20,backgroundColor:colors.purplePeel,padding:0}} >
                                <img className="suqare border border-3 rounded-9"  style={{width:'100%',borderRadius:20}} src='https://www.pim.in.th/images/all-side-dish-chicken-egg-duck/spicy-chicken-fried-with-holy-basil/spicy-chicken-fried-with-holy-basil-11.JPG' />
                                <p style={{fontSize:12,padding:0,margin:0}} >กะเพราไก่ ไข่ดาว</p>
                                <h6 style={{fontSize:12,padding:0,margin:0}} >50 .-</h6>
                              </Button>
                          </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Row>
    </div>
  );
}

export default ShopListDetail;

// import React, { useState, useContext, useEffect } from "react";
// import {
//   Button,
//   Form,
//   Row,
//   Col,
//   Container,
//   Table,
//   Modal,
//   Card,
//   Image,
//   Collapse,
// } from "react-bootstrap";
// import { colors } from "../configs";
// import { BrowserRouter as Router, Routes, Route, Link, useNavigate , Outlet, NavLink } from 'react-router-dom';

// function ShopListDetail() {

//     const [data, setData] = useState([])
//     const navigate = useNavigate();
//     useEffect(()=>{
        
//     },[])

//   return (
//     <div className="container" >
//         <Row style={{marginLeft:0,marginRight:0,marginTop:0}} >
//             <Col lg={3} style={{paddingLeft:0,paddingRight:0,marginTop:0,backgroundColor:'pink'}} >
//                 <div style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
//                     <img resizeMode='cover' style={{width:190,borderRadius:100,height:190}} alt="image" src='https://s359.kapook.com/pagebuilder/9c2ed769-e59e-4cfd-bf48-6ea2b64ec73b.jpg' />
//                 </div>
//                 <Link to="testroute">testroute</Link>
//                 <Button onClick={()=>{
//                     navigate("/market/shoplist/testroute",{state:'i am groot'})
//                 }} >testroute</Button>
//                 <Row>
//                     <h1>Shop Name</h1>
//                     <h3>ชื่อผู้ประกอบการ</h3>
//                     <h6>Tel : 085610-3603</h6>
//                     <div>
//                         ที่อยู่
//                     </div>
//                     <p>หมวดหมู้ร้านค้า</p>
//                     <p>วันที่เริ่มขาย</p>
//                 </Row>
//             </Col>
//             <Col lg={9} >
//                 <Row>
//                     <Col lg={4} style={{ paddingLeft: 5,paddingRight: 5 }} >
//                         <div style={{borderRadius:20,backgroundColor:colors.darkPurple,color:'white',padding:10,marginBottom:10}} >
//                             <h6>ยอดขายประจำวันนี้</h6>
//                             <h3><b>4,600</b></h3>
//                         </div>
//                     </Col>
//                     <Col lg={4} style={{ paddingLeft: 5,paddingRight: 5 }}  >
//                         <div style={{borderRadius:20,backgroundColor:colors.darkPurple,color:'white',padding:10,marginBottom:10}} >
//                             <h6>ยอดขายประจำเดือนนี้</h6>
//                             <h3><b>4,600</b></h3>
//                         </div>
//                     </Col>
//                     <Col lg={4} style={{ paddingLeft: 5,paddingRight: 5 }}    >
//                         <Row style={{ marginLeft: 0, marginRight: 0 }} >
//                             <Col xs={12} md={6} style={{ paddingLeft: 5,paddingRight: 5 }}    >
//                                 <div style={{borderRadius:20,backgroundColor:colors.purplePeel,color:colors.darkPurple,padding:10,marginBottom:10}} >
//                                     <h6 style={{margin:0}} >ยอดขายประจำวันนี้</h6>
//                                     <h5 style={{margin:0}} ><b>4,600</b></h5>
//                                 </div>
//                             </Col>
//                             <Col xs={12} md={6} style={{ paddingLeft: 5,paddingRight: 5 }}   >
//                                 <div style={{borderRadius:20,backgroundColor:colors.purplePeel,color:colors.darkPurple,padding:10,marginBottom:10}} >
//                                     <h6 style={{margin:0}} >ยอดขายประจำวันนี้</h6>
//                                     <h5 style={{margin:0}} ><b>4,600</b></h5>
//                                 </div>
//                             </Col>
//                         </Row>
//                     </Col>
//                 </Row>
//                 <Row>
//                     <Col md={4} style={{textAlign:'center',color:colors.darkGray,}}  >
//                         <h1><b>21</b></h1>
//                         <p>จำนวนวันที่เปิดร้าน</p>
//                     </Col>
//                     <Col md={4} style={{textAlign:'center',color:colors.darkGray,}}  >
//                         <h1><b>6</b></h1>
//                         <p>จำนวนวันที่ปิดร้าน</p>
//                     </Col>
//                     <Col md={4} style={{textAlign:'center',color:colors.darkGray,}}  >
//                         <h1><b>1</b></h1>
//                         <p>จำนวนครั้งผิดนัดชำระ</p>
//                     </Col>
//                 </Row>
//                 <div  >
//                     รายการสินค้า : 12 รายการ
//                 </div>
//                 <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}} >
//                 {Array.from({ length: 20 }).map((_, index) => (
//                     <div onClick={()=>{alert('ddd')}}  style={{width:90,margin:10,display:'flex',alignItems:'center',flexDirection:'column'}} >
//                         <img style={{width:80,height:80,borderRadius:10}} src='https://www.pim.in.th/images/all-side-dish-chicken-egg-duck/spicy-chicken-fried-with-holy-basil/spicy-chicken-fried-with-holy-basil-11.JPG' />
//                         <p style={{fontSize:12,padding:0,margin:0}} >กะเพราไก่ ไข่ดาว</p>
//                         <h6 style={{fontSize:12,padding:0,margin:0}} >50 .-</h6>
//                     </div>
//                 ))}
                    

//                 </div>
//             </Col>
//         </Row>
//     </div>
//   );
// }

// export default ShopListDetail;

