import mainImage from "./mainImage";

export default {
        landlordId:'',
        marketId:'',  // ใช้สำหรับบการสร้าง qr code
        name:[
            {
                brev:'TH',
                value:''
            }
        ],
        imageUrl:[
            {
                id:1, // จะ fixed เป็น default ให้
                type:'logo',
                imageId:''
            },
            {
                id:2, // จะ fixed เป็น default ให้
                type:'coverPhoto',
                imageId:mainImage.democoverphoto
            },
        ],
        address:[
            {
                brev:'TH',
                value:''
            }
        ],
        location:{latitude:'',longitude:''},
        enterprise:true,// เป็นรูปแบบบริษัทหรือไม่
        contact:[], // ทำเหมือน payment
        other:[], // ใส่ที่เหลือที่คิดออกทีหลัง
        timestamp:new Date(),
        marketType:[], // หมวดหมู่ของตลาด เช่น ตลาดเช้า ตลาดเย็น หน้าบ้าน งานวัด เป็นต้น
        status:false, // ค้นหาเจอ หรือค้นหาไม่เจอ
    }
  

