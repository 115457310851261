import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { db } from "../db/firestore";
import { diffArray, endCutoff, mapInArray, startCutoff, workingDay, findInArray } from '../Utility/function';
import { NumberYMD, minusDays, stringYMDHMS, stringYMDHMS3 } from '../Utility/dateTime';


const initialState = {
    shops:[],
    modal_Shop:false,
    bills:[],
    billDates:[],
    normalBill:[],
    voidedBill:[],
    graph:[],
    saleByChannel:[],
    payment:[],
    result:[],
    product:[],
    category:[],
    saleMan:[],
    selectedBill:[],
    selectedVoidedBill:[],
    modal_Bill:false,
    products:[],
    shifts:[],
    shiftDates:[],
    modal_Shift:false,
    todayBills:[],
    modal_TodayBill:false,
    selectedShop:{},
    branchs:[],
    modal_Branch:false,
    selectedBranch:{},
    profiles:[],
    modal_Profile:false,
    orders:[],
    billOrder:[],
    modal_Order:false,
    currentOrder:{thisOrder:[],status:false}
}


// fetch order
export const fetchOrder = createAsyncThunk(
  'branch/fetchOrder',
  async ({ billId, documentIds, receiptNumber }) => {
    let data = [];

    try {
      const fetchPromises = documentIds.map(async (docId) => {
        const docRef = db.collection('order').doc(docId);
        const docSnapshot = await docRef.get();

        if (docSnapshot.exists) {
          return {
            ...docSnapshot.data(),
            id: docSnapshot.id,
            billId,
            timestamp:docSnapshot.data().timestamp.toDate()
          };
        } else {
          console.warn(`Document with ID ${docId} does not exist.`);
          return null;
        }
      });

      const documentsData = await Promise.all(fetchPromises);

      data = documentsData.filter((doc) => doc !== null);
      // setDocuments(filteredDocuments);
    } catch (error) {
      console.error('Error fetching documents: ', error);
    }

    return {data,billId,receiptNumber};
  }
);


// fetch bill
export const fetchBill = createAsyncThunk(
    'admin/fetchBill',
    async ({ shopId, billDate, cutOff }) => {
      let data = [];
  
      // Split billDate into chunks of 10 elements each
      const chunkSize = 10;
      const billDateChunks = [];
      for (let i = 0; i < billDate.length; i += chunkSize) {
        billDateChunks.push(billDate.slice(i, i + chunkSize));
      }
  
      // Use Promise.all to make multiple queries
      const promises = billDateChunks.map(async (chunk) => {
        const query = db.collection('bill')
          .where("shopId", "==", shopId)
          .where('billDate', 'in', chunk);
  
        try {
          const qsnapshot = await query.get();
          if (qsnapshot.docs.length > 0) {
            qsnapshot.forEach((doc) => {
              data.push({ id: doc.id, ...doc.data(), timestamp: doc.data().timestamp.toDate() });
            });
          } else {
            console.log('No items found for chunk:', chunk);
          }
        } catch (err) {
          console.error('Error:', err);
        }
      });
  
      // Wait for all queries to complete
      await Promise.all(promises);
  
      return { data, billDate, cutOff };
    }
  );

  // fetchBranch
export const fetchBranch = createAsyncThunk(
  'branch/fetchBranch',
  async (id) => {
    let data = []
    await db.collection('branch').where('humanResource', 'array-contains', id).get().then((docs)=>{
      docs.forEach((doc)=>{data.push({id:doc.id,...doc.data(),timestamp:doc.data().timestamp.toDate()})})
    })
    return data;
  }
);


  // fetch fetchTodayBills
export const fetchTodayBills = createAsyncThunk(
    'admin/fetchTodayBills',
    async ({ shops }) => {
      let data = [];
  
      // Split shops into chunks of 10 elements each
      const chunkSize = 10;
      const shopChunks = [];
      
      for (let i = 0; i < shops.length; i += chunkSize) {
        shopChunks.push(shops.slice(i, i + chunkSize));
      }
  
      // Use Promise.all to make multiple queries
      const promises = shopChunks.map(async (chunk) => {
        const query = db.collection('bill')
          .where("billDate", "==", stringYMDHMS3(new Date()))
          .where('shopId', 'in', chunk);
  
        try {
          const qsnapshot = await query.get();
          if (qsnapshot.docs.length > 0) {
            qsnapshot.forEach((doc) => {
              data.push({ id: doc.id, ...doc.data(), timestamp: doc.data().timestamp.toDate() });
            });
          } else {
            console.log('No items found for chunk:', chunk);
          }
        } catch (err) {
          console.error('Error:', err);
        }
      });
  
      // Wait for all queries to complete
      await Promise.all(promises);
  
      return data;
    }
);

// fetch shops
export const fetchShops = createAsyncThunk(
  'branch/fetchShops',
  async ({ shops }) => {
    let data = [];

    try {
      const fetchPromises = shops.map(async (docId) => {
        const docRef = db.collection('shop').doc(docId);
        const docSnapshot = await docRef.get();

        if (docSnapshot.exists) {
          return {
            ...docSnapshot.data(),
            id: docSnapshot.id,
            cutOff:docSnapshot.data().cutOff.toDate()
          };
        } else {
          console.warn(`Document with ID ${docId} does not exist.`);
          return null;
        }
      });

      const documentsData = await Promise.all(fetchPromises);

      // Filter out null values for documents that don't exist
      const filteredDocuments = documentsData.filter((doc) => doc !== null);
      data = documentsData.filter((doc) => doc !== null);
      // setDocuments(filteredDocuments);
    } catch (error) {
      console.error('Error fetching documents: ', error);
    }

    return data;
  }
);

// fetch profiles
export const fetchProfiles = createAsyncThunk(
  'branch/fetchProfiles',
  async ({ humanResource }) => {
    let data = [];

    try {
      const fetchPromises = humanResource.map(async (docId) => {
        const docRef = db.collection('profile').doc(docId);
        const docSnapshot = await docRef.get();

        if (docSnapshot.exists) {
          return {
            ...docSnapshot.data(),
            id: docSnapshot.id,
          };
        } else {
          console.warn(`Document with ID ${docId} does not exist.`);
          return null;
        }
      });

      const documentsData = await Promise.all(fetchPromises);

      // Filter out null values for documents that don't exist
      data = documentsData.filter((doc) => doc !== null);
    } catch (error) {
      console.error('Error fetching documents: ', error);
    }
    return data;
  }
);


// fetch product
export const fetchProduct = createAsyncThunk(
  'product/fetchProduct',
  async (id) => {
    let data = []
    await db.collection('product').where("shopId", "==", id).get().then((docs)=>{
      docs.forEach((doc)=>{data.push({id:doc.id,...doc.data(),timestamp:doc.data().timestamp.toDate()})})
    })
    return data;
  }
);


  // fetch shift
export const fetchShift = createAsyncThunk(
  'product/fetchShift',
  async ({ shopId, shiftDate }) => {
    let data = [];

    // Split shiftDate into chunks of 10 elements each
    const chunkSize = 10;
    const billDateChunks = [];
    for (let i = 0; i < shiftDate.length; i += chunkSize) {
      billDateChunks.push(shiftDate.slice(i, i + chunkSize));
    }

    // Use Promise.all to make multiple queries
    const promises = billDateChunks.map(async (chunk) => {
      const query = db.collection('shiftHistory')
        .where("shopId", "==", shopId)
        .where('openDate', 'in', chunk);

      try {
        const qsnapshot = await query.get();
        if (qsnapshot.docs.length > 0) {
          qsnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data(), openTime: doc.data().openTime.toDate(), closeTime: doc.data().closeTime.toDate()  });
          });
        } else {
          console.log('No items found for chunk:', chunk);
        }
      } catch (err) {
        console.error('Error:', err);
      }
    });

    // Wait for all queries to complete
    await Promise.all(promises);

    return { data, shiftDate };
  }
);

export const updateFieldBranch = createAsyncThunk(
    'branch/updateFieldBranch', async ({doc,field}) => {
    await db.collection('branch').doc(doc).update(field)
    return {doc,field};
});

// add branch to firestore
export const addBranchToFirebase = createAsyncThunk(
  'branch/addBranchToFirebase',
  async (branch)=>{
      let newBranch = {}
      await db.collection('branch').add(branch).then((doc)=>{
          newBranch = { ...branch,id: doc.id}
      })
      return newBranch;
  }
);

// deleteBranch 
export const deleteBranch = createAsyncThunk(
  'branch/deleteBranch',
  async (id)=>{
      await db.collection('branch').doc(id).delete()
      return id
  }
);



export const branchSlice = createSlice({
  name: 'branch',
  initialState,
  reducers: {
    updateModal_Branch: state => {
      state.modal_Branch = !state.modal_Branch
    },
    updateSelectedShop: (state, action) => {
      state.selectedShop = action.payload
      state.bills = []
      state.normalBill = []
      state.voidedBill =[]
      state.billDates = []
      state.shifts = []
    },
    updateSelectedBranch: (state, action) => {
      state.selectedBranch = action.payload
    },
    updateDashBoard: (state, action) => {
        const { graph, saleByChannel, payment, result, product , category, saleMan, selectedBill, selectedVoidedBill } = action.payload;
        state.graph = graph
        state.saleByChannel = saleByChannel
        state.payment = payment
        state.result = result
        state.product = product
        state.category = category
        state.saleMan = saleMan
        state.selectedBill = selectedBill
        state.selectedVoidedBill = selectedVoidedBill
    },
    updateCurrentOrder: (state, action) => {
      state.currentOrder = action.payload
    },

  },
  extraReducers: builder => {
    builder.addCase(deleteBranch.pending, state => {
      state.modal_Branch = true
    })
    builder.addCase(deleteBranch.fulfilled, (state, action) => {
        state.branchs = state.branchs.filter(a=>a.id !== action.payload)
        state.modal_Branch = false
    })
    builder.addCase(deleteBranch.rejected, state => {
        state.modal_Branch = false
    })
    builder.addCase(updateFieldBranch.pending, state => {
      state.modal_Branch = true
    })
    builder.addCase(updateFieldBranch.fulfilled, (state, action) => {
      const { doc, field} = action.payload;
        state.branchs = mapInArray(state.branchs,'id',doc,{...state.selectedBranch,...field})
        state.selectedBranch = {...state.selectedBranch,...field}
        state.modal_Branch = false
    })
    builder.addCase(updateFieldBranch.rejected, state => {
      state.modal_Branch = false
    })
    builder.addCase(addBranchToFirebase.pending, state => {
      state.modal_Branch = true
    })
    builder.addCase(addBranchToFirebase.fulfilled, (state, action) => {
      state.branchs = [...state.branchs,action.payload]
      state.modal_Branch = false
    })
    builder.addCase(addBranchToFirebase.rejected, state => {
      state.modal_Branch = false
    })
    builder.addCase(fetchBill.pending, state => {
      state.modal_Bill = true
    })
    builder.addCase(fetchBill.fulfilled, (state, action) => {
        const { data, billDate, cutOff } = action.payload;
        let YMD = NumberYMD(minusDays(new Date(),1))
        let findThisDay = billDate.some((item)=>{return(Number(item)>=YMD)}) 
        if(findThisDay){
          const thisDayBills = data.filter((item)=>{return(Number(item.billDate )>=YMD)})
          state.bills = [...diffArray(state.bills,thisDayBills),...data]
          state.normalBill = [...diffArray(state.normalBill,thisDayBills.filter((item)=>{return(!Boolean(item.void))})),...data.filter((item)=>{return(!Boolean(item.void))})]
          state.voidedBill = [...diffArray(state.voidedBill,thisDayBills.filter((item)=>{return(item.void===true)})),...data.filter((item)=>{return(item.void===true)})]
          state.billDates = [...state.billDates,...billDate.filter((item)=>{return(Number(item)<YMD)})]  // เพื่อให้ของวันนี้ เกิดการ refresh เสมอ
        } else {
          state.bills = [...state.bills,...data]
          state.normalBill = [...state.normalBill,...data.filter((item)=>{return(!Boolean(item.void))})]
          state.voidedBill = [...state.voidedBill,...data.filter((item)=>{return(item.void===true )})]
          state.billDates = [...state.billDates,...billDate]  
        }
        state.modal_Bill = false
    })
    builder.addCase(fetchBill.rejected, state => {
        state.modal_Bill = false
    })
    builder.addCase(fetchBranch.pending, state => {
      state.modal_Branch = true
    })
    builder.addCase(fetchBranch.fulfilled, (state, action) => {
      state.branchs = action.payload
      state.modal_Branch = false
    })
    builder.addCase(fetchBranch.rejected, state => {
      state.modal_Branch = false
    })
    builder.addCase(fetchProfiles.pending, state => {
      state.modal_Profile = true
    })
    builder.addCase(fetchProfiles.fulfilled, (state, action) => {
      state.profiles = action.payload
      state.modal_Profile = false
    })
    builder.addCase(fetchProfiles.rejected, state => {
      state.modal_Profile = false
    })

    builder.addCase(fetchShops.pending, state => {
      state.modal_Shop = true
    })
    builder.addCase(fetchShops.fulfilled, (state, action) => {
      state.shops = action.payload
      state.modal_Shop = false
    })
    builder.addCase(fetchShops.rejected, state => {
      state.modal_Shop = false
    })
    
    builder.addCase(fetchTodayBills.pending, state => {
        state.modal_TodayBill = true
      })
    builder.addCase(fetchTodayBills.fulfilled, (state, action) => {
      state.todayBills = action.payload
      state.modal_TodayBill = false
    })
    builder.addCase(fetchTodayBills.rejected, state => {
      state.modal_TodayBill = false
    })
    builder.addCase(fetchProduct.pending, state => {
      state.modal_Shop = true
    })
    builder.addCase(fetchProduct.fulfilled, (state, action) => {
      state.products = action.payload
      state.modal_Shop = false
    })
    builder.addCase(fetchProduct.rejected, state => {
      state.modal_Shop = false
    })
    builder.addCase(fetchShift.pending, state => {
      state.modal_Shift = true
    })
    builder.addCase(fetchShift.fulfilled, (state, action) => {
        const { data, shiftDate } = action.payload;
        let YMD = NumberYMD(minusDays(new Date(),1))
        let findThisDay = shiftDate.some((item)=>{return(Number(item)>=YMD)}) 
        if(findThisDay){
          const thisDayShift = data.filter((item)=>{return(Number(item.openDate )>=YMD)})
          state.shifts = [...diffArray(state.shifts,thisDayShift),...data]
          state.shiftDates = [...state.shiftDates,...shiftDate.filter((item)=>{return(Number(item)<YMD)})]  // เพื่อให้ของวันนี้ เกิดการ refresh เสมอ
        } else {
          state.shifts = [...state.shifts,...data]
          state.shiftDates = [...state.shiftDates,...shiftDate]  
        }
        state.modal_Shift = false
    })
    builder.addCase(fetchShift.rejected, state => {
        state.modal_Shift = false
    })
    builder.addCase(fetchOrder.pending, state => {
      state.modal_Order = true
    })
    builder.addCase(fetchOrder.fulfilled, (state, action) => {
        const { data, billId, receiptNumber } = action.payload;
        state.orders = [...state.orders,...data]
        state.billOrder = [...state.billOrder,billId]
        state.modal_Order = false
        state.currentOrder = {thisOrder:data,status:true,receiptNumber}
    })
    builder.addCase(fetchOrder.rejected, state => {
        state.modal_Order = false
    })
  }
})

export const { updateModal_Branch, updateDashBoard, 
  updateSelectedShop, updateSelectedBranch, updateCurrentOrder
} = branchSlice.actions

export default branchSlice.reducer