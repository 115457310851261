import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { colors } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { stringFullTime } from "../Utility/dateTime";
import { Modal_NewRenter } from "../modal";
import { fetchRenter, fetchShop, setModal_Renter } from "../redux/renterSlice";
import Pagination from '@mui/material/Pagination';
import { allFilter, goToTop } from "../Utility/function";
import { normalSort, reverseSort } from "../Utility/sort";


function ShopRequestScreen() {
  const dispatch = useDispatch();
  const { renters_Pending, modal_Renter } = useSelector((state)=> state.renter);
  const { currentMarket } = useSelector((state)=> state.market);
  const { currentLandlord } = useSelector((state)=> state.landlord)

  const [masterDataSource, setMasterDataSource] = useState([])
  const [currentDisplay, setCurrentDisplay] = useState([]) // จำนวนที่แสดงในหนึ่งหน้า
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1)
  const shopPerPage = 12;

    useEffect(()=>{
      let fData = masterDataSource.filter((item,index)=>{return(index >=((page-1)*shopPerPage) && index <= (page*shopPerPage)-1)})
        setCurrentDisplay(fData)
    },[page,masterDataSource])

    useEffect(()=>{
      const data = reverseSort('createDate',allFilter(renters_Pending,'marketId',currentMarket.id));
      setMasterDataSource(data);
      let pages = Math.ceil(data.length/shopPerPage)
      setTotalPage(pages)
    },[renters_Pending])

    useEffect(()=>{
      if(allFilter(renters_Pending,'marketId',currentMarket.id).length == 0){
        dispatch(fetchRenter(currentLandlord.id))
      }
    },[])

    const handleChange = (event, value) => {
      setPage(value);
      goToTop()
    };

  return (
        <div className="container"  >
          <Modal_NewRenter
              show={modal_Renter}
              onHide={()=>{dispatch(setModal_Renter(false))}}
          />
        <Row style={{marginLeft:'auto',marginRight:'auto'}} >
  
        {currentDisplay.map((item, idx) => (
          <Col md='6' lg='4' style={{paddingLeft:5,paddingRight:5,marginBottom:10}} >
            <div style={{backgroundColor:colors.purplePeel,borderRadius:10,paddingBottom:10}}  >
              <img  src={item.shopCoverPhoto} style={{borderRadius:10,width:'100%',height:'auto'}} width="150" height="37.5" />
              <Row>
                <Col sm='4' style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
                    <img style={{width:'80%',borderRadius:'100%',height:'auto'}} width={150} height={150}  src={item.shopLogoPhoto} />
                </Col>
                <Col sm='8' >
                  <h4 style={{padding:0,margin:0}}>{item.shopName}</h4>
                </Col>
              </Row>
              <div style={{paddingLeft:5,paddingRight:5,paddingBottom:10}} >
                
              <Row>
                  <Col style={{fontSize:12}} >ยื่นใบสมัครโดย</Col>
                  <Col style={{fontSize:12,color:colors.darkGray}} >{item.name}</Col>
                </Row>
                <Row>
                  <Col style={{fontSize:12}} >ส่งคำขอเมื่อ</Col>
                  <Col style={{fontSize:12,color:colors.darkGray}} >{stringFullTime(new Date(item.createDate))}</Col>
                </Row>
                {/* <Row>
                  <Col style={{fontSize:12}} >ประเภทร้านค้า</Col>
                  <Col style={{fontSize:12,color:colors.darkGray}} >ร้านอาหาร</Col>
                </Row>
                <Row>
                  <Col style={{fontSize:12}} >ประเภทสินค้า</Col>
                  <Col style={{fontSize:12,color:colors.darkGray}} >อาหารตามสั่ง/เครื่องดื่ม</Col>
                </Row> */}
                {/* <Row>
                  <Col style={{fontSize:12}} >ที่อยู่</Col>
                  <Col style={{fontSize:12,color:colors.darkGray}} >กรุงเทพมหานคร</Col>
                </Row> */}
              </div>
                <div style={{width:'100%',display:'flex',justifyContent:'center',paddingLeft:10,paddingRight:10}} >
                  {/* <Button  style={{width:'45%',backgroundColor:colors.darkPurple,borderRadius:50,borderColor:colors.darkPurple}} >Accept</Button> */}
                  <Button onClick={()=>{dispatch(fetchShop({shopId:item.shopId,currentRenter:item}))}}  style={{width:'45%',backgroundColor:colors.softGray,borderRadius:50,borderColor:colors.softGray}} >See more</Button>
                </div>
            </div>
          </Col>
        ))}
          <Pagination 
            count={totalPage} 
            color="secondary" 
            onChange={handleChange}
            page={page}
          />
      </Row>
            
        </div>
  );
}

export default ShopRequestScreen;
