import React, { useContext } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { colors, contact, mainImage } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { findInArray } from "../Utility/function";
import { stringFullDate, stringFullTime } from "../Utility/dateTime";



function Modal_Order({
  backdrop=true, // true/false/static
  animation=true,
  onHide,
  centered=true,
  size='lg',
  currentOrder,
  selectedShop
}) {
  const dispatch = useDispatch();
  const { thisOrder, status, receiptNumber } = currentOrder;
  const { humanResource } = selectedShop;

  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={status}
      onHide={onHide}
      centered={centered}
      size={size}
    >
      <Modal.Header closeButton>
        <b>หมายเลขบิล : {receiptNumber}</b>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'transparent',maxHeight:'80vh',overflowY:'auto'}} >
        <Row>
        {thisOrder.map((item,index)=>{
          const { product, timestamp } = item;
            return(
                <Row>
                    <div>Order : {index+1} , เวลา {stringFullTime(timestamp)}  </div>
                    <Table striped bordered hover responsive  variant="light"   >
                      <thead  >
                      <tr>
                          <th style={{textAlign:'center', width: '10%'}} >No.</th>
                          <th style={{textAlign:'center', width: '20%'}} >name</th>
                          <th style={{textAlign:'center', width: '10%'}}>qty</th>
                          <th style={{textAlign:'center', width: '10%'}}>totalPrice</th>
                          <th style={{textAlign:'center', width: '10%'}}>status</th>
                          <th style={{textAlign:'center', width: '20%'}}>staff</th>
                          <th style={{textAlign:'center', width: '20%'}}>time</th>
                      </tr>
                      </thead>
                      <tbody  >
                      {product.map((a, i) =>{
                        let time = new Date()
                        let staff = ''
                        let status = 'ปกติ'
                        const { name, qty, totalPrice,  productStaffs } = a
                        let cancel = findInArray(productStaffs,'process','cancel')
                        if(cancel && cancel.id){
                          time = cancel.timestamp.toDate()
                          staff = findInArray(humanResource,'id',cancel.id)?.name||'ไม่ทราบชื่อ'
                          status = 'ยกเลิก'
                        } else {
                          let normal = findInArray(productStaffs,'process','ordered')
                          if(normal && normal.id){
                            time = normal.timestamp.toDate()
                            staff = findInArray(humanResource,'id',normal?.id)?.name||'ลูกค้า'
                          } else {
                            staff = 'ลูกค้า'
                            time = timestamp
                          }
                        }
                        return (
                          <tr  style={{cursor: 'pointer'}} key={i} >
                          <td style={{textAlign:'center', width: '10%'}} >{i+1}.</td>
                          <td style={{textAlign:'center', width: '20%'}} >{name}</td>
                          <td style={{textAlign:'center', width: '10%'}}>{qty}</td>
                          <td style={{textAlign:'center', width: '10%'}}>{totalPrice?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                          <td style={{textAlign:'center', width: '10%'}}>{status}</td>
                          <td style={{textAlign:'center', width: '20%'}}>{staff}</td>
                          <td style={{textAlign:'center', width: '20%'}}>{stringFullTime(time|| new Date())}</td>
                          </tr>
                        )
                      })}
                      </tbody>
                  </Table>
                </Row>
            )
        })}
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default Modal_Order;
