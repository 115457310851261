// import logo from './logo.svg';
// import './App.css';
import { Table, Container } from 'react-bootstrap';
import React, { useEffect, useContext } from "react";

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { MarketScreen, ShopListScreen, ShopRequestScreen, 
  LandlordScreen, MarketDashboardScreen, ShopListDetail, 
   ReportScreen, SettingScreen, MarketProfile,
  LoginScreen, 
  RegisterScreen,
  HomeScreen,
  LandlordProfile,
  Login,
  NewMarketScreen,
  TakeOrderScreen,
  ResReportScreen,
  DeliveryScreen,
  PaymentScreen,
  TempScreen,
  CheckOutScreen,
  ResHomeScreen,
  ResProductCategoryScreen,
  ResProductOptionScreen,
  ResProductWareHouseScreen,
  ResReportShiftScreen,
  ResInvoiceScreen,
  ResBOMWarehouse,
  ResRawMatScreen,
  ResAdminScreen,
  BranchHomeScreen,
  BranchScreen,
  BranchDashboardScreen,
  BranchReportScreen,
  BranchReportShiftScreen,
  BranchQrcodeScreen,
  TookHomeScreen,
  BranchShopListScreen,
  BranchHumanResource,
  RestableStaticOrder,
  BranchPortScreen,
  AdminScreen,
  AdminHomeScreen,
  AdminDashboardScreen,
  AdminReportScreen,
  AdminReportShiftScreen,
  PhoneAuth,
  Practise_CSV,
  ResSaleScreen,
  ResReportByCategory,
  ResReportByHour,
  ResReportByOption,
  ResReportByCustomer,
  ResReportByReceipt,
  ResReportByDay,
  ResReportByTax,
  ResReportByDiscount
} from './screens';
import { AuthContext, AuthProvider,
        ProfileProvider, LandlordProvider, 
        MarketProvider

} from './context';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from './redux/authSlice';
import {firebaseAuth, firebaseStorage} from './db/firestore'

import "react-datepicker/dist/react-datepicker.css";
import ReportDetail from './screens/ReportDetail';
import SummaryScreen from './screens/SummaryScreen';
import RestaurantScreen from './screens/RestaurantScreen';
import ResDashboardScreen from './screens/ResDashboardScreen';
// import ResTableOrder from './screens/ResTableOrder';
import { ResTableOrder } from './screens'
import Panda404 from './components/Panda404';


function App() {
  // const {
  //   state: { currentUser, loading },
  //   checkAuth,
  // } = useContext(AuthContext);

  // const auth = useSelector( state => state.auth);
  const { currentUser, loading } = useSelector( state => state.auth);
  
  const dispatch = useDispatch();

  // useEffect(()=>{
  //   const deleteFirebaseStorage = async (oldUrltoDelete='') => {
  //     const oldFileRef = await firebaseStorage.refFromURL(oldUrltoDelete).fullPath;
  //     // alert(oldFileRef);
      
  //     firebaseStorage.ref(oldFileRef).getDownloadURL()
  //       .then(()=>{
  //         firebaseStorage.ref(oldFileRef).delete();
  //         // alert('success delete file in firebaseStorage!')
  //       })
  //       .catch((err)=>{
  //         console.log('oldUrltoDelete file does not exits!')
  //         // alert('oldUrltoDelete file does not exits!')
  //         console.log(err);
  //       });   
  //   }

  //   deleteFirebaseStorage('https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/test%2Fxxx_02.jpeg?alt=media&token=7a9fc77e-26ae-4978-a698-a95690ca78cf');

  // },[]);

  // useEffect(() => {
  //   if (currentUser == null) {
  //     checkAuth();
  //   }
  //   if (currentUser !== null) {

  // }},[currentUser]);

  useEffect(() => {
    
    // alert('checkAuth')
    let unsubscribe;
    async function checkAuth (){
      unsubscribe = await firebaseAuth.onAuthStateChanged(user => {
        console.log('uid:'+user?.id)
        if(!!user?.uid){ // ไม่มี 
          dispatch(login(user));
        } else {
          let obj =  firebaseAuth.signInWithEmailAndPassword('anonymoususer@gmail.com', '123123')
          // let obj =  firebaseAuth.signInWithEmailAndPassword('siripongsrisukha@gmail.com', '123456')
          dispatch(login(obj));
        }
      })
    }

    if (currentUser == null) {
      checkAuth()
      // dispatch(logout())
    }
    if (currentUser !== null) {

    }

    return () => {
      // unsubscribe;   // error with close listener for prevent memory leak , will edit later !!!
    }
  },[currentUser]);
  
  return (
    <Router>
    {/* <div className="App"> */}
    <div >
      <Routes>
          {/* <Route path='/' element={<HomeScreen/>} /> */}
          <Route path='/' element={<HomeScreen/>} />
          {/* <Route path='/:shopId' element={<ReportDetail/>} /> */}
          <Route path='/:shopName' element={<TakeOrderScreen/>} />
          <Route path='/summary' element={<SummaryScreen/>} />
          <Route path='/404' element={<Panda404/>} />
  
          <Route path='payment' element={<CheckOutScreen/>} />
          <Route path='loginScreen' element={<LoginScreen/>} />
          <Route path='register' element={<RegisterScreen/>} />
          <Route path='landlord' element={<LandlordScreen/>} />
          <Route path='landlordProfile' element={<LandlordProfile/>} />
          <Route path='market' element={<MarketScreen/>} >
              <Route index  element={<MarketDashboardScreen/>} />
              <Route path='shoplist' element={<ShopListScreen/>} >
                  <Route path='shopdetail' element={<ShopListDetail/>} />
              </Route>
              <Route path='shoprequest' element={<ShopRequestScreen/>} />
              <Route path='marketdashboard' element={<MarketDashboardScreen/>} />
              <Route path='report' element={<ReportScreen/>} />
              <Route path='setting' element={<SettingScreen/>} />
              <Route path='profile' element={<MarketProfile/>} />
          </Route>
          <Route path='phone' element={<PhoneAuth/>} />
          <Route path='resHome' element={<ResHomeScreen/>} />
          <Route path='restaurant' element={<RestaurantScreen/>} >
              <Route index  element={<ResDashboardScreen/>} />
              <Route path='resDashboard' element={<ResDashboardScreen/>} />
              <Route path='resSale' element={<ResSaleScreen/>} />
              <Route path='resReport' element={<ResReportScreen/>} />
              <Route path='resReportShift' element={<ResReportShiftScreen/>} />
              <Route path='resProductCategory' element={<ResProductCategoryScreen/>} />
              <Route path='resProductOption' element={<ResProductOptionScreen/>} />
              <Route path='resProductWareHouse' element={<ResProductWareHouseScreen/>} />
              <Route path='resInvoice' element={<ResInvoiceScreen/>} />
              <Route path='resBom' element={<ResBOMWarehouse/>} />
              <Route path='resRawmat' element={<ResRawMatScreen/>} />
              <Route path='resReportByCategory' element={<ResReportByCategory/>} />
              <Route path='resReportByHour' element={<ResReportByHour/>} />
              <Route path='resReportByOption' element={<ResReportByOption/>} />
              <Route path='resReportByCustomer' element={<ResReportByCustomer/>} />
              <Route path='resReportByReceipt' element={<ResReportByReceipt/>} />
              <Route path='resReportByDay' element={<ResReportByDay/>} />
              <Route path='resReportByTax' element={<ResReportByTax/>} />
              <Route path='resReportByDiscount' element={<ResReportByDiscount/>} />

              
              
          </Route>
          <Route path='newMarket' element={<NewMarketScreen/>} />
          <Route path='table/:docId' element={<ResTableOrder/>} />
          <Route path='tableStatic/:docId' element={<RestableStaticOrder/>} />
          <Route path='delivery/:docId' element={<DeliveryScreen/>} />
          <Route path='branchPort' element={<BranchPortScreen/>} />
          <Route path='branch' element={<BranchScreen/>} />
          <Route path='branch/home' element={<BranchHomeScreen/>} >
              <Route index  element={<BranchDashboardScreen/>} />
              <Route path='dashboard' element={<BranchDashboardScreen/>} />
              <Route path='report' element={<BranchReportScreen/>} />
              <Route path='reportShift' element={<BranchReportShiftScreen/>} />
              {/* <Route path='branchShopList' element={<BranchShopListScreen/>} /> */}
              <Route path='human' element={<BranchHumanResource/>} />
          </Route>
          <Route path='took' element={<TookHomeScreen/>} />
          <Route path='csv' element={<Practise_CSV/>} />
          <Route path='admin' element={<AdminScreen/>} />
          <Route path='admin/home' element={<AdminHomeScreen/>} >
              <Route index  element={<AdminDashboardScreen/>} />
              <Route path='dashboard' element={<AdminDashboardScreen/>} />
              <Route path='report' element={<AdminReportScreen/>} />
              <Route path='reportShift' element={<AdminReportShiftScreen/>} />
          </Route>
      </Routes>
      </div>
    </Router>
  );
}

export default () => {
  return (
    <AuthProvider>
      <ProfileProvider>
        <LandlordProvider>
          <MarketProvider>
            <App />
          </MarketProvider>
        </LandlordProvider>
      </ProfileProvider>
    </AuthProvider>
  );
};

// import logo from './logo.svg';
// // import './App.css';
// import { Table, Container } from 'react-bootstrap';
// import { MarketScreen, ShopListScreen, ShopRequestScreen, LandlordScreen, MarketDashboardScreen, ShopListDetail } from './screens';
// import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';


// function App() {
//   return (
//     <Router>
//     <div className="App">
//       <Routes>
//           <Route path='/' element={<LandlordScreen/>} />
//           <Route path='market' element={<MarketScreen/>} >
//               <Route index  element={<MarketDashboardScreen/>} />
//               <Route path='shoplist' element={<ShopListScreen/>} />
//               <Route path='shopdetail' element={<ShopListDetail/>} />
//               <Route path='shoprequest' element={<ShopRequestScreen/>} />
//               <Route path='marketdashboard' element={<MarketDashboardScreen/>} />
//           </Route>
      
//       </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;


