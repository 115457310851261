import {
    fetchProfile as firebaseFetch,
    updateProfile as firebaseUpdate,
    addProfile as firebaseAdd,
} from '../db/firestore'

import createDataContext from './createDataContext';


const profileReducer = (state,action) => {
    switch(action.type){
        case 'fetchProfile':
            return action.payload;
        case 'updateProfile':
            return action.payload;
        default:
            return state;
    }
};

const addProfile = (dispatch) => async (data) => {
    const promise = new Promise(async (resolve,reject)=>{
        try{
            let profile = await firebaseAdd(data)
            dispatch({type:'fetchProfile',payload:profile})
            resolve(profile)
        }catch(err){
            reject(err)
        }
      })
    return promise
}

const fetchProfile = (dispatch) => async (id) => {
    const promise = new Promise(async (resolve,reject)=>{
        try{
            let profile = await firebaseFetch(id)
            dispatch({type:'fetchProfile',payload:profile})
            resolve(profile)
        }catch(err){
            reject(err)
        }
      })
    return promise
}
const updateProfile = (dispatch) => async (data) => {
    const promise = new Promise(async (resolve,reject)=>{
        try{
            firebaseUpdate(data.id,data)
            dispatch({type:'updateProfile',payload:data})
            resolve(data)
        }catch(err){
            reject(err)
        }
      })
      return promise
}


export const {Provider,Context} = createDataContext(
    profileReducer,
    {updateProfile,fetchProfile, addProfile},
  
    []
);