export default {  // ยอดขายรายวัน ของทุกร้านที่ยังเป๋นสมาชิกของตลาด  ซึ่งจะต้องทำก้อนยอดขายของทุกร้าน แม้จะไม่มียอดแล้วก็ตาม
    landlordId:'',
    marketId:'',
    shopId:'',
    scShopId:'',
    shopName:'',
    shopEmail:'', // เอาไว้ส่ง noti และกล่องข้อความ
    shopTel:'', // เอาไว้โทรไปสอบถาม
    sales:[ // เอาเฉพาะออเดอร์ที่ไม่ถูก void
        {
            id:1,
            type:'dinein',
            order:'', // จำนวนออเดอร์
            payment:[ // ช่องทางการชำระเงิน
                {
                    id:1,
                    type:'cash',
                    amount:100
                }
            ]
        },
        {
            id:2,
            type:'salepage',
            order:'',

        },
        {
            id:3,
            type:'grab',
            order:'',

        },
    ],
    zone:[],
    timestamp:new Date(),
    date:20230616,
    others:[],
    orders:1, // จำนวนออเดอร์รายวัน
    net:0, // ยอดขายรายวัน
}