import React, { useState, useConh1, useEffect, useRef } from "react";
import {useParams} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addCurrentProduct, addOrderToFirebase, clearCart, deleteProductInCart, deleteSomeCurrentCart, fetchHistoryOrder, fetchProductOrder, fetchShopOrder, updateCurrentCart, updateCurrentProduct, updateLoadingOrder, updateModal_Order, updateProductInCart } from "../redux/orderSlice";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink, useNavigate } from 'react-router-dom';
import { bath, colors, ink, mainImage } from "../configs";
import { BsCircle, BsFillCircleFill } from "react-icons/bs";

import {
    Button, 
    Form,
    Row,
    Col,
    Container,
    Table,
    Modal,
    Card,
    Image,
    Collapse,
    InputGroup,
    OverlayTrigger,
    Tooltip,
    Carousel
  } from "react-bootstrap";
import { filterChoice, checkCategory, compareArrays, manageCategory, someInArray, summary, findInArray, findTotalPrice, leanProduct } from "../Utility/function";
import { Modal_Confirm, Modal_HungraPanda, Modal_Loading, Modal_LoadingPanda, Modal_Success, Modal_Test } from "../modal";
import { BorderHorizontal } from "../components";
import { reverseSort } from "../Utility/sort";
import { getHoursMinute } from "../Utility/dateTime";
import '../styles/CartScreen.css';
import { pushByOneFilter, pushByTwoFilter } from '../api/onesignal';
import { db } from "../db/firestore";




function SummaryScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [option, setOption] = useState('รายการที่กำลังสั่ง')
  const options = ['รายการที่กำลังสั่ง','ประวัติการสั่ง']
  const { tableData, cart, orderHistory, loading_Order, productInHistory, linkId, type, limitItems } = useSelector((state)=> state.order);
  const {  tableName,  mainId, shopId, scShopId, tableId, smartChef   } = tableData;
  const [delete_Modal, setDelete_Modal] = useState(false)
  const initialShopProduct = {tempId:'',status:false};
  const [showProduct, setShowProduct] = useState(initialShopProduct)
  const { tempId, status } = showProduct;
  const [product, setProduct] = useState({})
  const [success_Modal, setSuccess_Modal] = useState(false)
  const [noOrder_Modal, setNoOrder_Modal] = useState(false)
  const [isInOrderProcess,setIsInOrderProcess] = useState(false);

  function onDelete(item){
    if(item.qty >1){
      dispatch(updateProductInCart({...item,qty:item.qty-1,totalPrice:findTotalPrice(item.option,mainId,item.price,item.qty-1)}))
    } else {
      setProduct(item)
      setDelete_Modal(true)
    }
  } 

  useEffect(() => {  // เอาไว้กำหนด display ของ sidebar
    // Function to update the window width state
    const handleResize = () => {
      console.log(window.innerWidth);
    };

    // Event listener to update the window width state on resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [delete_Modal]);

  useEffect(()=>{
    if(option==='ประวัติการสั่ง'){
      dispatch(fetchHistoryOrder({shopId,tableId}))
    }
  },[option]);


  const addDocumentWithCondition = (existingDocId, products, limited, newDocumentData) => {
    const collectionRef = db.collection('order').doc(existingDocId);
  
    return db.runTransaction(async (transaction) => {
      const doc = await transaction.get(collectionRef);
      let remainLimit = doc.data()?.remainLimit || limited
  
      const { limitedProduct, newProduct, content } = checkLimitProduct(remainLimit, products);
        console.log('limitedProduct')
        console.log(limitedProduct)
        if(newProduct.length>0){
            transaction.update(collectionRef, { remainLimit: limitedProduct });
            await db.collection('order').add({ ...newDocumentData, product: newProduct });
            return content
        } else {
            throw new Error('cannot order anymore');
        }
      
    })
      .then((newDocRef) => {
        return newDocRef
      })
      .catch((error) => {
        console.error('Transaction failed:', error);
        return null; // or handle the error accordingly
      });
  };
  function submit(){
    if(cart.length===0){
        setNoOrder_Modal(true)
        setTimeout(()=>{setNoOrder_Modal(false); navigate(-1)},1500)
    } else {
        let newOrder = {
            shopId,
            scShopId,
            channelId:tableId,
            product:cart.map(item=>leanProduct(item)),
            timestamp:new Date(),
            staff:[],
            process:'ordered',
            isPrinted:false,
            arrProcess:['ordered'], // add from first daft
            customerQty:1,  // add from first daft
        }
        if(limitItems.length>0){
            dispatch(updateLoadingOrder(true))
            let limitProducts = limitItems.slice()
            let { id } = findInArray([...orderHistory],'type','buffet')
            addDocumentWithCondition(id, cart.map(item=>leanProduct(item)), limitProducts, newOrder)
            .then(result => {
                if (result !== null) {
                  // Document updated and new document added successfully
                    if(smartChef){
                        for(const item of smartChef){
                        pushByTwoFilter({id:item,shopId,content:`สั่งอาหาร ${cart.length} รายการ`,heading:tableName})
                    }
                    }
                    setSuccess_Modal(true)
                    if(result){
                        alert(result)
                    }
                } else {
                  // Handle the case when the document is not updated or added
                  alert('กรุณาสั่งใหม่อีกครั้ง ในรายการที่ไม่จำกัดจำนวน')
                }
                dispatch(clearCart())
                setTimeout(()=>{setSuccess_Modal(false); navigate(-1)},1500)
              });
        } else {
            dispatch(addOrderToFirebase(newOrder)).then(async ()=>{
                if(smartChef){
                    for(const item of smartChef){
                      pushByTwoFilter({id:item,shopId,content:`สั่งอาหาร ${cart.length} รายการ`,heading:tableName})
                  }
                }
                setSuccess_Modal(true)
                setTimeout(()=>{setSuccess_Modal(false); navigate(-1)},1500)
            })
        }
    }
};

function checkLimitProduct(remainQuota,products){
    const limitedProduct = remainQuota.map(obj => ({ ...obj }));
    let newProduct = []
    let content = ''
    for(const item of products){ // products ทั้งหมดที่ต้องการสั่ง
        let findProduct = limitedProduct.find(a=>a.id===item.id)
        if(findProduct && findProduct.id){ // จำกัดโควต้า
            if(findProduct.remain>0.99){ // ยังเหลือให้สั่งได้
                if(findProduct.remain>=item.qty){ // เหลือมากกว่าที้่ต้องการสั่ง
                    newProduct.push(item)
                    findProduct.remain = findProduct.remain - item.qty
                } else { // เหลือน้อยกว่าที่ต้องการสั่ง
                    newProduct.push({...item,qty:findProduct.remain})
                    content += `\n${item.name} สั่งได้เพียง ${findProduct.remain} หน่วย`
                    findProduct.remain = 0
                }
            } else {
                content += `\n${item.name} สั่งถึงจำนวนสูงสุดแล้ว`
            }
        } else { // ไม่จำกัดโควต้า
            newProduct.push(item)
        }
    }
    return {limitedProduct,newProduct,content}
}

    const checkDocumentExists = async () => {
        setIsInOrderProcess(true);

        if(type==='static'){
            submit()
        } else {
            try {
                const documentRef = db.collection('qrcodeOrder').doc(linkId);
                const documentSnapshot = await documentRef.get();
            
                if (documentSnapshot.exists) {
                    console.log('Document exists!');
                    submit()
                    // Do something if the document exists
                } else {
                    navigate(`/404`)
                    console.log('Document does not exist.');
                    // Do something if the document does not exist
                }
                } catch (error) {
                console.error('Error checking document:', error);
                }
        }
        
    };

  return (
    // <div className="d-flex flex-column min-vh-100 ">
    <div style={{width:'calc(100% - 12px)',minHeight:'100vh',display:'flex',flexDirection:'column'}} >
        <Modal_LoadingPanda show={loading_Order} />
        {/* <Modal_LoadingPanda show={true} /> */}
        <Modal_Success show={success_Modal} />
        <Modal_HungraPanda show={noOrder_Modal} />

        <Modal_Test 
          screen='SummaryScreen' 
          visible={status}
          onRequestClose={()=>{setShowProduct(initialShopProduct)}}
          tempId={tempId}
        />
        <Modal_Confirm
            show={delete_Modal}
            onHide={()=>{setDelete_Modal(false)}}
            content={`ยืนยันการลบ "${product.name}" ออกจากตะกร้า`}
            // backdrop='static'
            onClick={()=>{
                setDelete_Modal(false);
                dispatch(deleteProductInCart(product.tempId))
            }}
        />
        <header className="sticky-top bg-light ">
          <Row  >
              {options.map((item,index)=>{
                  return(
                  <Col onClick={()=>{setOption(item)}} key={index} sx='6' 
                  style={{display:'flex',justifyContent:'center',alignItem:'center',backgroundColor:option===item?colors.softGray:colors.white,padding:'0.75rem',cursor:'pointer',color:option===item?colors.white:colors.darkGray}}  >
                      {item}
                  </Col>
                  )
              })}
          </Row>
        </header>
        <div  className='cart2' onClick={()=>{navigate(-1)}} >
            <div   style={{position:'absolute',bottom:60,right:20,padding:10,borderRadius:20,backgroundColor:colors.redOrange,color:colors.white,display:'flex',flexDirection:'column',alignItems:'center',cursor:'pointer',width:'70px'}} >
                <p style={{padding:0,margin:0}} >+</p>
                สั่งเพิ่ม
            </div>
        </div>
        {/* <div className='cart' onClick={()=>{navigate(-1)}} style={{position:'absolute',bottom:60,right:20,padding:10,borderRadius:20,backgroundColor:colors.redOrange,color:colors.white,display:'flex',flexDirection:'column',alignItems:'center',cursor:'pointer'}} >
            <p style={{padding:0,margin:0}} >+</p>
            สั่งเพิ่ม
        </div> */}
        {option==='ประวัติการสั่ง'
            ?<div style={{backgroundColor:colors.blueBackground,width:'calc(100vw - 4px)'}} >
              <div className="flex-grow-1 overflow-auto p-1" style={{marginBottom:'3rem'}} >
              {reverseSort('timestamp',[...orderHistory]).map((item,index)=>{
                    return(
                        <div key={index} style={{display:'flex',backgroundColor:colors.white,flexDirection:'row',margin:10,padding:10,borderRadius:10,marginBottom:0,paddingLeft:0,alignItems:'center'}} >
                            <p style={{paddingLeft:15,color:colors.redOrange}} >{getHoursMinute(item.timestamp)}</p>
                            <div style={{borderLeftWidth:1,height:'90%',marginLeft:5,borderColor:colors.theme3}} />
                            <div style={{flex:1}} >
                                {item.product.map((a,i)=>{
                                    return(
                                        <div key={i} style={{backgroundColor:colors.white,padding:10}}  >
                                            <div  style={{display:'flex',flexDirection:'row',justifyContent:'space-between',paddingHorizontal:15}} >
                                                <div style={{display:'flex',flexDirection:'row',marginRight:10,flex:1}} > 
                                                {a.process==='cancel'
                                                    ?<h6 style={{display:'flex',flex:1,textDecorationLine:'line-through',textDecorationColor:colors.redPink,color:colors.redPink}} ><b>{i+1}. {a.name} </b></h6>
                                                    :<h6 style={{display:'flex',flex:1}} ><b>{i+1}. {a.name} </b></h6>
                                                }
                                                    <h6 style={{color:colors.purple}} >x {a.qty}</h6>
                                                </div>
                                                <h6 style={{color:colors.theme2}} >{a.totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</h6>
                                            </div>
                                            {a.addOn
                                                ?<h6 style={{paddingLeft:15}} >เพิ่มเติม : {a.addOn}</h6>
                                                :null
                                            }
                                            <div style={{ width: '100%', overflowX: 'auto', display:'flex' }} className="custom-scrollbar" >
                                                {filterChoice(a.option).map((x,y)=>{
                                                    return(
                                                        <div key={y} style={{padding:5,borderRadius:10,backgroundColor:colors.purplePeel,marginRight:5}} >
                                                            <h6  >{x.choiceName}</h6>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
              </div>
                
            
              <footer className="bg-light position-fixed bottom-0 w-100">
                <div style={{padding:10,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:colors.softGray}} >
                    ยอดสินค้าโดยประมาณ : {summary(productInHistory,'totalPrice').toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}
                </div>
              </footer>
            </div>
            :<div>
                <div className="flex-grow-1 overflow-auto p-3" style={{marginBottom:'2rem'}} >
                  {/* Your scrollable body content goes here */}
                  {cart.map((item,index)=>{
                    const { imageId, name, totalPrice, qty, tempId, price, option, addOn } = item;
                      return(
                          <Row key={index} style={{borderBottom:`1px solid ${colors.lightGray}`,paddingTop:'1rem',paddingBottom:'1rem'}} >
                              <Col xs='3' style={{display:'flex',justifyContent:'center',alignItems:'flex-start'}} >
                                  <img style={{width:'100%',height:'auto',borderRadius:'1rem'}} src={imageId||mainImage.reslogo} />
                              </Col>
                              <Col xs='9' >
                                  <p><b>{name}</b></p>
                                  <p><b>{(totalPrice/qty).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}  {bath} x {qty}</b></p>
                                  {option.length >0
                                      ?filterChoice(option).map((a,i)=>{
                                          return(
                                            <Row key={i} >
                                                <Col xs='6' style={{paddingLeft:20}} ><BsFillCircleFill style={{color:colors.primaryColor, fontSize: '1rem'}} />  {a.choiceName}</Col>
                                                <Col xs='6' style={{display:'flex',justifyContent:'flex-end',paddingRight:'1rem'}} ><b>+ ฿{findInArray(a.price,'id',mainId)?.price||0}</b></Col>
                                            </Row>
                                          )
                                      })
                                      :null
                                  }
                                  {addOn
                                      ?<div>
                                          เพิ่มเติม : {addOn}
                                      </div>
                                      :null
                                  }
                                  <Row>
                                      <Col xs='6' >ราคารวม</Col>
                                      <Col xs='6' style={{display:'flex',justifyContent:'flex-end',paddingRight:'1rem'}} ><b>{item.totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})} {bath}</b></Col>
                                  </Row>
                                  <Row>
                                      <Col xs='6' style={{display:'flex'}} >
                                          <div onClick={()=>{setShowProduct({tempId:tempId,status:true})}} style={{padding:'0.5rem',borderRadius:10,backgroundColor:colors.lightGray,paddingTop:0,paddingBottom:0,cursor:'pointer',display:'flex'}} >
                                              <i class="bi bi-pencil-square"> </i>
                                              แก้ไข
                                          </div>
                                          <div onClick={()=>{setProduct(item);setDelete_Modal(true)}}  style={{padding:'0.5rem',borderRadius:10,backgroundColor:colors.lightGray,paddingTop:0,paddingBottom:0,marginLeft:'1rem',cursor:'pointer',display:'flex'}} >
                                              <i class="bi bi-trash3"> </i>
                                              ลบ
                                          </div>
                                      </Col>
                                      {/* <Col xs='6' style={{display:'flex',justifyContent:'flex-end',paddingRight:'1rem'}} >
                                          <i onClick={()=>{onDelete(item)}} style={{fontSize:'20px',cursor: 'pointer'}} class="bi bi-dash-circle-fill"></i>
                                          <div style={{paddingLeft:'1rem',paddingRight:'1rem',fontSize:'15px',paddingTop:'5px'}} >
                                          {item.qty}
                                          </div>
                                          <i onClick={()=>{dispatch(updateProductInCart({...item,qty:qty+1,totalPrice:findTotalPrice(option,mainId,price,qty+1)}))}} style={{fontSize:'20px',cursor: 'pointer'}}  class="bi bi-plus-circle-fill"></i>
                                      </Col> */}
                                  </Row>
                              </Col>
                          </Row>
                      )
                  })}
                </div>
                <footer className="bg-light position-fixed bottom-0 w-100">
                  <Row>
                      <Col xs='6' style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}} >
                      <h3 style={{padding:0,margin:0}} ><b>{summary(cart,'totalPrice').toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})} {bath}</b></h3>
                      ราคาทั้งหมด
                      </Col>
                      {!isInOrderProcess?
                        <Col xs='6' onClick={checkDocumentExists} style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:colors.redPink,padding:'1rem',color:colors.white,cursor:'pointer'}} >
                            ส่งสินค้า
                        </Col>
                        :
                        <Col xs='6' style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:colors.softGray,padding:'1rem',color:colors.white,cursor:'pointer'}} >
                            ส่งสินค้า
                        </Col>
                      }
                  </Row>
                </footer>
            </div>
        }
    </div>
  );
}

export default SummaryScreen;
