import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Card } from "react-bootstrap";
import '../styles/ShopListScreen.css'
import * as loadingpanda from '../loadingpanda.json'
import { ink, colors, mainImage } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { compareArrays, filterDeleteInArray, filterInArray, findInArray, findTotalPrice, leanProduct, mapInArray, someInArray, summary } from "../Utility/function";
import { addOrderToFirebase } from "../redux/orderSlice";
import { v4 as uuidv4 } from 'uuid';
import { pushByOneFilter, pushByTwoFilter } from '../api/onesignal';




function Modal_PreOrder({
  backdrop='static', // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='md',
  value,
  success,
}) {
  const dispatch = useDispatch();

  const { tableData, products, cart, orderHistory, } = useSelector((state)=> state.order);
  const { scShopId, shopId, shopName, shopLogo, tableId, smartChef,
    tableName, smartCategory, smartOption, mainId, smartStep } = tableData;
 
    const [totalRound, setTotalRound] = useState(1);
    const [currentRound, setCurrentRound] = useState(1);



    const [selectedId, setSelectedId] = useState([])  // ศูนย์รวมไอดีที่ถูกเลือก
    const [selectedChoice, setSelectedChoice] = useState({value:{minQty:0}});
    const { value:{minQty} } = selectedChoice;
    const [allValue, setAllValue] = useState([]);
    const initialJourney = {
        header:'',
        display:[],
        product:[],
        type:'choice',
        progress:[],
        selected:[
            // {
            //     name:'',
            //     id:'', 
            // },
            // {
            //     id:'',
            //     qty:'',
            // }
        ], // สิ่งที่ถูกเลือก
        round:1
    }
    const [journey, setJourney] = useState([]);
    const [currentJourney, setCurrentJourney] = useState(initialJourney);
    const { header, display, product, type, progress, selected } = currentJourney;

    useEffect(()=>{
        if(show){
            const { name, steps } = smartStep
            setCurrentJourney(
                {
                    header:name,
                    display:steps[0]?.value||[],
                    product:[],
                    type:'choice',
                    progress:[],
                    selected:{},
                    round:1
                }
            )
            let res = []
            for(const item of steps){
                res = [...res,...item.value]
            }
            setAllValue(res)
        }
    },[show])



    function checkId(id){
        if(type==='choice'){  // แปลว่าเลือกได้แค่ 1 อันเท่านั้น
            setSelectedId(id)
        } else {
            if(someInArray(selectedId,'id',id)){
                setSelectedId(selectedId.filter(a=>a!==id))
            } else {
                if(selectedId.length===2){
                    setSelectedId([...selectedId,id].slice(1,2+1))
                } else {
                    setSelectedId([...selectedId,id])
                }
            }
        }
    }

    function manageProduct(id,type){
        let { value:{ maxQty }} = selectedChoice;
        let findProduct = findInArray(selected,'id',id)
        if(type==='plus'){
            if(summary(selected,'qty')===maxQty){
                alert('คุณเลือกถึงจำนวนสูงสุดแล้ว')
            } else {
                if(findProduct && findProduct.id){
                    setCurrentJourney({...currentJourney,selected:mapInArray(selected,'id',id,{id,qty:findProduct.qty+1})})
                } else {
                    setCurrentJourney({...currentJourney,selected:[...selected,{id,qty:1}]})
                }
            }
        } else {
            let newQty = findProduct.qty - 1
            if(newQty===0){
                setCurrentJourney({...currentJourney,selected:filterDeleteInArray(selected,'id',id)})
            } else {
                setCurrentJourney({...currentJourney,selected:mapInArray(selected,'id',id,{id,qty:newQty})})
            }
        }
    }


    function submit(){
        let { id, nextTopic, value:{ status, product, name }} = selectedChoice;
        if(type==='choice'){
            if(selectedId.length===0){
                alert('กรุณาเลือก 1 รายการ')
            } else {
                let newProgress = [...progress,id]
                setJourney([...journey,{...currentJourney,selected:{name:header,id}}])

                if(status){  // แปลว่ามีสินค้าในตัวเลือกนี้
                    setCurrentJourney({
                        header:name,
                        display:product,
                        product:[],
                        type:'product',
                        progress:newProgress,
                        selected:[],
                        round:currentRound
                    })
                } else {
                    let result = []
                    for(const item of allValue){
                        if(newProgress.length >=1){
                            if(compareArrays(item.aboveId,newProgress)){
                                result.push(item)
                            }
                        } else {
                            result.push(item)
                        }
                    }
                    if(result.length>0){
                        setCurrentJourney({
                            header:nextTopic,
                            display:result,
                            product:[],
                            type:'choice',
                            progress:newProgress,
                            selected:[],
                            round:currentRound
                        })
                    } else {
                        finished([...journey,currentJourney])
                    }
                    
                }
            }
        } else {
            setJourney([...journey,currentJourney])
            let result = []
            for(const item of allValue){
                if(progress.length >=1){
                    if(compareArrays(item.aboveId,progress)){
                        result.push(item)
                    }
                } else {
                    result.push(item)
                }
            }
            if(result.length>0){
                setCurrentJourney({
                    header:nextTopic,
                    display:result,
                    product:[],
                    type:'choice',
                    progress,
                    selected:[],
                    round:currentRound
                })
            } else {
                finished([...journey,currentJourney])
            }
            
            
        }
    }


    function manageBack(){
        if(journey.length===0){
            setJourney([])
        } else {
            setCurrentJourney(journey[journey.length-1])
            setJourney(journey.slice(0, -1))
            setCurrentRound(journey[journey.length-1].round)
        }
    };

    function finished(data){
        if(totalRound===currentRound){
            let thisProduct = filterInArray(data,'type','product')
            let res = []
            for(const item of thisProduct){
                res = [...res,...item.selected]
            }
                let newCart = []
                for(const item of res){
                    const { id, qty } = item;
                    let findProduct = findInArray(products,'id',id)
                    if(findProduct && findProduct.id){
                        const { option } = findProduct;
                        let product = findProduct;
                        if(smartOption && option.length >0){
                            let newOption = []
                            for(const item of option){
                                let data = {...findInArray(smartOption,'optionId',item)};
                                if(data && data.optionId){
                                    newOption.push(data)
                                }
                            }
                            product = {...findProduct,option:newOption,addOn:''}
                        } else{
                            product = {...findProduct,addOn:''}
                        }
                        newCart.push({
                            ...product,
                            qty,
                            totalPrice:findTotalPrice(product.option,mainId,product.price,qty),
                            tempId:uuidv4(),
                            process:'ordered',
                            diffQty:0,
                            productStaffs:[],
                            special_Free:{id:0}, // แปลว่าว่างเปล่า = เป็นสินค้าปกติ
                            special_Discount:{},
                        })
                    }
                    
                }
                let newOrder = {
                    shopId,
                    scShopId,
                    channelId:tableId,
                    product:newCart.map(item=>leanProduct(item)),
                    timestamp:new Date(),
                    staff:[],
                    process:'ordered',
                }

                console.log('newOrder')
                console.log(newOrder)
            
                dispatch(addOrderToFirebase(newOrder)).then(async ()=>{
                    if(smartChef){
                        for(const item of smartChef){
                          pushByTwoFilter({id:item,shopId,content:`สั่งอาหาร ${newCart.length} รายการ`,heading:tableName})
                      }
                    }
                    success()
                })
        } else {
            const { name, steps } = smartStep
            setCurrentJourney(
                {
                    header:name,
                    display:steps[0].value,
                    product:[],
                    type:'choice',
                    progress:[],
                    selected:{},
                    round:currentRound+1
                }
            )
            setCurrentRound(prev=>prev+1)
        }
    }

  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      className='loading-screen'
      size={size}
    >
      <Modal.Header  >
        <h3>{header}</h3>
      </Modal.Header>
      <Modal.Body 
      style={{
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        zIndex: 999,
        background: colors.transparent,
        borderRadius: 30,
        overflowY: 'auto',  // Allow vertical scrolling
        // height:400,
        maxHeight: `70vh`, // Adjust the maximum height of the body

      }}
      >

        {totalRound>1
            ?<h3 style={{fontSize:25,padding:10,textAlign:'center'}} >{currentRound}/{totalRound}</h3>
            :null
        }
        {type==='choice'
            ?<Row>
                {display.map((item,index)=>{
                     let status = selectedChoice.id===item.id
                    return(
                        <Col xs='6' sm='6' key={index} onClick={()=>{checkId(item.id);setSelectedChoice(item)}} 
                        style={{display:'flex',flexDirection:'column',alignItems:'center',
                        cursor:'pointer',backgroundColor:status?colors.purpleTransparent:colors.white,
                        color:status?colors.white:colors.dark,borderRadius:20}}  >
                            <img style={{width:100,height:100,borderRadius:20}}  src={item.imageId||mainImage.reslogo} />
                            <p>{item.choiceName}</p>
                        </Col>
                    )
                })}
            </Row>
            :<Row>
            {display.map((item,index)=>{
                let have = someInArray(selected,'id',item)
                const { name, imageId, status } = findInArray(products,'id',item)||{name:'',imageId}
                if (status !== 'available') {  // ตัวที่หมดแล้ว จะถูกปิดไป
                    return null
                }
                return(
                    <Col key={index} style={{display:'flex',flexDirection:'column',alignItems:'center'}}   >
                        <img onClick={()=>{manageProduct(item,'plus')}}  style={{width:100,height:100,bordreRadius:20}} src={imageId||mainImage.logo} />
                        {name}
                        {have
                            ?<Row style={{backgroundColor:colors.redPinkTransparent,position:'absolute',borderRadius:20}} >
                                <Col onClick={()=>{manageProduct(item,'minus')}} style={{zIndex:999}} >
                                    <i  style={{fontSize:'30px',cursor: 'pointer'}} class="bi bi-dash-circle-fill"></i>
                                </Col>
                                <Col  >
                                    <h3 style={{fontSize:30,width:'32px'}} >{findInArray(selected,'id',item)?.qty}</h3>
                                </Col>
                            </Row>
                            :null
                        }
                    </Col>
                )
            })}
        </Row>
           
        }
        
        <Row  >
            <Col onClick={manageBack} style={{padding:10,backgroundColor:colors.purple,borderRadius:20,margin:20,color:colors.white,cursor:'pointer'}} >
                <h5  >ย้อนกลับ</h5>
            </Col>
            {type==='choice'
                ?selectedChoice.id
                    ?<Col onClick={submit} style={{padding:10,backgroundColor:colors.purple,borderRadius:20,margin:20,cursor:'pointer',color:colors.white}} >
                        <h5  >ถัดไป</h5>
                    </Col>
                    :<Col  style={{padding:10,backgroundColor:colors.darkGray,borderRadius:20,margin:20,cursor:'pointer',color:colors.white}} >
                        <h5  >ถัดไป</h5>
                    </Col>
                :summary(selected,'qty')>=minQty
                    ?<Col onClick={submit} style={{padding:10,backgroundColor:colors.purple,borderRadius:20,margin:20,cursor:'pointer',color:colors.white}} >
                        <h5 >ถัดไป</h5>
                    </Col>
                    :<Col  style={{padding:10,backgroundColor:colors.darkGray,borderRadius:20,margin:20,cursor:'pointer',color:colors.white}} >
                        <h5  >ถัดไป</h5>
                    </Col>
            }
            
        </Row>

      </Modal.Body>
   
    </Modal>
  );
}

export default Modal_PreOrder;

