import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { colors } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { checkCategory2, compareArrays, findInArray,  goToTop,  manageCategory,  someInArray,  totalField } from "../Utility/function";
import { CSVLink } from "react-csv";
import { normalSort, timestampSort } from "../Utility/sort";
import TablePagination from '@mui/material/TablePagination';
import { stringFullTime, stringYMDHMS3 } from "../Utility/dateTime";


function ResBOMWarehouse() {
    const dispatch = useDispatch();
    const { shop } = useSelector((state) => state.resShop);
    const { BOMCategory } = shop;
    const { boms } = useSelector((state) => state.resBom);
    const [categorySetting, setCategorySetting] = useState([])
    const [display, setDisplay] = useState([]);
    const [statusId, setStatusId] = useState(1); // ทั้งหมด
    const [currentDisplay, setCurrentDisplay] = useState([]); // จำนวนที่แสดงในหนึ่งหน้า
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [csvName, setCsvName] = useState('วัตถุดิบทั้งหมด')


    useEffect(()=>{
        let result = []
        let length = categorySetting.length;
        if(length===0){  // แปลว่าไม่ได้ fixed สมาชิก
            result = [...boms]
        } else {
            for(const item of boms){
                if(item.category.length >0){
                    if(compareArrays(item.category.slice(0,length),totalField(categorySetting,'id'))){
                        result.push(item)
                    }
                }
            }
        }
        
        manageDisplay(timestampSort(result))
    },[categorySetting,boms,statusId])

    const handleChangePage = (event, newPage) => {
      setPage(newPage); // start form 0
      goToTop()
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      goToTop()
  };
  useEffect(()=>{
    let fData = display.map((item,index)=>{return({...item,no:index})}).filter((item,index)=>{return(index >=(page*rowsPerPage) && index <= ((page+1)*rowsPerPage)-1)})
      setCurrentDisplay(fData)
  },[page,rowsPerPage,display])


  const multipleUnit = (amount,arr) =>{
        let text = ''
        if(Number(amount) >=0){ // amount คือ stock ของหน่วยย่อยที่สุด
        let x = Number(amount)
        let newAllUnit = arr.map((item)=>{return({...item,amount:Number(item.amount)})})
        for(const item of normalSort('amount',newAllUnit)){
            let cutText = Math.floor(x/Number(item.amount))
            text = text + ~~cutText + '  ' + item.name + '  '
            x = x % Number(item.amount)
        }
        }
        return text
    }

    function checkStock(minimunStock,stock,unit){
        const { id, qty, status } = minimunStock;
        let text = 'available'
        if(stock<=0){
            text = 'out'
        } else {
            if(status){
                let amount = findInArray(unit,'id',id)?.amount
                if(Number(amount)*Number(qty) > Number(stock)){
                    text = 'lower'
                }
            }
        }
        return text
    }

    const manageDisplay = (arr) =>{  // จัดการกรอบเวลาในการค้นหาข้อมูล
      if(statusId===1){
        setDisplay(arr)
        setCsvName('วัตถุดิบทั้งหมด')
      } else if(statusId===2){
        setDisplay(arr.filter((item)=>{return(checkStock(item.minimumStock,item.stock,item.unit)==='available')}))
        setCsvName('วัตถุดิบปกติ')
      } else if(statusId===3){
        setDisplay(arr.filter((item)=>{return(checkStock(item.minimumStock,item.stock,item.unit)==='lower')}))
        setCsvName('วัตถุดิบต่ำกว่าปกติ')
      } else {
        setDisplay(arr.filter(a=>a.stock<=0))
        setCsvName('วัตถุดิบหมด')
      }
  }

    const statusOptions = [
      {id:1,name:'ทั้งหมด'},
      {id:2,name:'ปกติ'},
      {id:3,name:'ต่ำกว่ากำหนด'},
      {id:4,name:'หมด'},
    ]

  return (
    <div  >
      <div>
        {BOMCategory?.map((item,index)=>{
                let length = categorySetting.length===0?true:false
                return(
                    <div key={index} style={{ width: '100%', overflowX: 'auto' }} className="custom-scrollbar" >
                      <div style={{ display: 'flex' }}>
                        {item.level === 1 ? (
                          <div
                            onClick={() => {
                              setCategorySetting([]);
                            }}
                            className="category"
                            style={{
                              backgroundColor: length ? colors.redPink : null,
                              color: length ? colors.white : null,
                            }}
                          >
                            ทั้งหมด
                          </div>
                        ) : null}

                        {checkCategory2(categorySetting, item).map((a, i) => {
                          let status = someInArray(categorySetting, 'id', a.id);
                          return (
                            <div
                              onClick={() => {
                                setCategorySetting(manageCategory(item.value, categorySetting, a));
                              }}
                              key={i}
                              className="category"
                              style={{
                                backgroundColor: status ? colors.redPink : null,
                                color: status ? colors.white : null,
                                padding: '5px 10px', // Adjust padding for a smaller bar
                                minWidth: '8rem',
                                maxHeight: '5em',
                                overflow: 'hidden',
                                marginRight: '5px', // Add some spacing between items
                                borderRadius: '16px', // Add rounded corners for a nicer look
                                display:'flex',
                                justifyContent:'center'
                              }}
                            >
                              {a.name}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                )
            })}
        </div>

      <Row>
          {statusOptions.map((item,index)=>{
            return(
              <Col xs='12' md='6' lg='3' key={index} style={{cursor:'pointer',padding:3}} onClick={()=>{setStatusId(item.id)}} >
                  <div style={{marginLeft:0,marginRight:0,display:'flex',flexDirection:'column',justifyContent:'center',backgroundColor:item.id===statusId?'yellow':colors.white,borderRadius:20,marginBottom:5,alignItems:'center',padding:'5px 0px 2.5px 0px'}} >
                      <h6>{item.name}</h6>
                  </div>
              </Col>
            )
          })}
      </Row>
      <Row >
          <Col lg='4' style={{padding:10}} >
          {/* <CSVLink 
            style={{backgroundColor:'#292929',textDecoration:'none',color:colors.white,padding:10,borderRadius:20,paddingLeft:30,paddingRight:30}}
            filename={`รายการวัตถุดิบ${stringYMDHMS3(new Date())}`}
            data={
              display.map((item)=>{
                const { name, stock, unit,  minimumStock, id } = item;
                return({
                    ชื่อสาขา:shop.name,
                    วันเวลา:stringFullTime(new Date()),
                    รหัสเมนู:id,
                    ชื่อเมนู:name,
                    ปริมาณ:0,
                    ยอดขายทั้งหมด:0,
                    ทั้งหมด:multipleUnit(stock,unit),
                    คงเหลือ:stock,
                    หน่วยย่อย:findInArray(unit,'amount','1')?.name,
                  })})
            }
          >
              Export fmh
          </CSVLink> */}
            <CSVLink 
              style={{backgroundColor:'#292929',textDecoration:'none',color:colors.white,padding:10,borderRadius:20,paddingLeft:30,paddingRight:30}}
              filename={`${csvName}${stringYMDHMS3(new Date())}`}
              data={
                display.map((item)=>{
                  const { name, stock, unit,  minimumStock } = item;
                  let text = checkStock(minimumStock,stock,unit);
                  return({
                        ชื่อ:name,
                        ทั้งหมด:multipleUnit(stock,unit),
                        คงเหลือ:stock,
                        หน่วยย่อย:findInArray(unit,'amount','1')?.name,
                        สถานะ:text,
                    })})
              }
            >
                Export
            </CSVLink>
          </Col>
          
      </Row>
      
        
            <h6 style={{paddingLeft:10}} >ค้นพบ {display.length} item</h6>
              <Row>
                <Table striped bordered hover responsive  variant="light"   >
                  <thead  >
                    <tr>
                      <th style={{ width: '5%' }}>No.</th>
                      <th style={{ width: '15%' }}>ชื่อ</th>
                      <th style={{ width: '10%' }}>ทั้งหมด</th>
                      <th style={{ width: '10%' }}>คงเหลือ</th>
                      <th style={{ width: '10%' }}>หน่วยย่อย</th>
                      <th style={{ width: '10%' }}>สถานะ</th>
                    </tr>
                  </thead>
                  <tbody  >
                    {currentDisplay.map((item, index) => {
                        const { name, stock, unit,  minimumStock } = item;
                        let text = checkStock(minimumStock,stock,unit);

                        return(
                            <tr  style={{cursor: 'pointer'}} key={index} >
                                <td>{item.no+1}.</td>
                                <td >{name}</td>
                                <td >{multipleUnit(stock,unit)}</td>
                                <td >{stock}</td>
                                <td >{findInArray(unit,'amount','1')?.name}</td>
                                <td >{text}</td>
                            </tr>
                        )
                    })}
                  </tbody>
                </Table>
                <TablePagination
                    component="div"
                    count={display.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Row>
    </div>
  );
}

export default ResBOMWarehouse;
