import React, { useState, useContext } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { InputArea2, InputText, InputText2 } from "../components";

function CheckOutScreen() {
    const initialUser = {name:"",tel:'',address:'',subDistrict:'',district:'',province:'',zipcode:''}
    const [user, setUser] = useState({})
    const { name, tel, address, subDistrict, district, province, zipcode } = user;
  return (
    <Container style={{}} >
        <h1>ตรวจสอบข้อมูลการสั่งซื้อ</h1>
        <p>* กรุณาตรวจสอบข้อมูลชื่อ ที่อยู่และเบอร์โทรที่ถูกต้องเพื่อให้ร้านค้าจัดส่งสินค้าได้เร็วขึ้น</p>
        <Row>
            <Col md='6'>
                <InputText2
                    name='name'
                    placeholder="ชื่อ-นามสกุล"
                    onChange={(event)=>{setUser({...user,name:event.target.value})}}
                    value={name}
                />
            </Col>
            <Col md='6'>
                <InputText2
                    name='tel'
                    placeholder="เบอร์โทรศัพท์"
                    onChange={(event)=>{setUser({...user,tel:event.target.value})}}
                    value={tel}
                />
            </Col>
        </Row>
        <Row>
            <InputArea2
                name='address'
                placeholder="ที่อยู่จัดส่ง"
                onChange={(event)=>{setUser({...user,address:event.target.value})}}
                value={address}
            />
        </Row>
        <Row>
            <Col md='6'>
                <InputText2
                    name='subDistrict'
                    placeholder="ตำบล / แขวง"
                    onChange={(event)=>{setUser({...user,subDistrict:event.target.value})}}
                    value={subDistrict}
                />
            </Col>
            <Col md='6'>
                <InputText2
                    name='district'
                    placeholder="อำเภอ / เขต"
                    onChange={(event)=>{setUser({...user,district:event.target.value})}}
                    value={district}
                />
            </Col>
        </Row>
        <Row>
            <Col md='6'>
                <InputText2
                    name='province'
                    placeholder="จังหวัด"
                    onChange={(event)=>{setUser({...user,province:event.target.value})}}
                    value={province}
                />
            </Col>
            <Col md='6'>
                <InputText2
                    name='zipcode'
                    placeholder="รหัสไปรษณีย์"
                    onChange={(event)=>{setUser({...user,zipcode:event.target.value})}}
                    value={zipcode}
                />
            </Col>
        </Row>
        
        <h1>ตรวจสอบข้อมูลการสั่งซื้อ</h1>
        <h1>อัพโหลดหลักฐานการชำระเงิน</h1>
    </Container>
  );
}

export default CheckOutScreen;
