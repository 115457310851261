import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfiles, updateFieldBranch } from "../redux/branchSlice";
import { Modal_Alert, Modal_HumanResource, Modal_Loading } from "../modal";
import * as trash from '../trash.json';
import Lottie from "react-lottie";
import { lottieDefault } from "../configs";
import Modal_OneInput from "../modal/Modal_OneInput";
import { db } from "../db/firestore";



function BranchHumanResource() {
  const dispatch = useDispatch();
  const [report_Modal, setReport_Modal] = useState(false);
  const { profiles, selectedBranch, modal_Profile } = useSelector((state)=> state.branch);
  const { humanResource, createBy, id } = selectedBranch;
  const [delete_Modal, setDelete_Modal] = useState(false);
  const [selectedHuman, setSelectedHuman] = useState({});
  const [add_Modal, setAdd_Modal] = useState(false);
  const [email, setEmail] = useState('');
  const [search, setSearch] = useState([]);
  const [search_Modal, setSearch_Modal] = useState(false)

  useEffect(()=>{
    dispatch(fetchProfiles({humanResource}))
  },[humanResource])

  function deleteHuman(){
    dispatch(updateFieldBranch({doc:id,field:{humanResource:[...humanResource.filter(a=>a!==selectedHuman.id)]}}))
    setDelete_Modal(false)
  }

  function addHuman(profileId){
    dispatch(updateFieldBranch({doc:id,field:{humanResource:[...humanResource,profileId]}}))
    setSearch_Modal(false)
    setSearch([])
  }

  function submit(){
    setAdd_Modal(false)
    setEmail('')
    db.collection('profile').where('email','==',email).get()
      .then((docs)=>{
        let res = []
        docs.forEach((doc)=>{res.push({...doc.data(),id:doc.id})})
        if(res.length>0){
          setSearch(res)
          setSearch_Modal(true)
        } else {
          alert('ไม่พบผลลัพธ์การค้นหา')
        }
      })
  }

  return (
    <div  >
        <Modal_Loading
            show={modal_Profile}
        />
        <Modal_Alert
          show={delete_Modal}
          onHide={()=>{setDelete_Modal(false)}}
          onClick={deleteHuman}
          content={selectedHuman.name}
          header="ลบเพื่อนร่วมงาน"
        />
        <Modal_OneInput
          header='เพิ่มบุคลากร'
          show={add_Modal}
          placeholder='email'
          value={email}
          onChange={setEmail}
          onHide={()=>{setEmail('');setAdd_Modal(false)}}
          onClick={submit}
        />
        <Modal_HumanResource
          show={search_Modal}
          search={search}
          addHuman={addHuman}
          onHide={()=>{setSearch([]);setSearch_Modal(false)}}
        />
        <h1>บุคลากร</h1>
        <Button onClick={()=>{setAdd_Modal(true)}} variant="success" >
          เพิ่มบุคลากร
        </Button>
        {profiles.map((item,index)=>{
          const { name, email } = item;
          return(
            <Row key={index} >
                <Col md='5' >{index+1}. {name}</Col>
                <Col md='5' >{email}</Col>
                {createBy===item
                    ?<Col md='2' ></Col>
                    :<Col md='2' 
                      onClick={()=>{setSelectedHuman(item);setDelete_Modal(true)}} 
                      style={{cursor:'pointer'}}
                    >
                      <Lottie options={{...lottieDefault,animationData: trash.default}} height={30} width={30} />
                    </Col>
                }
            </Row>
          )
        })}
    </div>
  );
}

export default BranchHumanResource;
