export default {
    shopId:'',
    branch:'',
    externalId:[],
    imageId:'',
    name:'',
    detail:'',
    option:[],
    price:[
        // {
        //     type:'dineId', // ประเภททานที่ร้าน
        //     price:'',
        //     promotionStatus:false,
        //     promotion:{
        //         price:'',
        //         startDate:'',
        //         endDate:'',
        //         status:true
        //     },
        //     status:true, // แปลว่าขายบนช่องทางนี้
        // },
    ],
    status:'available',// available/out/notsale
    BOM:[],
    incentive:0,
    timestamp:new Date(),
    category:[],
    other:[],
}