import {
    fetchMarket as firebaseFetch,
    updateMarket as firebaseUpdate,
    addMarket as firebaseAdd,
} from '../db/firestore'

import createDataContext from './createDataContext';


const marketReducer = (state,action) => {
    switch(action.type){
        case 'fetchMarket':
            return [...action.payload];
        case 'addMarket':
            return [...state,action.payload];
        case 'updateMarket':
            return state.map((item) => {
                return item.id == action.payload.id
                    ? action.payload
                    : item;     
            });
        default:
            return state;
    }
};

const addMarket = (dispatch) => async (data) => {
    const promise = new Promise(async (resolve,reject)=>{
        try{
            let landlord = await firebaseAdd(data)
            dispatch({type:'addMarket',payload:landlord})
            resolve(landlord)
        }catch(err){
            reject(err)
        }
      })
    return promise
}

const fetchMarket = (dispatch) => async (token) => {
    const promise = new Promise(async (resolve,reject)=>{
        try{
            let landlord = await firebaseFetch(token)
            dispatch({type:'fetchMarket',payload:landlord})
            resolve(landlord)
        }catch(err){
            reject(err)
        }
      })
    return promise
}
const updateMarket = (dispatch) => async (data) => {
    const promise = new Promise(async (resolve,reject)=>{
        try{
            firebaseUpdate(data.id,data)
            dispatch({type:'updateMarket',payload:data})
            resolve(data)
        }catch(err){
            reject(err)
        }
      })
      return promise
}




export const {Provider,Context} = createDataContext(
    marketReducer,
    {updateMarket,fetchMarket, addMarket },
  
    []
);