import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, Outlet, NavLink } from 'react-router-dom';
import { colors, ink, mainImage } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { Translator, allFilter, findInArray } from "../Utility/function";
import '../styles/Restaurant.css'
import { BsCircle, BsFillCircleFill } from "react-icons/bs";


function AdminHomeScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { profile } = useSelector((state)=> state.resProfile)
  const { shop } = useSelector((state)=> state.resShop)


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {  // เอาไว้กำหนด display ของ sidebar
    // Function to update the window width state
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Event listener to update the window width state on resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const sidebarItems = [
    {
      label: 'หน้าหลัก',
    },
    {
      topic: 'Dashboard',
      icon: 'bi-bar-chart-fill',
      to: 'dashboard',
    },
    {
        topic: 'รายงาน',
        icon: 'bi-clipboard-data',
        to: 'report',
    },
    {
      topic: 'รายงานกะ',
      icon: 'bi bi-people',
      to: 'reportShift',
    },
    // {
    //   label: 'คลังวัตถุดิบ',
    // },
    // {
    //   topic: 'วัตถุดิบคงเหลือ',
    //   icon: 'bi-egg-fill',
    //   to: 'resBom',
    // },
    // {
    //     topic: 'วัตถุดิบที่ใช้ไป',
    //     icon: 'bi bi-egg',
    //     to: 'resRawmat',
    // },
    // {
    //   label: 'จัดการสินค้า',
    // },
    // {
    //     topic: 'คลังสินค้า',
    //     icon: 'bi-shop-window',
    //     to: 'resProductWareHouse',
    // },
    // {
    //   topic: 'หมวดหมู่สินค้า',
    //   icon: 'bi-grid-fill',
    //   to: 'resProductCategory',
    // },
    // {
    //   label: 'สาขา',
    // },
    // {
    //   topic: 'Qrcode HQ',
    //   icon: 'bi-speedometer',
    //   to: 'branchQrcode',
    // },
    // {
    //   topic: 'Product Option',
    //   icon: 'bi-speedometer',
    //   to: 'resProductOption',
    // },
    // {
    //   topic: 'ใบกำกับภาษี',
    //   icon: 'bi-speedometer',
    //   to: 'resInvoice',
    // },
    // {
    //   topic: 'Promotion',
    //   icon: 'bi-speedometer',
    //   to: 'shoplist',
    // },
    // {
    //   topic: 'ตั้งค่าสิทธิ์',
    //   icon: 'bi-person-add',
    //   to: 'shoprequest',
    // },
    // {
    //   label: 'จัดการ Profile',
    // },
  ];


  
  return (
    // <div className="market" >

    <div style={{backgroundColor:colors.backgroundColor}} >
        {windowWidth < 576
            ?<Row style={{color:'black',backgroundColor:colors.white}} >
                <Col xs={3} style={{display:'flex',justifyContent:'center'}} >
                    <NavLink exact to="dashboard" activeClassname='sidebar a.active'  >
                        <i class="bi-bar-chart-fill" style={{fontSize:'1rem',color:'black'}} ></i>
                    </NavLink>
                </Col>
                <Col xs={3} style={{display:'flex',justifyContent:'center'}} >
                    <NavLink exact to="report" activeClassname='sidebar a.active'  >
                        <i class="bi-clipboard-data" style={{fontSize:'1rem',color:'black'}}></i>
                    </NavLink>
                </Col>
                <Col xs={3} style={{display:'flex',justifyContent:'center'}} >
                    <NavLink exact to="reportShift" activeClassname='sidebar a.active' >
                        <i class="bi bi-people" style={{fontSize:'1rem',color:'black'}}></i>
                    </NavLink>
                </Col>
            </Row>
            :null
        }
        {/* <div className="market" > */}
        <div style={{display:'flex'}} >
            {windowWidth < 576
                ?null
                :<div style={{ width: '200px', minWidth: '200px', position: 'fixed',backgroundColor:'#343333' }}>
                  <div style={{display:'flex',justifyContent:'center'}} >
                    <img style={{width:'150px',backgroundColor:'#343333'}} src={mainImage.shopchampplus}/>
                  </div>
                    <div style={{ maxHeight: '100vh', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                      <div className="resSidebar">
                        {sidebarItems.map((item, index) => (
                            <React.Fragment key={index}>
                            {item.label && (
                                <div
                                style={{
                                    width: '100%',
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                }}
                                >
                                {item.label}
                                </div>
                            )}
                            {item.to && (
                                <NavLink
                                exact
                                to={item.to}
                                activeClassName='resSidebar a.active'
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                >
                                <i
                                    className={`bi ${item.icon}`}
                                    style={{fontSize: '1rem'}}
                                />
                                &emsp;{item.topic}
                                {item.count > 0 && (
                                    <h6
                                    style={{
                                        backgroundColor: colors.purpleRed,
                                        color: 'white',
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        borderRadius: 20,
                                        marginLeft: 5,
                                    }}
                                    >
                                    {item.count}
                                    </h6>
                                )}
                                </NavLink>
                            )}
                            </React.Fragment>
                        ))}
                      </div>
                    </div>
                    
                </div> 
            }
            <div style={{width:windowWidth < 576 ? '100%' : 'calc(100% - 212px)', marginLeft: windowWidth < 576 ? 0 : '200px',paddingLeft:20,paddingRight:20,paddingTop:40,backgroundColor:colors.backgroundColor, height:'100%' }}>
                <Outlet />
            </div>
            {/* <div>
            <Outlet />

            </div> */}
        </div>
        
    </div>
  );
}

export default AdminHomeScreen;