import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { bath, colors, initialDailySale, mainImage } from "../configs";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate , Outlet, NavLink } from 'react-router-dom';
import { Modal_Loading, Modal_NewLandlord, Modal_NewMarket } from "../modal";
import { useSelector, useDispatch } from "react-redux";
import { createNewLandlord, fetchLandlord, setNewLandlord_Modal } from "../redux/landlordSlice";
import { chooseMarket, createNewMarket, fetchMarket, setNewMarket_Modal } from "../redux/marketSlice";
import { stringDateTime3, stringFullDate } from "../Utility/dateTime";
import { LandlordChart } from "../components";
import '../styles/LandlordScreen.css'
import { FilterTranslator, Translator, summary } from "../Utility/function";
import { fetchRenter } from "../redux/renterSlice";
import { fetchThisDaySale, fetchThisMonthSale } from "../redux/dailySaleSlice";


function LandlordScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentProfile } = useSelector((state)=> state.profile)
  const { landlords, loading_Landlord, newLandlord_Modal, currentLandlord } = useSelector((state)=> state.landlord)
  const { markets, newMarket_Modal, loading_Market } = useSelector((state)=> state.market)
  const { renters_Normal, loading_Renter } = useSelector((state)=> state.renter)
  const { thisDaySales } = useSelector((state)=> state.dailySale)

  useEffect(()=>{
    dispatch(fetchLandlord(currentProfile.email))
  },[])

  useEffect(()=>{
    if(currentLandlord && currentLandlord.id){
      dispatch(fetchMarket(currentLandlord.id))
      dispatch(fetchThisDaySale(currentLandlord.id))
      dispatch(fetchThisMonthSale(currentLandlord.id))
      dispatch(fetchRenter(currentLandlord.id))
    }
  },[currentLandlord?.id])


  return (
    <div  style={{backgroundColor:colors.backgroundColor,padding:0,margin:0,height:'100vh',overflow:'auto'}} >
        <Modal_Loading show={loading_Landlord || loading_Market || loading_Renter} />
        <Modal_NewLandlord
          show={newLandlord_Modal}
          onHide={()=>{dispatch(setNewLandlord_Modal(false))}}
        />
        <Modal_NewMarket
          show={newMarket_Modal}
          onHide={()=>{dispatch(setNewMarket_Modal(false))}}
        />
        {landlords.length >0
            ?<div style={{paddingTop:'6rem',paddingLeft:'auto',paddingRight:'auto',width:'95%',height:'100hv'}} >
              <Row>
                  <Col sm={3}  >
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center',backgroundColor:colors.white,position:'relative',height:'100%',borderRadius:20,paddingTop:90,marginLeft:10}} >
                      <div style={{position:'absolute',top:-75,width:150,height:150}} >
                          <img style={{width:150,borderRadius:'100%',border: '4px solid #ddd',padding:'5px',height:'auto'}} width="150" height="150"  src={Translator(landlords[0].imageUrl,'logo')} alt='logo'/>
                      </div>
                      <h2 style={{padding:5}} ><b>{Translator(landlords[0].name,'TH')}</b></h2>
                      <p  ></p>
                      <div style={{display:'flex',width:'95%',borderTop:`1px solid ${colors.softGray}`}} >
                          <div style={{width:'50%',textAlign:'center',color:colors.softGray}} >
                              <h2><b>{markets.length}</b></h2>
                              <h4>Markets</h4>
                          </div>
                          <div style={{height:'100%',borderRight:`1px solid ${colors.softGray}`}} />
                          <div style={{width:'50%',textAlign:'center',color:colors.softGray}} >
                              <h2><b>{renters_Normal.length}</b></h2>
                              <h4>Stores</h4>
                          </div>
                      </div>
                        <div style={{width:'100%',padding:10}} >
                          <img style={{width:30}} src={mainImage.iconaddress} />&emsp;{Translator(landlords[0].address,'TH')}
                        </div>
                        <div style={{width:'100%',padding:10}} >
                          <img style={{width:30}} src={mainImage.iconlocation} />&emsp;Lat:  {landlords[0]?.location.latitude},Long:  {landlords[0]?.location.longitude}
                        </div>
                        {landlords[0].contact.map((item,index)=>{
                          return(
                            <a target="_blank" href={item.type=='tel'?null:item.value} key={item.id} style={{width:'100%',padding:10,textDecorationLine:'none',color:colors.darkGray}} >
                                <img style={{width:30,borderRadius:'100%'}} src={item.icon} />
                                &emsp;{item.value.slice(0,20)}
                            </a>
                          )
                        })}
                    </div>
                  </Col>
                  <Col sm={9}  >
                    <Row >
                      {markets.map((item, index) => (
                          <Col key={index} sm='6' onClick={()=>{dispatch(chooseMarket(item),navigate("/market"))}}  style={{padding:10}} >
                              <Button variant="light"  style={{borderRadius:20,backgroundColor:colors.purplePeel,padding:0,width:'100%'}} >
                                <img  style={{width:'100%',borderRadius:20,height:'auto'}} width="400" height="100" src={Translator(item.imageUrl,'coverPhoto')||mainImage.democoverphoto} alt='coverPhoto' />
                                <div style={{padding:10}} >
                                  <h2><b>{Translator(item.name,'TH')}</b></h2>
                                  <h6 style={{fontSize:12,padding:0,margin:0}} >Created date : {stringDateTime3(item.timestamp)} </h6>
                                </div>
                                <Row style={{color:colors.darkGray}} >
                                  <Col md='6' >
                                      <h2><b>{bath}{summary(FilterTranslator(thisDaySales,'marketId',item.id),'net').toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</b></h2>
                                      {/* <h6>Stores members</h6> */}
                                      <h6>Daily sale</h6>
                                  </Col>
                                  <Col md='6' >
                                      <h2><b>{FilterTranslator(renters_Normal,'marketId',item.id)?.length}</b></h2>
                                      <h6>Stores</h6>
                                  </Col>
                                </Row>
                              </Button>
                          </Col>
                      ))}
                      <Col sm='6' onClick={()=>{dispatch(createNewMarket())}}  style={{display:'flex',justifyContent:'center',alignItems:'center',padding:10}} >
                          <Button variant="light" style={{width:'100%',height:'100%',borderRadius:20}}  >
                              <i className="bi bi-plus-circle-fill" style={{fontSize:80,color:colors.purpleRed}} ></i>
                              <h3 style={{color:colors.purpleRed}} >Add New Market</h3>
                          </Button>
                      </Col>
                    </Row>
                  </Col>
              </Row>
              {/* <div className="background-gradient" >
                    <p style={{color:colors.white}} ><b>Dashboard</b></p>
              </div>
              <Row>
                <Col md='4' >
                  <div style={{margin:5,backgroundColor:colors.darkPurple,padding:10,borderRadius:20,color:'white',position:'relative'}} >
                      <h4>ยอดขายวันนี้</h4>
                      <h1><b>396,000</b></h1>
                      <img onClick={()=>{}} style={{width:'10%',position:'absolute',top:10,right:10,minWidth:20}} alt='excel' src={mainImage.eye} />
                  </div>
                </Col>
                <Col md='4'  >
                  <div style={{margin:5,backgroundColor:colors.purpleRed,padding:10,borderRadius:20,color:'white',position:'relative'}} >
                    <h4>ยอดขายเดือนนี้</h4>
                    <h1 ><b>396,00000</b></h1>
                    <img onClick={()=>{}} style={{width:'10%',position:'absolute',top:10,right:10,minWidth:20}} alt='excel' src={mainImage.eye} />
                  </div>
                </Col>
                <Col md='4'  >
                  <div style={{margin:5,backgroundColor:colors.purplePeel,paddingLeft:40,borderRadius:30,color:'white'}} >
                    <h6>จำนวนออเดอร์ วันนี้: </h6>
                    <h5><b>22,040,550</b></h5>
                  </div>
                  <div style={{margin:5,backgroundColor:'orange',paddingLeft:40,borderRadius:30,color:'white'}} >
                    <h6>จำนวนออเดอร์ เดือนนี้: </h6>
                    <h5><b>22,040,55</b></h5>
                  </div>
                </Col>
              </Row>
              <LandlordChart  /> */}
            </div>
            :<div style={{backgroundColor:colors.purplePeel,width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}} >
              <Button onClick={()=>{dispatch(createNewLandlord())}} variant="dark">Create New Landlord</Button>
            </div>
        }
        
        
    </div>
  );
}

export default LandlordScreen;
