import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink, useNavigate } from 'react-router-dom';
import { bath, colors, mainImage } from "../configs";
import {
    Button, 
    Form,
    Row,
    Col,
  } from "react-bootstrap";
import '../styles/CartScreen.css';
import { Modal_Request, Modal_Test } from "../modal";
import { ProductDisplay } from "../components";
import { Modal_LoadingPanda } from "../modal";


function TakeOrderScreen() {
  const navigate = useNavigate();
  const { cart } = useSelector((state)=> state.order);

  return (
    <div  style={{width:'calc(100% - 16px)'}}>
        <div  className='cart' onClick={()=>{navigate(`/summary`)}} >
            {cart.length >0
                ?<div className="qty" style={{position:'absolute',top:-5,right:-10}} >
                    {cart.length}
                </div>
                :null
            }
            <i class="bi bi-basket"></i>
        </div>
        <ProductDisplay/>
    </div>
  );
}

export default TakeOrderScreen;
