import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, Outlet, NavLink } from 'react-router-dom';
import '../styles/MarketScreen.css'
import { colors, mainImage } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { Translator, allFilter } from "../Utility/function";
import { createMarketSale, fetchThisDaySale } from "../redux/dailySaleSlice";

function MarketScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { currentMarket } = useSelector((state)=> state.market)
  const { renters_Pending } = useSelector((state)=> state.renter)
  const { currentLandlord } = useSelector((state)=> state.landlord)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let marketRenter = allFilter(renters_Pending,'marketId',currentMarket.id).length

  useEffect(() => {  // เอาไว้กำหนด display ของ sidebar
    // Function to update the window width state
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Event listener to update the window width state on resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  useEffect(()=>{
    dispatch(fetchThisDaySale(currentLandlord.id)).then((data)=>{
        if(data.payload){
          dispatch(createMarketSale(currentMarket.id))
        }
    })
  },[])


  
  return (
    // <div className="market" >
    <div >
        <div style={{height:'100px'}} >
        <div style={{position:'fixed',width:'100%',zIndex:999}} >
            <div style={{backgroundColor:'white',display:'flex',alignItems:'center',width:'100%',justifyContent:'space-between'}} >
                <div style={{minWidth:200,padding:10}} >
                  <img onClick={()=>{navigate("/")}} style={{width:100,cursor:'pointer'}}  src={mainImage.logo} />
                </div>
                <div style={{padding:10,display:'flex',flexGrow:1,justifyContent:'flex-start'}} ><b style={{fontSize:30}} >{Translator(currentMarket.name,'TH')}</b></div>
            </div>
          {windowWidth < 576
              ?<Row style={{color:'black',backgroundColor:colors.white}} >
                  <Col xs={3} style={{display:'flex',justifyContent:'center'}} >
                      <NavLink exact to="marketdashboard" activeClassname='sidebar a.active'  >
                        <i class="bi bi-speedometer" style={{fontSize:'1rem',color:'black'}} ></i>
                      </NavLink>
                  </Col>
                  <Col xs={3} style={{display:'flex',justifyContent:'center'}} >
                      <NavLink exact to="shoplist" activeClassname='sidebar a.active'  >
                        <i class="bi bi-shop" style={{fontSize:'1rem',color:'black'}}></i>
                      </NavLink>
                  </Col>
                  <Col xs={3} style={{display:'flex',justifyContent:'center'}} >
                      <NavLink exact to="shoprequest" activeClassname='sidebar a.active' >
                        <i class="bi bi-person-add" style={{fontSize:'1rem',color:'black'}}></i>
                      </NavLink>
                  </Col>
                  <Col xs={3} style={{display:'flex',justifyContent:'center'}} >
                      <NavLink exact to="profile" activeClassname='sidebar a.active'  >
                        <i class="bi bi-person-bounding-box" style={{fontSize:'1rem',color:'black'}} ></i>
                      </NavLink>
                  </Col>
              </Row>
              :null
          }
          
        </div>
        </div>
        
        
        <div className="market" >
          {windowWidth < 576
              ?null
              :<div style={{width:'200px',minWidth:'200px'}}>
                <div className="sidebar"  >
                  <div style={{color:'black',width:'100%',paddingLeft:'12px',fontSize:12,fontWeight:'bold'}} >Dashboard</div>
                  <NavLink exact to="marketdashboard" activeClassname='sidebar a.active'  >
                    <i class="bi bi-speedometer" style={{fontSize:'1rem'}} >    </i>
                    &emsp;Dashboard
                  </NavLink>
                  <div style={{color:'black',width:'100%',paddingLeft:'12px',fontSize:12,fontWeight:'bold'}} >Report</div>
                  <NavLink exact to="report" activeClassname='sidebar a.active'  >
                    <i class="bi bi-speedometer" style={{fontSize:'1rem'}} >    </i>
                    &emsp;Report
                  </NavLink>
                  <div style={{color:'black',width:'100%',paddingLeft:'12px',fontSize:12,fontWeight:'bold'}} >Shop Profile</div>
                  <NavLink exact to="shoplist" activeClassname='sidebar a.active'  >
                    <i class="bi bi-shop" style={{fontSize:'1rem'}}></i>
                    &emsp;shop List
                  </NavLink>
                  <div style={{color:'black',width:'100%',paddingLeft:'12px',fontSize:12,fontWeight:'bold'}} >Shop Request</div>
                  <NavLink exact to="shoprequest" activeClassname='sidebar a.active' >
                    <i class="bi bi-person-add" style={{fontSize:'1rem'}}></i>
                    &emsp;Shop Request
                    {marketRenter >0
                      ?<h6 style={{backgroundColor:colors.purpleRed,color:'white',paddingLeft:10,paddingRight:10,borderRadius:20,marginLeft:5}} >{marketRenter}</h6>
                      :null
                    }
                  </NavLink>
                  <div style={{borderTop:`1px solid ${colors.softGray}`,width:'90%'}}/>
                  <div style={{color:'black',width:'100%',paddingLeft:'12px',fontSize:12,fontWeight:'bold'}} >Profile</div>
                  <NavLink exact to="profile" activeClassname='sidebar a.active'  >
                    <i class="bi bi-person-bounding-box" style={{fontSize:'1rem'}} ></i>
                    &emsp;Profile
                  </NavLink>
                </div>
            </div> 
          }
        
        
        <div className="mainDisplay" >
          <Outlet />
        </div>

        </div>
        
    </div>
  );
}

export default MarketScreen;