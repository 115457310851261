import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { db } from "../db/firestore";
import { convertToInt, diffArray, endCutoff, filterDeleteInArray, manageBill, startCutoff, useToDate } from '../Utility/function';
import { NumberYMD, minusDays, stringYMDHMS3 } from '../Utility/dateTime';

const initialState = {
    bills:[], // 
    normalBill:[],
    voidedBill:[],
    modal_Bill:false,
    billDates:[],
    graph:[],
    saleByChannel:[],
    payment:[],
    result:[],
    product:[],
    category:[],
    saleMan:[],
    selectedBill:[],
    selectedVoidedBill:[],
    orders:[], // ออเดอร์ที่ถูกเรียก
    billOrder:[], // เก็บ billId ที่ถูกเรียกดู order
    modal_Order:false,
}

// fetch order
export const fetchOrder = createAsyncThunk(
  'product/fetchOrder',
  async ({ billId, documentIds }) => {
    let fetchedDocuments = [];


      try {
        // Use firestore.getAll() to fetch multiple documents at once
        const documentsSnapshot = await db.getAll(
          ...documentIds.map((docId) => db.collection('order').doc(docId))
        );

        // Extract data from each document snapshot
       fetchedDocuments = documentsSnapshot.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          billId
        }));

        // Set the state with the fetched documents
      } catch (error) {
        console.error('Error fetching documents:', error);
      }

    return {fetchedDocuments,billId};
  }
);

// fetch bill
export const fetchBill = createAsyncThunk(
  'product/fetchBill',
  async ({ shopId, billDate, cutOff, startDate, endDate }) => {
    let data = [];

    // Split billDate into chunks of 10 elements each
    const chunkSize = 10;
    const billDateChunks = [];
    for (let i = 0; i < billDate.length; i += chunkSize) {
      billDateChunks.push(billDate.slice(i, i + chunkSize));
    }

    // Use Promise.all to make multiple queries
    const promises = billDateChunks.map(async (chunk) => {
      const query = db.collection('bill')
        .where("shopId", "==", shopId)
        .where('billDate', 'in', chunk);

      try {
        const qsnapshot = await query.get();
        if (qsnapshot.docs.length > 0) {
          qsnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data(), timestamp: useToDate(doc.data().timestamp), product:doc.data().product.map((a)=>{return({...a,totalPrice:convertToInt(a.totalPrice)})}) });
          });
        } else {
          console.log('No items found for chunk:', chunk);
        }
      } catch (err) {
        console.error('Error:', err);
      }
    });

    // Wait for all queries to complete
    await Promise.all(promises);
    return { data, billDate, cutOff, startDate, endDate };
  }
);


// add bill to firestore
export const addBillTofirebase = createAsyncThunk(
    'bill/addBillTofirebase',
    async (bill)=>{
        let newBill = {}
        db.collection('bill').add(bill).then((doc)=>{
            newBill = {id: doc.id, ...bill}
        })
        // return newBill;
    }
);

// voidBill
export const voidBill = createAsyncThunk(
  'bill/voidBill',
  async ({id,field}) => {
      await db.collection('bill').doc(id).update(field)
    //   return bill
  }
);



export const billSlice = createSlice({
  name: 'resBill',
  initialState,
  reducers: {
    updateModal_Bill: state => {
      state.modal_Bill = !state.modal_Bill
    },
    fetchNormalBill: (state, action) => {
        state.bills = action.payload
    },
    updateDashBoard: (state, action) => {
        const { graph, saleByChannel, payment, result, product , category, saleMan, selectedBill, selectedVoidedBill } = action.payload;
        state.graph = graph
        state.saleByChannel = saleByChannel
        state.payment = payment
        state.result = result
        state.product = product
        state.category = category
        state.saleMan = saleMan
        state.selectedBill = selectedBill
        state.selectedVoidedBill = selectedVoidedBill
    },
    updateBills: (state, action) => {
      const { startDate, endDate, cutOff } = action.payload
      state.selectedBill = state.normalBill.filter((item)=>{return(new Date(item.timestamp) > startCutoff(startDate,new Date(cutOff)) && new Date(item.timestamp) <= endCutoff(endDate,new Date(cutOff)))})
      state.selectedVoidedBill = state.voidedBill.filter((item)=>{return(new Date(item.timestamp) > startCutoff(startDate,new Date(cutOff)) && new Date(item.timestamp) <= endCutoff(endDate,new Date(cutOff)))})
    }, 
  },
  extraReducers: builder => {
    builder.addCase(fetchBill.pending, state => {
      state.modal_Bill = true
    })
    builder.addCase(fetchBill.fulfilled, (state, action) => {
      const { data, billDate, cutOff, startDate, endDate } = action.payload;
        let duplicate = billDate.some((item)=>{return(Number(item)>=NumberYMD(minusDays(new Date(),1)))})
        if(duplicate){
          const { bills, normalBills, voidedBills } = manageBill(state.bills,data)
          state.bills = bills
          state.normalBill = normalBills
          state.voidedBill = voidedBills
        } else {
          state.bills = [...state.bills,...data]
          state.normalBill = [...state.normalBill,...data.filter((item)=>{return(!Boolean(item.void))})]
          state.voidedBill = [...state.voidedBill,...data.filter((item)=>{return(item.void===true )})]
        }
        state.selectedBill = state.normalBill.filter((item)=>{return(new Date(item.timestamp) > startCutoff(startDate,new Date(cutOff)) && new Date(item.timestamp) <= endCutoff(endDate,new Date(cutOff)))})
        state.selectedVoidedBill = state.voidedBill.filter((item)=>{return(new Date(item.timestamp) > startCutoff(startDate,new Date(cutOff)) && new Date(item.timestamp) <= endCutoff(endDate,new Date(cutOff)))})
        state.billDates = [...state.billDates,...billDate.filter((item)=>{return(item < stringYMDHMS3(new Date()))})] 
        state.modal_Bill = false
    })
    builder.addCase(fetchBill.rejected, state => {
        state.modal_Bill = false
    })
    builder.addCase(fetchOrder.pending, state => {
      state.modal_Order = true
    })
    builder.addCase(fetchOrder.fulfilled, (state, action) => {
        const { fetchedDocuments, billId } = action.payload;
        state.orders = [...state.orders,...fetchedDocuments]
        state.billOrder = [...state.billOrder,billId]
        state.modal_Order = false
    })
    builder.addCase(fetchOrder.rejected, state => {
        state.modal_Order = false
    })
  }
})

export const { updateModal_Bill, fetchNormalBill, updateDashBoard, updateBills
} = billSlice.actions

export default billSlice.reducer