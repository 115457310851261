import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { colors, mainImage } from "../configs";
import { FloatingText, InputArea, InputText } from "../components";
import Modal_Contact from "./Modal_Contact";
import { v4 as uuidv4 } from 'uuid';
import Modal_Alert from "./Modal_Alert";
import Modal_CropImage from "./Modal_CropImage";
import { useSelector, useDispatch } from "react-redux";
import { prepareFirebaseImage } from "../db/firestore";
import { addMarketToFirestore, setLoading_Market, setNewMarket, setNewMarket_Modal } from '../redux/marketSlice'
import { 
  Translator, manageArray, manageArray_Id, manageObject,
  manageCategory, findInArray,checkAddCategory,checkCategory2, 
} from "../Utility/function";
import Modal_FlatListTwoColumn from "./Modal_FlatListTwoColumn";
import { useNavigate } from "react-router-dom";
import Modal_Option from "./Modal_Option";


function Modal_NewResProduct({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { newMarket } = useSelector((state)=> state.market)
    const { landlords } = useSelector((state)=> state.landlord)
    const [contact_Modal, setContact_Modal] = useState(false)
    const [selected, setSelected] = useState({})
    const [delete_Modal, setDelete_Modal] = useState(false)

    // alert modal series
    const [categoryAlert_Modal,setCategoryAlert_Modal] = useState(false);

    const [crop_Modal, setCrop_Modal] = useState(false)
    
    const [loading, setLoading] = useState(false)

    const { shop } = useSelector((state)=> state.resShop)
    // const { products } = useSelector((state) => state.product);
    const { smartCategory, smartOption } = shop;
    // const {option} = product;
    const [currentCategory, setCurrentCategory] = useState([]);
    const [category_Modal, setCategory_Modal] = useState(false) //category modal
    const [selectedCategory, setSelectedCategory] = useState([])

  async function checkMarketProfile(){
    
    if(Translator(newMarket.name,'TH') == null){
      alert('กรุณาใส่ชือ market')
    } else if(Translator(newMarket.address,'TH') == null){
      alert('กรุณาใส่ address')
    } else if(newMarket.location.latitude == '' || newMarket.location.longitude == ''){
        alert('กรุณาใส่ location')
    }  else if(Translator(newMarket.imageUrl,'coverPhoto') == null){
      alert('กรุณาเลือกรูปภาพ')
    } else {
      dispatch(setLoading_Market(true))
      const newFormData = { ...newMarket }
      newFormData.timestamp = new Date()
      newFormData.landlordId = landlords[0].id
      let newImageUri = []
      for(const image of newFormData.imageUrl){
        if(image.value.slice(0,5) !== 'https'){
            const imageUrl = await prepareFirebaseImage(image.value,'/market/')
            newImageUri.push({...image,value:imageUrl})
        } else {
          newImageUri.push(image)
        }
      }
      dispatch(addMarketToFirestore({...newFormData,imageUrl:newImageUri}))
    }
  }

  function deleteContact(id){
      let newContact = newMarket.contact.filter((item)=>{return(item.id !==id)})
      dispatch(setNewMarket({...newMarket,contact:newContact}))

  }


  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      fullscreen='xxl-down'
    //   size={size}
    >
      <Modal.Header closeButton>
        <h2><b>สร้างสินค้า</b></h2>
        
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'transparent'}} >
          <Modal_Contact 
                show={contact_Modal}
                onHide={()=>{setContact_Modal(false)}}
                onClick={(value)=>{
                    dispatch(setNewMarket({...newMarket,contact:[...newMarket.contact,{...value,id:uuidv4()}]}))
                    setContact_Modal(false)}}
            />
            <Modal_Alert
                show={delete_Modal}
                onHide={()=>{setDelete_Modal(false)}}
                onClick={()=>{setDelete_Modal(false);deleteContact(selected.id)}}
                name={selected.type}
            />
            <Modal_CropImage
              show={crop_Modal}
              onHide={()=>{setCrop_Modal(false)}}
              onClick={(value)=>{
                setCrop_Modal(false);
                manageArray(newMarket.imageUrl,'coverPhoto',value,(imageUrl)=>{dispatch(setNewMarket({...newMarket,imageUrl}))})
              }}
              ratio={4/1}
          />

          {/* ================== New Modal for Restaurant ================== */}
          {/* Category Modal */}
          <Modal_FlatListTwoColumn 
            show={category_Modal}
            header='เลือกหมวดหมู่ที่ต้องการ'
            onHide={()=>{setCategory_Modal(false)}}
            onClick={(value)=>{setCurrentCategory(manageCategory(selectedCategory,currentCategory||[],value));setCategory_Modal(false)}}
            value={selectedCategory}
          />
           {/* For Category Modal, Alert when not has any "Category" yet */}
          <Modal_Alert
              show={categoryAlert_Modal}
              onHide={()=>{setCategoryAlert_Modal(false)}}
              onClick={()=>{setCategoryAlert_Modal(false);navigate("/resProductCategory")}}
              // name={selected.type}
          />
          {/* Option Modal */}
          {/* <Modal_Option
              visible={option_Modal}
              option={option}
              onRequestClose={()=>{setOption_Modal(false)}}
              onPress={checkOption}
          /> */}
          {/* ================== End of New Modal for Restaurant ================== */}

        {/* ================= Restautrant ================== */}
        <Row style={{marginBottom:'1rem'}}>
            <Col sm='3' >รูปสินค้า</Col>
            <Col sm='9' >
                <Button onClick={()=>{setCrop_Modal(true)}} variant="light" >
                    <Image style={{width:'100%',maxWidth:400}}  src={Translator(newMarket.imageUrl,'coverPhoto')||mainImage.democoverphoto} />
                </Button>
            </Col>
        </Row>
        <Col sm='12'>
            <InputText
              name='Name'
              placeholder="ชื่อสินค้า"
              onChange={(event)=>{manageArray(newMarket.name,'TH',event.target.value,(name)=>{dispatch(setNewMarket({...newMarket,name}))})}}
              value={Translator(newMarket.name,'TH')}
            />
        </Col>
        <Col sm='12'>
            <InputArea
              name='Detail'
              placeholder="รายละเอียดสินค้า"
              onChange={(event)=>{manageArray(newMarket.address,'TH',event.target.value,(address)=>{dispatch(setNewMarket({...newMarket,address}))})}}
              value={Translator(newMarket.address,'TH')}
            />
        </Col>
        <Row style={{marginBottom:'1rem'}} >
            <Col sm='3' >
                สถานะการขาย
            </Col>
            <Col sm='9' >                
                <Button onClick={()=>{setContact_Modal(true)}} style={{backgroundColor:colors.darkPurple,borderColor:colors.darkPurple,margin:'10px'}} >available</Button>
                <Button onClick={()=>{setContact_Modal(true)}} style={{backgroundColor:colors.darkPurple,borderColor:colors.darkPurple,margin:'10px'}} >out</Button>
                <Button onClick={()=>{setContact_Modal(true)}} style={{backgroundColor:colors.darkPurple,borderColor:colors.darkPurple,margin:'10px'}} >notSale</Button>
            </Col>
        </Row>
        <Row style={{marginBottom:'1rem'}} >
          <Col sm='3' >
              ตั้งราคาตามช่องทางการขาย
          </Col>
          <Col sm='9'>
            {['ทานที่ร้าน','Take Away','Delivery'].map((item, index) => (
              <FloatingText
                name={item}
                placeholder={item}
                onChange={(event)=>{manageObject(newMarket.location,'latitude',event.target.value,(location)=>{dispatch(setNewMarket({...newMarket,location}))})}}
                value={newMarket.location.latitude}
              />
            ))}         
          </Col>
        </Row>
        <Row style={{marginBottom:'1rem'}} >
            <Col sm='3' >
              กำหนดหมวดหมู่สินค้า
            </Col>
            <Col sm='9'>
            {smartCategory?.length >0
                    ?currentCategory.length >0
                        ?currentCategory.map((item,index)=>{
                            let res = []
                            const { level, name, } = item;
                            const value = findInArray(smartCategory,'level',level).value
                            const selectedSmart = findInArray(smartCategory,'level',level+1) // เพื่อตรวจดูว่ามันมีชั้นถัดไปไหม
                            if(selectedSmart && selectedSmart.level){ // ใช้ตรวจสอบว่าชั้นถัดไป มีตรงกับ above ก่อนหน้ามั้ย
                                res = checkCategory2(currentCategory,selectedSmart)
                            }
                            return(
                              <div key={index} sm='6'>
                                <Button variant="light" onClick={()=>{setSelectedCategory(value);setCategory_Modal(true)}} style={{border: '1px solid #dee2e6',borderRadius:20,backgroundColor:'white',padding:0,width:'100%'}} >
                                  <div style={{padding:10}} >
                                    <h4><b>{name}</b></h4>
                                    <h6 style={{color:colors.darkGray}}>หมวดหมู่ระดับ {level}</h6>
                                  </div>
                                </Button>
                                {currentCategory.length === index +1  // เพื่อให้แสดงเฉพาะตัวสุดท้าย
                                    ?res.length >0
                                        ?<Button variant="light" onClick={()=>{setSelectedCategory(res);setCategory_Modal(true)}} style={{border: '1px solid #dee2e6',borderRadius:20,backgroundColor:'white',padding:0,width:'100%'}} >
                                          <div style={{padding:10}} >
                                            <h4><b>--ยังไม่ได้กำหนด--</b></h4>
                                            <h6 style={{color:colors.darkGray}}>หมวดหมู่ระดับ 1</h6>
                                          </div>
                                        </Button>
                                        :null
                                    :null
                                }
                              </div>
                            )
                        })
                        :<div sm='6' onClick={()=>{setSelectedCategory(checkAddCategory(smartCategory,findInArray(smartCategory,'level',1),currentCategory,()=>{setCategory_Modal(true)}))}}  style={{padding:10}} >
                            <Button variant="light"  style={{border: '1px solid #dee2e6',borderRadius:20,backgroundColor:'white',padding:0,width:'100%'}} >
                              <div style={{padding:10}} >
                                <h4><b>--ยังไม่ได้กำหนด--</b></h4>
                                <h6 style={{color:colors.darkGray}}>หมวดหมู่ระดับ 1</h6>
                              </div>
                            </Button>
                        </div>
                    :<div sm='6' onClick={()=>{setCategoryAlert_Modal(true)}}  style={{padding:10}} >
                        <Button variant="light"  style={{border: '1px solid #dee2e6',borderRadius:20,backgroundColor:'white',padding:0,width:'100%'}} >
                          <div style={{padding:10}} >
                            <h4><b>--ยังไม่ได้กำหนด--</b></h4>
                          </div>
                        </Button>
                    </div>
                }


              {/* {['catLv001','catLv002',''].map((item, index) => (
                <div key={index} sm='6' onClick={()=>{alert('เลือกหมวดหมู่ที่ต้องการ')}}  style={{padding:10}} >
                    <Button variant="light"  style={{border: '1px solid #dee2e6',borderRadius:20,backgroundColor:'white',padding:0,width:'100%'}} >
                      <div style={{padding:10}} >
                        {item?
                          <h4><b>{item}</b></h4>:
                          <h4><b>--ยังไม่ได้กำหนด--</b></h4>
                        }
                      </div>
                      <div style={{color:colors.darkGray}} >
                        <h6>หมวดหมู่ระดับ {index+1}</h6>
                      </div>
                    </Button>
                </div>
              ))}               */}
            </Col>
        </Row>
        <Row style={{marginBottom:'1rem'}} >
          <Col sm='3' >
            Option
          </Col>
          <Col sm='9'>
            {['เพิ่มชีส','เพิ่มเส้น',''].map((item, index) => (
              <div key={index} sm='6' onClick={()=>{alert('เลือกหมวดหมู่ที่ต้องการ')}}  style={{padding:10}} >
                  <Button variant="light"  style={{border: '1px solid #dee2e6',borderRadius:20,backgroundColor:'white',padding:0,width:'100%'}} >
                    <div style={{padding:10}} >
                      <h4><b>{item}</b></h4>
                    </div>
                    <div style={{color:colors.darkGray}} >
                      <h6>หมวดหมู่ระดับ {index+1}</h6>
                    </div>
                  </Button>
              </div>
            ))}
            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
              <Button onClick={()=>{setContact_Modal(true)}} style={{backgroundColor:colors.darkPurple,borderColor:colors.darkPurple,padding:25,paddingTop:15,paddingBottom:15}} >เพิ่มตัวเลือก</Button>
            </div>
            
          </Col>
          {/* <Col sm='9'>
            {['เพิ่มชีส','เพิ่มเส้น',''].map((item, index) => (
              <div key={index} sm='6' onClick={()=>{alert('เลือกหมวดหมู่ที่ต้องการ')}}  style={{padding:10}} >
                  <Button variant="light"  style={{borderRadius:20,backgroundColor:colors.purplePeel,padding:0,width:'100%'}} >
                    <div style={{padding:10}} >
                      <h4><b>{item}</b></h4>
                    </div>
                    <div style={{color:colors.darkGray}} >
                      <h6>หมวดหมู่ระดับ {index+1}</h6>
                    </div>
                  </Button>
              </div>
            ))}
            <Button onClick={()=>{setContact_Modal(true)}} style={{backgroundColor:colors.darkPurple,borderColor:colors.darkPurple}} >เพิ่มตัวเลือก</Button>
          </Col> */}
        </Row>
        <Row style={{marginBottom:'1rem'}} >
          <Col sm='3' >
            Incentive (เชียร์ขายสินค้า)
          </Col>
          <Col sm='9'>
            <FloatingText
              name="ใส่จำนวนเงิน"
              placeholder="ใส่จำนวนเงิน"
              onChange={(event)=>{manageObject(newMarket.location,'latitude',event.target.value,(location)=>{dispatch(setNewMarket({...newMarket,location}))})}}
              value={newMarket.location.latitude}
            />
          </Col>
        </Row>
        <Row style={{marginBottom:'1rem'}} >
          <Col sm='3' >
            ต้นทุนสินค้า
          </Col>
          <Col sm='9'>
            <FloatingText
              name="ใส่ต้นทุน"
              placeholder="ใส่ต้นทุน"
              onChange={(event)=>{manageObject(newMarket.location,'latitude',event.target.value,(location)=>{dispatch(setNewMarket({...newMarket,location}))})}}
              value={newMarket.location.latitude}
            />
          </Col>
        </Row>
        <Row style={{marginBottom:'1rem'}} >
          <Col sm='3' >
            BOM (สูตรวัตถุดิบ)
          </Col>
          <Col sm='9' >
            {['เพิ่มชีส','เพิ่มเส้น'].map((item, index) => (
              <Row>
                <Col xs='6' >{index+1}. {item}</Col>
                <Col xs='6' style={{display:'flex',justifyContent:'flex-end',paddingRight:'1rem'}} >{3} {"หัว"} <i class="bi bi-trash" style={{marginLeft:'1rem'}} onClick={()=>{alert("Del_Bom_Item")}}></i></Col>
              </Row>
            ))}
            <Button onClick={()=>{setContact_Modal(true)}} style={{backgroundColor:colors.darkPurple,borderColor:colors.darkPurple,marginTop:'2rem'}} >เพิ่มวัตถุดิบ</Button>
          </Col>
        </Row>
        <Row style={{marginBottom:'1rem'}} >
            <Col sm='3' >
              ลบรายการสินค้า
            </Col>
            <Col sm='9' >
              <Button onClick={()=>{setContact_Modal(true)}} style={{backgroundColor:colors.primaryColor,borderColor:colors.primaryColor}} >
                ลบรายการสินค้า 
                <i class="bi bi-trash" style={{marginLeft:'1rem'}} onClick={()=>{alert("Del_Bom_Item")}}></i>   </Button>
            </Col>
        </Row>
        {/* ================= End of Restautrant ================== */}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="secondary">Cancel</Button>
        <Button onClick={checkMarketProfile} variant="primary">Confirm</Button>
    </Modal.Footer>
    </Modal>
  );
}

export default Modal_NewResProduct;
