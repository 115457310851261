import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { colors, mainImage } from "../configs";
import Modal_Report from "../modal/Modal_Report";
import { useSelector, useDispatch } from "react-redux";
import { endCutoff,  findInArray,  goToTop,  manageDashboard, startCutoff, summary } from "../Utility/function";
import { normalSort, reverseSort } from "../Utility/sort";
import { NumberYMD, minusDays, plusDays, stringFullTime, stringTime, stringYMDHMS, stringYMDHMS3, stringYMDHMS4 } from "../Utility/dateTime";
import TablePagination from '@mui/material/TablePagination';
import { Modal_Loading, Modal_ReportReceipt } from "../modal";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import { fetchBill, updateBills, updateDashBoard } from "../redux/resBillSlice";
import { CSVLink } from "react-csv";

// test
import Dropdown from 'react-bootstrap/Dropdown';


const billReportOptions = [
  {id:1,name:'ยอดสรุปบิล'},
  {id:2,name:'รายการในแต่ละบิล'},
  {id:3,name:'รายการในแต่ละบิลพร้อม Option'},
]

function ResReportScreen() {
  const dispatch = useDispatch();
  const [report_Modal, setReportId_Modal] = useState(false)
  const { shop } = useSelector((state)=> state.resShop)
  const { cutOff, channel, smartTable, humanResource, smartCategory, POSs, smartOption } = shop;
  const { products } = useSelector((state)=> state.resProduct)
  const { bills, billDates, modal_Bill, payment, product , category, 
    saleMan, normalBill, voidedBill, selectedBill, selectedVoidedBill, saleByChannel,
  orders, billOrder } = useSelector((state)=> state.resBill)
  const [reportId, setReportId] = useState(1);
  const [currentDisplay, setCurrentDisplay] = useState([]) // จำนวนที่แสดงในหนึ่งหน้า
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [store_Modal, setStore_Modal] = useState(false)
  const today = new Date();
  const [startDate, setStartDate] = useState(today); //some many of day array to initial graph
  const [endDate, setEndDate] = useState(today); //  today
  const [display, setDisplay] = useState([]);
  const [csvName, setCsvName] = useState('รายงานบิล')

  const [ billReportOption, setBillReportOption ] = useState({id:1,name:'ยอดสรุปบิล'})

  const reportOption = [
    {id:1,name:'บิล',imageId:mainImage.receiptblack},
    {id:2,name:'สินค้า',imageId:mainImage.productlist},
    {id:3,name:'หมวดหมู่',imageId:mainImage.categoryblack},
    {id:4,name:'พนักงาน',imageId:mainImage.humanresource2},
    // {id:5,name:'โปรโมชั่น',imageId:mainImage.promotion},
    {id:6,name:'ช่องทางขาย',imageId:mainImage.channelblack},
    {id:7,name:'ช่องทางชำระเงิน',imageId:mainImage.channelblack},
    // {id:8,name:'กะ',imageId:mainImage.shift},
    // {id:9,name:'วัตถุดิบ',imageId:mainImage.rawmat},
  ]


 useEffect(()=>{  
  let rewSearch = []
  let newSearch = []
  let start = minusDays(startDate,1)

  do {
    rewSearch.push(stringYMDHMS3(start))
    start = plusDays(start,1)
  }
  while (NumberYMD(start) < NumberYMD(plusDays(endDate,2)));

  for(const item of rewSearch){
    if(!billDates.includes(item)){
      newSearch.push(item)
    }
  }
  if(newSearch.length >0){
    dispatch(fetchBill({shopId:shop.id,billDate:newSearch,cutOff,startDate,endDate}))
  } else {
      dispatch(updateBills({startDate,endDate,cutOff}))
  }
  
},[startDate,endDate])

 useEffect(()=>{
    if(reportId===1){

        let displayBillData = [];
        if(billReportOption?.id === 1){
          displayBillData = [...selectedBill,...selectedVoidedBill];
        // }else{  // bill and product
        //   const originBill  = [...selectedBill,...selectedVoidedBill];
        //   // const displayBillData  = [...selectedBill,...selectedVoidedBill];


        //  originBill.forEach((bill,i) =>{

        //     const { receiptNumber,timestamp,channel:billChannel } = bill;

        //     bill.product.forEach(product =>{
        //       const { id, name, qty, diffQty, totalPrice } = product;

              
        //       let allSalesChannelName = '';     // !! Now is only easy detect channnel, not support multi channel in one receipt
        //       if(billChannel.length > 0){
        //         allSalesChannelName = 'dine in';
        //       }else{
        //         if(findInArray(channel,'id',billChannel[0])?.name){
        //           allSalesChannelName = findInArray(channel,'id',billChannel[0])?.name;
        //         }else{
        //           allSalesChannelName = 'dine in';
        //         }
        //       }


        //       displayBillData.push({
        //         transactionId:receiptNumber,
        //         timestamp:timestamp,
        //         date:stringYMDHMS4(timestamp|| new Date()),
        //         // date:'stringYMDHMS4(timestamp|| new Date())',
        //         time:stringTime(timestamp|| new Date()),
        //         // salesChannel:channel.map(a => findInArray(channel,'id',a)?.name).join('+'),
        //         salesChannel: allSalesChannelName,
        //         menuId:id,
        //         menuName:name,
        //         quantity:(qty-diffQty),
        //         price:Math.round(totalPrice/(qty-diffQty)),
        //         totalPaid:totalPrice,
        //         void:bill?.void? 'true':'false',

        //       })

              
        //     })
        //   })
          
        // }

        }else{  // bill and product
          const originBill  = [...selectedBill,...selectedVoidedBill];
          // const displayBillData  = [...selectedBill,...selectedVoidedBill];

          let allChoice = smartOption.flatMap(a=>a.choice.map(({choiceId,choiceName})=>({choiceId,choiceName,optionName:a.optionTopic,optionBackOfficeName:a?.backOfficeName,optionId:a.optionId})))

          originBill.forEach((bill,i) =>{

            const { receiptNumber,timestamp,channel:billChannel } = bill;

            bill.product.forEach(product =>{
              const { id, name, qty, diffQty, totalPrice } = product;

              // manage sale channel
              let allSalesChannelName = '';     // !! Now is only easy detect channnel, not support multi channel in one receipt
              if(billChannel.length > 0){
                allSalesChannelName = 'dine in';
              }else{
                if(findInArray(channel,'id',billChannel[0])?.name){
                  allSalesChannelName = findInArray(channel,'id',billChannel[0])?.name;
                }else{
                  allSalesChannelName = 'dine in';
                }
              }

              // manage option and choice
              const newChoice = []
              for(const choiceId of product.option){
                let findChoice = newChoice.find(b=>b.id===choiceId);
                if(findChoice && findChoice.id){
                  findChoice.qty++
                }else{
                  let availableChoice = allChoice.find(c=>c.choiceId === choiceId);
                  if(availableChoice && availableChoice.choiceId){
                    newChoice.push({
                      choiceId:choiceId,
                      qty:1,
                      choiceName:availableChoice?.choiceName,
                      optionName:availableChoice?.optionName,
                      optionBackOfficeName:availableChoice?.optionBackOfficeName,
                      optionId:availableChoice?.optionId,
                    })
                  }

                }
              }

              // // sort choice of option to make each choice of same option will enclose together
              // console.log('newChoice')
              // console.log(newChoice)


              displayBillData.push({
                transactionId:receiptNumber,
                timestamp:timestamp,
                date:stringYMDHMS4(timestamp|| new Date()),
                // date:'stringYMDHMS4(timestamp|| new Date())',
                time:stringTime(timestamp|| new Date()),
                // salesChannel:channel.map(a => findInArray(channel,'id',a)?.name).join('+'),
                salesChannel: allSalesChannelName,
                menuId:id,
                menuName:name,
                quantity:(qty-diffQty),
                // === additional field ===
                displayOptionId:newChoice.map(choice => choice.optionId).join(' + ') || '',
                displayChoiceId:newChoice.map(choice => choice.choiceId).join(' + ') || '',
                displayChoiceName:newChoice.map(({optionName, optionBackOfficeName, choiceName}) => 
                  `${optionBackOfficeName.trim() ? optionName+' ('+optionBackOfficeName+')':optionName}:${choiceName}`
                ).join(' + ') || '',
                displayChoiceQty:newChoice.map(choice => choice.qty).join(' + ') || '',
                addOn:product?.addOn,
                // === end of additional field ===
                price:Math.round(totalPrice/(qty-diffQty)),
                totalPaid:totalPrice,
                void:bill?.void? 'true':'false',

              })

              
            })
          })
          
        }


        // setDisplay(reverseSort('timestamp',[...selectedBill,...selectedVoidedBill]))
        setDisplay(reverseSort('timestamp',displayBillData))
        if(billReportOption?.id === 1) setCsvName('รายงานบิล')
        if(billReportOption?.id === 2) setCsvName('รายงานบิล (รายการอาหารในบิล)')
        if(billReportOption?.id === 3) setCsvName('รายงานบิล (รายการอาหารในบิลพร้อม Option)')
    } else if(reportId===2){
        setDisplay(normalSort('allTotalPrice',[...product]))
        setCsvName('รายงานสินค้า')
    } else if(reportId===3){
        setDisplay(normalSort('allTotalPrice',[...category]))
        setCsvName('รายงานหมวดหมู่')
    } else if(reportId===4){
        setDisplay(normalSort('allTotalPrice',[...saleMan]))
        setCsvName('รายงานพนักงาน')
    } else if(reportId===5){
        setDisplay([])
        setCsvName('รายงานโปรโมชั่น')
    } else if(reportId===6){
        setDisplay(normalSort('allTotalPrice',[...saleByChannel]))
        setCsvName('รายงานช่องทางขาย')
    } else {
        setDisplay(normalSort('allTotalPrice',[...payment]))
        setCsvName('รายงานชำระเงิน')
    } 
 },[selectedBill,reportId,billReportOption])

 useEffect(()=>{
  let selectedBill = normalBill.filter((item)=>{return(stringYMDHMS(item.timestamp) > stringYMDHMS(startCutoff(startDate,new Date(cutOff))) && stringYMDHMS(item.timestamp) <= stringYMDHMS(endCutoff(endDate,new Date(cutOff))))})
  let selectedVoidedBill = voidedBill.filter((item)=>{return(stringYMDHMS(item.timestamp) > stringYMDHMS(startCutoff(startDate,new Date(cutOff))) && stringYMDHMS(item.timestamp) <= stringYMDHMS(endCutoff(endDate,new Date(cutOff))))})
  setDisplay(bills.filter((item)=>{return(stringYMDHMS(item.timestamp) > stringYMDHMS(startCutoff(startDate,new Date(cutOff))) && stringYMDHMS(item.timestamp) <= stringYMDHMS(endCutoff(endDate,new Date(cutOff))))}))
  dispatch(updateDashBoard({...manageDashboard({ selectedBill, products, channel, smartTable, humanResource, smartCategory, selectedVoidedBill}),graph:[]}))

},[startDate, endDate, normalBill])

  const handleChangePage = (event, newPage) => {
      setPage(newPage); // start form 0
      goToTop()
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      goToTop()
  };


  useEffect(()=>{
    let fData = display.map((item,index)=>{return({...item,no:index})}).filter((item,index)=>{return(index >=(page*rowsPerPage) && index <= ((page+1)*rowsPerPage)-1)})
      setCurrentDisplay(fData)
  },[page,rowsPerPage,display])

  // // ==== test ====
  // console.log('currentDisplay')
  // console.log(currentDisplay)
  // // ==== end of test =====

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{borderRadius:20}} onClick={onClick} ref={ref}>
      {value}
    </div>
  ));


  return (
    <div  >
      <Modal_Loading show={modal_Bill} />
      <Modal_Report
        show={report_Modal}
        onHide={()=>{setReportId_Modal(false)}}
      />
      <Modal_ReportReceipt
        show={store_Modal}
        onHide={()=>{setStore_Modal(false)}}
        data={display}
        reportId={reportId}
      />
      <Row >
          <Col lg='4'  >
              <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
                <img style={{width:30}} src={mainImage.calender} />
                <div style={{paddingLeft:10,paddingRight:10}} >  Start: </div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<ExampleCustomInput />}
                />
              </div>
          </Col>
          <Col lg='4'  >
            <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
              <img style={{width:30}} src={mainImage.calender} />
              <div style={{paddingLeft:10,paddingRight:10}} >  End: </div>
              <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  customInput={<ExampleCustomInput />}
              />
            </div>
          </Col>
          <Col lg='4' style={{padding:10}} >
            <CSVLink 
              style={{backgroundColor:'#292929',textDecoration:'none',color:colors.white,padding:10,borderRadius:20,paddingLeft:30,paddingRight:30}}
              filename={`${csvName}${stringYMDHMS3(startDate)}-${stringYMDHMS3(endDate)}`}
              data={
                // reportId===1
                //     ?display.map((item)=>{return({
                //       receiptNumber:item.receiptNumber,
                //       totalQty:item.totalQty,
                //       subTotal:item.subTotal,
                //       discount:summary(item.discount,'amount'),
                //       serviceChargeAmount:item.serviceChargeAmount,
                //       vatAmount:item.vatAmount,
                //       net:item.net,
                //       void:item.void,
                //       timestamp:stringFullTime(item.timestamp)
                //     })})
                //     :display.map((item)=>{return({
                //       ชื่อ:item.name,
                //       จำนวน:item.allQty,
                //       ยอดขาย:item.allTotalPrice,
                //     })})

                reportId===1
                    ?(
                      billReportOption.id === 1 ?
                        display.map((item)=>{return({
                          receiptNumber:item.receiptNumber,
                          totalQty:item.totalQty,
                          subTotal:item.subTotal,
                          discount:summary(item.discount,'amount'),
                          serviceChargeAmount:item.serviceChargeAmount,
                          vatAmount:item.vatAmount,
                          net:item.net,
                          void:item.void,
                          timestamp:stringFullTime(item.timestamp),
                          customerQty:item.customerQty,
                          average:item.customerQty?item.net/item.customerQty:''
                        })})
                      // :
                      // display.map((item)=>{return({
                      //   transactionId:item.transactionId,
                      //   date:item.date,
                      //   time:item.time,
                      //   salesChannel:item.salesChannel,
                      //   menuId:item.menuId,
                      //   menuName:item.menuName,
                      //   quantity:item.quantity,
                      //   price:item.price,
                      //   totalPaid:item.totalPaid,
                      //   void:item.void,
                      // })})
                      
                      : billReportOption.id === 2 ?
                        display.map((item)=>{return({
                          transactionId:item.transactionId,
                          date:item.date,
                          time:item.time,
                          salesChannel:item.salesChannel,
                          menuId:item.menuId,
                          menuName:item.menuName,
                          quantity:item.quantity,
                          price:item.price,
                          totalPaid:item.totalPaid,
                          void:item.void,
                        })}) 
                      :
                        display.map((item)=>{return({
                          transactionId:item.transactionId,
                          date:item.date,
                          time:item.time,
                          salesChannel:item.salesChannel,
                          menuId:item.menuId,
                          menuName:item.menuName,
                          quantity:item.quantity,
                          displayChoiceName:item.displayChoiceName,
                          displayChoiceQty:item.displayChoiceQty,
                          price:item.price,
                          totalPaid:item.totalPaid,
                          void:item.void,
                        })})

                      
                    )
                    :display.map((item)=>{return({
                      ชื่อ:item.name,
                      จำนวน:item.allQty,
                      ยอดขาย:item.allTotalPrice,
                    })})
              }
            >
                Export
            </CSVLink>
          </Col>
          
      </Row>
        <Row style={{borderRadius:20,marginTop:10,marginBottom:10}} >
            {reportOption.map((item,index)=>{
              return(
                  <Col key={index} xs='4' sm='4' md='4' lg='3' onClick={()=>{setReportId(item.id)}} style={{cursor:'pointer',padding:3}} >
                      <div style={{marginLeft:0,marginRight:0,display:'flex',flexDirection:'column',justifyContent:'center',backgroundColor:item.id===reportId?'yellow':colors.white,borderRadius:20,marginBottom:5,alignItems:'center',padding:'5px 0px 2.5px 0px'}} >
                          <img src={item.imageId} style={{width:'50px'}} />
                          <h6>{item.name}</h6>
                      </div>
                  </Col>
              )
            })}
        </Row>
        {reportId===1 &&
          <Dropdown data-bs-theme="dark" style={{marginBottom:10}} >
            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary" >
              {billReportOption.name}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }} >
              {billReportOptions.map((item,index)=>{
                return(
                  <Dropdown.Item key={index} onClick={()=>{setBillReportOption(item)}} >{item.name}</Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        }
        {reportId===1
          ?(billReportOption.id === 1 ? 
            <Row>
              <Table striped bordered hover responsive  variant="light"   >
                <thead  >
                  <tr>
                    <th style={{textAlign:'center', width: '10%'}} >No.</th>
                    <th style={{textAlign:'center'}} >เลขที่บิล</th>
                    <th style={{textAlign:'center'}}>POS</th>
                    <th style={{textAlign:'center'}}>ยอดขาย</th>
                    <th style={{textAlign:'center'}}>สถานะ</th>
                    <th style={{textAlign:'center'}}>วัน เวลา</th>
                    <th style={{textAlign:'center'}}>จำนวนลูกค้า</th>
                    <th style={{textAlign:'center'}}>ยอดขายต่อหัว</th>
                  </tr>
                </thead>
                <tbody  >
                  {currentDisplay.map((item, index) => (
                    <tr  style={{cursor: 'pointer'}} onClick={()=>{alert(item.receiptNumber)}} key={index} >
                      <td>{item.no+1}.</td>
                      <td >{item.receiptNumber}</td>
                      <td style={{textAlign:'center'}}>{findInArray(POSs,'id',item.posId)?.name||item.posId}</td>
                      <td style={{textAlign:'center'}}>{item.net?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                      <td style={{textAlign:'center'}}>{item.void?'ยกเลิก':'ปกติ'}</td>
                      <td style={{textAlign:'center'}}>{stringFullTime(item.timestamp|| new Date())}</td>
                      <td style={{textAlign:'center'}}>{item.customerQty}</td>
                      <td style={{textAlign:'center'}}>{item.customerQty?(item.net/item.customerQty)?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2}):''}</td>

                    </tr>
                  ))}
                </tbody>
              </Table>
              <TablePagination
                  component="div"
                  count={display.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Row>
            // :
            // <Row>
            //   <Table striped bordered hover responsive  variant="light"   >
            //     <thead  >
            //       <tr>
            //         <th style={{textAlign:'center'}} >Transaction ID</th>
            //         <th style={{textAlign:'center'}}>Date</th>
            //         <th style={{textAlign:'center'}}>Time</th>
            //         <th style={{textAlign:'center'}}>Sales Channel</th>
            //         <th style={{textAlign:'center'}}>Memu ID</th>
            //         <th style={{textAlign:'center'}}>Menu Name</th>
            //         <th style={{textAlign:'center'}}>Quantity</th>
            //         <th style={{textAlign:'center'}}>Price</th>
            //         <th style={{textAlign:'center'}}>Total Paid</th>
            //         <th style={{textAlign:'center'}}>Void</th>
            //       </tr>
            //     </thead>
            //     <tbody  >
            //       {currentDisplay.map((item, index) => (
            //         <tr  style={{cursor: 'pointer'}} onClick={()=>{alert(item.transactionId)}} key={index} >
            //           <td >{item.transactionId}</td>
            //           <td style={{textAlign:'center'}}>{item.date}</td>
            //           <td style={{textAlign:'center'}}>{item.time}</td>
            //           <td style={{textAlign:'center'}}>{item.salesChannel}</td>
            //           <td style={{textAlign:'center'}}>{item.menuId}</td>
            //           <td style={{textAlign:'center'}}>{item.menuName}</td>
            //           <td style={{textAlign:'center'}}>{item.quantity}</td>
            //           <td style={{textAlign:'center'}}>{item.price}</td>
            //           <td style={{textAlign:'center'}}>{item.totalPaid}</td>
            //           <td style={{textAlign:'center'}}>{item.void}</td>
            //         </tr>
            //       ))}
            //     </tbody>
            //   </Table>
            //   <TablePagination
            //       component="div"
            //       count={display.length}
            //       page={page}
            //       onPageChange={handleChangePage}
            //       rowsPerPage={rowsPerPage}
            //       onRowsPerPageChange={handleChangeRowsPerPage}
            //   />
            // </Row>

            : billReportOption.id === 2 ?
            <Row>
              <Table striped bordered hover responsive  variant="light"   >
                <thead  >
                  <tr>
                    <th style={{textAlign:'center'}} >Transaction ID</th>
                    <th style={{textAlign:'center'}}>Date</th>
                    <th style={{textAlign:'center'}}>Time</th>
                    <th style={{textAlign:'center'}}>Sales Channel</th>
                    <th style={{textAlign:'center'}}>Memu ID</th>
                    <th style={{textAlign:'center'}}>Menu Name</th>
                    <th style={{textAlign:'center'}}>Quantity</th>
                    <th style={{textAlign:'center'}}>Price</th>
                    <th style={{textAlign:'center'}}>Total Paid</th>
                    <th style={{textAlign:'center'}}>Void</th>
                  </tr>
                </thead>
                <tbody  >
                  {currentDisplay.map((item, index) => (
                    <tr  style={{cursor: 'pointer'}} onClick={()=>{alert(item.transactionId)}} key={index} >
                      <td >{item.transactionId}</td>
                      <td style={{textAlign:'center'}}>{item.date}</td>
                      <td style={{textAlign:'center'}}>{item.time}</td>
                      <td style={{textAlign:'center'}}>{item.salesChannel}</td>
                      <td style={{textAlign:'center'}}>{item.menuId}</td>
                      <td style={{textAlign:'center'}}>{item.menuName}</td>
                      <td style={{textAlign:'center'}}>{item.quantity}</td>
                      <td style={{textAlign:'center'}}>{item.price}</td>
                      <td style={{textAlign:'center'}}>{item.totalPaid}</td>
                      <td style={{textAlign:'center'}}>{item.void}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <TablePagination
                  component="div"
                  count={display.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Row>

            :
            <Row>
              <Table striped bordered hover responsive  variant="light"   >
                <thead  >
                  <tr>
                    <th style={{textAlign:'center'}} >Transaction ID</th>
                    <th style={{textAlign:'center'}}>Date</th>
                    <th style={{textAlign:'center'}}>Time</th>
                    <th style={{textAlign:'center'}}>Sales Channel</th>
                    <th style={{textAlign:'center'}}>Memu ID</th>
                    <th style={{textAlign:'center'}}>Menu Name</th>
                    <th style={{textAlign:'center'}}>Quantity</th>
                    <th style={{textAlign:'center'}}>Choice Name</th>
                    <th style={{textAlign:'center'}}>Choice Qty</th>
                    <th style={{textAlign:'center'}}>Price</th>
                    <th style={{textAlign:'center'}}>Total Paid</th>
                    <th style={{textAlign:'center'}}>Void</th>
                  </tr>
                </thead>
                <tbody  >
                  {currentDisplay.map((item, index) => (
                    <tr  style={{cursor: 'pointer'}} onClick={()=>{alert(item.transactionId)}} key={index} >
                      <td >{item.transactionId}</td>
                      <td style={{textAlign:'center'}}>{item.date}</td>
                      <td style={{textAlign:'center'}}>{item.time}</td>
                      <td style={{textAlign:'center'}}>{item.salesChannel}</td>
                      <td style={{textAlign:'center'}}>{item.menuId}</td>
                      <td style={{textAlign:'center'}}>{item.menuName}</td>
                      <td style={{textAlign:'center'}}>{item.quantity}</td>
                      <td style={{textAlign:'center'}}>{item.displayChoiceName}</td>
                      <td style={{textAlign:'center'}}>{item.displayChoiceQty}</td>
                      <td style={{textAlign:'center'}}>{item.price}</td>
                      <td style={{textAlign:'center'}}>{item.totalPaid}</td>
                      <td style={{textAlign:'center'}}>{item.void}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <TablePagination
                  component="div"
                  count={display.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </Row>
            ):
          <Row>
            <Table striped bordered hover responsive  variant="light"   >
              <thead  >
                <tr>
                  <th style={{ width: '10%' }}>No.</th>
                  <th style={{ width: '50%' }}>ชื่อ</th>
                  <th style={{ width: '20%' }}>จำนวน</th>
                  <th style={{ width: '20%' }}>ยอดขาย</th>
                </tr>
              </thead>
              <tbody  >
                {currentDisplay.map((item, index) => (
                  <tr  style={{cursor: 'pointer'}} key={index} >
                    <td>{item.no+1}.</td>
                    <td >{item.name}</td>
                    <td style={{textAlign:'center'}}>{item.allQty}</td>
                    <td style={{textAlign:'center'}}>{item.allTotalPrice?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TablePagination
                component="div"
                count={display.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Row>
        }
    </div>
  );
}

export default ResReportScreen;
