import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink, useNavigate } from 'react-router-dom';
import { anonymous_Id, mainImage } from '../configs';
import { useSelector, useDispatch } from "react-redux";
import { logout, updatePath } from '../redux/authSlice';
import { updateCurrentProfile } from '../redux/profileSlice';

function NavBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state)=> state.auth);

  function checkRealLogin(route){
    if(currentUser.uid == anonymous_Id){
      dispatch(updatePath(route))
      navigate("/loginScreen")
    } else {
      navigate(route)
    }
  };
  console.log(currentUser)
  const adminID = ['cZ7XkJeZzNOrr5HEZKEPgAjtMrx2','vid0EZwJTPg9GHA8pjgsMpOmg9j1','eQLm1OgPWGMVMzwbwKSbjGYJJ5f1']
  function checkRight(){
    if(adminID.includes(currentUser.uid)){
      navigate("/admin")
    }
    navigate("/admin")
  }


  return (
    <Navbar bg="light" style={{paddingTop:0,paddingBottom:0,display:'flex',justifyContent:'space-between'}} >
        <div>
            <Navbar.Brand  style={{paddingLeft:20,paddingTop:0,paddingBottom:0}} >
              <img onClick={checkRight} src={mainImage.logo} style={{width:150}} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
        <div>
          <Navbar.Collapse id="basic-navbar-nav"  >
            <Nav className="me-auto">
              {/* <button onClick={()=>{alert('logout')}}>logout</button> */}
              {/* <Nav.Link href="#home">หน้าแรก</Nav.Link>
              <Nav.Link href="#link">ติดต่อเรา</Nav.Link>
              <Nav.Link href="#link">คำถามที่พบบ่อย</Nav.Link> */}
              {/* {currentProfile.name} */}
              <NavDropdown title="เข้าสู่ระบบ" id="basic-nav-dropdown"  >
                <a onClick={()=>{checkRealLogin("/landlord")}} >
                  <NavDropdown.Item >landlord</NavDropdown.Item>
                </a>
                <a onClick={()=>{checkRealLogin("/resHome")}}>
                  <NavDropdown.Item >ร้านอาหาร</NavDropdown.Item>
                </a>
                <a onClick={()=>{checkRealLogin("/branchPort")}}>
                  <NavDropdown.Item >ร้านอาหาร(สาขา)</NavDropdown.Item>
                </a>
                {/* <NavDropdown.Item href="#action/3.3">retail</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  other
                </NavDropdown.Item> */}
              </NavDropdown>
              <Nav.Link onClick={()=>{dispatch(logout()).then(()=>{dispatch(updateCurrentProfile());alert('ออกจากระบบ เรียบร้อยแล้ว')})}}>ออกจากระบบ</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </div>
        
    </Navbar>
  );
}

export default NavBar;