export default {
    backgroundColor:'#EDF1F5',
    transparent:'rgba(0, 0, 0, 0)',
    purplePeel:'#E3DAFF',
    purpleRed:'#B053C9',
    darkPurple:'#5E17EB',
    softGray:'#A1A4A9',
    blueBackground:'rgb(225, 231, 238)',
    darkGray:"#777777",
    white:'#FFFFFF',
    lightGray:'#DCDDDE',
    lightGreen:'#59CD59',
    purple:'#9A6DF8',
    purpleTransparent:'rgba(197, 172, 248,1)',
    primaryColor: '#f44336ff',
    redPink:'#E9245F',
    redPinkTransparent:'rgba(233, 36, 95,0.3)',
    orange:'#FF914d',
    redOrange:'#FF6F1D',
    dark:'rgba(0,0,0)',
    transparent:'rgba(0,0,0,0)',
    lightGray:'#F0F3F4',
    softWhite:'#F4F6FA',
};