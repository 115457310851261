import React, { useState, useContext } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";

function FloatingText({
    name='name',
    placeholder="Put here...",
    onChange,
    value
}) {

  return (
    <Form.Floating className="mb-3" style={{width:'100%'}} >
        <Form.Control
            name={name}
            type="text"
            placeholder={placeholder}
            onChange={onChange}
            value={value}
        />
        <label >{name}</label>
    </Form.Floating>
  );
}

export default FloatingText;
