import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Modal, Card, Image, Table } from "react-bootstrap";
import { colors, contact, mainImage } from "../configs";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { stringDate, stringFullDate } from '../Utility/dateTime';
import { useSelector, useDispatch } from "react-redux";


function Modal_ShopReport({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='xl',
  onClick,
  data
}) {
    const today = new Date();

  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      size={size}
    >
      <Modal.Header style={{paddingTop:50,position:'relative'}} >
           
            {/* <div style={{borderRadius:20,backgroundColor:colors.darkPurple,padding:10,color:colors.white,display:'flex',alignItems:'center'}} >
                <i class="bi bi-box-arrow-in-down" style={{paddingRight:10}} ></i>
                Export
                
            </div> */}
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-secondary"
                table="table-to-xls2"
                filename={`ร้านค้าทั้งหมด${stringDate(new Date())}`}
                sheet="all store"
                buttonText='Export all store'
            />
            <div onClick={onHide}  style={{position:'absolute',top:0,right:15,borderRadius:100,fontSize:36,borderWidth:0,cursor: 'pointer',color:colors.darkGray}} >
                x
            </div>
            
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'transparent',overflow:'auto',maxHeight:'500px'}} >
        <Table   responsive id="table-to-xls2" >
          <thead >
            <tr>
              <th>No.</th>
              <th>Shop Name</th>
              <th>Entrepreneur</th>
              <th>วันที่เข้าร่วม</th>
              <th>Credit</th>
              <th>Zone</th>
            </tr>
          </thead>
          <tbody>
              
              {data.map((item, index) => (
                <tr key={index}  >
                  <td>{index+1}</td>
                  <td >{item.shopName}</td>
                  <td>{item.name}</td>
                  <td style={{textAlign:'center'}}>{stringFullDate(new Date(item.timestamp))}</td>
                  <td style={{textAlign:'center'}}>{item.point}</td>
                  <td style={{textAlign:'center'}}>0</td>
                </tr>
              ))}
            {/* {Array.from({ length: 500 }).map((_, index) => (
                <tr >
                  <td>{index+1}</td>
                  <td>
                    สมพงษ์
                  </td>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <td  key={index}>Table cell {index}</td>
                  ))}
                </tr>
              ))} */}
            
          </tbody>
        </Table>
        
      </Modal.Body>
    </Modal>
  );
}

export default Modal_ShopReport;
