import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import ChartScreen from "./ChartScreen";
import { bath, chartColor, colors, mainImage } from "../configs";
import Modal_Report from "../modal/Modal_Report";
import { useSelector, useDispatch } from "react-redux";
import { endCutoff,  filterDeleteInArray,  findInArray,  manageDashboard, startCutoff, summary } from "../Utility/function";
import { normalSort } from "../Utility/sort";
import { NumberYMD, createDate, firstDayOfMonth, getWeek, lastDayOfMonth, plusDays, stringDateTime3, stringGetYearMonth, stringYMDHMS, stringYMDHMS3, totalDateOfMonth } from "../Utility/dateTime";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import { minusDays } from "../db/firestore";
import Modal_Loading from "../modal/Modal_Loading";
import Dropdown from 'react-bootstrap/Dropdown';
import { fetchBill, updateBills, updateDashBoard, updateSelectedShop } from "../redux/adminSlice";


function AdminDashboardScreen() {
  const dispatch = useDispatch();
  const [report_Modal, setReport_Modal] = useState(false);
  const { shops, products, selectedShop, billDates, modal_Bill, graph, payment, product , category, saleMan, normalBill, voidedBill, selectedVoidedBill, selectedBill } = useSelector((state)=> state.admin);
  const { cutOff, channel, smartTable, humanResource, smartCategory, id:shopId } = selectedShop;
  const [durationId, setDurationId] = useState(3); // เดือนนี้
  const today = new Date();
  const [startDate, setStartDate] = useState(firstDayOfMonth()); //some many of day array to initial graph
  const [endDate, setEndDate] = useState(lastDayOfMonth()); //  today
  const [display, setDisplay] = useState([]);

  useEffect(()=>{  
    let rewSearch = []
    let newSearch = []
    let start = minusDays(startDate,1)
  
    do {
      rewSearch.push(stringYMDHMS3(start))
      start = plusDays(start,1)
    }
    while (NumberYMD(start) < NumberYMD(plusDays(endDate,2)));
  
    for(const item of rewSearch){
      if(!billDates.includes(item)){
        newSearch.push(item)
      }
    }
    if(newSearch.length >0){
      dispatch(fetchBill({shopId,billDate:newSearch,cutOff,startDate,endDate}))
    } else {
        dispatch(updateBills({startDate,endDate,cutOff}))
    }
    
  },[startDate,endDate])


 useEffect(()=>{
    let datasets = [] // ยอดขายรายวัน
    let selectedBill = normalBill.filter((item)=>{return(stringYMDHMS(item.timestamp) > stringYMDHMS(startCutoff(startDate,new Date(cutOff))) && stringYMDHMS(item.timestamp) <= stringYMDHMS(endCutoff(endDate,new Date(cutOff))))})
    let selectedVoidedBill = voidedBill.filter((item)=>{return(stringYMDHMS(item.timestamp) > stringYMDHMS(startCutoff(startDate,new Date(cutOff))) && stringYMDHMS(item.timestamp) <= stringYMDHMS(endCutoff(endDate,new Date(cutOff))))})
    
    for(let i=1;i<=totalDateOfMonth();i++){
      let date = createDate(i)
      let data = normalBill.filter((item)=>{return(stringYMDHMS(item.timestamp) > stringYMDHMS(startCutoff(date,new Date(cutOff))) && stringYMDHMS(item.timestamp) <= stringYMDHMS(endCutoff(date,new Date(cutOff))))})
  
      datasets.push({
        name:String(i),
        ยอดขายรายวัน:summary(data,'net'),
        store:data.length,
      })
    }
    dispatch(updateDashBoard({...manageDashboard({ selectedBill, products, channel, smartTable, humanResource, smartCategory, selectedVoidedBill}),graph:datasets}))
  
  },[startDate, endDate, normalBill])

  const [goodSale, setGoodSale] = useState(1)

  useEffect(()=>{
    if(goodSale===1){
      setDisplay(normalSort('allTotalPrice',[...category]).slice(0,8))
    } else if(goodSale===2){
      setDisplay(normalSort('allTotalPrice',[...product]).slice(0,8))
    } else {
      setDisplay(normalSort('allTotalPrice',[...saleMan]).slice(0,8))
    }
  },[goodSale,product])

  const goodSaleOption = [
    {id:1,name:'Top Category'},
    {id:2,name:'Top Product'},
    {id:3,name:'Top Saler'},
  ]

  const durationOption = [
    {id:1,name:'วันนี้'},
    {id:2,name:'สัปดาห์นี้'},
    {id:3,name:'เดือนนี้'},
    {id:4,name:'กำหนดเอง'},
  ]

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{borderRadius:20}} onClick={onClick} ref={ref}>
      {value}
    </div>
  ));
  let weekDuration = getWeek(new Date()); // วันจันทร์ - วันอาทิตย์
  const manageDuration = (object) =>{  // จัดการกรอบเวลาในการค้นหาข้อมูล
    if(object.id ===1){
        arrayDay(today,today)
    } else if(object.id ===2){
        arrayDay(weekDuration.startDate,today)
    } else if(object.id ===3){
        let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
        arrayDay(firstDayOfMonth,today)
    } else{
        // arrayDay(custom_StartDate,custom_EndDate)
    }
}
const arrayDay = (startDay,endDay) =>{
  setStartDate(startDay)
  setEndDate(endDay)
}

function checkShop(shop){
  if(shop.id !== selectedShop.id){ // แปลว่ามีการเปลี่ยน shop
    dispatch(updateSelectedShop(shop))
    let newSearch = []
    let start = minusDays(startDate,1)

    do {
      newSearch.push(stringYMDHMS3(start))
      start = plusDays(start,1)
    }
    while (NumberYMD(start) < NumberYMD(plusDays(endDate,2)));
    if(newSearch.length >0){
      dispatch(fetchBill({shopId:shop.id,billDate:newSearch,cutOff:shop.cutOff}))
    }
  }
}

  return (
    <div id="google_translate_element" >
      {/* <script type="text/javascript">
      function googleTranslateElementInit() {
        new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element')
      }
      </script>
      <script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script> */}
      <Modal_Loading show={modal_Bill} />
      <Modal_Report
        show={report_Modal}
        onHide={()=>{setReport_Modal(false)}}
      />
      <Dropdown data-bs-theme="dark" style={{marginBottom:10}} >
          <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary" >
            {selectedShop.name}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }} >
              {shops.map((item,index)=>{
                return(
                    <Dropdown.Item key={index} onClick={()=>{checkShop(item)}} >{item.name}</Dropdown.Item>
                )
              })}
          </Dropdown.Menu>
      </Dropdown>
      <div style={{display:'flex'}} >
        <h2><b>Dashboard</b></h2>
        
        <h2 style={{color:colors.purple}} ><b>&emsp;ประจำวันที่ {stringDateTime3(new Date())}</b></h2>
      </div>
      <Row>
          {durationOption.map((item,index)=>{
            return(
              <Col xs='12' md='6' lg='3' key={index} style={{cursor:'pointer',padding:3}} onClick={()=>{setDurationId(item.id);manageDuration(item)}} >
                  <div style={{marginLeft:0,marginRight:0,display:'flex',flexDirection:'column',justifyContent:'center',backgroundColor:item.id===durationId?'yellow':colors.white,borderRadius:20,marginBottom:5,alignItems:'center',padding:'5px 0px 2.5px 0px'}} >
                      <h6>{item.name}</h6>
                  </div>
              </Col>
            )
          })}
      </Row>
      {durationId===4
          ?<Container
              fluid
              style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems:'center',
              marginBottom:40
              }}
          >
              <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white}} >
                  <img style={{width:30}} src={mainImage.calender} />
                  <div style={{paddingLeft:10,paddingRight:10}} >  Start: </div>
                  <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      customInput={<ExampleCustomInput />}
                  />
              </div>
              <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginLeft:20}} >
                <img style={{width:30}} src={mainImage.calender} />
                <div style={{paddingLeft:10,paddingRight:10}} >  End: </div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    customInput={<ExampleCustomInput />}
                />
              </div>
          </Container>
          :null
      }

      <Row style={{backgroundColor:colors.white,borderRadius:20,marginLeft:0,marginRight:0,marginBottom:20}} >
          <Col  lg={12} >
            <div style={{backgroundColor:'white',margin:5,padding:10,borderRadius:20,height:'400px',marginRight:0}} >
              ยอดขายเดือน {stringGetYearMonth(new Date())}
              <ChartScreen chart={graph} />
            </div>
          </Col>
      </Row>
        <Row style={{ backgroundColor: colors.white, borderRadius: 20, marginLeft: 0, marginRight: 0, marginBottom:20 }}>
            <Row style={{paddingTop:10}} >
                <Col md='6' lg='3' style={{ display: 'flex', flexDirection: 'column',}} >
                    <h4>ยอดขายรวม</h4>
                    <h2><b>{bath} {summary(selectedBill,'net')?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</b></h2>
                </Col>
                <Col md='6' lg='3' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',marginBottom:10 }}>
                    <div style={{ border: `1px solid ${colors.lightGray}`, borderRadius: 20, padding: 10 }}>
                        <h5 style={{ color: colors.softGray, whiteSpace: 'nowrap' }}>ยอดเงินสด</h5>
                        <h4 style={{ color: colors.softGray, textAlign: 'center' }}>{(findInArray(payment,'id','001')?.totalAmount||0).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</h4>
                    </div>
                </Col>
                <Col md='6' lg='3' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',marginBottom:10 }}>
                    <div style={{ border: `1px solid ${colors.lightGray}`, borderRadius: 20, padding: 10 }}>
                        <h5 style={{ color: colors.softGray, whiteSpace: 'nowrap' }}>ยอดที่ไม่ใช่เงินสด</h5>
                        <h4 style={{ color: colors.softGray, textAlign: 'center' }}>{summary(filterDeleteInArray([...payment],'id','001'),'totalAmount').toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</h4>
                    </div>
                </Col>
                <Col md='6' lg='3' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',marginBottom:10 }}>
                    <div style={{ border: `1px solid ${colors.lightGray}`, borderRadius: 20, padding: 10 }}>
                        <h5 style={{ color: colors.softGray, whiteSpace: 'nowrap' }}>จำนวนออเดอร์</h5>
                        <h4 style={{ color: colors.softGray, textAlign: 'center'  }}>{summary(payment,'orderQty')}</h4>
                    </div>
                </Col>
            </Row>
            <h4>ช่องทางการชำระเงิน</h4>
            <Row>
                {selectedShop?.payment?.map((item,index)=>{
                    const { totalAmount, orderQty } = findInArray(payment,'id',item.id) || {totalAmount:0,orderQty:0}
                    return(
                        <Col key={index} md='6' lg='4' xl='3' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                            <div style={{ border: `1px solid ${colors.lightGray}`, borderRadius: 20, padding: 10, marginBottom:10, backgroundColor:findInArray(chartColor,'id',9- index)?.color }}>
                                <h5 style={{ color: colors.white, whiteSpace: 'nowrap' }}><b>{item.name}</b></h5>
                                <h4 style={{ color: colors.white, textAlign: 'center' }}>{totalAmount.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</h4>
                                <h6 style={{fontSize:'0.75rem'}} >จำนวน order : {orderQty}</h6>
                                <h6 style={{fontSize:'0.75rem'}} >ยอดขายเฉลี่ย</h6>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </Row>
        <Row style={{ borderRadius: 20, marginLeft: 0, marginRight: 0,padding:0 }}>
            <Col md='12' lg='8' style={{padding:0,paddingRight:5}} >
                <div style={{ border: `1px solid ${colors.lightGray}`, borderRadius: 20, padding: 10,backgroundColor:colors.white,  marginBottom:20 }}>
                    <h5><b>Ranking</b></h5>
                    <Row>
                        {goodSaleOption.map((item,index)=>{
                            return(
                                <Col key={index} xs='4' onClick={()=>{setGoodSale(item.id)}} style={{cursor:'pointer'}} >
                                    <div style={{marginLeft:5,marginRight:5,borderBottom:`${item.id===goodSale?'2px':'0px'} solid ${colors.purple}`,paddingBottom:5,display:'flex',justifyContent:'center'}} >
                                        {item.id===goodSale
                                            ?<h6 style={{color:colors.purple}} ><b>{item.name}</b></h6>
                                            :<h6>{item.name}</h6>
                                        }
                                    </div>
                                </Col>
                            )
                        })}
                        <Row>
                            <Col xs='6' >ชื่อ</Col>
                            <Col xs='3' >จำนวน</Col>
                            <Col xs='3' >ยอดขาย</Col>
                        </Row>

                        {display.map((item,index) =>{
                          let backgroundColor = index===0?colors.redOrange:index===1?colors.redPink:index===2?colors.darkPurple:colors.darkGray
                          return(
                            <Row key={index}  >
                              <Col xs='6'  style={{display:'flex',flexDirection:'row',alignItems:'center',paddingTop:3,paddingBottom:3}} >
                                <div style={{minWidth:30,height:30,backgroundColor,borderRadius:10,display:'flex',justifyContent:'center',alignItems:'center',marginRight:8}} >
                                  {index+1}
                                </div>
                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }} >
                                  {item.name}
                                </div>
                              </Col>
                              <Col xs='3' style={{display:'flex',alignItems:'flex-end',paddingRight:5}} >
                                  {item.allQty}
                              </Col>
                              <Col xs='3' style={{display:'flex',alignItems:'flex-end',paddingRight:5}} >
                                  {item.allTotalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}
                              </Col>
                            </Row>
                          )
                        })}
                    </Row>
                </div>
                
            </Col>
            <Col md='12' lg='4' style={{padding:0,paddingLeft:5}} >
                <div style={{ border: `1px solid ${colors.lightGray}`, borderRadius: 20, padding: 10,backgroundColor:colors.white, height:'200px', marginBottom:20 }}>
                    <h5><b>Order ที่ถูกยกเลิก</b></h5>
                    <h6>จำนวนออเดอร์ : {selectedVoidedBill.length}</h6>
                    <h6>ยอดสุทธิ : {summary(selectedVoidedBill,'net').toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</h6>
                </div>
            </Col>
        </Row>
    </div>
  );
}

export default AdminDashboardScreen;