// import OneSignal from 'react-native-onesignal';
import { shopchampRestaurantAPI } from '../Utility/api';
import onesignalDefalut from '../configs/onesignalDefalut';
// import firebaseAuth from '@react-native-firebase/auth';
// import { fbUpdateOnesignalTags } from '../db/firestore';
import { firebaseAuth } from '../db/firestore';


// ############################### OneSignal Constant ##########################################
export const androidChannelIds = {
  urgent:'f291d5e7-95c7-411c-8f3a-2bdd44d183df',   // urgent => show on any app foreground (heading notification), vibration/sound/LED, icon on status bar, notification drawer
  high:'9f5c4b95-e7b0-4e4f-8bf5-c4829ac7dfbc',  // high => vibration/sound/LED, icon on status bar, notification drawer
  medium:'595f6b58-81ad-4d05-92c3-8c8ce193f544',  // medium => icon on status bar, notification drawer
  low:'3df6acc9-4aa5-41ee-afc5-459aa84b7920', // low =>   notification drawer
  updateProfileStatus:'c2f5e5c0-488b-432d-8144-50bcab1f0783',
}

export const smallIcons = {
  ic_launcher:"ic_launcher",
  ic_launcher_round:"ic_launcher_round",
  ic_stat_onesignal_default:"ic_stat_onesignal_default"
}
// ############################### End of  ##########################################


// ############################### Build-in Support Function ##########################################
export const fbGetIdToken = () => {
  const promise = new Promise(async (resolve,reject) => {
    try{
      const authToken = await firebaseAuth.currentUser.getIdToken(true);
      resolve(authToken);

    }catch(err){
      console.log(err);
      reject(err);
    }    
  });

  return promise;
}


function keyNameToEmptyStringObj(arr) {
  const objFromArr = {};
  for (let i = 0; i < arr.length; ++i)
      objFromArr[arr[i]] = '';
  return objFromArr;
}

// ############################### End of Build-in Support Function ##########################################





// // ############################### Enable/Disable Notification ##########################################

// export function onesignalInit(){
//     // OneSignal Initialization
//     OneSignal.setLogLevel(6, 0);    // setLogLevel(Android LogCat log/Xcode log,alert dialogs)  // 0 -> 6 (less => more)
//     OneSignal.setAppId("93c6c3ba-ea1e-425f-9e93-a5cf5444234e");


//     // Permission not compete yet, but can work in android, will see later !!!!!!!!!!!!!!!!!!!!!!!!
//     // promptForPushNotificationsWithUserResponse will show the native iOS or Android notification permission prompt.
//     // We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 8)
//     OneSignal.promptForPushNotificationsWithUserResponse(response => {
//       console.log("promptForPushNotificationsWithUserResponse:", response); // true || false
//     });


//     //Listener for handling notifications received while app in foreground
//     OneSignal.setNotificationWillShowInForegroundHandler(notificationReceivedEvent => {
//       let notification = notificationReceivedEvent.getNotification();
//       const data = notification.additionalData
      
//       console.log("promptForPushNotificationsWithUserResponse:", notificationReceivedEvent);
//       console.log("notification: ", notification);
//       console.log("additionalData: ", data);

//       notificationReceivedEvent.complete(notification);     // Complete with null means don't show a notification.
//     });

//     //Listener for handling notifications opened (click at message while app in foreground/click in backgroug,click in app close)
//     OneSignal.setNotificationOpenedHandler(openedEvent => {
//       console.log("setNotificationOpenedHandler:", openedEvent);
//     });
    
// } 

// export async function onesignalHandleSignInUser(uid) {  // Prepare notification tracking for sign in user (Clear some old tracking value from lasted sign in user (if have) )
//   try{
//     await onesignalSetPushSubsciption(true);  // !!! must be first to any onesignal method and await,Do not use Promise.all() !!!
//     await onesignalDeleteAllTagsByPlayerId();
//     await onesignalSetExtUid(uid);
    
//     console.log('onesignalHandleSignInUser success');
//   }catch(err){
//     console.log('onesignalHandleSignInUser error:',err);
//   }

//   // ### Attach tags from firebase to onesignal at "fetchShop" for read billing amount of firebase
//   // if player_id same when new login (not necessary to bring user data to tag) 
//   // but its busy to check.So, tag by any signin whether sign in from old player_id (same device,not delete app) or new player_id (new device or delete and reinstall app, Are update app version has new player_id ????)
//   // (eg. await onesignalUpdateTagsByExternalUserId(uid,{nSignIn:"1"}); )

//   // ### May send player_id to user collection in firestore to make notification tracking by device
// }

// export async function onesignalHandleSignOutUser() {  // Remove multiple notification tracking ,for cannot send notification when user sign out, not use this function for can send notification when user sign out
//   try{
//     await await onesignalClearExtUid();
//     await onesignalDeleteAllTagsByPlayerId();
//     await onesignalSetPushSubsciption(false); // auto delete all tag when unsubscribtion
//     console.log('onesignalHandleSignOutUser success');
//   }catch(err){
//     console.log('onesignalHandleSignOutUser error:',err);
//   }
// }


// export async function onesignalGetDeviceState(){  // get notification Permission State ( and etc. ?????? )
//   const devicesState = await OneSignal.getDeviceState();
//   // console.log('player_id:',devicesState.userId);  // player_id
//   // console.log('devicesState:',devicesState);
  
//   return devicesState ;
// }

// export async function onesignalAskPushPermission(){
//   await OneSignal.promptForPushNotificationsWithUserResponse(response => {
//     console.log("Push Permission:", response); // true || false
//   });
// }

// // let user to select subscipt push notificaiton in yes/no => set "subscribed" in onesignal user account, the default when user grant to init onesignal in app the "subscribed" = true
// export function onesignalSetPushSubsciption(isWantSubscription = true){ 
//   // RN SDK (react-native-onesignal) 4.x.x use
//   switch(isWantSubscription){ 
//     case false:
//         OneSignal.disablePush(true);
//         break;
//     case true:
//         OneSignal.disablePush(false);
//         break;                          
//   }

//   // // RN SDK (react-native-onesignal) 3.x.x use, not use for current version if use in current version its will error!!!
//   // switch(isWantSubscription){ 
//   //   case false:
//   //       OneSignal.setSubscription(false);        
//   //       break;
//   //   case true:
//   //       OneSignal.setSubscription(true);        
//   //       break;                          
//   // }
// }

// // ############################### End of Enable/Disable Notification ##########################################


// // ############################### Listener ##########################################
// export function onesignalAddPermissionObserver(){ // Event Listener when notificaition permision change
//   OneSignal.addPermissionObserver(event => {
//     console.log("OneSignal: permission changed:", event);
//   });
// }

// export function onesignalAddSubscriptionObserver(){ // Event Listener when subscribtion state change eg.push token,player_id,external_user_id,subscribed
//   OneSignal.addSubscriptionObserver(event => {
//     console.log("OneSignal: subscription changed event:", event);
//     console.log("OneSignal: subscription changed from userId:", event.from.userId);
//     console.log("OneSignal: subscription changed to userId:", event.to.userId);
//     console.log("OneSignal: subscription changed from pushToken:", event.from.pushToken);
//     console.log("OneSignal: subscription changed to pushToken:", event.to.pushToken);
//     console.log("OneSignal: subscription changed from isPushDisabled:", event.from.isPushDisabled);
//     console.log("OneSignal: subscription changed to isPushDisabled:", event.to.isPushDisabled);
//     console.log("OneSignal: subscription changed from isSubscribed:", event.from.isSubscribed);
//     console.log("OneSignal: subscription changed to isSubscribed:", event.to.isSubscribed);
//   });
// }


// // ############################### End of Listener ##########################################



// ############################### API => Get Player || Get/Update/Delete Tags || Send Push ##########################################

// export async function onesignalGetPlayerId(){
//   const onesignalDeviceState = await onesignalGetDeviceState();   // Except this, must from SDK
//   const player_id = onesignalDeviceState.userId;
  
//   return player_id ;
// }

// export async function onesignalGetFullPlayerData(_player_id='') {   // use to get "external_user_id" of user in onesignal
//   let player_id = _player_id;  
//   if (!player_id) player_id = await onesignalGetPlayerId();

//   const fullPlayerData =  await shopchampRestaurantAPI.get("/onesignal/getFullPlayerData/"+player_id);
//   return fullPlayerData.data;
// }

// export async function onesignalGetTags(_player_id='') {   // use to get "external_user_id" of user in onesignal
//   let player_id = _player_id;  
//   if (!player_id) player_id = await onesignalGetPlayerId();
  
//   const fullPlayerData = await onesignalGetFullPlayerData();
//   const tags = fullPlayerData?.tags;
//   return tags;
// }

// export async function onesignalGetExtUid(_player_id='') {   // use to get "external_user_id" of user in onesignal
//   let player_id = _player_id;  
//   if (!player_id) player_id = await onesignalGetPlayerId();
  
//   const fullPlayerData = await onesignalGetFullPlayerData();
//   const external_user_id = fullPlayerData?.external_user_id;
//   return external_user_id;
// }

// export async function onesignalSetExtUid(extUid = "",_player_id = '') {
//   let player_id = _player_id;  
//   if (!player_id) player_id = await onesignalGetPlayerId();

//   console.log('onesignalSetExtUid player_id:',player_id)
  
//   return shopchampRestaurantAPI.put(
//     "/onesignal/setExternalUserId/"+player_id,
//     {external_user_id:extUid}
//   );
// }


// export async function onesignalClearExtUid(_player_id = '') {
//   let player_id = _player_id;  
//   if (!player_id) player_id = await onesignalGetPlayerId();

//   console.log('onesignalDelExtUid player_id:',player_id)
  
//   return shopchampRestaurantAPI.put(
//     "/onesignal/setExternalUserId/"+player_id,
//     {external_user_id:""}
//   );
// }

// export async function onesignalInitUserTagsByPlayerId(objTags={},_player_id='') {  // update tag to all same external_user_id in all player of onesignal
//   let player_id = _player_id;  
//   if (!player_id) player_id = await onesignalGetPlayerId();

//   console.log('player_id:',player_id);
//   console.log('onesignalInitUserTagsByPlayerId objTags:',JSON.stringify(objTags,null,4))

//   // await fbUpdateOnesignalTags(shopDoc, {...strObjTags}); // in future, may change random doc id to doc id from uid (equivalent external_user_uid)


//   // !! should tag only curent player id on this device, use other code instead below !!
//   return shopchampRestaurantAPI.put(
//     "/onesignal/updateTagsByPlayerId/"+player_id,
//     {tags:{...objTags}}
//   );
// }


// export async function onesignalInitShopTagsByPlayerId(objShop={},_player_id='') {  // update tag to all same external_user_id in all player of onesignal
//   let player_id = _player_id;  
//   if (!player_id) player_id = await onesignalGetPlayerId();

//   // console.log('initShopTagsByPlayerId objShop:',JSON.stringify(objShop,null,4))

//   return shopchampRestaurantAPI.put(
//     "/onesignal/initShopTagsByPlayerId/"+player_id,
//     {objShop:{...objShop}}
//   );
// }

// export async function onesignalInitProfileTagsByPlayerId(objProfile={},_player_id='') {  // update tag to all same external_user_id in all player of onesignal
//   let player_id = _player_id;  
//   if (!player_id) player_id = await onesignalGetPlayerId();

//   // console.log('initProfileTagsByPlayerId objProfile:',JSON.stringify(objProfile,null,4))

//   return shopchampRestaurantAPI.put(
//     "/onesignal/initProfileTagsByPlayerId/"+player_id,
//     {objProfile:{...objProfile}}
//   );
// }



export async function onesignalUpdateUserTagsByExtUid(objTags={},_external_user_id='',shopDoc='') {  // update tag to all same external_user_id in all player of onesignal
  let external_user_id = _external_user_id;  
  // if (!external_user_id) external_user_id = await onesignalGetExtUid();

  // await fbUpdateOnesignalTags(shopDoc, {...strObjTags}); // in future, may change random doc id to doc id from uid (equivalent external_user_uid)


  // return shopchampRestaurantAPI.put(
  //   "/onesignal/updateTagsByExternalUserId/"+external_user_id,
  //   {tags:{...objTags}}
  // );

  const res = await shopchampRestaurantAPI.put(
    "/onesignal/updateTagsByExternalUserId/"+external_user_id,
    {tags:{...objTags}}
  );
  console.log('res.status:',res.status)


}

// build-in delete tag function both onesignal and firebase
export async function onesignalDeleteUserTagsByExtUid(arrTagsKey=[],_external_user_id='',shopDoc='') {  // delete tag to all same external_user_id in all player of onesignal
  let external_user_id = _external_user_id;  
  // if (!external_user_id) external_user_id = await onesignalGetExtUid();

  const objEmptyString = keyNameToEmptyStringObj([...arrTagsKey])
  
  // await fbDeleteOnesignalTags(shopDoc, {...objTags}); // in future, may change random doc id to doc id from uid (equivalent external_user_uid)
  
  return shopchampRestaurantAPI.put(   // !!! use this instead send array in axios, will change later ....
    "/onesignal/updateTagsByExternalUserId/"+external_user_id,
    {tags:{...objEmptyString}}
  );
}

// export async function onesignalDeleteTagsByPlayerId(arrTagsKey=[],_player_id='',shopDoc='') {
//   let player_id = _player_id;  
//   if (!player_id) player_id = await onesignalGetPlayerId();

//   const objEmptyString = keyNameToEmptyStringObj([...arrTagsKey])     // ['dateTime','name','age']

//   // return shopchampRestaurantAPI.delete(       // !!! have an error, cannot send array data via axios to nodejs ,must to confix if need, not occur when test with postman
//   //   "/onesignal/deleteTagsByPlayerId/"+player_id,
//   //   JSON.stringify({ arrTagsKey:["name","af_favSport"] }),
//     // {
//     //   // headers: {'Content-Type': 'application/json'}
//     //   headers: {'content-type': 'application/x-www-form-urlencoded'}
//     // }
//   // );

//   return shopchampRestaurantAPI.put(   // !!! use this instead send array in axios, will change later ....
//     "/onesignal/updateTagsByPlayerId/"+player_id,
//     // {tags:{name:'',af_first_name:''}}
//     { tags:{...objEmptyString} }
//   );
// }


// export async function onesignalDeleteAllTagsByPlayerId(_player_id='') {
//   let player_id = _player_id;  
//   if (!player_id) player_id = await onesignalGetPlayerId();

//   return shopchampRestaurantAPI.delete(
//     "/onesignal/deleteAllTagsByPlayerId/"+player_id
//   );
// }



export async function onesignalSendPush(pushParam) {  // update tag to all same external_user_id in all player of onesignal
  const authToken = await fbGetIdToken();

  // return await shopchampRestaurantAPI.put("/updateTagsByExternalUserId/"+external_user_id,{uid:objRes.user.uid,appName:'merchant'});
  return shopchampRestaurantAPI.post(
    "/onesignal/sendPush/",
    {...pushParam},
    { authorization: `Bearer ${authToken}` }
  );

}


// ############################### End of API => Get Player || Get/Update/Delete Tags || Send Push ##########################################




// ###################### Modify Message and Send Push Function ######################

export async function apiExtTags(objTags={},external_user_uid='',shopDoc=''){
  if(!objTags){
    alert('please specify tag !');
    return;
  }

  await onesignalUpdateUserTagsByExtUid(objTags,external_user_uid,shopDoc);
}


// export async function apiPlayTags(objTags={},player_id ='',shopDoc=''){
//   if(!objTags){
//     alert('please specify tag !');
//     return;
//   }

//   await onesignalInitUserTagsByPlayerId(objTags)
// }



// ###################### End of Modify Message and Send Push Function ######################


// ###################### Modify Message and Send Push Function ######################


// ##### Easy test send push
// ##### Push with Android Notification Level
export async function sendPush(){
  await onesignalSendPush({
    campaignName:"urgent",
    android_channel_id: androidChannelIds.urgent,
    small_icon:smallIcons.ic_stat_onesignal_default,
    heading:"ส่งให้เฉพาะวิน",
    content:"abc defg 1234",
    priority:10,
    enable_frequency_cap:false,
    objTargerUserCondition:{
      filters: [
          // {         
          //     field: "tag",
          //     key: "name",
          //     relation: "=",
          //     value: "win"
          // }
          {         
              field: "tag",
              key: "name",
              relation: "=",
              value: "winApiExt"
          }
      ]     
    }
  });

  alert('success send push')
}

export async function sendPush2(){
  await onesignalSendPush({
    campaignName:"urgent",
    android_channel_id: androidChannelIds.urgent,
    small_icon:smallIcons.ic_stat_onesignal_default,
    heading:"ส่งให้เฉพาะวิน",
    content:"abc defg 1234",
    priority:10,
    enable_frequency_cap:false,
    objTargerUserCondition:{
      filters: [
          {         
              field: "tag",
              key: "af_name",
              relation: "=",
              value: "win"
          }
      ]     
    }
  });

  alert('success send push')
}


export async function pushWithAndroidNotiLevel(androidNotiLevel = 'test'){
  let cAndroidChannelId = '';
  switch(androidNotiLevel){
    case 'urgent':
      cAndroidChannelId = androidChannelIds.urgent;
      break;
    case 'high':
      cAndroidChannelId = androidChannelIds.high;
      break;
    case 'medium':
      cAndroidChannelId = androidChannelIds.medium;
      break;
    case 'low':
      cAndroidChannelId = androidChannelIds.low;
    default:
      cAndroidChannelId = androidChannelIds.low;
      break;
  } 

  alert(cAndroidChannelId);

  await onesignalSendPush({
    campaignName:androidNotiLevel,
    android_channel_id: cAndroidChannelId,
    small_icon:smallIcons.ic_stat_onesignal_default,
    heading:"ส่งให้เฉพาะวิน",
    content:"abc defg 1234",
    priority:10,
    enable_frequency_cap:false,
    objTargerUserCondition:{
      filters: [
          {         
              field: "tag",
              key: "email",
              relation: "=",
              value: "winionian@gmail.com"
          }
      ]     
    }
  });

  // alert('success send push')
}



// ######## <Send Push Patttern>
// Now have ….. patterns , can only use 1 pattern of them
// #1 by “Filters” => a target that setting with default tags by OneSignal such as device data,account data also include “tags” (json data that we can built-in)
// in free plan limit at 2 filters !!!!
                          // # 1.1 one filter
//     //                       "filters": [
                                    // {         
                                    //   field: "tag",
                                    //   key: "name",
                                    //   relation: "=",
                                    //   value: "win"
                                    // }
//     //                        ]
                          // # 1.2 more than one filter
                          // # 1.2.1 "AND",  operator by default to each condtion object
//     //                       "filters": [
//     //                           {"field": "tag", "key": "level", "relation": "=", "value": "10"},
//     //                           {"field": "amount_spent", "relation": ">","value": "0"}
//     //                        ]
                          // # 1.2.2 "OR" operator
//     //                        "filters": [
//     //                            {"field": "tag", "key": "level", "relation": "=", "value": "10"}, 
//     //                            {"operator": "OR"}, {"field": "tag", "key": "level", "relation": "=", "value": "20"}
//     //                         ]


// // # 1.1 one filter
// export async function pushByOneFilter({heading,content,email,campaignName,big_picture,url,data}){
  export async function pushByOneFilter(dataSet){
    let config = {...onesignalDefalut,...dataSet}
  await onesignalSendPush({
    ...config,
    android_channel_id: androidChannelIds.urgent,
    small_icon:smallIcons.ic_stat_onesignal_default,
    priority:10,
    enable_frequency_cap:false,
    objTargerUserCondition:{
      filters: [
        { field: "tag",key: "email",relation:"=",value: config.email}
      ]     
    }
  })
  .then(res=>{
    console.log("res.status:"+res.status)

  });

  // alert('success send push')
}


// export async function pushByOneFilter(){
//   await onesignalSendPush({
//     campaignName:"urgent",
//     android_channel_id: androidChannelIds.urgent,
//     small_icon:smallIcons.ic_stat_onesignal_default,
//     heading:"ยอดขายเบญจมาศ อารีย์",
//     content:"13,700 บาท",
//     priority:10,
//     enable_frequency_cap:false,
//     objTargerUserCondition:{
//       filters: [
//         { field: "tag",key: "email",relation:"=",value: "siripongsrisukha@gmail.com" }
//       ]     
//     }
//   });

//   alert('success send push')
// }


export async function pushByTwoFilter(dataSet){
  let config = {...onesignalDefalut,...dataSet}
  await onesignalSendPush({
    ...config,
    android_channel_id: androidChannelIds.urgent,
    small_icon:smallIcons.ic_stat_onesignal_default,
    // small_icon:'https://entertain.teenee.com/thaistar/img5/927933.jpg',
    priority:10,
    enable_frequency_cap:false,
    objTargerUserCondition:{
      filters: [
        // { field: "tag",key: "email",relation:"=",value: config.email}
        { field: "tag",key: "id",relation:"=",value: config?.id },
          { operator:"AND" },
        { field: "tag",key: "shopId",relation:"=",value: config?.shopId}
      ]     
    }
  });

  // alert('success send push')
}

// # 1.2.1 "AND",  operator by default to each condtion object
export async function pushByMultiFilter_AND(){
  await onesignalSendPush({
    campaignName:"urgent",
    android_channel_id: androidChannelIds.urgent,
    small_icon:smallIcons.ic_stat_onesignal_default,
    heading:"ส่งให้เฉพาะวิน",
    content:"abc defg 1234",
    priority:10,
    enable_frequency_cap:false,
    objTargerUserCondition:{
      filters: [
        { field: "tag",key: "email",relation:"=",value: "winionian@gmail.com" },
        { operator:"AND" },
        { field: "tag",key: "gender",relation:"=",value: "male" }
      ]     
    }
  });

  alert('success send push')
}


// # 1.2.2 "OR" operator
export async function pushByMultiFilter_OR(){
  await onesignalSendPush({
    campaignName:"urgent",
    android_channel_id: androidChannelIds.urgent,
    small_icon:smallIcons.ic_stat_onesignal_default,
    heading:"ส่งให้เฉพาะวิน",
    content:"abc defg 1234",
    priority:10,
    enable_frequency_cap:false,
    objTargerUserCondition:{
      filters: [
        { field: "tag",key: "email",relation:"=",value: "winioinan@gmail.com" },
        { operator:"OR" },
        { field: "tag",key: "gender",relation:"=",value: "male" }
      ]     
    }
  });

  alert('success send push')
}



// #2 by “Segments” => same as "Filters" but created condition beforehand, 
//     //              only combo compatible only in 2 fields by "included_segments" and "excluded_segments",
//     //              must create segment or use defualt segment to send
//     //                  eg. 
//     //                      included_segments:["Active Users", "Engaged Users"],
//     //                      excluded_segments:["All SMS Subscribers"]



// #2 by “Segments”
export async function pushBySegment(){
  await onesignalSendPush({
    campaignName:"urgent",
    android_channel_id: androidChannelIds.urgent,
    small_icon:smallIcons.ic_stat_onesignal_default,
    heading:"ส่งให้ Active Users หรือ Engaged Users",
    content:"abc defg 1234",
    priority:10,
    enable_frequency_cap:false,
    objTargerUserCondition:{
      included_segments:["Active Users", "Engaged Users"],
      excluded_segments:["All SMS Subscribers"]   
    }
  });

  alert('success send push')
}


// #3 by Specific Device => 
//     //              proper for send individual or some user/device
//     //              all of field in this type => "Limit of 2,000 entries per REST API call" (in array value)
//    # 3.1 by “external user id”, an an built-in user id that give to OneSignal (eg.from "uid" from firebase) to attach to  any wanted device of user
//     //        # 3.1.1 multi “external user id”
//     //		include_external_user_ids:['123456789','99999']     // send to all channel,all platform (if have more than 1 channel ,1 platform)
//     //                   
//    # 3.1.2  multi “external user id” only push and android platform
//     //                      {                                                           
//     //                            "include_external_user_ids": ["external_user_id"],
//     //                            "channel_for_external_user_ids": "push",
//     //                            "isAndroid": true
//     //                        }
//    # 3.2 by “player id”, an id that OneSignal bind to each device
//    //			include_player_ids:["1dd608f2-c6a1-11e3-851d-000c2940e62c"]

// # 3.1.1 multi “external user id”
export async function pushByMultiExtUid(){
  await onesignalSendPush({
    campaignName:"urgent",
    android_channel_id: androidChannelIds.urgent,
    small_icon:smallIcons.ic_stat_onesignal_default,
    heading:"ส่งตาม External User Id ที่เลือก",
    content:"abc defg 1234",
    priority:10,
    enable_frequency_cap:false,
    objTargerUserCondition:{
      include_external_user_ids:['52QzJ1Gnccc8r8G3CbD0pM3zRBk2','HYoXUJXmk0YW65VUUaXABzM5OrA2'] 
    }
  });

  alert('success send push')
}

// # 3.1.2  multi “external user id” only push and android platform
export async function pushByMultiExtUid_onlyPushAndAndroid(){
  await onesignalSendPush({
    campaignName:"urgent",
    android_channel_id: androidChannelIds.urgent,
    small_icon:smallIcons.ic_stat_onesignal_default,
    heading:"ส่งตาม External User Id ที่เลือก เฉพาะ Android",
    content:"abc defg 1234",
    priority:10,
    enable_frequency_cap:false,
    objTargerUserCondition:{
      include_external_user_ids:['52QzJ1Gnccc8r8G3CbD0pM3zRBk2','HYoXUJXmk0YW65VUUaXABzM5OrA2'],
      channel_for_external_user_ids: "push",
      isAndroid: true 
    }
  });

  alert('success send push')
}

// # 3.2 by “player id”, an id that OneSignal bind to each device
export async function pushByMultiPlyId(){
  await onesignalSendPush({
    campaignName:"urgent",
    android_channel_id: androidChannelIds.urgent,
    small_icon:smallIcons.ic_stat_onesignal_default,
    heading:"ส่งตาม Player Id ที่เลือก",
    content:"abc defg 1234",
    priority:10,
    enable_frequency_cap:false,
    objTargerUserCondition:{
      include_player_ids:["bc1b079c-42f2-4afc-9ebb-3fa7c44ba123","e7c393a7-3241-4891-a9c4-0f14d73ae134"]
    }
  });

  alert('success send push')
}

// ###################### End of Modify Message and Send Push Function ######################







/* // !!!! Do not use for multiple Tags handle device , use only when handle tags for one device !!!!!, also in set/remove external user id has some bug from cache ("tags" also) when set/remove it's has latency delay time and some time cannot change value should not use !!!!!!
// ############################### SDK Tags Handle ##########################################

// ** In android Tags 

export function onesignalSdkSetExternalUserId(externalUserId){
    // let externalUserId = '123456789'; // You will supply the external user id to the OneSignal SDK

    OneSignal.setExternalUserId(externalUserId, (results) => {
      // The results will contain push and email success statuses
      // console.log('Results of setting external user id');
      // console.log(results);
      
      // Show detail information if success
      if (results.push && results.push.success) {
        console.log('Results of setting external user id push status:');
        console.log(results.push.success);
      }
      
      // // check external_id bind to email
      // if (results.email && results.email.success) {
      //   console.log('Results of setting external user id email status:');
      //   console.log(results.email.success);
      // }

      // // check external_id bind to sms (via phone number)
      // if (results.sms && results.sms.success) {
      //   console.log('Results of setting external user id sms status:');
      //   console.log(results.sms.success);
      // }

    });

}


export function onesignalSdkRemoveExternalUserId(){
    OneSignal.removeExternalUserId((results) => {
        // The results will contain push and email success statuses
        console.log('Results of removing external user id');
        console.log(results);

        // // Push can be expected in almost every situation with a success status, but
        // // as a pre-caution its good to verify it exists
        // if (results.push && results.push.success) {
        // console.log('Results of removing external user id push status:');
        // console.log(results.push.success);
        // }
        
        // // Verify the email is set or check that the results have an email success status
        // if (results.email && results.email.success) {
        // console.log('Results of removoing external user id email status:');
        // console.log(results.email.success);
        // }


    });

}


// !!! shoud not use this, use "onesignalUserUpdateTagsByExtUid" instead, Due to ...
//   1) not update tag for all device in same external_user_uid
//   2) has bug some time in android when update same tags between this SDK method and end point API method ,not update value that come from SDK
//   Note: may use for tags not depend on uid but only 1 device to reduce our API traffic 
export function onesignalSdkSendTags(objStringVal){  // !!! must only set value of key in object as string only.Number ,Unix Timestamp can but as string => One signal target logic can understand the number string as number value
  // OneSignal.sendTags({shopInw:'true',useLevel:'31'});

  OneSignal.sendTags({...objStringVal});
}


// !! In android, can only get tags on cache (sdk tags), when tags on api (api tags) not tags in cache
export async function onesignalSdkGetTags(){
  const promise = await new Promise(async (resolve,reject)=>{
    try{
      await OneSignal.getTags(tags => {resolve(tags);console.log('inside Promise:',JSON.stringify(tags,null,4))});   // "getTags()" cannot return as function if without await Promise
    }catch(err){
      reject(err);
    }
  })

  console.log('onesignalSdkGetTags promise:',promise);

  return promise;
}

export function onesignalSdkDeleteTags(arrStringTagKey=[]){ // put key name as string in array
  // OneSignal.deleteTags(["userLevel", "coupon"]);

  OneSignal.deleteTags([...arrStringTagKey]);
}

// use case: clear all tags from lasted user login in device for set new tags for new  login user that come from third party db eg. firebase
// !!! not surely to delete api tags (surely on delete sdk tags)
export async function onesignalSdkClearAllTags(){  
  const onesignalTags = await onesignalSdkGetTags();

  // return 

  const arrAllTagKey = await Object.keys(onesignalTags || {} );  // "|| {}" prevent warning when onesignalTags = null
  if(arrAllTagKey.length > 0){
    onesignalSdkDeleteTags(arrAllTagKey);
  }
}


// ############################### End of SDK Tags Handle ########################################## */