import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { colors, mainImage } from '../configs'
import '../styles/ShopListScreen.css'
import { createAllTable, fetchSale_Table } from "../redux/dailySaleSlice";
import { useSelector, useDispatch } from "react-redux";
import { Translator, findDateForFetch, findTranslator, paymentToTable } from "../Utility/function";
import { stringDate } from "../Utility/dateTime";
import { Modal_Loading } from "../modal";


function ReportScreen() {

  const dispatch = useDispatch();
  const { table_Header, payment_Header, table, table_Mode, marketSale, loading_DailySale } = useSelector((state)=> state.dailySale)
  const { currentMarket } = useSelector((state)=> state.market);
  const { renters_Normal } = useSelector((state)=> state.renter);

  const today = new Date();
  const [startDate, setStartDate] = useState(today); //some many of day array to initial graph
  const [endDate, setEndDate] = useState(today); //  today

  const tableOptions = ['shopId','day','month','year']
  useEffect(()=>{
    dispatch(createAllTable({type:table_Mode,startDate:new Date(),endDate:new Date()}))
    setStartDate(new Date())
    setEndDate(new Date())
  },[])

  function checkDateAvilable(){
    let unAvailableDate =  findDateForFetch(startDate,endDate,marketSale)
    if(unAvailableDate.length >0){
      dispatch(fetchSale_Table({marketId:currentMarket.id,arrayOfTime:unAvailableDate})).then((data)=>{
        if(data.payload){
          console.log(data.payload)
          dispatch(createAllTable({type:table_Mode,startDate,endDate}))
        }
      })
    } else {
      dispatch(createAllTable({type:table_Mode,startDate,endDate}))
    }
  };

  return (
    <div className="container" >
      <Modal_Loading show={loading_DailySale} />
        <b>Duration</b>
            <Container
                fluid
                style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems:'center'
                }}
            >
                <img style={{width:30}} src={mainImage.calender} />
                <div>Start: </div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                />
                <img style={{width:30}} src={mainImage.calender} />
                <div>End: </div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                />
                <Button onClick={checkDateAvilable} variant="success" style={{marginRight:'1rem'}} >
                  ค้นหา
                </Button>
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-secondary"
                    table="table-to-xls"
                    // filename="ยอดขายทั้งตลาด"
                    filename={`ยอดขาย ${Translator(currentMarket.name,'TH')} ${stringDate(startDate)}-${stringDate(endDate)}`}
                    sheet="tablexls"
                    buttonText='Export'
                />
            </Container>
            <div style={{display:'flex'}} >
            {tableOptions?.map((item,index)=>{
              return(
                <div onClick={()=>{dispatch(createAllTable({type:item,startDate,endDate}))}} key={index} style={{borderRadius:100,width:100,margin:15,padding:10,backgroundColor:item==table_Mode?colors.darkPurple:colors.lightGray,cursor: 'pointer',color:item==table_Mode?colors.white:'black',display:'flex',justifyContent:'center'}} >{item}</div>
              )})}

          </div>
          <Table   responsive id="table-to-xls" >
          <thead  >
            <tr>
              {table_Header?.map((item,index)=>{
                return(
                  <th key={index} >{item}</th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {table?.map((item,index)=>{
              return(
                <tr key={index} >
                  <td>{index+1}</td>
                  {table_Mode ==='shopId'
                      ?<td>{findTranslator(renters_Normal,'shopId',item.properties).shopName || item.properties}</td>
                      :<td>{item.properties}</td>
                  }
                  <td>{item.orders}</td>
                  <td>{item.net}</td>
                  {payment_Header?.map((a,i)=>{
                    return(
                      <td key={i} >{paymentToTable(a,item.payment)}</td>
                    )
                  })}
                </tr>
              )
            })}
              {/* {Array.from({ length: 500 }).map((_, index) => (
                <tr >
                  <td>{index+1}</td>
                  <td>
                    สมพงษ์
                  </td>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <td  key={index}>Table cell {index}</td>
                  ))}
                </tr>
              ))} */}
          </tbody>
        </Table>
    </div>
  );
}

export default ReportScreen;
