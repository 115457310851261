import React, { PureComponent } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart
} from 'recharts';


const getIntroOfPage = (label) => {
    if (label === 'Page A') {
      return "Page A is about men's clothing";
    }
    if (label === 'Page B') {
      return "Page B is about women's dress";
    }
    if (label === 'Page C') {
      return "Page C is about women's bag";
    }
    if (label === 'Page D') {
      return 'Page D is about household goods';
    }
    if (label === 'Page E') {
      return 'Page E is about food';
    }
    if (label === 'Page F') {
      return 'Page F is about baby food';
    }
    return '';
  };
  
  const CustomTooltip = ({ active, payload, label,value }) => {
    const { name, content } = value;
    if (active && payload && payload.length) {
      return (
        <div style={{backgroundColor:'rgba(255,255,255, 0.5)',borderRadius:20,padding:10}}>
          <p className="label">{`${name} ${label} : ${content} ${payload[0].value.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}`}</p>
          <p className="intro">{getIntroOfPage(label)}</p>
        </div>
      );
    }
  
    return null;
  };





function ChartScreen({chart,bar,name,content}) {
  const data = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
    return (
        <ResponsiveContainer width="100%" height="90%">
        <BarChart width={150} height={40} data={chart}  >
          <Bar dataKey={bar} fill="#ff7300" label={{ position: 'top' }} />
          <XAxis dataKey="name" stroke="#ff7300" />
          <YAxis />
          <Tooltip  content={<CustomTooltip value={{name,content}} />} />
          <CartesianGrid strokeDasharray="3 3" />
          <Area type="monotone" dataKey="amt" fill="#ff7300" stroke="#ff7300" />
          <Line type="monotone" dataKey="pv" stroke="#ff7300" />
          <Legend />

        </BarChart>
        
      </ResponsiveContainer>
    );
  }
  
  export default ChartScreen;
