import mainImage from "./mainImage";

export default {
        landlordId:'',
        marketId:'',  // ใช้สำหรับบการสร้าง qr code
        name:[
            // {
            //     type:'TH',
            //     value:''
            // }
        ],
        imageUrl:[
            // {
            //     id:1,
            //     type:'logo',
            //     value:''
            // },
            // {
            //     id:2, 
            //     type:'coverPhoto',
            //     value:mainImage.democoverphoto
            // },
        ],
        address:[
            // {
            //     type:'TH',
            //     value:''
            // }
        ],
        location:{latitude:'',longitude:''},
        enterprise:true,// เป็นรูปแบบบริษัทหรือไม่
        contact:[], // ทำเหมือน payment
        other:[], // ใส่ที่เหลือที่คิดออกทีหลัง
        timestamp:new Date(),
        marketType:[], // หมวดหมู่ของตลาด เช่น ตลาดเช้า ตลาดเย็น หน้าบ้าน งานวัด เป็นต้น
        status:true, // ค้นหาเจอ หรือค้นหาไม่เจอ
    }
  

