import React, { useContext } from "react";
import { Container, Row, Col, Button, Modal, Card, Image } from "react-bootstrap";
import { colors, contact, mainImage } from "../configs";



function Modal_FlatListTwoColumn({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
  header='',
  onClick,
  value,
  selectedId
}) {

  console.log('value_modal_flatlisttwocolumn')
  console.log(JSON.stringify(value,null,4));


  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
    //   size={size}
    >
      <Modal.Header closeButton>
        <b>{header}</b>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'transparent'}} >
        <Row>
            {value?.map((item,index)=>{
                return(
                  <Col key={index} sm='6' style={{display:'flex',justifyContent:'center'}} >
                    <Button onClick={()=>{onClick(item)}} 
                      style={{
                        backgroundColor:selectedId===item.id?colors.darkPurple:colors.white,
                        borderColor:selectedId===item.id?colors.darkPurple:colors.white,
                        color:selectedId===item.id?colors.white:colors.dark,
                        margin:'10px'
                      }} >
                        {item?.name}
                        {/* {item?.value} */}
                    </Button>
                  </Col>
                )
            })}
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default Modal_FlatListTwoColumn;
