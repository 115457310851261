import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { colors, mainImage } from "../configs";
import { FloatingText, InputArea, InputText } from "../components";
import Modal_Contact from "./Modal_Contact";
import { v4 as uuidv4 } from 'uuid';
import Modal_Alert from "./Modal_Alert";
import { Translator, manageArray, manageArray_Id, manageObject } from "../Utility/function";
import Modal_CropImage from "./Modal_CropImage";
import { addLandlordToFirebase, setLoading_Landlord, setNewLandlord } from "../redux/landlordSlice";
import { useSelector, useDispatch } from "react-redux";
import { prepareFirebaseImage } from "../db/firestore";


function Modal_HQ({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
  branch,
  setBranch,
  submit
}) {
    const { name, imageId } = branch;
    const dispatch = useDispatch();
    const [crop_Modal, setCrop_Modal] = useState(false)


  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      // fullscreen='xxl-down'
      size={size}
    >
      {/* <Modal.Header closeButton>
        <h2><b>สร้างสำนัก</b></h2>
        
      </Modal.Header> */}
      <Modal.Body style={{backgroundColor:'transparent'}} >
            <Modal_CropImage
              show={crop_Modal}
              onHide={()=>{setCrop_Modal(false)}}
              onClick={(value)=>{
                setCrop_Modal(false);
                setBranch({...branch,imageId:value})
            }}
              ratio={1/1}
          />
            <Col sm='12'>
                <InputText
                  name='name'
                  placeholder="ชื่อสำนักงานใหญ่"
                  onChange={(event)=>{setBranch({...branch,name:event.target.value})}}
                  value={name}
                />
            </Col>
        <Row>
            <Col sm='3' >Logo</Col>
            <Col sm='9' >
                <Button onClick={()=>{setCrop_Modal(true)}} variant="light" >
                    <Image style={{width:200,height:200}} roundedCircle src={imageId||mainImage.reslogo} />
                </Button>
            </Col>
        </Row>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="secondary">Cancel</Button>
        <Button onClick={submit} variant="success">Confirm</Button>
    </Modal.Footer>
    </Modal>
  );
}

export default Modal_HQ;
