import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from "../db/firestore";
import { initialProfile, mainImage } from "../configs";
import { v4 as uuidv4 } from 'uuid';
import { arrayToNewArray2, filterDeleteInArray, filterInArray, findInArray } from "../Utility/function";


const initialState = {
    shopOrder:{},
    products:[],
    cart:[],
    loading_Order:false,
    currentProduct:{},
    loading_Order:false,
    modal_Product:false,
    modal_Cart:false,
    table:'',
    tableData:{},
    orderHistory:[],
    productInHistory:[],
    linkId:'',
    preOrder_Modal:false,
    masterDataSource:[],
    type:'dynamic',
    limitItems:[], // สินค้าที่ีถูกจำกัดจำนวน
}


// fetch shop
export const fetchShopOrder = createAsyncThunk(
    'shop/fetchShopOrder',
    async (token) => {
      let data = []
      await db.collection('shop').doc(token).get()
      .then((doc)=>{
        data = doc.data()
      })
      return data;
    }
);

// fetch shop
export const fetchShop = createAsyncThunk(
  'shop/fetchShop',
  async ({token,tableId}) => {
    let tableData = {}
    await db.collection('shop').doc(token).get()
      .then((doc)=>{
        const { scShopId, name, imageUrl, smartTable, smartCategory,
          smartOption, smartBuffet, smartStep, serviceType, smartRequest,
          smartTask, smartChef, buffetHeader  } = doc.data();
          tableData = {
            shopId:token,
            scShopId,
            shopName:name,
            shopLogo:findInArray(imageUrl,'type','logo')?.value||mainImage.reslogo,
            tableId,
            tableName:findInArray(smartTable,'id',tableId)?.name||'',
            mainId:smartTable[0].mainId,
            smartCategory,
            smartOption,
            smartBuffet:smartBuffet||[],
            smartStep:smartStep||{steps:[],name:'',status:false},
            serviceType:serviceType||[{id:1,name:'A La Carte',status:true},{id:2,name:'Buffet',status:false}],
            smartRequest:smartRequest||[],
            smartTask:smartTask||[],
            smartChef:smartChef||[],
            buffetHeader:buffetHeader||{left:'All You Can Eat',right:'เมนูพิเศษ'},
          
          }
      })
    return tableData;
  }
);

export const fetchqrCodeOrder = createAsyncThunk(
  'shop/fetchShopOrder',
  async (token) => {
    let data = []
    await db.collection('qrcodeOrder').doc(token).get()
    .then((doc)=>{
      data = doc.data()
    })
    return data;
  }
);

// fetch historyOrder
export const fetchHistoryOrder = createAsyncThunk(
  'shop/fetchHistoryOrder',
  async ({shopId,tableId}) => {
    let data = []
    try {
      await db.collection('order')
      .where("shopId", "==", shopId)
      .where('channelId','==',tableId)
      .where('process', 'in', ['cooked', 'ordered'])
      .get().then((docs)=>{
        docs.forEach((doc)=>{data.push({id:doc.id,...doc.data(),timestamp:doc.data().timestamp.toDate()})})
      })
    } catch (err){
        console.log(err)
    }
    // console.log('fetchHistoryOrder')
    // console.log(data)
    return data;
  }
);


// fetch products
export const fetchProductOrder = createAsyncThunk(
    'shop/fetchProductOrder',
    async (token) => {
      let data = []
      await db.collection('product').where("shopId", "==", token).get().then((docs)=>{
        docs.forEach((doc)=>{data.push({id: doc.id,...doc.data(),timestamp:doc.data().timestamp.toDate()})})
      })
      return data;
    }
);

// add cart to firestore
export const addOrderToFirebase = createAsyncThunk(
  'cart/addOrderToFirebase',
  async (order)=>{
      let newOrder = {}
      await db.collection('order').add(order).then((doc)=>{
          newOrder = {id: doc.id, ...order}
      })
      return newOrder;
  }
);

// add request to firestore
export const addRequestTofirebase = createAsyncThunk(
  'request/addRequestTofirebase',
  async (request)=>{
      let newRequest = {}
      await db.collection('task').add(request).then((doc)=>{
          newRequest = {id: doc.id, ...request}
      })
      return newRequest;
  }
);


export const orderSlice = createSlice({
    name:'order',
    initialState,
    reducers: {
      setNewProfile: (state,action) => {
        state.newProfile = action.payload
      },
      updateCurrentProfile: (state,action) => {
        state.currentProfile = {}
      },
      updateType: (state,action) => {
        state.type = action.payload
      },
      updateTable: (state,action) => {
        state.tableData = action.payload
      },
      updateLimitItems: (state,action) => {
        state.limitItems = action.payload
      },
      updateCurrentCart: (state,action) => {
        state.cart = state.cart.map((item)=>{
          return item.tempId === action.payload.tempId
              ?action.payload
              :item
        })
        state.currentProduct ={}
        state.modal_Product = false
      },
      addProductToCurrentCart: (state,action) => {
        state.cart = [...state.cart,action.payload]
        state.currentProduct ={}
        state.modal_Product = false
      },
      addProduct: (state, action) => {
        const { product } = action.payload;
        let newProduct = {
          ...product,
          tempId:uuidv4(),
          process:'ordered',
          diffQty:0,
          productStaffs:[{id:'selfOrder',process:'ordered',timestamp:new Date()}],
      }
        state.cart = [...state.cart,newProduct]
      },
      deleteProductInCart: (state, action) => {
        state.cart = filterDeleteInArray(state.cart,'tempId',action.payload)
    },
      updateCurrentProduct: (state,action) => {
        state.currentProduct = action.payload
      },
      addCurrentProduct: (state,action) => {
        state.currentProduct = action.payload
        state.modal_Product = true
      },
      updateMasterDataSource: (state, action) => {
          state.masterDataSource = action.payload
      },
      updateOption: (state,action) => {
        const { optionId, choiceId } = action.payload
        let newOption = state.currentProduct.option
        let newChoice = []
        let selectedOption = newOption.find((item)=>{return(item.optionId == optionId)})
        if(selectedOption.maxChose===1){
            if(selectedOption.minChose ===0){
                newChoice = selectedOption.choice.map((item)=>{
                    return item.choiceId == choiceId
                        ?{...item,chose:!item.chose}
                        :{...item,chose:false}
                })
            } else {
                newChoice = selectedOption.choice.map((item)=>{
                    return item.choiceId == choiceId
                        ?{...item,chose:true}
                        :{...item,chose:false}
                })
            }
            
        } else {
            let boforeNewChoice = selectedOption.choice.map((item)=>{
                return item.choiceId == choiceId
                    ?{...item,chose:!item.chose}
                    :item
            }) // สำหรับการเปลี่ยนตัวเลือกที่กดให้มี สถานะ ตรงกันข้าม
            let checkMax = boforeNewChoice.filter((item)=>{return(item.chose == true)})
            if(checkMax.length === selectedOption.maxChose){
                newChoice = boforeNewChoice.map((item)=>{
                    return item.chose === false
                        ?{...item,chose:'block'}
                        :item
                })
            } else {
                newChoice = boforeNewChoice.map((item)=>{
                    return item.chose === 'block'
                        ?{...item,chose:false}
                        :item
                })
            }
        }
        // block คือ กดไม่ได้
        // false คือ กดได้ แต่ลูกค้ายังไม่กด
        // true คือ กดได้ และลูกค้ากดแล้ว
        let index = newOption.findIndex((obj => obj.optionId == optionId))
        newOption[index].choice = newChoice
        if(filterInArray(newChoice,'chose',true).length >= selectedOption.minChose){
            newOption[index].mainChose = true
        } else {
            newOption[index].mainChose = false
        }
        state.currentProduct = {...state.currentProduct,option:newOption}
        },
      clearCurrentProduct: (state,action) => {
        state.currentProduct = {}
        state.modal_Product = false
      },
      clearCart: (state,action) => {
        state.cart = []
        state.loading_Order = false
        state.limitItems = [];
      },
      updateLinkId: (state,action) => {
        state.linkId = action.payload;
      },
      updateModal_Order: (state,action) => {
        state.modal_Order = action.payload
      },
      updateLoadingOrder: (state,action) => {
        state.loading_Order = action.payload
      },
      deleteSomeCurrentCart: (state,action) => {
        state.cart = state.cart.filter(a=>a.tempId !== state.currentProduct.tempId)
        state.currentProduct = {}
      },
      updateProductInCart: (state, action) => {
        state.cart = state.cart.map((item)=>{
            return item.tempId == action.payload.tempId 
            ?action.payload
            :item
        })
    },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchShopOrder.pending, (state, action) => {
          state.loading_Order = true
        })
        .addCase(fetchShopOrder.fulfilled, (state, action) => {
          state.shopOrder = action.payload
          state.loading_Order = false
        })
        .addCase(fetchShopOrder.rejected, (state, action) => {
          state.loading_Order = false
        })
        .addCase(fetchShop.pending, (state, action) => {
          state.loading_Order = true
        })
        .addCase(fetchShop.fulfilled, (state, action) => {
          state.tableData = action.payload
          state.loading_Order = false
        })
        .addCase(fetchShop.rejected, (state, action) => {
          state.loading_Order = false
        })
        .addCase(fetchProductOrder.pending, (state, action) => {
          state.loading_Order = true
          state.products = []
        })
        .addCase(fetchProductOrder.fulfilled, (state, action) => {
          state.products = action.payload
          state.loading_Order = false
        })
        .addCase(fetchProductOrder.rejected, (state, action) => {
          state.loading_Order = false
        })
        .addCase(fetchHistoryOrder.pending, (state, action) => {
          state.loading_Order = true

        })
        .addCase(fetchHistoryOrder.fulfilled, (state, action) => {
          state.orderHistory = action.payload
          state.productInHistory = arrayToNewArray2(state.orderHistory,'product')
          state.loading_Order = false
          if(state.orderHistory.length===0){
            state.preOrder_Modal = true
          }
        })
        .addCase(fetchHistoryOrder.rejected, (state, action) => {
          state.loading_Order = false
        })
        .addCase(addOrderToFirebase.pending, (state, action) => {
          state.loading_Order = true
        })
        .addCase(addOrderToFirebase.fulfilled, (state, action) => {
          state.cart = []
          state.orderHistory = [...state.orderHistory,action.payload]
          state.productInHistory = arrayToNewArray2(state.orderHistory,'product')
          state.preOrder_Modal = false
          state.loading_Order = false
        })
        .addCase(addOrderToFirebase.rejected, (state, action) => {
          state.loading_Order = false
        })
      }
})

export const  { addProductToCurrentCart, updateCurrentProduct, addCurrentProduct, 
  clearCurrentProduct, updateModal_Order, deleteSomeCurrentCart, updateCurrentCart,
  updateTable, addProduct, deleteProductInCart, updateProductInCart, updateOption, clearCart,
  updateLinkId, updateMasterDataSource, updateType, updateLimitItems, updateLoadingOrder
 } = orderSlice.actions;
export default orderSlice.reducer;