import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
  Carousel
} from "react-bootstrap";
import { NavBar } from "../components";
import { anonymous_Id, colors, homeScreenOption, initialDailySale, mainImage } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfile } from "../redux/profileSlice";
// import '../styles/HomeScreen.css';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink, useNavigate } from 'react-router-dom';
import { Modal_Success } from "../modal";
// import translate from 'translate-google-api';
import { translate } from '@vitalets/google-translate-api';
import { getWeek } from "date-fns";



function HomeScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state)=> state.auth)
  const [index, setIndex] = useState(0);
  console.log(currentUser?.uid)
  useEffect(()=>{
    if(!!currentUser?.uid){
      if(currentUser?.uid !== anonymous_Id ){
        dispatch(fetchProfile(currentUser?.uid))
      }
    }
  },[currentUser?.uid])

  // useEffect(() => {
  //   // Load Google Translate API script
  //   const script = document.createElement('script');
  //   script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   // Function to initialize Google Translate Element
  //   window.googleTranslateElementInit = () => {
  //     new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
  //   };

  //   // Clean up function
  //   return () => {
  //     document.body.removeChild(script);
  //     delete window.googleTranslateElementInit;
  //   };
  // }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  async function testMultiLanguage(){
    const result = await translate(`I'm fine.`, {
      tld: "cn",
      to: "vi",
    });
    console.log('result')
    console.log(result)
    alert(result)
  }

  async function translateText(){
        const { text } = await translate('สวัสดี', { to: 'en' });
        console.log(text) // => 'Hello World! How are you?'
    }

  
  return (
    <div style={{backgroundColor:colors.purplePeel,height:'100%',width:'100%',position:'relative'}} >
      {/* <div id="google_translate_element"></div> */}
      <header>
        <NavBar />
      </header>
      {/* <Button onClick={()=>{navigate("/csv")}} >
        Practise_CSV
      </Button>
      <div>
      {isAndroid && <p>Running on Android</p>}
      {isiOS && <p>Running on iOS</p>}
      {isWindows && <p>Running on Windows</p>}
      {isMacOS && <p>Running on macOS</p>}
    </div> */}
      {/* <Button onClick={()=>{navigate("/phone")}} >
        phone
      </Button> */}
      {/* <Button onClick={()=>{navigate("/branch")}} >
        branch
      </Button>
      <Button onClick={()=>{navigate("/branchPort")}} >
        branchPort
      </Button> */}
      {/* <Button onClick={()=>{navigate("/branchHome")}} >
          branch
      </Button>
      <Button onClick={()=>{navigate("/took")}} >
          took
      </Button>
      <a href="https://www.niceoppai.net/" >niceopai</a> */}
      {/* <Button onClick={()=>{navigate("/delivery")}} >
          DeliveryScreen
      </Button>
      <Button onClick={()=>{translateText()}} >
      translateText
      </Button>
      <Button onClick={()=>{navigate("/payment")}} >
      checkOut
      </Button> */}
  
      <div style={{backgroundColor:colors.purplePeel}} >
        <img style={{width:'100%'}} src={mainImage.empowering} alt='empowering' />
        <div style={{display:'flex',paddingTop:10,paddingLeft:20}}>
            {homeScreenOption.map((item,_)=>{
              return(
                <div onClick={()=>{setIndex(item.index)}} key={item.id} style={{padding:10,borderRadius:20,marginRight:10,color:colors.white,backgroundColor:item.index==index?colors.darkPurple:colors.softGray,cursor: 'pointer'}} >
                    {item.name}
                </div>  
              )
            })}
        </div>
        <div style={{width:'99%'}} >
        <div style={{borderRadius:20,margin:20}} >
            <Carousel activeIndex={index} onSelect={handleSelect} style={{padding:0,margin:0}} >
              {homeScreenOption.map((item,index)=>{
                return(
                  <Carousel.Item style={{marginTop:0,paddingTop:0}} key={index} >
                    <img
                      src={item.imageId}
                      alt="First slide"
                      style={{width:'100%',borderRadius:50}}
                    />
                  </Carousel.Item>
                )
              })}
            </Carousel>
              </div>
        </div >
        <div style={{backgroundColor:colors.white,paddingLeft:20,paddingBottom:20}} >
          <img style={{width:200,filter: 'grayscale(70%)'}} src={mainImage.logo} alt="image" />
          <p style={{margin:0}} >ตำแหน่งที่ตั้ง : 25 ซอยชิดลม ถนน</p>
          <p style={{margin:0}} >เพลินจิต แขวงลุมพินี เขตปทุมวัน</p>
          <p style={{margin:0}} >กรุงเทพมหานคร 10330</p>
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
