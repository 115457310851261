export default {
        shopId:'',
        rights:[],
        printer:{
            name:'',
            address:'',
            size:{
                type:'80',
                pixel:550
            }
        }
    }