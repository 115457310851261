import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { colors, mainImage, lottieDefault } from "../configs";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink, useNavigate } from 'react-router-dom';
import { Modal_Alert, Modal_HQ, Modal_Loading, Modal_Qrcode } from "../modal";
import { fetchShops, fetchTodayBills, fetchBranch, updateFieldBranch, addBranchToFirebase, deleteBranch, updateSelectedBranch } from "../redux/branchSlice";
import Lottie from "react-lottie";
import * as qrcode from '../qrcode.json';
import * as setting from '../setting.json';
import * as trash from '../trash.json';
import firebase from 'firebase/app';
import { db, prepareFirebaseImage, webImageDelete } from "../db/firestore";


function BranchPortScreen() {
  const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state)=> state.auth);
    const { branchs } = useSelector((state)=> state.branch);
    const [loading, setLoading] = useState(false);
    const [qr_Modal, setQr_Modal] = useState(false);
    const initialHQ = {name:'',imageId:'',id:'',oldImageId:'',status:false}
    const [branch, setBranch] = useState(initialHQ);
    const [branch_Modal, setBranch_Modal] = useState(false);
    const { name, imageId, id,  oldImageId } = branch;
    const [delete_Modal, setDelete_Modal] = useState(false)


    useEffect(()=>{
        dispatch(fetchBranch(currentUser.uid))
    },[])

    async function submit(){
        let newImageId = imageId
        if(id){
            if(imageId && imageId.slice(0,5) !== 'https'){
                const imageUrl = await prepareFirebaseImage(imageId,'/branch/')
                newImageId = imageUrl
            }
            if(oldImageId){
                webImageDelete(oldImageId)
            }
            dispatch(updateFieldBranch({doc:id,field:{name,imageId:newImageId}}))
        } else {
            if(imageId){
                const imageUrl = await prepareFirebaseImage(imageId,'/branch/')
                newImageId = imageUrl
            }
            dispatch(addBranchToFirebase({
                createBy:currentUser.uid,
                humanResource:[currentUser.uid],
                imageId:newImageId,
                name,
                shopId:[],
                timestamp:firebase.firestore.FieldValue.serverTimestamp()
            }))
        }
        setBranch_Modal(false)
    }


    function goToBranch(branch){
        const { shopId, name } = branch;
        dispatch(updateSelectedBranch(branch))
        Promise.all(
            [
                dispatch(fetchTodayBills({shops:shopId})), // ดึงข้อมูลบิลวันนี้มั้งหมด
                dispatch(fetchShops({shops:shopId})) // ดึงข้อมูลร้านค้าทั้งหมด
            ]
        )
        .catch(() => {
            setLoading(false)
            alert(`ไม่สามารถเข้า ${name} ได้`)
        })
        .finally(() => {
          setLoading(false)
          navigate("/branch")
        }) 
    }

    function deleteThisBranch(){
        if(oldImageId){
            webImageDelete(oldImageId)
        }
        dispatch(deleteBranch(id))
        setDelete_Modal(false)
    }
    
  return (
    <div style={{backgroundColor:colors.white,height:'100vh'}} >
        <Modal_Loading show={loading} />
        <Modal_Qrcode
            visible={qr_Modal}
            branch={branch}
            onRequestClose={()=>{setQr_Modal(false)}}
        />
        <Modal_HQ
            show={branch_Modal}
            onHide={()=>{setBranch_Modal(false)}}
            branch={branch}
            setBranch={setBranch}
            submit={submit}
        />
        <Modal_Alert
            show={delete_Modal}
            onHide={()=>{setDelete_Modal(false)}}
            header={`ยืนยันการลบ ${name}`}
            onClick={()=>{deleteThisBranch()}}
        />
        <div style={{backgroundColor:colors.white,display:'flex',justifyContent:'center'}} >
            <h1>เลือกสำนักงาน</h1>
        </div>
        <div style={{height:'100vh',marginLeft:20,marginRight:20,borderTopLeftRadius:20,borderTopRightRadius:20}} >
            <Row style={{padding:20,margin:20,backgroundColor:colors.lightGray,borderRadius:20}} >
                {branchs.map((item,index)=>{
                    return(
                        <Col key={index} xs='12' md='6' lg='4' style={{padding:10}}   >
                            <div style={{borderRadius:30,padding:10,backgroundColor:colors.white,display:'flex',position:'relative'}} >
                                <img style={{width:100,height:100,borderRadius:20,margin:10}} src={item.imageId || mainImage.reslogo} />
                                <div onClick={()=>{goToBranch(item)}} style={{cursor:'pointer'}} >
                                    <h6 style={{fontSize:24}} >{item.name}</h6>
                                    <h6 >เข้าสู่สาขา</h6>
                                </div>
                                <div style={{position:'absolute',right:10,bottom:10}} >
                                    {item.createBy===currentUser.uid
                                        ?<div onClick={()=>{setBranch({...item,oldImageId:item.imageId});setDelete_Modal(true)}}  >
                                            <Lottie options={{...lottieDefault,animationData: trash.default}} height={30} width={30} />
                                        </div>
                                        :null
                                    }
                                    <div onClick={()=>{setBranch({...item,oldImageId:item.imageId});setBranch_Modal(true)}}  >
                                        <Lottie options={{...lottieDefault,animationData: setting.default}} height={40} width={40} />
                                    </div>
                                    <div onClick={()=>{setBranch({...item,oldImageId:item.imageId});setQr_Modal(true)}} >
                                        <Lottie options={{...lottieDefault,animationData: qrcode.default,}} height={40} width={40} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )
                })}
                <Col  xs='12' md='6' lg='4' style={{padding:10}}   >
                    <div onClick={()=>{setBranch(initialHQ);setBranch_Modal(true)}} style={{borderRadius:30,padding:10,backgroundColor:colors.darkGray,width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',color:colors.white,cursor:'pointer'}} >
                        <h3>+ เพิ่มสำนักงานใหญ่</h3>
                    </div>
                </Col>
            </Row>
        </div>
        
    </div>
  );
}

export default BranchPortScreen;
