import React, { useContext } from "react";
import { Container, Row, Col, Button, Modal, Card } from "react-bootstrap";
import { colors } from "../configs";
import '../styles/ShopListScreen.css'
import Lottie from "react-lottie";
import QRCode from "qrcode.react";


function Modal_Qrcode({
  backdrop=true, // true/false/static
  animation=true,
  visible,
  onRequestClose,
  centered=true,
  size='md',
  branch
}) {
    const { name, id } = branch;
    const downloadQR = () => {
        const canvas = document.getElementById("123456");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `Qrcode ${name}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={visible}
      onHide={onRequestClose}
      centered={centered}
      className='loading-screen'
      size={size}
    >
      <Modal.Body   >
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}} >
          <QRCode
            id="123456"
            value={id}
            size={290}
            level={"H"}
            includeMargin={true}
          />
          <Button onClick={downloadQR} variant="success" >
                Download QR 
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Modal_Qrcode;

