import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from "../db/firestore";
import { reverseSort } from "../Utility/sort";
import { initialMarket } from "../configs";

const initialState = {
  markets:[],
  loading_Market:false,
  error: "",
  currentMarket:{},
  editMarket:{},
  editMarket_Modal:false,
  newMarket: initialMarket,
  newMarket_Modal:false,

}

// add newMarket to firestore
export const addMarketToFirestore = createAsyncThunk(
    'market/addMarketToFirestore',
    async (market)=>{
        let newMarket = {}
        await db.collection('market').add(market).then((doc)=>{
            newMarket = {id: doc.id, ...market,timestamp:new Date(market.timestamp)}
        })
        return newMarket;
    }
);

// fetch market
export const fetchMarket = createAsyncThunk(
    'market/fetchMarket',
    async (id) => {
      let data = []
      await db.collection('market').where("landlordId", "==", id).get().then((docs)=>{
        docs.forEach((doc)=>{data.push({id: doc.id,...doc.data(),timestamp:doc.data().timestamp.toDate()})})
      })
      return reverseSort('timestamp',data)
      // return data;
    }
);

// updateMarket
export const updateMarket = createAsyncThunk(
  'market/updateMarket',
  async (newMarket) => {
    await db.collection('market').doc(newMarket.id).update({...newMarket,timestamp:new Date(newMarket.timestamp)})
    return newMarket
  }
);

export const marketSlice = createSlice({
    name:'market',
    initialState,
    reducers: {
      chooseMarket: (state, action) => {
        state.currentMarket = action.payload
        state.editMarket = action.payload
      },
      setNewMarket_Modal: (state, action) => {
        state.newMarket_Modal = action.payload
      },
      createNewMarket: (state, action) => {
        state.newMarket = initialMarket
        state.newMarket_Modal = true
      },
      setNewMarket: (state, action) => {
        state.newMarket = action.payload
      },
      setLoading_Market: (state, action) => {
        state.loading_Market = action.payload
      },
      setEditMarket_Modal: (state, action) => {
        state.editMarket_Modal = action.payload
      },
      editMarket: (state, action) => {
        state.editMarket = state.currentMarket
        state.editMarket_Modal = true
      },
      setEditMarket: (state, action) => {
        state.editMarket = action.payload
        state.editMarket_Modal = true
      },
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchMarket.pending, (state, action) => {
            state.loading_Market = true
          })
          .addCase(fetchMarket.fulfilled, (state, action) => {
            state.markets = action.payload
            state.loading_Market = false
          })
          .addCase(fetchMarket.rejected, (state, action) => {
            state.loading_Market = false
            state.error = action.error.message
          })
          .addCase(addMarketToFirestore.pending, (state, action) => {
            // state.loading_Market = true
          })
          .addCase(addMarketToFirestore.fulfilled, (state, action) => {
            state.markets.push(action.payload)
            state.newMarket_Modal = false
            state.loading_Market = false
          })
          .addCase(addMarketToFirestore.rejected, (state, action) => {
            state.loading_Market = false
            state.error = action.error.message
            alert('เพิ่มตลาดไม่สำเร็จ')
          })
          .addCase(updateMarket.pending, (state, action) => {
            // state.loading_Market = true
          })
          .addCase(updateMarket.fulfilled, (state, action) => {
            let newMarkets = state.markets.map((item)=>{
              return item.id == action.payload.id
                  ?action.payload
                  :item
            })
            state.markets = newMarkets
            state.currentMarket = action.payload
            state.loading_Market = false
            state.editMarket_Modal = false
          })
          .addCase(updateMarket.rejected, (state, action) => {
            state.loading_Market = false
            state.error = action.error.message
          })
      }
})

export const  { chooseMarket, setNewMarket_Modal, setLoading_Market,
                 setEditMarket_Modal, setEditMarket, editMarket,
                 createNewMarket, setNewMarket 
                } = marketSlice.actions;
export default marketSlice.reducer;