import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { colors } from "../configs";
import { v4 as uuidv4 } from 'uuid';
import Modal_Alert from "./Modal_Alert";
import { checkCategory2, filterDeleteInArray, findInArray, includesOutInArray, manageCategory, mapInArray, someInArray } from "../Utility/function";
import { useSelector, useDispatch } from "react-redux";
import Modal_OneInput from "./Modal_OneInput";
import { updateFieldShop } from "../redux/resShopSlice";
import Modal_Loading from "./Modal_Loading";
import Modal_LoadingPanda from "./Modal_LoadingPanda";


function Modal_CategorySetting({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
}) {
    const dispatch = useDispatch();
    const { shop, modal_Shop } = useSelector((state)=> state.resShop);
    const { smartCategory, id } = shop;
    const [currentCategory, setCurrentCategory] = useState(smartCategory||[]);
    const [allSelectedCategory, setAllSelectedCategory] = useState([]);
    const initialCategory = {name:'',status:false,level:1,aboveId:[]};
    const [newCategory, setNewCategory] = useState(initialCategory);
    const [selectedCategory, setSelectedCategory] = useState({aboveId:[],id:'',name:'',level:''});
    const [editCategory, setEditCategory] = useState({});
    const [editCategory_Modal, setEditCategory_Modal] = useState(false);
    const initialDeleteAlert = {status:false,content:'',onClick:()=>{},header:'คำเตือน'}
    const [deleteAlert, setDeleteAlert] = useState(initialDeleteAlert)
    const { status, content, header, onClick} = deleteAlert;

    useEffect(()=>{
      if(show){
        setCurrentCategory(smartCategory)
      }
    },[show])

      async function submit(){
        dispatch(updateFieldShop({doc:id,field:{smartCategory:currentCategory}})).then(()=>{
          onHide()
        })
      }
    
      function addNewCategory(){
        const { level, name, aboveId } = newCategory;
        let cat = findInArray(currentCategory,'level',level)
        if(cat && cat.level){
            let newSmartCategory = currentCategory.map((item)=>{
                return item.level === level
                    ?{...item,
                        value:[
                            ...item.value,
                            {
                                level,
                                name,
                                id:uuidv4(),
                                aboveId
                            }
                        ]}
                    :item
            })
            setCurrentCategory(newSmartCategory)
            setNewCategory(initialCategory)
        } else {
            setCurrentCategory([
                ...currentCategory,
                {
                    level,
                    value:[
                        {
                            id:uuidv4(),
                            name,
                            level,
                            aboveId
                        }
                    ] 
                }
            ])
            setNewCategory(initialCategory)
        }
    }
    
    function deleteCategory(item){ //100%
        const { level, id } = item;
        let highLevel = currentCategory.filter(a=>a.level < level)
        let value = filterDeleteInArray(findInArray(currentCategory,'level',level).value,'id',id)
        let lowLevel = []
        currentCategory.filter(b=>b.level > level).forEach((a)=>{
            let value = includesOutInArray(a.value,'aboveId',id);
            if(value.length >0){
                lowLevel.push({
                    ...a,
                    value,
                })
            }
        })
        if(value.length >0){
            setCurrentCategory([...highLevel,{level,value},...lowLevel])
        } else {
            setCurrentCategory([...highLevel,...lowLevel])
        }
        setAllSelectedCategory(filterDeleteInArray(allSelectedCategory,'id',id))
    }
    
    function updateEditCategory(){
        const { level, value } = findInArray(currentCategory,'level',editCategory.level)
        setCurrentCategory(mapInArray(currentCategory,'level',level,{level,value:mapInArray(value,'id',editCategory.id,editCategory)}))
        setEditCategory_Modal(false)
    };


  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      fullscreen='xxl-down'
    //   size={size}
    >
      <Modal.Header closeButton >
        <h2><b>จัดการหมวดหมู่</b></h2>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'transparent'}} >
        <Modal_LoadingPanda show={modal_Shop} />
            <Modal_Alert
                show={status}
                onHide={()=>{setDeleteAlert(initialDeleteAlert)}}
                content={content}
                header={header}
                onClick={onClick}
            />
            <Modal_OneInput  // สำหรับสร้างหมวดหมู่ใหม่
                show={newCategory.status}
                header={newCategory.level===1?'ตั้งชื่อหมวดหมู่หลัก':`ตั้งชื่อหมวดหมู่ย่อยของ ${selectedCategory.name}`}
                onHide={()=>{setNewCategory({...newCategory,status:false})}}
                value={newCategory.name}
                onClick={addNewCategory}
                placeholder='ใส่ชื่อที่ต้องการ'
                onChange={(value)=>{setNewCategory({...newCategory,name:value})}}
            />
            <Modal_OneInput  // สำหรับแก้ไขหมวดหมู่
                show={editCategory_Modal}
                header={`แก้ไขชื่อหมวดหมู่ ชื่อเดิม"${selectedCategory.name.slice()}"`}
                onHide={()=>{setEditCategory_Modal(false)}}
                value={editCategory.name}
                onClick={updateEditCategory}
                placeholder='ใส่ชื่อที่ต้องการ'
                onChange={(value)=>{setEditCategory({...editCategory,name:value})}}
            />
            <div>
        <div style={{ overflowY: 'auto', height: '100vh' }}>
          {currentCategory.map((item, index) => {
            return (
              <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ padding: '10px', borderRadius: '20px', margin: '5px',  textAlign: 'center' }}>
                  <h3 >ชั้นที่ {item.level}</h3>
                </div>
                <div style={{ overflowX: 'auto', display: 'flex', flexDirection: 'row' }}>
                  {/* Map over categories and render your components */}
                  {checkCategory2(allSelectedCategory, item).map((a, i) => {
                    const { id, name, aboveId, level } = a;
                    let status = someInArray(allSelectedCategory, 'id', id);
                    return (
                      <div
                        onClick={() => {
                          setSelectedCategory(a);
                          setAllSelectedCategory(manageCategory(item.value, allSelectedCategory, a));
                        }}
                        key={i}
                        style={{
                          padding: '10px',
                          borderRadius: '20px',
                          margin: '5px',
                          backgroundColor: status ? colors.purple : colors.softGray,
                          minWidth: '150px',
                          textAlign: 'center',
                          cursor:'pointer',
                          display:'flex',
                          flexDirection:'column',
                          justifyContent:'space-between'
                        }}
                      >
                        
                        <h4 style={{color: status ? colors.white : colors.dark,}}>{name}</h4>
                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '10px' }}>
                          <span
                            style={{ flex: 1, textAlign: 'center', fontSize: 'YourFontSize' }}
                            onClick={() => {
                              setDeleteAlert({
                                ...initialDeleteAlert,
                                status:true,
                                content:`${name} และหมวดหมู่ย่อยที่เกี่ยวข้องจะถูกลบ`,
                                onClick:()=>{deleteCategory(a);setDeleteAlert(initialDeleteAlert)}
                              })
                            }}
                          >
                            ลบ
                          </span>
                          <span
                            style={{ flex: 1, textAlign: 'center', cursor:'pointer'}}
                            onClick={() => {
                              setEditCategory(a);
                              setEditCategory_Modal(true)
                            }}
                          >
                            แก้ไข
                          </span>
                          <span
                            style={{ flex: 1, textAlign: 'center', cursor:'pointer' }}
                            onClick={() => {
                              setNewCategory({ ...newCategory, level: level + 1, status: true, aboveId: [...aboveId, id] });
                              setSelectedCategory(a);
                              setAllSelectedCategory(manageCategory(item.value, allSelectedCategory, a));
                            }}
                          >
                            +
                          </span>
                        </div>
                      </div>
                    );
                  })}
                  {item.level === 1
                      ?<div
                          onClick={()=>{setNewCategory({...newCategory,status:true})}} 
                          style={{padding:10,borderRadius:20,margin:5,backgroundColor:colors.purpleRed,minWidth: '150px',cursor:'pointer'}}
                        >
                          <h4  >+ เพิ่มหมวดหมู่หลัก</h4>
                      </div>
                      :null
                  }
                </div>
              </div>
            );
          })}
        </div>
      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="secondary">Cancel</Button>
        <Button onClick={submit} variant="success">Confirm</Button>
    </Modal.Footer>
    </Modal>
  );
}

export default Modal_CategorySetting;
