import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchProduct, fetchShops, fetchTodayBills, updateSelectedShop } from "../redux/adminSlice";
import { colors } from "../configs";
import { filterInArray, goToTop, searchFilterFunction, searchFilterReturn, summary } from "../Utility/function";
import TablePagination from '@mui/material/TablePagination';
import { normalSort } from "../Utility/sort";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink, useNavigate } from 'react-router-dom';
import Modal_Loading from "../modal/Modal_Loading";

function AdminScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { shops, todayBills, modal_TodayBill, modal_Shop, } = useSelector((state)=> state.admin);
  const [currentDisplay, setCurrentDisplay] = useState([])
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [masterDataSource, setMasterDataSource] = useState([]);

  // console.log('shops')
  // console.log(shops.length)

  const handleChangePage = (event, newPage) => {
      setPage(newPage); // start form 0
      goToTop()
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      goToTop()
  };

  useEffect(()=>{
    dispatch(fetchShops())
    dispatch(fetchTodayBills()) // ดึงข้อมูลบิลวันนี้มั้งหมด
  },[])

  useEffect(()=>{
    if(todayBills.length>0){
      let res = []
      for(const item of shops){
        let bill = filterInArray(todayBills,'shopId',item.id)
        res.push({...item,qty:bill.length,net:summary(bill,'net')})
      }
      setMasterDataSource(normalSort('net',res).map((item,index)=>{return({...item,no:index+1})}))
    } else {
      setMasterDataSource(shops.map((item,index)=>{return({...item,no:index+1})}))
    }
  },[shops,todayBills])

  useEffect(()=>{
    let mainData = masterDataSource
    if(search){
      mainData = searchFilterReturn(search,'name',masterDataSource)
      setPage(0)
    }
    let fData = mainData.filter((item,index)=>{return(index >=(page*rowsPerPage) && index <= ((page+1)*rowsPerPage)-1)})
      setCurrentDisplay(fData)
  },[page,rowsPerPage,masterDataSource,search])

  function fetchData(shop){
    dispatch(updateSelectedShop(shop))
    dispatch(fetchProduct(shop.id)).then(()=>{
      navigate("/admin/home")
    })
  }


  return (
    <div style={{backgroundColor:colors.lightGray,width:'100vw-100px',padding:'1rem'}} >
        <Modal_Loading
          show={modal_TodayBill||modal_Shop}
        />
        <div style={{paddingTop:5,paddingBottom:5,display:'flex',marginBottom:20}} >
          <Form className="d-flex" style={{display:'flex',flex:1}} >
              <Form.Control
                  type="search"
                  placeholder='ค้นหาร้านค้า'
                  className="me-2 rounded-pill"
                  aria-label="Search"
                  onChange={(event)=>{setSearch(event.target.value)}}
              />
          </Form>
      </div>
      <Table    hover  responsive id="table-to-xls" >
        
        <thead>
          <tr>
            <th style={{width:'5%'}} >ลำดับ</th>
            <th style={{width:'50%'}} >ร้านค้า</th>
            <th style={{textAlign:'center',width:'20%'}} >ยอดขายวันนี้</th>
            <th style={{textAlign:'center',width:'10%'}} >บิลวันนี้</th>
            <th style={{textAlign:'center',width:'15%'}} >tel</th>
          </tr>
        </thead>
        <tbody>
          {currentDisplay.map((item, index) => (
            <tr onClick={()=>{fetchData(item)}}  style={{cursor: 'pointer'}} key={index} >
              <td style={{width:'5%'}} >{item.no}.</td>
              <td style={{width:'50%'}} >{item.name}</td>
              <td style={{textAlign:'center',width:'20%'}} >{item.net?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
              <td style={{textAlign:'center',width:'10%'}}>{item.qty}</td>
              <td style={{textAlign:'center',width:'15%'}}>{item.tel}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <TablePagination
            component="div"
            count={masterDataSource.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
  );
}

export default AdminScreen;