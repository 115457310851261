import initialCurrentLogin from "./initialCurrentLogin";

export default {
    imageId:'',
    timestamp:new Date(),
    name:'',
    tel:'',
    email:'',
    gender:'',
    birthday:'',
    port:[], // port จะถูกเติมทุกครั้งที่มีการเข้าร้านใหม่ๆ แต่จะถูกลบเมื่อกดเข้าร้านนั้นไม่ได้อีก เป็นการกดมือ
    currentLogin:initialCurrentLogin,
    other:[]
}