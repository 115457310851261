import React, { useState } from 'react';
import { firebaseAuth } from '../db/firestore';



const PhoneAuth = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);

  const handleSendCode = async () => {
    try {
      const thGlobalPhoneNum = '+66' + phoneNumber.substring(1)
      const confirmationResult = await firebaseAuth.signInWithPhoneNumber(thGlobalPhoneNum,null);
      setConfirmationResult(confirmationResult);
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyCode = async () => {
    try {
      const credential = firebaseAuth.PhoneAuthProvider.credential(confirmationResult.verificationId, verificationCode);
      await firebaseAuth.signInWithCredential(credential);
      console.log('Phone authentication successful');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <label>Phone Number:</label>
      <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />

      <button onClick={handleSendCode}>Send Code</button>

      <label>Verification Code:</label>
      <input type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />

      <button onClick={handleVerifyCode}>Verify Code</button>
    </div>
  );
};

export default PhoneAuth;