import React, { useContext } from "react";
import { Container, Row, Col, Button, Modal, Card } from "react-bootstrap";



function Modal_Alert({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=false,
  size='md',
  onClick,
  content,
  header='Alert'
}) {


  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      size={size}
    >
      <Modal.Header closeButton>
        <b>{header}</b>
      </Modal.Header>
      <Modal.Body >
        {content}
      </Modal.Body>
      <Modal.Footer>
          <Button onClick={onHide} variant="secondary">Cancel</Button>
          <Button onClick={onClick} variant="primary">Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Modal_Alert;
