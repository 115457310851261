import mainImage from "./mainImage"
export default [
    {
        id:1,
        type:'tel',
        icon:mainImage.phone,
        value:''
    },
    {
        id:2,
        type:'website',
        icon:mainImage.www,
        value:''
    },
    {
        id:3,
        type:'email',
        icon:mainImage.email,
        value:''
    },
    {
        id:4,
        type:'facebook',
        icon:mainImage.facebook,
        value:''
    },
    {
        id:5,
        type:'Messenger',
        icon:mainImage.messenger,
        value:''
    },
    {
        id:6,
        type:'line',
        icon:mainImage.line,
        value:''
    },
    {
        id:7,
        type:'Whatsapp',
        icon:mainImage.whatsapp,
        value:''
    },
    {
        id:8,
        type:'Discord',
        icon:mainImage.discord,
        value:''
    },
    {
        id:9,
        type:'Instagram',
        icon:mainImage.ig,
        value:''
    },
    {
        id:10,
        type:'Snapchat',
        icon:mainImage.snapchat,
        value:''
    },
    {
        id:11,
        type:'Twitter',
        icon:mainImage.twitter,
        value:''
    },
    {
        id:12,
        type:'Wechat',
        icon:mainImage.wechat,
        value:''
    },
    {
        id:13,
        type:'Tiktok',
        icon:mainImage.tiktok,
        value:''
    },
    {
        id:14,
        type:'Youtube',
        icon:mainImage.youtube,
        value:''
    },
    {
        id:15,
        type:'Telegram',
        icon:mainImage.telegram,
        value:''
    },
    {
        id:16,
        type:'Linkedin',
        icon:mainImage.linkedin,
        value:''
    },
] 

