import { createSlice } from '@reduxjs/toolkit'
import { resInitialChoice, resInitialOption } from '../configs'

const initialState = {
    choiceConfig:resInitialChoice, // 
    modal_Choice:false,
    optionConfig:resInitialOption,
}


export const resChoiceSlice = createSlice({
  name: 'resChoice',
  initialState,
  reducers: {
    updateChoice: (state,action) => {
      state.choiceConfig = action.payload
    },
    updateModal_Choice: (state,action) => {
        state.modal_Choice = action.payload
    },
    clearChoice: (state,action) => {
        state.choiceConfig = resInitialChoice
        state.modal_Choice = false
    },
    updateOption: (state,action) => {
      state.optionConfig = action.payload
    },
    clearOption: (state,action) => {
      state.optionConfig = resInitialOption
      state.modal_Choice = false
    },

  },
  extraReducers: builder => {
  }
})

export const { updateChoice, updateModal_Choice, clearChoice, updateOption, clearOption
} = resChoiceSlice.actions

export default resChoiceSlice.reducer