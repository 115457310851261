import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { colors, mainImage } from "../configs";
import Modal_Report from "../modal/Modal_Report";
import { useSelector, useDispatch } from "react-redux";
import { endCutoff,  filterInArray,  findInArray,  goToTop,  manageDashboard, startCutoff, summary } from "../Utility/function";
import { normalSort, reverseSort } from "../Utility/sort";
import { NumberYMD, minusDays, plusDays, stringFullTime, stringYMDHMS, stringYMDHMS3 } from "../Utility/dateTime";
import TablePagination from '@mui/material/TablePagination';
import { Modal_Loading, Modal_Order, Modal_ReportReceipt } from "../modal";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import { CSVLink } from "react-csv";
import Dropdown from 'react-bootstrap/Dropdown';
import { fetchBill, fetchOrder, updateCurrentOrder, updateDashBoard, updateSelectedShop } from "../redux/branchSlice";



function BranchReportScreen() {
  const dispatch = useDispatch();
  const [report_Modal, setReportId_Modal] = useState(false)
  const { shops, products, selectedShop, billDates, modal_Bill, graph, payment, 
    product , category, saleMan, normalBill, voidedBill, selectedVoidedBill, 
    selectedBill, saleByChannel, bills, billOrder, orders, currentOrder } = useSelector((state)=> state.branch);
  const { cutOff, channel, smartTable, humanResource, smartCategory, POSs  } = selectedShop;

  const [reportId, setReportId] = useState(1);
  const [currentDisplay, setCurrentDisplay] = useState([]) // จำนวนที่แสดงในหนึ่งหน้า
  const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [store_Modal, setStore_Modal] = useState(false)
  const today = new Date();
  const [startDate, setStartDate] = useState(today); //some many of day array to initial graph
  const [endDate, setEndDate] = useState(today); //  today
  const [display, setDisplay] = useState([]);
  const [csvName, setCsvName] = useState('รายงานบิล');
  const [show_Order, setShowOrder] = useState({display:[],status:false})


  const reportOption = [
    {id:1,name:'บิล',imageId:mainImage.receiptblack},
    {id:2,name:'สินค้า',imageId:mainImage.productlist},
    {id:3,name:'หมวดหมู่',imageId:mainImage.categoryblack},
    {id:4,name:'พนักงาน',imageId:mainImage.humanresource2},
    // {id:5,name:'โปรโมชั่น',imageId:mainImage.promotion},
    {id:6,name:'ช่องทางขาย',imageId:mainImage.channelblack},
    {id:7,name:'ช่องทางชำระเงิน',imageId:mainImage.channelblack},
    // {id:8,name:'กะ',imageId:mainImage.shift},
    // {id:9,name:'วัตถุดิบ',imageId:mainImage.rawmat},
  ]

  useEffect(()=>{  
    let rewSearch = []
    let newSearch = []
    let start = minusDays(startDate,1)

    do {
      rewSearch.push(stringYMDHMS3(start))
      start = plusDays(start,1)
    }
    while (NumberYMD(start) < NumberYMD(plusDays(endDate,2)));

    for(const item of rewSearch){
      if(!billDates.includes(item)){
        newSearch.push(item)
      }
    }
    if(newSearch.length >0){
      dispatch(fetchBill({shopId:selectedShop.id,billDate:newSearch,cutOff}))
    }
 },[startDate,endDate])

 function checkShop(shop){
  if(shop.id !== selectedShop.id){ // แปลว่ามีการเปลี่ยน shop
    dispatch(updateSelectedShop(shop))
    let newSearch = []
    let start = minusDays(startDate,1)

    do {
      newSearch.push(stringYMDHMS3(start))
      start = plusDays(start,1)
    }
    while (NumberYMD(start) < NumberYMD(plusDays(endDate,2)));
    if(newSearch.length >0){
      dispatch(fetchBill({shopId:shop.id,billDate:newSearch,cutOff:shop.cutOff}))
    }
  }
}

 useEffect(()=>{
    if(reportId===1){
        setDisplay(reverseSort('receiptNumber',[...selectedBill,...selectedVoidedBill]))
        setCsvName('รายงานบิล')
    } else if(reportId===2){
        setDisplay(normalSort('allTotalPrice',[...product]))
        setCsvName('รายงานสินค้า')
    } else if(reportId===3){
        setDisplay(normalSort('allTotalPrice',[...category]))
        setCsvName('รายงานหมวดหมู่')
    } else if(reportId===4){
        setDisplay(normalSort('allTotalPrice',[...saleMan]))
        setCsvName('รายงานพนักงาน')
    } else if(reportId===5){
        setDisplay([])
        setCsvName('รายงานโปรโมชั่น')
    } else if(reportId===6){
        setDisplay(normalSort('allTotalPrice',[...saleByChannel]))
        setCsvName('รายงานช่องทางขาย')
    } else {
        setDisplay(normalSort('allTotalPrice',[...payment]))
        setCsvName('รายงานชำระเงิน')
    } 
 },[selectedBill,reportId])

 useEffect(()=>{
  console.log('cutOff')
  console.log(cutOff)
  console.log(stringYMDHMS(startCutoff(startDate,new Date(cutOff))))
  console.log(stringYMDHMS(endCutoff(endDate,new Date(cutOff))))
  let selectedBill = normalBill.filter((item)=>{return(stringYMDHMS(item.timestamp) > stringYMDHMS(startCutoff(startDate,new Date(cutOff))) && stringYMDHMS(item.timestamp) <= stringYMDHMS(endCutoff(endDate,new Date(cutOff))))})
  let selectedVoidedBill = voidedBill.filter((item)=>{return(stringYMDHMS(item.timestamp) > stringYMDHMS(startCutoff(startDate,new Date(cutOff))) && stringYMDHMS(item.timestamp) <= stringYMDHMS(endCutoff(endDate,new Date(cutOff))))})
  setDisplay(bills.filter((item)=>{return(stringYMDHMS(item.timestamp) > stringYMDHMS(startCutoff(startDate,new Date(cutOff))) && stringYMDHMS(item.timestamp) <= stringYMDHMS(endCutoff(endDate,new Date(cutOff))))}))
  dispatch(updateDashBoard({...manageDashboard({ selectedBill, products, channel, smartTable, humanResource, smartCategory, selectedVoidedBill}),graph:[]}))

},[startDate, endDate, normalBill])

  const handleChangePage = (event, newPage) => {
      setPage(newPage); // start form 0
      goToTop()
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      goToTop()
  };


  useEffect(()=>{
    let fData = display.map((item,index)=>{return({...item,no:index})}).filter((item,index)=>{return(index >=(page*rowsPerPage) && index <= ((page+1)*rowsPerPage)-1)})
      setCurrentDisplay(fData)
  },[page,rowsPerPage,display])

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{borderRadius:20}} onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  function checkBillOrder(bill){
    if(bill.orderId){
      if(billOrder.includes(bill.id)){
          dispatch(updateCurrentOrder({
            thisOrder:filterInArray(orders,'billId',bill.id),
            status:true,
            receiptNumber:bill.receiptNumber
          }))
        } else {
          dispatch(fetchOrder({billId:bill.id,documentIds:bill.orderId,receiptNumber:bill.receiptNumber}))
        }
        }
        console.log(bill.orderId)
  }

  return (
    <div  >
      <Modal_Loading show={modal_Bill} />
      <Modal_Report
        show={report_Modal}
        onHide={()=>{setReportId_Modal(false)}}
      />
      <Modal_Order
          onHide={()=>{dispatch(updateCurrentOrder({thisOrder:[],status:false,receiptNumber:''}))}}
          currentOrder={currentOrder}
          selectedShop={selectedShop}
      />
        <Dropdown data-bs-theme="dark" style={{marginBottom:10}}  >
          <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary" >
            {selectedShop.name}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }} >
              {shops.map((item,index)=>{
                return(
                    <Dropdown.Item key={index} onClick={()=>{checkShop(item)}} >{item.name}</Dropdown.Item>
                )
              })}
          </Dropdown.Menu>
      </Dropdown>
      <Row >
          <Col lg='4'  >
              <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
                <img style={{width:30}} src={mainImage.calender} />
                <div style={{paddingLeft:10,paddingRight:10}} >  Start: </div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<ExampleCustomInput />}
                />
              </div>
          </Col>
          <Col lg='4'  >
            <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
              <img style={{width:30}} src={mainImage.calender} />
              <div style={{paddingLeft:10,paddingRight:10}} >  End: </div>
              <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  customInput={<ExampleCustomInput />}
              />
            </div>
          </Col>
          <Col lg='4' style={{padding:10}} >
            <CSVLink 
              style={{backgroundColor:'#292929',textDecoration:'none',color:colors.white,padding:10,borderRadius:20,paddingLeft:30,paddingRight:30}}
              filename={`${csvName}${stringYMDHMS3(startDate)}-${stringYMDHMS3(endDate)}`}
              data={
                reportId===1
                    ?display.map((item)=>{return({
                      receiptNumber:item.receiptNumber,
                      totalQty:item.totalQty,
                      subTotal:item.subTotal,
                      discount:summary(item.discount,'amount'),
                      serviceChargeAmount:item.serviceChargeAmount,
                      vatAmount:item.vatAmount,
                      net:item.net,
                      void:item.void,
                      timestamp:item.timestamp
                    })})
                    :display.map((item)=>{return({
                      ชื่อ:item.name,
                      จำนวน:item.allQty,
                      ยอดขาย:item.allTotalPrice,
                    })})
              }
            >
                Export
            </CSVLink>
          </Col>
          
      </Row>
        <Row style={{borderRadius:20,marginTop:10,marginBottom:10}} >
            {reportOption.map((item,index)=>{
              return(
                  <Col key={index} xs='4' sm='4' md='4' lg='3' onClick={()=>{setReportId(item.id)}} style={{cursor:'pointer',padding:3}} >
                      <div style={{marginLeft:0,marginRight:0,display:'flex',flexDirection:'column',justifyContent:'center',backgroundColor:item.id===reportId?'yellow':colors.white,borderRadius:20,marginBottom:5,alignItems:'center',padding:'5px 0px 2.5px 0px'}} >
                          <img src={item.imageId} style={{width:'50px'}} />
                          <h6>{item.name}</h6>
                      </div>
                  </Col>
              )
            })}
        </Row>
        {reportId===1
              ?<Row>
                <Table striped bordered hover responsive  variant="light"   >
                  <thead  >
                    <tr>
                      <th style={{textAlign:'center', width: '10%'}} >No.</th>
                      <th style={{textAlign:'center'}} >เลขที่บิล</th>
                      <th style={{textAlign:'center'}}>POS</th>
                      <th style={{textAlign:'center'}}>ยอดขาย</th>
                      <th style={{textAlign:'center'}}>สถานะ</th>
                      <th style={{textAlign:'center'}}>วัน เวลา</th>
                    </tr>
                  </thead>
                  <tbody  >
                    {currentDisplay.map((item, index) => (
                      <tr  style={{cursor: 'pointer'}} key={index} onClick={()=>{checkBillOrder(item)}} >
                        <td>{item.no+1}.</td>
                        <td >{item.receiptNumber}</td>
                        <td style={{textAlign:'center'}}>{findInArray(POSs,'id',item.posId)?.name||item.posId}</td>
                        <td style={{textAlign:'center'}}>{item.net?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                        <td style={{textAlign:'center'}}>{item.void?'ยกเลิก':'ปกติ'}</td>
                        <td style={{textAlign:'center'}}>{stringFullTime(item.timestamp|| new Date())}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <TablePagination
                    component="div"
                    count={display.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Row>
              :<Row>
                <Table striped bordered hover responsive  variant="light"   >
                  <thead  >
                    <tr>
                      <th style={{ width: '10%' }}>No.</th>
                      <th style={{ width: '50%' }}>ชื่อ</th>
                      <th style={{ width: '20%' }}>จำนวน</th>
                      <th style={{ width: '20%' }}>ยอดขาย</th>
                    </tr>
                  </thead>
                  <tbody  >
                    {currentDisplay.map((item, index) => (
                      <tr  style={{cursor: 'pointer'}} key={index} >
                        <td>{item.no+1}.</td>
                        <td >{item.name}</td>
                        <td style={{textAlign:'center'}}>{item.allQty}</td>
                        <td style={{textAlign:'center'}}>{item.allTotalPrice?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <TablePagination
                    component="div"
                    count={display.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Row>
          }
    </div>
  );
}

export default BranchReportScreen;