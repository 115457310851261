import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { db } from "../db/firestore";


const initialState = {
    boms:[], // 
    modal_Bom:false,
    units:[],
}

// fetch bom
export const fetchBom = createAsyncThunk(
    'bom/fetchBom',
    async (id) => {
      let data = []
      await db.collection('bom').where("shopId", "==", id).get().then((docs)=>{
        docs.forEach((doc)=>{data.push({id:doc.id,...doc.data(),timestamp:doc.data().timestamp.toDate()})})
      })
      return data;
    }
  );


// add bom to firestore
export const addBomTofirebase = createAsyncThunk(
    'bom/addBomTofirebase',
    async (bom)=>{
        let newBom = {}
        await db.collection('bom').add(bom).then((doc)=>{
            newBom = {id: doc.id, ...bom}
        })
        return newBom;
    }
);


// deleteBom 
export const deleteBom = createAsyncThunk(
    'bom/deleteBom',
    async (id)=>{
        await db.collection('bom').doc(id).delete()
        return id
    }
);

// updateBom
export const updateBom = createAsyncThunk(
    'bom/updateBom',
    async (bom) => {
        await db.collection('bom').doc(bom.id).update(bom)
        return bom
    }
);
  


export const bomSlice = createSlice({
  name: 'bom',
  initialState,
  reducers: {
    update_Modal_Bom: state => {
      state.modal_Bom = !state.modal_Bom
    },
    fetchNormal_Bom: (state, action) => {
        state.boms = action.payload
        let fullUnit = []
        for(const item of action.payload){// pัด id ของแต่ละ bom ลงไปใน unit
            let newUnit = item.unit.map((a)=>{return({...a,rawMatName:item.name,rawMatId:item.id})})
            fullUnit = [...fullUnit,...newUnit]
        }
        state.units = fullUnit
    },

  },
  extraReducers: builder => {
    builder.addCase(addBomTofirebase.pending, state => {
        state.modal_Bom = true
    })
    builder.addCase(addBomTofirebase.fulfilled, (state, action) => {
        state.boms = [...state.boms,action.payload]
        const { unit, name, id } = action.payload;
        let newUnit = unit.map((a)=>{return({...a,rawMatName:name,rawMatId:id})})
        state.units = [...state.units,...newUnit]
        state.modal_Bom = false
    })
    builder.addCase(addBomTofirebase.rejected, state => {
        state.modal_Bom = false
    })
    builder.addCase(fetchBom.pending, state => {
        state.modal_Bom = true
    })
    builder.addCase(fetchBom.fulfilled, (state, action) => {
        state.boms = action.payload
        let fullUnit = []
        for(const item of action.payload){// pัด id ของแต่ละ bom ลงไปใน unit
            let newUnit = item.unit.map((a)=>{return({...a,rawMatName:item.name,rawMatId:item.id})})
            fullUnit = [...fullUnit,...newUnit]
        }
        state.units = fullUnit
        state.modal_Bom = false
    })
    builder.addCase(fetchBom.rejected, state => {
        state.modal_Bom = false
    })
    builder.addCase(deleteBom.pending, state => {
        state.modal_Bom = true
    })
    builder.addCase(deleteBom.fulfilled, (state, action) => {
        state.boms = state.boms.filter(a=>a.id !== action.payload)
        state.units = state.units.filter(a=>a.rawMatId !== action.payload)
        state.modal_Bom = false
    })
    builder.addCase(deleteBom.rejected, state => {
        state.modal_Bom = false
    })
    builder.addCase(updateBom.pending, state => {
        state.modal_Bom = true
    })
    builder.addCase(updateBom.fulfilled, (state, action) => {
        let oldUnit = state.units.filter(a=>a.rawMatId !== action.payload.id)
        let fullUnit = []
        const { name, id, unit } = action.payload;
        let newUnit = unit.map((a)=>{return({...a,rawMatName:name,rawMatId:id})})
        fullUnit = [...fullUnit,...newUnit]
        
        state.boms = state.boms.map((item)=>{
            return item.id === action.payload.id
                ?action.payload
                :item
        })
        state.units = [...oldUnit,...fullUnit]
        state.modal_Bom = false
    })
    builder.addCase(updateBom.rejected, state => {
        state.modal_Bom = false
    })
  }
})

export const { update_Modal_Bom, fetchNormal_Bom
} = bomSlice.actions

export default bomSlice.reducer