export default  {
    choiceId:'',
    choiceName:'',
    price:[
        // {
        //     type:'dineIn', // ประเภททานที่ร้าน
        //     price:'',
        // }
    ],
    chose:false,
    status:"available",
    BOM:[
        // {
        //     rawMatId:'',
        //     qty:99
        // }
    ],
}