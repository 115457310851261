export default [
    {
        id:'001',
        // imageId:mainImage.cash2,
        name:'เงินสด',
        type:'cash',
        fee:""
    },
    {
        id:'002',
        // imageId:mainImage.creditcard,
        name:'บัตรเครดิต',
        type:'creditCard',
        fee:""
    },
    {
        id:'003',
        // imageId:mainImage.creditcard,
        name:'Visa Standard',
        type:'creditCard',
        fee:""
    },
    {
        id:'004',
        // imageId:mainImage.creditcard,
        name:'Visa Premium/Platinum',
        type:'creditCard',
        fee:""
    },
    {
        id:'005',
        // imageId:mainImage.creditcard,
        name:'MasterCard Standard',
        type:'creditCard',
        fee:""
    },
    {
        id:'006',
        // imageId:mainImage.creditcard,
        name:'MasterCard Premium/Platinum',
        type:'creditCard',
        fee:""
    },
    {
        id:'007',
        // imageId:mainImage.creditcard,
        name:'JCB',
        type:'creditCard',
        fee:""
    },
    {
        id:'008',
        // imageId:mainImage.creditcard,
        name:'American Express',
        type:'creditCard',
        fee:""
    },
    {
        id:'009',
        // imageId:mainImage.thaiqr,
        name:'thaiqr',
        type:'qr',
        fee:"",
        promptpayId:'',
        promptpayName:''
    },
    {
        id:'010',
        // imageId:mainImage.truemoney,
        name:'true wellet',
        type:'wallet',
        fee:""
    },
    {
        id:'011',
        // imageId:mainImage.sabuymoney,
        name:'sabuy wellet',
        type:'wallet',
        fee:""
    },
    {
        id:'012',
        // imageId:mainImage.bitkub,
        name:'bit coin',
        type:'wallet',
        fee:""
    },
    {
        id:'013',
        // imageId:mainImage.kpaylater,
        name:'k pay later',
        type:'wallet',
        fee:""
    },
    {
        id:'014',
        // imageId:mainImage.rabbitlinepay,
        name:'rabbit LINEPay',
        type:'wallet',
        fee:""
    },
    {
        id:'015',
        // imageId:mainImage.shoppeepay,
        name:'Shoppee Pay',
        type:'wallet',
        fee:""
    },
    {
        id:'016',
        // imageId:mainImage.airpay,
        name:'AirPay',
        type:'wallet',
        fee:""
    },
    {
        id:'017',
        // imageId:mainImage.lazpay,
        name:'LAZYPAY',
        type:'wallet',
        fee:""
    },
    {
        id:'018',
        // imageId:mainImage.lazpay,
        name:'credit1', // จ่ายด้วยเงินในระบบ
        type:'wallet',
        fee:""
    },
    {
        id:'019',
        // imageId:mainImage.lazpay,
        name:'credit2', // จ่ายด้วยเงินเซ็นต์
        type:'wallet',
        fee:""
    },
    {
        id:'999',
        // imageId:mainImage.other,
        name:'อื่นๆ',
        type:'other',
        fee:""
    },
]