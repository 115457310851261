import {
    fetchLandlord as firebaseFetch,
    updateLandlord as firebaseUpdate,
    addLandlord as firebaseAdd,
} from '../db/firestore'

import createDataContext from './createDataContext';


const landlordReducer = (state,action) => {
    switch(action.type){
        case 'fetchLandlord':
            return [...action.payload];
        case 'addLandlord':
            return [...state,action.payload];
        case 'updateLandlord':
            return state.map((item) => {
                return item.id == action.payload.id
                    ? action.payload
                    : item;     
            });
        default:
            return state;
    }
};

const addLandlord = (dispatch) => async (data) => {
    const promise = new Promise(async (resolve,reject)=>{
        try{
            console.log(data)
            let landlord = await firebaseAdd(data)
            dispatch({type:'addLandlord',payload:landlord})
            resolve(landlord)
        }catch(err){
            reject(err)
        }
      })
    return promise
}

const fetchLandlord = (dispatch) => async (email) => {
    console.log(email+'email')
    const promise = new Promise(async (resolve,reject)=>{
        try{
            let landlord = await firebaseFetch(email)
            dispatch({type:'fetchLandlord',payload:landlord})
            resolve(landlord)
        }catch(err){
            reject(err)
        }
      })
    return promise
}
const updateLandlord = (dispatch) => async (data) => {
    const promise = new Promise(async (resolve,reject)=>{
        try{
            firebaseUpdate(data.id,data)
            dispatch({type:'updateLandlord',payload:data})
            resolve(data)
        }catch(err){
            reject(err)
        }
      })
      return promise
}
const updateLandlordContext = (dispatch) => async (data) => {
    const promise = new Promise(async (resolve,reject)=>{
        try{
            dispatch({type:'updateLandlord',payload:data})
            resolve(data)
        }catch(err){
            reject(err)
        }
      })
      return promise
}



export const {Provider,Context} = createDataContext(
    landlordReducer,
    {updateLandlord,fetchLandlord, addLandlord,updateLandlordContext},
  
    []
);