import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { colors, mainImage } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { exportVat, goToTop } from "../Utility/function";
import { NumberYMD, minusDays, plusDays, stringDateTimeShort, stringYMDHMS3 } from "../Utility/dateTime";
import TablePagination from '@mui/material/TablePagination';
import { Modal_Loading } from "../modal";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import { fetchBill, updateBills } from "../redux/resBillSlice";
import { CSVLink } from "react-csv";


function ResReportByTax() {
  const dispatch = useDispatch();
  const { shop } = useSelector((state)=> state.resShop)
  const { cutOff } = shop;
  const {billDates, modal_Bill, selectedBill } = useSelector((state)=> state.resBill)
  const [currentDisplay, setCurrentDisplay] = useState([]) // จำนวนที่แสดงในหนึ่งหน้า
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const today = new Date();
  const [startDate, setStartDate] = useState(today); //some many of day array to initial graph
  const [endDate, setEndDate] = useState(today); //  today

    useEffect(()=>{  
    let rewSearch = []
    let newSearch = []
    let start = minusDays(startDate,1)

    do {
        rewSearch.push(stringYMDHMS3(start))
        start = plusDays(start,1)
    }
    while (NumberYMD(start) < NumberYMD(plusDays(endDate,2)));

    for(const item of rewSearch){
        if(!billDates.includes(item)){
        newSearch.push(item)
        }
    }
    if(newSearch.length >0){
        dispatch(fetchBill({shopId:shop.id,billDate:newSearch,cutOff,startDate,endDate}))
    } else {
        dispatch(updateBills({startDate,endDate,cutOff}))
    }
    
    },[startDate,endDate])



  const handleChangePage = (event, newPage) => {
      setPage(newPage); // start form 0
      goToTop()
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      goToTop()
  };


  useEffect(()=>{
    let fData = selectedBill.map((item,index)=>{return({...item,no:index})}).filter((item,index)=>{return(index >=(page*rowsPerPage) && index <= ((page+1)*rowsPerPage)-1)})
      setCurrentDisplay(fData)
  },[page,rowsPerPage,selectedBill])

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{borderRadius:20}} onClick={onClick} ref={ref}>
      {value}
    </div>
  ));


  return (
    <div  >
      <Modal_Loading show={modal_Bill} />

      <h1>รายงานภาษีขาย</h1>
      <Row >
          <Col lg='4'  >
              <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
                <img style={{width:30}} src={mainImage.calender} />
                <div style={{paddingLeft:10,paddingRight:10}} >  Start: </div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<ExampleCustomInput />}
                />
              </div>
          </Col>
          <Col lg='4'  >
            <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
              <img style={{width:30}} src={mainImage.calender} />
              <div style={{paddingLeft:10,paddingRight:10}} >  End: </div>
              <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  customInput={<ExampleCustomInput />}
              />
            </div>
          </Col>
      </Row>
      <div style={{paddingBottom:30}} >
      <CSVLink 
              style={{backgroundColor:'#292929',textDecoration:'none',color:colors.white,padding:10,borderRadius:20,paddingLeft:30,paddingRight:30,marginBottom:50}}
              filename={`รายงานยอดขายรายวัน ${stringYMDHMS3(startDate)}-${stringYMDHMS3(endDate)}`}
              data={exportVat(selectedBill)}
            >
                Export CSV
            </CSVLink>
      </div>
        
            
        <Row>
                <Table striped bordered hover responsive  variant="light"   >
                    <thead  >
                    <tr>
                        <th style={{ width: '10%' }}>ที่</th>
                        <th style={{ width: '10%' }}>วันที่</th>
                        <th style={{ width: '10%' }}>เลขที่บิล</th>
                        <th style={{ width: '10%' }}>ชื่อลูกค้า</th>
                        <th style={{ width: '10%' }}>เลขผู้เสียภาษี</th>
                        <th style={{ width: '10%' }}>ยอดก่อนภาษี</th>
                        <th style={{ width: '10%' }}>ภาษี</th>
                        <th style={{ width: '10%' }}>ยอดสุทธิ</th>
                    </tr>
                  </thead>
                  <tbody  >
                    {currentDisplay.map((item, index) => (
                      <tr  style={{cursor: 'pointer'}} key={index} >
                        <td>{index+1}.</td>
                        <td>{stringDateTimeShort(item.timestamp)}</td>
                        <td>{item.receiptNumber}</td>
                        {item.taxNumber
                            ?<>
                            <td>{item?.taxCustomer?.name}</td>
                            <td>{item?.taxCustomer?.taxNumber}</td>
                            </>
                            :<>
                            <td>ลค.</td>
                            <td>-</td>
                            </>
                        }
              
                        <td >{item.beforeVat.toLocaleString()}</td>
                        <td >{item.vatAmount.toLocaleString()}</td>
                        <td >{item.net.toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <TablePagination
                    component="div"
                    count={selectedBill.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Row>
              
    </div>
  );
}

export default ResReportByTax;
