import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { colors, mainImage } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { endCutoff, exportReportDay, findInArray,  goToTop, haveCommonElement, setDifference, startCutoff, summary } from "../Utility/function";
import { NumberYMD, minusDays, plusDays, stringDateTimeShort, stringYMDHMS3 } from "../Utility/dateTime";
import TablePagination from '@mui/material/TablePagination';
import { Modal_Loading } from "../modal";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import { fetchBill, updateBills } from "../redux/resBillSlice";
import { CSVLink } from "react-csv";


function ResReportByDay() {
  const dispatch = useDispatch();
  const { shop } = useSelector((state)=> state.resShop)
  const { cutOff, smartReceipt } = shop;
  const {billDates, modal_Bill, selectedBill } = useSelector((state)=> state.resBill)
  const [currentDisplay, setCurrentDisplay] = useState([]) // จำนวนที่แสดงในหนึ่งหน้า
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const today = new Date();
  const [startDate, setStartDate] = useState(today); //some many of day array to initial graph
  const [endDate, setEndDate] = useState(today); //  today
  const { products } = useSelector((state)=> state.resProduct);

  function calculatePoints(thisBill) {
    return thisBill.reduce((totalPoints, item) => totalPoints + (item.pointDiscount?.value || 0), 0);
}

    useEffect(()=>{  
    let rewSearch = []
    let newSearch = []
    let start = minusDays(startDate,1)

    do {
        rewSearch.push(stringYMDHMS3(start))
        start = plusDays(start,1)
    }
    while (NumberYMD(start) < NumberYMD(plusDays(endDate,2)));

    for(const item of rewSearch){
        if(!billDates.includes(item)){
        newSearch.push(item)
        }
    }
    if(newSearch.length >0){
        dispatch(fetchBill({shopId:shop.id,billDate:newSearch,cutOff,startDate,endDate}))
    } else {
        dispatch(updateBills({startDate,endDate,cutOff}))
    }
    
    },[startDate,endDate])

    const display = useMemo(()=>{
        let start = startDate
        const dates = []
        do {
          const thisBill = selectedBill.filter((item)=>{return(new Date(item.timestamp) > startCutoff(start,new Date(cutOff)) && new Date(item.timestamp) <= endCutoff(start,new Date(cutOff)))})
          let obj = {
            date:stringDateTimeShort(start),
            length:thisBill.length,
            totalNet:summary(thisBill,'net'),
            beforeVat:summary(thisBill,'beforeVat'),
            totalVat:summary(thisBill,'vatAmount'),
            totalCreditCharge:summary(thisBill,'creditChargeAmount'),
            totalServiceChargeAmount:summary(thisBill,'serviceChargeAmount'),
            totalFine:summary(thisBill.flatMap(a=>a.fine),'amount'),
            totalPrice:0,
            totalDiscount:summary(thisBill.flatMap(a=>a.discount),'amount')+calculatePoints(thisBill),
            totalSubPrice:summary(thisBill,'subTotal')-(summary(thisBill.flatMap(a=>a.discount),'amount')+calculatePoints(thisBill)),
          }
          if(smartReceipt?.status){
            let totalProduct = thisBill.flatMap(a=>a.product);
            let haveCategoryProduct = []
            let categoryReceipt = smartReceipt.value.map((item)=>{
                let newProduct = []
                for(const a of totalProduct){
                    const { category } = findInArray(products,'id',a.id)||{category:[]};
                    if(haveCommonElement(item.category,category)){
                        newProduct.push(a)
                        haveCategoryProduct.push(a)
                    }
                }
                return{
                    id:item.id,
                    totalPrice:summary(newProduct,'totalPrice'),
                    name:item.name
                }
            })
            obj.totalPrice = [...categoryReceipt,{id:'x',totalPrice:summary(setDifference(totalProduct,haveCategoryProduct),'totalPrice'),name:'อื่นๆ'}]
          } else {
            obj.totalPrice = summary(thisBill,'subTotal')
          }
          dates.push(obj)
          start = plusDays(start,1)
        }
        while (NumberYMD(start) <= NumberYMD(endDate));
        return dates
    },[selectedBill,startDate,smartReceipt]);


  const handleChangePage = (event, newPage) => {
      setPage(newPage); // start form 0
      goToTop()
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      goToTop()
  };


  useEffect(()=>{
    let fData = display.map((item,index)=>{return({...item,no:index})}).filter((item,index)=>{return(index >=(page*rowsPerPage) && index <= ((page+1)*rowsPerPage)-1)})
      setCurrentDisplay(fData)
  },[page,rowsPerPage,display])

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{borderRadius:20}} onClick={onClick} ref={ref}>
      {value}
    </div>
  ));


  return (
    <div  >
      <Modal_Loading show={modal_Bill} />

      <h1>รายงานยอดขายรายวัน</h1>
      <Row >
          <Col lg='4'  >
              <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
                <img style={{width:30}} src={mainImage.calender} />
                <div style={{paddingLeft:10,paddingRight:10}} >  Start: </div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<ExampleCustomInput />}
                />
              </div>
          </Col>
          <Col lg='4'  >
            <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
              <img style={{width:30}} src={mainImage.calender} />
              <div style={{paddingLeft:10,paddingRight:10}} >  End: </div>
              <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  customInput={<ExampleCustomInput />}
              />
            </div>
          </Col>
      </Row>
      <div style={{paddingBottom:30}} >
      <CSVLink 
              style={{backgroundColor:'#292929',textDecoration:'none',color:colors.white,padding:10,borderRadius:20,paddingLeft:30,paddingRight:30,marginBottom:50}}
              filename={`รายงานยอดขายรายวัน ${stringYMDHMS3(startDate)}-${stringYMDHMS3(endDate)}`}
              data={exportReportDay(display,smartReceipt)}
            >
                Export CSV
            </CSVLink>
      </div>
        
            
        <Row>
                <Table striped bordered hover responsive  variant="light"   >
                    <thead  >
                    <tr>
                        <th style={{ width: '10%' }}>วันที่</th>
                        {smartReceipt?.status
                            ?[...smartReceipt.value,{id:'x',name:'อื่นๆ'}].map(a=>(
                                    <th style={{ width: '10%' }}>{a.name}</th>
                            ))
                            :<th style={{ width: '10%' }}>ยอดขาย</th>
                        }
                      <th style={{ width: '10%' }}>ส่วนลด</th>
                      <th style={{ width: '10%' }}>ยอดขายสุทธิ</th>
                      <th style={{ width: '10%' }}>ค่าบริการ</th>
                      <th style={{ width: '10%' }}>ค่าปรับ/อื่นๆ</th>
                      <th style={{ width: '10%' }}>ยอดก่อนภาษี</th>
                      <th style={{ width: '10%' }}>ภาษี</th>
                      <th style={{ width: '10%' }}>ยอดทั้งสิ้น</th>
                    </tr>
                  </thead>
                  <tbody  >
                    {currentDisplay.map((item, index) => (
                      <tr  style={{cursor: 'pointer'}} key={index} >
                        <td>{item.date}</td>
                        {smartReceipt?.status
                            ?item.totalPrice.map((a,i)=>(
                                <td>{a.totalPrice.toLocaleString()}</td>
                            ))
                            :<td>{item.totalPrice.toLocaleString()}</td>
                        }
                        <td >{item.totalDiscount.toLocaleString()}</td>
                        <td >{item.totalSubPrice.toLocaleString()}</td>
                        <td >{item.totalServiceChargeAmount.toLocaleString()}</td>
                        <td >{(item.totalFine+item.totalCreditCharge).toLocaleString()}</td>
                        <td >{item.beforeVat.toLocaleString()}</td>
                        <td >{item.totalVat.toLocaleString()}</td>
                        <td >{item.totalNet.toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <TablePagination
                    component="div"
                    count={display.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Row>
              
    </div>
  );
}

export default ResReportByDay;
