import React, { useState, useContext } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";

function SettingScreen() {

  return (
    <div style={{backgroundColor:'black'}} >
        SettingScreen
    </div>
  );
}

export default SettingScreen;
