import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { db } from "../db/firestore";
import initialProduct from '../configs/initialProduct';


const initialState = {
    products:[], // 
    modal_Product:false,
    error: "",
    newProduct:initialProduct,
    modal_newProduct:false,
}

// fetch product
export const fetchProduct = createAsyncThunk(
    'product/fetchProduct',
    async (id) => {
      let data = []
      await db.collection('product').where("shopId", "==", id).get().then((docs)=>{
        docs.forEach((doc)=>{data.push({id:doc.id,...doc.data(),timestamp:doc.data().timestamp.toDate()})})
      })
      return data;
    }
);


// add product to firestore
export const addProductToFirestore = createAsyncThunk(
    'product/addProductToFirestore',
    async (product)=>{
        let newProduct = {}
        await db.collection('product').add(product).then((doc)=>{
            newProduct = {id: doc.id, ...product}
        })
        return newProduct;
    }
);


// deleteProduct 
export const deleteProduct = createAsyncThunk(
    'product/deleteProduct',
    async (id)=>{
        await db.collection('product').doc(id).delete()
        return id
    }
);

// updateProduct
export const updateProduct = createAsyncThunk(
    'product/updateProduct',
    async (product) => {
        await db.collection('product').doc(product.id).update(product)
        return product
    }
);
  


export const produceSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    createNewProduct:(state,action) => {
        state.newProduct = initialProduct;
        state.modal_newProduct = true;
    },
    updateModal_Product: state => {
      state.modal_Product = !state.modal_Product
    },
    fetchNormalProduct: (state, action) => {
        state.products = action.payload
    },

  },
  extraReducers: builder => {
    builder.addCase(addProductToFirestore.pending, state => {
        state.modal_Product = true
    })
    builder.addCase(addProductToFirestore.fulfilled, (state, action) => {
        state.products = [...state.products,action.payload]
        state.modal_newProduct = false
        state.modal_Product = false
    })
    builder.addCase(addProductToFirestore.rejected, (state, action) => {
        state.modal_Product = false
        state.error = action.error.message
        alert('เพิ่มสินค้าไม่สำเร็จ กรุณาลองใหม่อีกครั้ง?')
    })
    builder.addCase(fetchProduct.pending, state => {
        state.modal_Product = true
    })
    builder.addCase(fetchProduct.fulfilled, (state, action) => {
        state.products = action.payload
        state.modal_Product = false
    })
    builder.addCase(fetchProduct.rejected, state => {
        state.modal_Product = false
    })
    builder.addCase(deleteProduct.pending, state => {
        state.modal_Product = true
    })
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
        state.products = state.products.filter(a=>a.id !== action.payload)
        state.modal_Product = false
    })
    builder.addCase(deleteProduct.rejected, state => {
        state.modal_Product = false
    })
    builder.addCase(updateProduct.pending, state => {
        state.modal_Product = true
    })
    builder.addCase(updateProduct.fulfilled, (state, action) => {
        state.products = state.products.map((item)=>{
            return item.id === action.payload.id
                ?action.payload
                :item
        })
        state.modal_Product = false
    })
    builder.addCase(updateProduct.rejected, (state, action) => {
        state.modal_Product = false
        state.error = action.error.message
        alert('อัปเดตสินค่าไม่สำเร็จ กรุณาลองใหม่อีกครั้ง!')
    })
  }
})

export const { createNewProduct,updateModal_Product, fetchNormalProduct
} = produceSlice.actions

export default produceSlice.reducer