import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Card } from "react-bootstrap";
import '../styles/ShopListScreen.css'
import { colors, initialService } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { filterDeleteInArray, filterInArray, findInArray, findTotalPrice, leanProduct, mapInArray, someInArray, summary } from "../Utility/function";
import { addOrderToFirebase } from "../redux/orderSlice";
import { v4 as uuidv4 } from 'uuid';
import { pushByTwoFilter } from '../api/onesignal';
import { plusMinute } from "../Utility/dateTime";
import { FcFullTrash } from "react-icons/fc";



function Modal_PackageBuffet({
  backdrop='static', // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='md',
  value,
  success,
}) {
  const dispatch = useDispatch();
  const { tableData, orderHistory, } = useSelector((state)=> state.order);
  const { scShopId, shopId, smartChef, channel, smartBuffet, 
    serviceType, tableId, tableName } = tableData;
    const [display, setDisplay] = useState([]);
    const [cart, setCart] = useState([]);
    const [masterData, setMasterData] = useState([])

    useEffect(()=>{
        let res = []
        if(smartBuffet){
            for(const item of smartBuffet){
                const { id, name, price } = item
                res.push({ id, name, price, qty:0, type:'buffet' })
            }
        }
        const { status } = findInArray(serviceType||initialService,'id',1)
        if(status){
            res = [{name:'A La Carte(จำนวนลูกค้า)',id:18,type:'alacarte',qty:0},...res]
        }
        setMasterData(res)
    },[])

    useEffect(()=>{
        if(cart.length===0){
            setDisplay(masterData)
        } 
        
        if (cart.length===1){
            if(someInArray(cart,'type','alacarte')){
                setDisplay(filterInArray(masterData,'type','alacarte'))
            } else {
                setDisplay(filterInArray(masterData,'type','buffet'))
            }
        }
    },[cart,masterData]) 

    function checkCart(item){
        const { id, name, price, type } = item;
        let findCart = findInArray(cart,'id',id)
        if(findCart && findCart.id){
            const { qty, totalPrice} = findCart;
            setCart(mapInArray(cart,'id',id,{...findCart,qty:qty+1,totalPrice:totalPrice+price}))
        } else {
            setCart([...cart,{
                id,
                qty:1,
                name,
                BOM:[], 
                addOn:'', 
                category:[], 
                option:[], 
                totalPrice:price, 
                tempId:uuidv4(),
                type
            }])
        }
    }


    function submit(){
        let newOrder = {}
        setCart([])
        if(someInArray(cart,'type','alacarte')){
            newOrder = {
                shopId,
                scShopId,
                channelId:channel.id,
                product:[],
                timestamp:new Date(),
                staff:[{id:'',process:'ordered'}],
                process:'ordered',
                customerQty:cart[0].qty,
                endTime:'',
                type:'alacarte'
            }
        } else {
            const { limitTime, time } = findInArray(smartBuffet,'id',cart[0].id)
            let timestamp = new Date()
            newOrder = {
                shopId,
                scShopId,
                channelId:tableId,
                product:cart.map(item=>leanProduct(item)),
                timestamp,
                staff:[{id:'',process:'ordered'}],
                process:'ordered',
                customerQty:summary(cart,'qty'),
                endTime:'',
                type:'buffet'
            }
            if(limitTime){
                newOrder.endTime = plusMinute(timestamp,time)
            }
        }
        dispatch(addOrderToFirebase(newOrder)).then(async ()=>{
            if(smartChef){
                if(orderHistory.length===0){
                    for(const item of smartChef){
                        pushByTwoFilter({id:item,shopId,content:`เปิดโต๊ะ ${cart.length} รายการ`,heading:tableName,data : {screen:"CookingScreen"}})
                    }
                } else {
                    for(const item of smartChef){
                        pushByTwoFilter({id:item,shopId,content:`สั่งอาหาร ${cart.length} รายการ`,heading:tableName,data : {screen:"CookingScreen"}})
                    }
                }
            }
            onHide()
        })
    }

  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
    //   onHide={onHide}
      centered={centered}
      className='loading-screen'
      size={size}
    >
      <Modal.Header  >
        <h3>เลือก Package ที่ต้องการ</h3>
      </Modal.Header>
      <Modal.Body 
      style={{
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        zIndex: 999,
        background: colors.transparent,
        borderRadius: 30,
        overflowY: 'auto',  // Allow vertical scrolling
        // height:400,
        maxHeight: `50vh`, // Adjust the maximum height of the body
        height:'50vh'

      }}
      >
        <Row>
            {display.map((item,index)=>{
                return(
                    <Col xs='6' key={index} >
                        <div onClick={()=>{checkCart(item)}} style={{display:'flex',justifyContent:'center',alignItems:'center',
                        backgroundColor:colors.softWhite,borderRadius:20,height:'90%',margin:10,padding:10,cursor:'pointer'}} >
                            {item.name}
                        </div>
                    </Col>
                )
            })}
        </Row>
        {cart.length>0
            ?cart.map((item,index)=>{
                const { name, qty, id } = item;
                return(
                    <div key={index} style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:10}} >
                        <h6 >{name} x {qty}</h6>
                        <div onClick={()=>{setCart(filterDeleteInArray(cart,'id',id))}} style={{cursor:'pinter'}}  >
                            <FcFullTrash style={{ fontSize: '1.5rem'}} />
                        </div>
                    </div>
                )
            })
            :null
        }
                

      </Modal.Body>
      <Modal.Footer>
        {cart.length>0
            ?<div onClick={()=>{submit()}} style={{padding:10,paddingHorizontal:25,backgroundColor:colors.purple,
            borderRadius:10,alignSelf:'center',margin:10,color:colors.white,cursor:'pointer'}} >
                <h6 >ส่งออเดอร์!</h6>
            </div>
            :<div style={{padding:10,paddingHorizontal:25,backgroundColor:colors.softGray,
            borderRadius:10,alignSelf:'center',margin:10,color:colors.white}} >
                <h6 >ส่งออเดอร์!</h6>
            </div>
        } 
      </Modal.Footer>
    </Modal>
  );
}

export default Modal_PackageBuffet;

