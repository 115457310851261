// import React, { useContext } from "react";
// import { Container, Row, Col, Button, Modal, Card } from "react-bootstrap";
// import ShopListDetail from "../screens/ShopListDetail";



// function Modal_Shop({
//   backdrop=true, // true/false/static
//   animation=true,
//   show,
//   onHide,
//   centered=false,
//   size='xl',
//   onClick,
//   name
// }) {


//   return (
//     <Modal
//       backdrop={backdrop}
//       animation={animation}
//       show={show}
//       onHide={onHide}
//       centered={centered}
//       size={size}
//     >
//       <Modal.Body style={{borderRadius:20}} >
//             <ShopListDetail  />
//       </Modal.Body>
//     </Modal>
//   );
// }

// export default Modal_Shop;

import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Carousel
} from "react-bootstrap";
import { bath, colors, mainImage } from "../configs";
import { BorderHorizontal, FloatingText, InputArea, InputText } from "../components";
import Modal_Contact from "./Modal_Contact";
import { v4 as uuidv4 } from 'uuid';
import Modal_Alert from "./Modal_Alert";
import { Translator, checkSource } from "../Utility/function";
import Modal_CropImage from "./Modal_CropImage";
import { useSelector, useDispatch } from "react-redux";
import { stringFullTime } from "../Utility/dateTime";
import Modal_Confirm from "./Modal_Confirm";
import { changePendingToNormal } from "../redux/renterSlice";
import { pushByOneFilter } from "../api/onesignal";
import Modal_Loading from "./Modal_Loading";



function Modal_Shop({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
  onClick,

}) {

  const dispatch = useDispatch();
  const { renter, loading_Renter   } = useSelector((state)=> state.renter);
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      fullscreen='xxl-down'
    //   size={size}
    >
      <Modal.Header closeButton>
        <h2><b>ร้าน {renter.shop?.name}</b></h2>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'transparent'}} >
        <Modal_Loading show={loading_Renter} />
          
        <Row  >
          <Col md='3'   >
            {/* <div style={{backgroundColor:'black'}} >  */}
            <Row>
              <Col md='6' style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}} >
                <img style={{width:'90%',height:'auto'}} src={Translator(renter.shop.imageUrl,'profile')} alt='image' />
                <h6>ผู้ประกอบการ</h6>
              </Col>
              <Col md='6' style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}} >
                <img style={{width:'90%',height:'auto'}} src={Translator(renter.shop.imageUrl,'logo')} alt='image' />
                <h6>logo</h6>
              </Col>
            </Row>
                <h2>ร้าน {renter.shop.name}</h2>
                <h5>ผู้สมัคร : {renter.currentRenter.name}</h5>
                <BorderHorizontal/>
                <h6><b>ที่อยู่</b></h6>
                <h6 style={{color:colors.softGray}} >{renter.shop.address}</h6>
                <BorderHorizontal/>
                <p><b>ประเภทร้านค้า</b></p>
                <h6 style={{color:colors.softGray}} >-</h6>
                <BorderHorizontal/>
                <p><b>ประเภทสินค้า</b></p>
                <h6 style={{color:colors.softGray}} >-</h6>
                <BorderHorizontal/>
                <p><b>วันที่ขอเข้าร่วม</b></p>
                <h6 style={{color:colors.softGray}} >{stringFullTime(new Date(renter.currentRenter.createDate))}</h6>
          </Col>
          <Col md='9' >
            {/* <Row>
              <Col lg={4} style={{ paddingLeft: 5,paddingRight: 5 }} >
                  <div style={{borderRadius:20,backgroundColor:colors.darkPurple,color:'white',padding:10,marginBottom:10}} >
                      <h6>ยอดขายประจำวันนี้</h6>
                      <h3><b>4,600 {bath}</b></h3>
                  </div>
              </Col>
              <Col lg={4} style={{ paddingLeft: 5,paddingRight: 5 }}  >
                  <div style={{borderRadius:20,backgroundColor:colors.darkPurple,color:'white',padding:10,marginBottom:10}} >
                      <h6>ยอดขายประจำเดือนนี้</h6>
                      <h3><b>4,600 {bath}</b></h3>
                  </div>
              </Col>
              <Col lg={4} style={{ paddingLeft: 5,paddingRight: 5 }}    >
                  <Row style={{ marginLeft: 0, marginRight: 0 }} >
                      <Col xs={12} md={6} style={{ paddingLeft: 5,paddingRight: 5 }}    >
                          <div style={{borderRadius:20,backgroundColor:colors.purplePeel,color:colors.darkPurple,padding:10,marginBottom:10}} >
                              <h6 style={{margin:0}} >จำนวนออเดอร์ประจำวันนี้</h6>
                              <h5 style={{margin:0}} ><b>4,600 {bath}</b></h5>
                          </div>
                      </Col>
                      <Col xs={12} md={6} style={{ paddingLeft: 5,paddingRight: 5 }}   >
                          <div style={{borderRadius:20,backgroundColor:colors.purplePeel,color:colors.darkPurple,padding:10,marginBottom:10}} >
                              <h6 style={{margin:0}} >จำนวนออเดอร์ประจำเดือนนี้</h6>
                              <h5 style={{margin:0}} ><b>4,600 {bath}</b></h5>
                          </div>
                      </Col>
                  </Row>
              </Col>
            </Row>
            <Row>
                <Col sm={12}  md={4} style={{textAlign:'center',color:colors.darkGray}}  >
                    <div>
                        <h1><b>-</b></h1>
                        <p>จำนวนวันที่เปิดร้าน</p>
                    </div>
                </Col>
                <Col sm={12}  md={4}   style={{textAlign:'center',color:colors.darkGray,display:'flex',alignItems:'center',paddingLeft:0,paddingRight:0,justifyContent:'space-between'}}  >
                    <div className="left-border" style={{height:'50%'}} />
                    <div>
                        <h1><b>-</b></h1>
                        <p>จำนวนวันที่ปิดร้าน</p>
                    </div>
                    <div className="right-border" style={{height:'50%'}} />

                </Col>
                <Col sm={12}  md={4} style={{textAlign:'center',color:colors.darkGray,}}  >
                    <h1><b>-</b></h1>
                    <p>จำนวนครั้งผิดนัดชำระ</p>
                </Col>
            </Row> */}
                  <h2>ภาพบรรยากาศภายในร้าน</h2>
                  <Carousel activeIndex={index} onSelect={handleSelect} style={{padding:0,margin:0,backgroundColor:colors.softGray}} >
                    {Translator(renter.shop.imageUrl,'otherPhoto')?.map((item)=>{
                      return(
                        <Carousel.Item style={{}} >
                          <div style={{display:'flex',justifyContent:'center'}} >
                            <img
                              src={item}
                              alt="First slide"
                              style={{height:400,width:'auto'}}
                            />
                          </div>
                          <Carousel.Caption>
                          </Carousel.Caption>
                        </Carousel.Item>
                      )
                    })}
                  </Carousel>
                  
          </Col>
        </Row>
        <Row style={{paddingTop:20}} >
          <BorderHorizontal/>
          <h2>รายการสินค้า : {renter.products.length} รายการ</h2>
          {renter.products.map((item,index)=>{
            return(
              <Col xs='6' sm='4' md='3' lg='2' style={{marginBottom:10}} >
                <div style={{backgroundColor:colors.purplePeel,borderRadius:10,height:'100%',marginBottom:0}} >
                  <img style={{width:'100%',height:'auto',borderRadius:10}} alt='(image)' src={item.imageId} />
                    <div style={{padding:5}} >
                      <p style={{padding:0,margin:0}} >{item.name}</p>
                      <p style={{padding:0,margin:0,color:colors.purpleRed}} ><b>{item.price[0].price}  {bath}</b></p>
                    </div>
                </div>
              </Col>
            )
          })}
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={onHide} variant="secondary">Cancel</Button>
        <Button onClick={onClick} variant="primary">Confirm</Button>
    </Modal.Footer> */}
    </Modal>
  );
}

export default Modal_Shop;
