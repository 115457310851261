export default {
    logo:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2Fshopchampconnect.png?alt=media&token=cd815a4f-264f-4f44-8309-a4ce6e0be7a5',
    ig:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Finstagram%20(1).png?alt=media&token=dc34a0b9-e6d0-4beb-adb8-4789baf5c45b',
    twitter:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Ftwitter%20(1).png?alt=media&token=b616c196-48f0-44ff-aac7-713c4d65d916',
    youtube:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Fyoutube%20(1).png?alt=media&token=7acc7774-99fa-40ba-86cf-e827e2cfdd01',
    facebook:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Ffacebook.png?alt=media&token=25251ea1-5e58-4859-8beb-a5a6426db8f5',
    tiktok:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Ftik-tok%20(1).png?alt=media&token=6e107a3b-ec6f-4799-a038-7f3360058154',
    telegram:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Ftelegram%20(1).png?alt=media&token=ee0e411c-a9a5-41a1-9ac9-699d3ed0a9a1',
    linkedin:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Flinkedin.png?alt=media&token=d867f013-3d71-4af9-90bc-96559ae198d7',
    whatsapp:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Fwhatsapp.png?alt=media&token=cd37c9ff-04b9-467d-a74e-87cff5702253',
    www:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2F5602732.png?alt=media&token=e148889f-9d95-4aae-935f-00555106be61',
    email:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Femail.png?alt=media&token=06c8788b-6dc0-4d1e-b825-113d5705ed4e',
    messenger:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2FFacebook_Messenger_logo_2020.svg%20(1).png?alt=media&token=3cf564c0-40b0-4673-b974-2fda6905464f',
    line:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2FLINE_New_App_Icon_(2020-12)%20(1).png?alt=media&token=e5b08ecf-4455-4b75-b079-70959ee7c7ba',
    discord:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Fconcours-discord-cartes-voeux-fortnite-france-6%20(1).png?alt=media&token=2edcba0e-0bdf-4ac3-b2a5-fb145e7c06bf',
    snapchat:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Fsnapchat%20(1).png?alt=media&token=8e48322c-5e28-4e21-9e15-c788c162663a',
    wechat:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Fwechat.png?alt=media&token=ec676837-bb39-424c-aaa0-2f17dd51c901',
    phone:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Fphone.png?alt=media&token=14a2a45f-0855-435a-a904-182c53cb860f',
    coverphoto:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2F%E0%B8%94%E0%B8%B5%E0%B9%84%E0%B8%8B%E0%B8%99%E0%B9%8C%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%B1%E0%B8%87%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%95%E0%B8%B1%E0%B9%89%E0%B8%87%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD.png?alt=media&token=bbfb6c0e-4f23-427b-a5f7-a4d30b5f3250',
    location:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Flocation.png?alt=media&token=2f26b4b6-29cd-4a66-8215-e39a6bca4dee',
    address:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/socialIcon%2Faddress.png?alt=media&token=e13d32fe-4368-4659-9f22-c8d80ce6ab90',
    excel:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2Fexcel%20(1).png?alt=media&token=443f44d8-f323-4ca5-b8a4-aec0c97e1322',
    empowering:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2FEmpowering%20your%20business.gif?alt=media&token=1860caf4-0d99-4e59-b899-ed2a85c8e8a6',
    calender:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2FCalendar.png?alt=media&token=a759cfcd-c004-43a5-8519-39f58c523182',
    eye:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2Feye.png?alt=media&token=ed9eef44-c22a-4bb9-a463-79407178ec8f',
    export:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2FExport.png?alt=media&token=6f620e49-32cc-4fff-bd88-5aab049ade51',
    democoverphoto:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/demoImage%2F%E0%B8%AD%E0%B8%B1%E0%B8%9E%E0%B9%82%E0%B8%AB%E0%B8%A5%E0%B8%94%E0%B8%A3%E0%B8%B9%E0%B8%9B%20cover%20photo.png?alt=media&token=377637fa-9b84-4c8a-9402-ccb941238cfc',
    demologo:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/demoImage%2F%E0%B8%8A%E0%B9%87%E0%B8%AD%E0%B8%9B%E0%B9%81%E0%B8%8A%E0%B8%A1%E0%B8%9B%E0%B9%8C-24%20(1).png?alt=media&token=2d2b555d-6905-4165-9a33-da6dc1b133b7',
    iconlocation:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/icon%2F4%20(5).png?alt=media&token=0614c2d8-812e-4152-be3d-febf7830ea2a',
    iconaddress:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/icon%2F5%20(2).png?alt=media&token=1b46344f-6d3d-4058-a80a-ac5016c32fd8',
    productlist:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2FProduct%20list.png?alt=media&token=20eb037c-e016-48f3-869b-72d4355be0b6',
    categoryblack:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2FCategory.png?alt=media&token=f66d6517-e4d6-4dff-8ee7-0075d29f63c2',
    humanresource2:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/demoImage%2FEmployee%20Report%20(1).png?alt=media&token=944e9050-2291-490f-987f-b99c6b77d1ee',
    paymentblack:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2FPayment.png?alt=media&token=6a4be1b5-3fef-452f-b44f-2c2f7dca7242',
    receiptblack:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2FRecipe.png?alt=media&token=14cad570-be36-4cad-ab0d-9bc8055a5fd8',
    channelblack:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2FChannel.png?alt=media&token=96f433fc-9292-46c3-af63-e317be5c9d41',
    shift:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2FShift.png?alt=media&token=8848d19b-7975-4022-afaa-b24dfab82eb8',
    rawmat:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2FRaw%20Mat%20(1).png?alt=media&token=997dd83b-412a-442f-97f6-c9bfd5fa8de2',
    promotion:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2FPromotion.png?alt=media&token=b64d9c19-0a0e-44cc-a221-5772a415d962',
    shopchampplus:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/mainImage%2FShopchamp-9.png?alt=media&token=6e5920aa-2cab-48a3-ab59-2ca89e49fdf0',
    reslogo:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/icon%2Flogocolor.png?alt=media&token=57344fb5-af2e-4f7c-bb49-6636bd2307b1',
    pot:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/web%2FPot%20(1).png?alt=media&token=ba1f2abb-7cac-4651-a6cf-0bbbf74b0aa6',
    call:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/web%2FCall%20Staff.png?alt=media&token=d88235ce-f9b2-4645-8368-b1d82b3af05e',
    pay:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/web%2FPay%20(1).png?alt=media&token=91f4709d-01c4-4580-a00b-f4a87fc5d516',
    addsoup:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/web%2Fadd%20soup.png?alt=media&token=37ac405a-aa34-4fd7-bead-3ce1a906766c',
    chopstick:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/web%2Fchopsticks%20(1).png?alt=media&token=2b5aa3f6-8b43-4d65-a2a7-93ddc7bdca95',
    request:'https://firebasestorage.googleapis.com/v0/b/shopchamp-restaurant.appspot.com/o/web%2Frequest%20(1).png?alt=media&token=8524ec07-3775-4997-9c8f-06f725259244',
    
};