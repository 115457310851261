
import React, { useState, useContext, useEffect } from "react";
import {useParams} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearCart, fetchHistoryOrder, fetchProductOrder, fetchShopOrder, fetchqrCodeOrder, updateLinkId, updateTable, updateType } from "../redux/orderSlice";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink, useNavigate } from 'react-router-dom';
import { db } from "../db/firestore";
import * as loadingpanda from '../loadingpanda.json';
import Lottie from "react-lottie";
import Panda404 from "../components/Panda404";


function ResTableOrder() {
  const { docId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector( state => state.auth);
  const { linkId } = useSelector( state => state.order);
  const [expire_Modal, setExpire_Modal] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingpanda.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  useEffect(()=>{

    if(currentUser && currentUser.uid){
      try {
        db.collection('qrcodeOrder').doc(docId).get().then((docs)=>{
            if(docs.exists){
                const { shopId, shopName, tableId } = docs.data();
                Promise.all(
                  [
                    dispatch(updateTable(docs.data())),
                    dispatch(fetchProductOrder(shopId)),
                    dispatch(fetchHistoryOrder({shopId,tableId})),
                    dispatch(updateLinkId(docId)),
                    dispatch(updateType('dynamic')),
                  ]
              )
              .catch(() => {
                  console.log("Failed to update account")
              })
              .finally(() => {
                if(docId!==linkId){  // ถ้าเป็นคนละร้าน ต้องเคลียร์ตะกร้าออก
                  dispatch(clearCart())
                }
                navigate(`/${shopName}`)
              }) 
                
            } else {  // สำหรับ ลิงค์ถูกลบ เพราะ เคลียร์ออเดอร์แล้ว
              setExpire_Modal(true)
            }
        })
      } catch (error){
        alert(error)
      }
    }
  },[currentUser])


  return (
    <div style={{display:'flex',justifyContent:'center',alignItem:'center',minHeight:'100vh'}} >
      {expire_Modal
          ?<Panda404/>
          :<Lottie options={defaultOptions} height={200} width={200} />
      }
    </div>
  );
}

export default ResTableOrder;

