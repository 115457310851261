import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { addProductToCurrentCart } from '../redux/orderSlice';



const useUpdateCart = () => {
    const dispatch = useDispatch();
    const { shopOrder, productOrder, currentCart } = useSelector((state)=> state.order);
    // const {state: cart,updateCart }= useContext(CartContext);
        
        const editOrder = (action='+',product={}) => {
        let newCart = currentCart.slice()
        let fIndex = newCart.findIndex((item)=>{return(item.tempId == product.tempId)})
        let newQty = 0
        switch(action){
            case '+':
                newQty = product.qty+1
                newCart[fIndex] = {...product,qty:newQty,totalPrice:newQty*Number(product.price[0].price)}
                // updateCart(newCart)
                break;

            case 'update':
                newCart[fIndex] = {...product,qty:product.qty,totalPrice:product.totalPrice,addOn:product.addOn}
                // updateCart(newCart)
                break;
            
            case 'add':
                let copyData = {...product}
                delete copyData.timestamp
                dispatch(addProductToCurrentCart({
                    ...product,
                    tempId:uuidv4(),
                    process:'ordered',
                    diffQty:0,
                    productStaffs:[{email:'email',process:'ordered',timestamp:new Date()}],
                    special_Free:{id:0}, // แปลว่าว่างเปล่า = เป็นสินค้าปกติ
                    special_Discount:{},
                }))
                // updateCart([
                //     ...cart,
                //     {...copyData,
                //         tempId:uuidv4(),
                //         process:'ordered',
                //         diffQty:0,
                //         productStaffs:[{email:profile.email,process:'ordered',timestamp:firebase.firestore.Timestamp.fromDate(new Date())}],
                //         special_Free:{id:0}, // แปลว่าว่างเปล่า = เป็นสินค้าปกติ
                //         special_Discount:{},
                //     }])
                // updateTakeOrder(product)
                break;
            
            case '-':
                newQty = product.qty-1
                newCart[fIndex] = {...product,qty:newQty,totalPrice:newQty*Number(product.price[0].price)}
                let res2 = newCart.filter((b) =>{return(b.qty !== 0)})
                // updateCart(res2)
                break;
            case '#':
                // updateCart([])
                break;
            // case 'trash':
            //     xx = newCart.filter(a => a.tempId !== product.tempId);
            //     updateCart(xx) 
            //     updateTakeOrder(product)
            //     break;
            default:
                console.log('No condition')
                break;
        }
        
    };



    
    return { editOrder };
}

export {useUpdateCart};