import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from "../db/firestore";
import { initialProfile } from "../configs";

const initialState = {
    currentProfile:{},
    newProfile:initialProfile,
    loading_Profile:false,
}

// add newProfile to firestore
export const addProfileToFirebase = createAsyncThunk(
  'profile/addProfileToFirebase',
  async ({obj,uid})=>{
      let newProfile = {}
      await db.collection('profile').doc(uid).set({
        ...obj,
        timestamp:new Date(obj.timestamp),
        birthday:new Date(obj.birthday)
      })
      return {id: uid, ...obj}
  }
);


// fetch fetchProfile
export const fetchProfile = createAsyncThunk(
    'profile/fetchProfile',
    async (docId) => {
      let data = {}
      await db.collection('profile').doc(docId).get().then((doc)=>{
        data = {id: doc.id,...doc.data()}
      })
      console.log(data)
      return data;
    }
  );

export const profileSlice = createSlice({
    name:'profile',
    initialState,
    reducers: {
      setNewProfile: (state,action) => {
        state.newProfile = action.payload
      },
      updateCurrentProfile: (state,action) => {
        state.currentProfile = {}
      },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchProfile.pending, (state, action) => {
          state.loading_Profile = true
        })
        .addCase(fetchProfile.fulfilled, (state, action) => {
          state.currentProfile = action.payload
          state.loading_Profile = false
        })
        .addCase(fetchProfile.rejected, (state, action) => {
          state.loading_Profile = false
          state.error = action.error.message
        })
        .addCase(addProfileToFirebase.pending, (state, action) => {
          state.loading_Profile = true
        })
        .addCase(addProfileToFirebase.fulfilled, (state, action) => {
          state.currentProfile = action.payload
          state.loading_Profile = false
          state.newProfile = initialProfile
        })
        .addCase(addProfileToFirebase.rejected, (state, action) => {
          state.loading_Profile = false
          state.error = action.error.message
        })
      }
})

export const  { setNewProfile, updateCurrentProfile } = profileSlice.actions;
export default profileSlice.reducer;