import React from "react";
import { Container, Row, Col, Button, Modal, Card } from "react-bootstrap";
import '../styles/ShopListScreen.css'
import * as hungrypanda from '../hungrypanda.json'

import Lottie from "react-lottie";

function Modal_HungraPanda({
  backdrop=false, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
  content='หิวๆๆๆ สั่งอาหารทางไหนค้าาา'
}) {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: hungrypanda.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      className='loading-screen'
    >
      <Modal.Body style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',zIndex:999}}  >
          <Lottie options={defaultOptions} height={200} width={200} />
          {content}
      </Modal.Body>
    </Modal>
  );
}

export default Modal_HungraPanda;

