import { v4 as uuidv4 } from 'uuid';
import { NumberYMD, createDate, getMonth, minusDays, numberHMS, plusDays, stringDateTimeShort, stringFullDate, stringGetYear, stringGetYearMonth, stringY, stringYM, stringYMD2, stringYMDHMS, stringYMDHMS2, totalDateOfMonth } from './dateTime';
import { normalSort, reverseSort } from './sort';
import { resPayment } from '../configs';

export function checkSource(value){
    let res = value
    if(typeof value == 'object'){
        res = URL.createObjectURL(value) 
    }
    return res
  }

  export function Translator(others,type='logo'){
    let res = others?.find((item)=>{return(item.type == type)})
    if(res !== undefined){
      return res.value
    } else {
      return null
    }
  }

  export function LinkTranslator(arr,keyword,value,propertise='name'){
    let res = arr?.find((item)=>{return(item[keyword] == value)})
    if(res !== undefined){
      return res[propertise]
    } else {
      return null
    }
  }

  export function FilterTranslator(arr=[],keyword,value){
    let res = arr?.filter((item)=>{return(item[keyword] == value)})
    return res
  } // ได้เป็นก้อน array ออกไป

  export function findTranslator(arr=[],keyword,value){
    let res = arr?.find((item)=>{return(item[keyword] == value)})
    return res
  } // ได้เป็นก้อน object ออกไป


  export function manageArray(others,type,value,callBack){
    let newOthers = []
    let fIndex = others.some((item)=>{return(item.type == type)})
    if(fIndex){ // แปลว่ามี
      newOthers = others.map((item)=>{
        return item.type == type
          ?{...item,value}
          :item
      })
    } else {
      newOthers = [...others,{id:uuidv4(),type:type,value}]
    }
    callBack(newOthers)
  };

  export function manageArray_Id(arr,id,value,callBack){
    let newArr = arr.map((item)=>{
        return item.id == id
          ?{...item,value}
          :item
      })
   
    callBack(newArr)
  };

  export   function manageObject(obj,properties,value,callBack){
    let newObj = {...obj}
    newObj[properties] = value
    callBack(newObj)
  };

  export   const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  };

  // export function summary(arr,propertise){
  //   let sum = arr?.reduce((a, b) => a + ((b[propertise]) || 0), 0);
  //   return sum
  // }

  export function summary(arr,propertise){
    let sum = arr?.reduce((a, b) => Number(a) + (Number(b[propertise]) || 0), 0);
    return sum
  };

  export function checkCategory(totalCategory,specific,currentCategory){
    const { level, name, value, } = specific;
    let res = []
   
      if(level>1){
        let previousLevel = findInArray(totalCategory,'level',level-1)
        let havePreviousLevel = false
        let previousId = ''
        for(const item of currentCategory){
          let previousValue = findInArray(previousLevel.value,'id',item.id)
          if(previousValue && previousValue.id){
            havePreviousLevel = true
            previousId = previousValue.id
            break;
          }
        }
        if(havePreviousLevel){
          res =  value.filter(a=>a.aboveId == previousId)
        }
  
      } else {
        res = value
      }
    
    return res;
  };

  export function checkCategory2(allSelectedCategory,thisLevel){ // เพื่อหาดูว่ามี value กี่ตัว(ใน level mี่ต่ำกว่า) ที่มี aboveId ตรงกับ level ก่อนหน้า
    const { level, value } = thisLevel;
    const above = findInArray(allSelectedCategory,'level',level-1)
    let res = []
    if(level ===1){ // แปลว่าแสดงทั้งหมดใน value นั้นๆ
      res = value
    } else {
      if(above && above.id){ //ต้องเช็คว่ามี level ก่อนหน้า แสดงอยู่มั้ย
        res = filterInCompareArray(value,'aboveId',[...above.aboveId,above.id])
      }
    }
    return res;
  };

  export function checkAddCategory(smartCategory,thisLevel,productCategory,callBack){
    const { level, value, } = thisLevel;
    let res = []
   
      if(level>1){
        let previousLevel = findInArray(smartCategory,'level',level-1)
        let havePreviousLevel = false
        let previousId = ''
        let previousValue = {}
        for(const item of productCategory){
          previousValue = findInArray(previousLevel.value,'id',item.id)
          if(previousValue && previousValue.id){
            havePreviousLevel = true
            previousId = previousValue.id
            break;
          }
        }
        if(havePreviousLevel){
          for(const item of value){
            if(compareArrays(item.aboveId,[...previousValue.aboveId,previousId])){
              res.push(item)
            }
          }
          if(res.length >0){
            callBack()
          } else {
            alert('ยังไม่มีหมวดหมู่ย่อยนี้')
          }
        } else {
          alert('กรุณาเลือกหมวดหมู่ในลำดับข้างบนก่อน')
        }
      } else {
        res = value
        callBack()
      }
    
   
    return res;
  };

  export function findTotalPrice(option,channelId,price,qty){
    let allChosen = []
    for(const a of option){
        a.choice.forEach((item)=>{
            if(item.chose===true){
                allChosen.push(item)
            }
        })
    }
    let totalPrice = 0  // คือ totalprice ของเมนูย่อย
    allChosen.forEach((item)=>{
        totalPrice += Number(findInArray(item.price,'id',channelId)?.price|| 0)
    })
    return (Number(findInArray(price,'id',channelId)?.price) + totalPrice)*qty
  };

  export  function getUniqueArray(array){
    var uniqueArray = [];
    // Loop through array values
    for(let i=0; i < array.length; i++){
        if(uniqueArray.indexOf(array[i]) === -1) {
            uniqueArray.push(array[i]);
        }
    }
    return uniqueArray;
  }

  export function manageCutOff(cutOff,date){
    let day = new Date(date)
      day.setHours(cutOff.getHours());
      day.setMinutes(cutOff.getMinutes());
  return day
};

export function managePeriod(startDate,endDate,cutOff){
  let start = new Date()
  let end = new Date()
    let cutOfftime = manageCutOff(cutOff,new Date())
    
    if(new Date().getDay()===startDate.getDay()){ // เป็นวันเดียวกัน
        if(stringYMDHMS2(cutOfftime) > stringYMDHMS2(new Date())){
          start = manageCutOff(cutOff,minusDays(startDate,5)) 
        } else {
          start = manageCutOff(cutOff,startDate) 
        }
    } else {
      start = manageCutOff(cutOff,startDate) 
    }
    if(new Date().getDay()===endDate.getDay()){ // เป็นวันเดียวกัน
      if(stringYMDHMS2(cutOfftime) > stringYMDHMS2(new Date())){
        endDate = manageCutOff(cutOff,endDate) 
      } else {
        endDate = manageCutOff(cutOff,plusDays(endDate,1)) 
      }
  } else {
    endDate = manageCutOff(cutOff,plusDays(endDate,1)) 
  }

  return {start,end}
}

export function startCutoff(startDate,cutOff){
  let res = new Date()
  let cutOfftime = manageCutOff(cutOff,new Date())
  if(new Date().getDate()===startDate.getDate()){ // เป็นวันเดียวกัน
      if(stringYMDHMS(cutOfftime) > stringYMDHMS(new Date())){
        res = manageCutOff(cutOff,minusDays(startDate,1)) 
      } else {
        res = manageCutOff(cutOff,startDate) 
      }
  } else {
    res = manageCutOff(cutOff,startDate) 
  }
  return res
}

export function endCutoff(endDate,cutOff){
  let res = new Date()
  let cutOfftime = manageCutOff(cutOff,new Date())
  if(new Date().getDay()===endDate.getDay()){ // เป็นวันเดียวกัน
      if(stringYMDHMS(cutOfftime) > stringYMDHMS(new Date())){
        res = manageCutOff(cutOff,endDate) 
      } else {
        res = manageCutOff(cutOff,plusDays(endDate,1)) 
      }
  } else {
    res = manageCutOff(cutOff,plusDays(endDate,1)) 
  }
  return res
}

export function mapInArray(arr,propertise,key,value){
  return arr.map((item)=>{
    return item[propertise] === key
        ?value
        :item
  })
};

export function includesOutInArray(arr,propertise,value){
  let res = []
  for(const item of arr){
    if(!item[propertise].includes(value)){
      res.push(item)
    }
  }
  return res
};

export function daysDifference(endDate,startDate){
  const timeDifference = endDate - startDate;
  return timeDifference / (1000 * 60 * 60 * 24)
}

export function createLabel(hr){
  let hour = Math.floor(hr)
  let arr = []
  for (let i = 0; i <= 23; i++) {
    let newHour = hour +i
    if(newHour > 23){
      arr.push(String(newHour - 24))
    } else {
      arr.push(String(newHour))
    }
  }
  return arr
}

export function createHourLabel(hr){
  let hour = Math.floor(hr)
  let arr = []
  for (let i = 0; i <= 23; i++) {
    let newHour = hour +i
    if(newHour > 23){
      arr.push(String(newHour - 24))
    } else {
      arr.push(String(newHour))
    }
  }
  return arr
}

export function arrayToNewArray(arr,proterties){
  let res = []
  for(const item of arr){
    res = [...res,item[proterties]]
  }
  return res
};

export function haveCommonElement(arr1, arr2) { // เช็ค 2 array ที่มีสมาชิกเหมือนกันอย่างน้อย 1 ตัว
  return arr1.some(item1 => arr2.includes(item1));
} // return เป็น true/false

export function manageDashboard({ selectedBill, products , channel, smartTable, humanResource, smartCategory, selectedVoidedBill}){
  let newProduct = []
  let newCategory = []
  let newSale = []
  let saleByChannel = []
  let rawPayment = []

  const allChannelId = arrayToNewArray(channel,'id');  // เอาเฉพาะ id
  const allTableId = arrayToNewArray(smartTable,'id')
  const mainId = smartTable[0]?.mainId ||1;
  for(const item of selectedBill){

    // 1. แยกตามช่องทางชำระเงิน
    for(const paid of item.payment){
      const { id, amount, fee } = paid;
        let checkPayment = findInArray(rawPayment,'id',id)
        if(checkPayment && checkPayment.id){
          checkPayment.totalAmount += Number(amount)
          checkPayment.allTotalPrice += Number(amount)
          checkPayment.orderQty ++
          checkPayment.allQty ++
        } else {
          rawPayment.push({
            name:`${findInArray(resPayment,'id',id)?.name||'ไม่พบข้อมูล'}`,
            population:100,
            color:'',
            legendFontColor:'',
            legendFontSize:15,
            commission:fee,
            totalAmount:Number(amount),
            allTotalPrice:Number(amount),
            allQty:1,
            id,
            orderQty:1
          })
        }
    }

    // 2. แยกตามช่องทางขาย

      if(haveCommonElement(item.channel,allTableId)){  // หาแบบอยู่ที่โต๊ะก่อน
          let find = findInArray(saleByChannel,'id',mainId)
          if(find && find.id){
            find.allQty ++
            find.allTotalPrice += Number(item.net)
          } else {
            saleByChannel.push({
              id:mainId,
              allQty:1,
              allTotalPrice:Number(item.net),
              name:findInArray(channel,'id',mainId)?.name||'ไม่ระบุ'
            })
          }
      } else if(haveCommonElement(item.channel.slice(0,1),allChannelId)){ // เป็นช่องทางหลัก
        let thisChannelId = item.channel.slice(0,1)[0]
        let find = findInArray(saleByChannel,'id',thisChannelId)
          if(find && find.channelId){
            find.allQty ++
            find.allTotalPrice += Number(item.net)
          } else {
            saleByChannel.push({
              id:thisChannelId,
              allQty:1,
              allTotalPrice:Number(item.net),
              name:findInArray(channel,'id',thisChannelId)?.name||'ไม่ระบุ'
            })
          }
      } else {
        let find = findInArray(saleByChannel,'id',18) // เป็นช่องทางอื่นๆ
          if(find && find.id){
            find.allQty ++
            find.allTotalPrice += Number(item.net)
          } else {
            saleByChannel.push({
              id:18,
              allQty:1,
              allTotalPrice:Number(item.net),
              name:findInArray(channel,'id',0)?.name||'ไม่มีหมวดหมู่'
            })
          }
      }
      
    // 3. แยกตามสินค้า
    for(const product of item.product){
      const { id, qty, totalPrice, productStaffs } = product;
      let checkProduct = findInArray(newProduct,'id',id)  // สำหรับการเรียง product
      if(checkProduct && checkProduct.id){
        checkProduct.allQty = checkProduct.allQty + qty
        checkProduct.allTotalPrice = checkProduct.allTotalPrice + totalPrice
      } else {
        newProduct.push({...product,allQty:qty,allTotalPrice:totalPrice})
      }
      //4. แยกตามหมวดหมู๋
      const { category } = findInArray(products,'id',id)||{category:[]};
      if(category.length===0){
          let checkCategory = findInArray(newCategory,'id',0) // หมวดหมู่ชั่นที่ 1
          if(checkCategory && checkCategory.id){
            checkCategory.allQty += qty
            checkCategory.allTotalPrice += totalPrice
          } else {
            newCategory.push({id:0,allQty:qty,allTotalPrice:totalPrice,name:'ไม่มีหมวดหมู่'})
          }
      } else {
        let checkCategory = findInArray(newCategory,'id',category[0]) // หมวดหมู่ชั่นที่ 1
        if(checkCategory && checkCategory.id){
          checkCategory.allQty += qty
          checkCategory.allTotalPrice += totalPrice
        } else {
          newCategory.push({id:category[0],allQty:qty,allTotalPrice:totalPrice,name:findInArray(smartCategory[0]?.value,'id',category[0])?.name||'ไม่ทราบหมวดหมู่'})
        }
      }

      // 5. แยกตามพนักงาน
      let sale = findInArray(productStaffs,'process','ordered')
      if(sale && sale.id){ // เผื่อเคส ลูกค้าสั่งออเดอร์เอง
        let checkSale = findInArray(newSale,'id',sale.id)
        if(checkSale && checkSale.id){
            checkSale.allQty = checkSale.allQty + qty
            checkSale.allTotalPrice = checkSale.allTotalPrice + totalPrice
        } else {
          newSale.push({id:sale.id,allQty:qty,allTotalPrice:totalPrice,name:findInArray(humanResource,'id',sale.id)?.name||''})
        }
      }
    }
  }
  let newPayment = normalSort('totalAmount',rawPayment).map((item,index)=>{
    return {...item,color:'black',population:Math.floor(((item.totalAmount/summary(rawPayment,'totalAmount'))*100) * 100) / 100,legendFontColor:'black'}
  })
  return {
    saleByChannel,
    payment:newPayment,
    result:newProduct,
    product:newProduct,
    category:newCategory,
    saleMan:newSale,
    selectedBill,
    selectedVoidedBill
  }
}

export const searchFilterReturn = (search,propertise,data) => {
  if (search) {
    const newData = data.filter(function (item) {
      const itemData = item[propertise]
        ? item[propertise].toUpperCase()
        : "".toUpperCase();
      const textData = search.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    return newData
  } else {
    return data
  }
};

export const searchFilterFunction = (search,propertise,callBack,data) => {
  if (search) {
    const newData = data.filter(function (item) {
      const itemData = item[propertise]
        ? item[propertise].toUpperCase()
        : "".toUpperCase();
      const textData = search.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    callBack(newData)
  } else {
    callBack(data)
  }
};

export const searchFilterFunction2 = (search,propertise,propertise2,callBack,data) => {
  if (search) {
    const newData = data.filter(function (item) {
      const itemData = item[propertise]
        ? item[propertise].toUpperCase()
        : "".toUpperCase();
      const textData = search.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    const newData2 = data.filter(function (item) {
      const itemData = item[propertise2]
        ? item[propertise2].toUpperCase()
        : "".toUpperCase();
      const textData = search.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    callBack(keepOneObjectWithSameId([...newData,...newData2]))
  } else {
    callBack(data)
  }
};

export function keepOneObjectWithSameId(arr) {
  const idMap = new Map();
  arr.forEach((item) => {
    idMap.set(item.id, item);
  });
  return Array.from(idMap.values());
}




export function manageDashboard2({ selectedBill, selectedVoidedBill }){
  let newProduct = []
  let newCategory = []
  let newSale = []
  let saleByChannel = []
  let rawPayment = []

  for(const item of selectedBill){

    // 1. แยกตามช่องทางชำระเงิน
    for(const paid of item.payment){
      const { id, amount, fee } = paid;
        let checkPayment = findInArray(rawPayment,'id',id)
        if(checkPayment && checkPayment.id){
          checkPayment.totalAmount += Number(amount)
          checkPayment.allTotalPrice += Number(amount)
          checkPayment.orderQty ++
          checkPayment.allQty ++
        } else {
          rawPayment.push({
            name:`${findInArray(resPayment,'id',id)?.name||'ไม่พบข้อมูล'}`,
            population:100,
            color:'',
            legendFontColor:'',
            legendFontSize:15,
            commission:fee,
            totalAmount:Number(amount),
            allTotalPrice:Number(amount),
            allQty:1,
            id,
            orderQty:1
          })
        }
    }
  }
  let newPayment = normalSort('totalAmount',rawPayment).map((item,index)=>{
    return {...item,color:'black',population:Math.floor(((item.totalAmount/summary(rawPayment,'totalAmount'))*100) * 100) / 100,legendFontColor:'black'}
  })
  return {
    saleByChannel,
    payment:newPayment,
    result:newProduct,
    product:newProduct,
    category:newCategory,
    saleMan:newSale,
    selectedBill,
    selectedVoidedBill
  }
}

export function totalField(arr,field){
  let newArr = []
  arr.forEach((item)=>{
      newArr.push(item[field])
  })
  return newArr
};

export function diffArray(arr1,arr2){
  return arr1.filter((aElement) => !arr2.some((bElement) => aElement.id === bElement.id));
}

export function workingDay(cutOff){
  let Cut = new Date(cutOff)
  let Day = new Date()
  if(numberHMS(Cut)>numberHMS(Day)){
     Day = minusDays(Day,1)
  }
  return Day
}

export function leanProductOption(product){  // เพื่อลดขนาดของบิล
  let newProduct = []
    for(const item of product){
      delete item.category
       if(item.option.length>0){  // ถ้ามี option
            let activeChoice = []
            for(const data of item.option){
              activeChoice = [...activeChoice,...arrayToNewArray(filterInArray(data.choice,'chose',true),'choiceId')]
            }
            newProduct.push({...item,option:activeChoice})
       } else {
        newProduct.push(item)
       }
    }
  return newProduct
};

export function leanProduct(product){
  const { BOM, addOn, category, id, option, qty, totalPrice, name } = product;
  return {
      BOM, addOn, category, id, option, qty, totalPrice, name,
      tempId:uuidv4(),
      process:'ordered',
      productStaffs:[],
  }
};

export function arrayToNewArray2(arr,proterties){
  let res = []
  for(const item of arr){
    res = [...res,...item[proterties]]
  }
  return res
}

export function useToDate(date) {
  if (date instanceof Date) {
      return date;
  }

  try {
      return date.toDate();
  } catch (err) {
      return new Date();
  }
};

export function convertToInt(inputString) {
  try {
      let floatNumber = parseFloat(inputString);
      let integerNumber = parseInt(floatNumber, 10);
      
      if (!isNaN(integerNumber)) {
          return integerNumber;
      } else {
          throw new Error("Input is not a valid number");
      }
  } catch (error) {
      console.log(error.message);
      return null;
  }
}

export const useToLocale = (value) => value?.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})||'';

function generateRandomCode(length) {
  const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let code = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }

  return code;
}

export const  setDifference = (setA,setB) => setA.filter(objA => !setB.some(objB => objB.id === objA.id));


export function mergeArrays(arr1, arr2) {
  // Step 1: Create a new array to store the merged objects
  const newArr = setDifference(arr1,arr2)
  let mergedArray = [...newArr,...arr2];


  // Step 5: The new array now contains the merged objects without duplicates
  return mergedArray;
};

export function exportReportDay(display,smartReceipt) {
  if(smartReceipt?.status){
    let res =  display.map(({ date, totalDiscount, totalSubPrice, totalServiceChargeAmount, 
      totalFine, totalCreditCharge, beforeVat, totalVat, totalNet, totalPrice }) => {
        let obj = {}
        totalPrice.forEach(({ name, totalPrice:finalPrice }) => {
          obj[name] = finalPrice;
        });
        return {
            วันที่: date,
            ...obj,
            ส่วนลด: totalDiscount,
            ยอดขายสุทธิ: totalSubPrice,
            ค่าบริการ: totalServiceChargeAmount,
            ค่าปรับและอื่นๆ: totalFine+totalCreditCharge,
            ยอดก่อนภาษี: beforeVat,
            ภาษี: totalVat,
            ยอดทั้งสิ้น: totalNet,
        }
      }
    );
    let more = [...smartReceipt.value,{id:'x',name:'อื่นๆ'}].map(a=>({name:a.name,totalPrice:summary(display.flatMap(c=>c.totalPrice).filter(b=>b.id===a.id),'totalPrice')}))
    let obj = {}
    more.forEach(({ name, totalPrice:finalPrice }) => {
          obj[name] = finalPrice;
        });
    return [...res,{
      วันที่: 'รวมทั้งสิ้น',
      ...obj,
      ส่วนลด: summary(display,'totalDiscount'),
      ยอดขายสุทธิ: summary(display,'totalSubPrice'),
      ค่าบริการ: summary(display,'totalServiceChargeAmount'),
      ค่าปรับและอื่นๆ: summary(display,'totalFine')+summary(display,'creditChargeAmount'),
      ยอดก่อนภาษี: summary(display,'beforeVat'),
      ภาษี: summary(display,'totalVat'),
      ยอดทั้งสิ้น: summary(display,'totalNet'),
    }]
  } else {
    let res =  display.map(({ date, totalDiscount, totalSubPrice, totalServiceChargeAmount, 
      totalFine, totalCreditCharge, beforeVat, totalVat, totalNet, totalPrice }) => ({
        วันที่: date,
        ยอดขาย: totalPrice,
        ส่วนลด: totalDiscount,
        ยอดขายสุทธิ: totalSubPrice,
        ค่าบริการ: totalServiceChargeAmount,
        ค่าปรับและอื่นๆ: totalFine+totalCreditCharge,
        ยอดก่อนภาษี: beforeVat,
        ภาษี:totalVat,
        ยอดทั้งสิ้น: totalNet,
    }));
  
    return [...res,{
      วันที่: 'รวมทั้งสิ้น',
      ยอดขาย:summary(display,'totalPrice'),
      ส่วนลด: summary(display,'totalDiscount'),
      ยอดขายสุทธิ: summary(display,'totalSubPrice'),
      ค่าบริการ: summary(display,'totalServiceChargeAmount'),
      ค่าปรับและอื่นๆ: summary(display,'totalFine')+summary(display,'creditChargeAmount'),
      ยอดก่อนภาษี: summary(display,'beforeVat'),
      ภาษี: summary(display,'totalVat'),
      ยอดทั้งสิ้น: summary(display,'totalNet'),
    }]
  }
};

export function exportVat(member) {
  let res =  member.map(({ timestamp, receiptNumber, beforeVat, vatAmount, net, taxNumber, taxCustomer },index) => ({
    ที่: index+1,
    วันที่: stringDateTimeShort(timestamp),
    เลขที่บิล: receiptNumber,
    ชื่อลูกค้า: taxNumber?taxCustomer?.name:'ลค.',
    เลขผู้เสียภาษี: taxNumber?taxCustomer?.taxNumber:'-',
    ยอดก่อนภาษี: beforeVat,
    ภาษี: vatAmount,
    ยอดสุทธิ: net,
  }));

  return [...res,{
    ที่: 'รวม',
    วันที่: '',
    เลขที่บิล: '',
    ชื่อลูกค้า: '',
    เลขผู้เสียภาษี:'',
    ยอดก่อนภาษี: summary(member,'beforeVat'),
    ภาษี: summary(member,'vatAmount'),
    ยอดสุทธิ: summary(member,'net'),
  }]
};



export function manageBill(arr1,arr2){
  let bills = mergeArrays(arr1,arr2)
  let normalBills = []
  let voidedBills = []
  bills.forEach(obj => {
    if (obj.void === true) {
      voidedBills.push(obj);
    } else {
      normalBills.push(obj);
    }
  });
  return {bills, normalBills, voidedBills}
};

  export function someInArray(arr,propertise,value){
    return arr.some(item=>item[propertise] === value)
  };

  export function findInArray(arr,propertise,value){
    return arr.find(item=>item[propertise] === value)
  };
  
  export function filterInArray(arr,propertise,value){
    return arr.filter(item=>item[propertise] === value)
  };

  export function filterChoice(option){
    let choice = []
    for(const item of option){
        choice = [...choice,...filterInArray(item.choice,'chose',true)]
    }
    return choice
  };

  export function filterDeleteInArray(arr,propertise,value){
    return arr.filter(item=>item[propertise] !== value)
  };
  
  export function filterInCompareArray(arr,propertise,value){
      let res = []
      for(const item of arr){
        if(compareArrays(item[propertise],value)){
          res.push(item)
        }
      }
      return res
  }
  
  export function compareArrays(a, b){
    return  a.length === b.length && a.every((element, index) => element === b[index]);
  } 

  // export function manageCategory(selectedCategory,currentCategory,category){
  //   const newCategory = []
  //   for(const item of currentCategory){
  //     let checkValue = selectedCategory.some((a)=>a.id === item.id)
  //     if(!checkValue){
  //       newCategory.push(item)
  //     }
  //   }
  //   return [...newCategory,category].filter(a=>a.level <= category.level)
  //   // return [...newCategory,category]
  // };

  export function manageCategory(thisLevel_Value,allSelectedCategory,selectedCategory){
    const newCategory = []
    for(const item of allSelectedCategory){
      if(!someInArray(thisLevel_Value,'id',item.id)){ // เอาไว้ลบ ตัวอื่นใน level เดียวกันออก
        newCategory.push(item)
      }
    }
    return [...newCategory,selectedCategory].filter(a=>a.level <= selectedCategory.level)
  };



  export function periodFilter(arr=[],period='thisDay'){
    let fData = arr;
    if(period == 'thisDay'){
      fData = arr?.filter(item => item.date === NumberYMD(new Date()) )
    } else if(period =='thisMonth'){
      fData = arr?.filter(item => String(item.date).slice(0,6) == stringYM() )
    }
    return fData
  };

  export function allFilter(arr=[],properties,value){ 
    return arr.filter(item => item[properties] === value)
  };

  export function shopSaleSummary(arr=[],period='thisDay',shopId){
    let fData = arr;
    if(period == 'thisDay'){
      fData = arr?.filter(item => item.date === NumberYMD(new Date()) ).filter(item => item.shopId === shopId)
    } else if(period =='thisMonth'){
      fData = arr?.filter(item => String(item.date).slice(0,6) == stringYM() ).filter(item => item.shopId === shopId)
    }
    return summary(fData,'net')
  }


export function orderType(id){
  let type=''
  switch(id){
    case 1:
        type = 'takeaway';
        break;
    case 2:
        type = 'salepage';
        break;
    case 3:
        type = 'others';
    default:
      type = 'delivery'
  }
  return type
};

export function managePayment(sales,totalPayment=[]){
  let totalPayment2 = totalPayment
  for(const sale of sales){
    sale.payment.forEach((item)=>{
      let checkType = totalPayment.find(a=>a.type == item.type)
        if(checkType && checkType.type){
          checkType.amount += Number(item.amount)
        } else {
          totalPayment2.push({...item,amount:Number(item.amount)})
        }
    })
  }
  return totalPayment2
};

export function createTable(marketSale,properties){
  let arr = []
  let allPayment = []
  marketSale.forEach((item)=>{
    const { net, orders, sales, timestamp } = item
    let check = []
    if(['day','month','year'].includes(properties)){
      check = arr.find(a => getAnyTime(a.timestamp,properties) == getAnyTime(timestamp,properties))
    } else {
      check = arr.find(a => a.properties == item[properties])
    }
    if(check && check.timestamp){
      let payment = managePayment(sales,check.payment)
      check.net += net
      check.orders += orders
      check.payment = payment
      allPayment.push(...payment)
    } else {
      let payment = managePayment(sales)
      let newData = {
        properties:item[properties],
        net,
        orders,
        payment,
        timestamp,
      }
      if(['day','month','year'].includes(properties)){
        newData.properties = getReadableTime(timestamp,properties)
      } 
      arr.push(newData)
      allPayment.push(...payment)
    }
  })
  if(['day','month','year'].includes(properties)){
      reverseSort('timestamp',arr)
  } else {
    normalSort('net',arr)
  }
  const payment_Header = excludeDuplicate(allPayment,'type')
return {arr,payment_Header}
};

export function getAnyTime(timestamp,type){
  let date = new Date(timestamp)
  let time=''
  switch(type){
    case 'day':
        time = NumberYMD(date); // 20230628
        break;
    case 'month':
        time = Number(stringYM(date)); // 202306
        break;
    case 'year':
        time = Number(stringY(date)); // 2023
        break;
    default:
        time = NumberYMD(date); // 20230628
  }
  return time
};

export function getReadableTime(timestamp,type){
  let date = new Date(timestamp)
  let time=''
  switch(type){
    case 'day':
        time = stringFullDate(date); //12 มี.ค. 2566
        break;
    case 'month':
        time = stringGetYearMonth(date); // มี.ค. 2566
        break;
    case 'year':
        time = stringGetYear(date); // 2023
        break;
    default:
        time = stringFullDate(date); // 20230628
  }
  return time
};

export function excludeDuplicate(arr,type){
  return [...(new Set(arr.map(({ type }) => type)))];
}; // ใช้ลบสมาชิกที่ซ้ำกันใน array ออก

export function shopTableHeader(chartShop){
    let allPayment = []
    for(const shop of chartShop){
      shop.payment.forEach((item)=>{
        let checkType = allPayment.some(a=>a == item.type)
        if(!checkType){
          allPayment.push(item.type)
        }
      })
    }
    return allPayment
}

export function paymentToTable(type,arr){
  let value = 0
  let res = arr.find(item=>item.type == type)
  if(res && res.type){
    value = res.amount
  }
  // return value.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})
  return value
}

export function findDateForFetch(startDate,endDate,arr){
    let emptyDate = []
    let dateStard = startDate
    while(NumberYMD(dateStard) <= NumberYMD(endDate)){
      let checkDate = arr.some(item=> item.date == NumberYMD(dateStard))
      if(!checkDate){
        emptyDate.push(dateStard)
      }
      dateStard = plusDays(dateStard,1)
    }
    return emptyDate
};

export const getObjectSizeInMB = (obj) => {
  const jsonString = JSON.stringify(obj);
  const bytes = new TextEncoder().encode(jsonString).length;
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(10);
};
