import React, { useConp, useState, useEffect } from "react";
import {
    Button,
    Form,
    Row,
    Col,
    Container,
    Table,
    Modal,
    Card,
    Image,
    Collapse,
    InputGroup,
    OverlayTrigger,
    Tooltip
  } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './YourComponent.css'; // Create this CSS file for custom styles
import { InputArea } from "../components";
import { bath, colors, mainImage } from "../configs";
import { useUpdateCart } from "../hook";
import { useSelector, useDispatch } from "react-redux";
import { addCurrentProduct, addProduct, clearCurrentProduct, deleteProductInCart, updateCurrentCart, updateCurrentProduct, updateOption, updateProductInCart } from "../redux/orderSlice";
import { filterInArray, findInArray, findTotalPrice, findTranslator } from "../Utility/function";
import { BsCircle, BsCheckCircleFill } from "react-icons/bs";

function Modal_Test({ screen='CartScreen', productId, visible, onRequestClose, tempId }) {
    const dispatch = useDispatch();
    const { masterDataSource, productOrder, modal_Product, currentProduct, cart, tableData, products } = useSelector((state)=> state.order);
    const { shopId, shopName, shopLogo, tableId, tableName, smartCategory, smartOption, mainId } = tableData;
    const {editOrder}= useUpdateCart();
    const [product, setProduct] = useState([])
    const { option, name, detail, price, imageId, addOn, maxQty } = currentProduct;
    const [qty, setQty] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0);
    const [next, setNext] = useState(false);
   
  function onHide(){
    setProduct([])
    setQty(1)
    onRequestClose()
  }
  useEffect(()=>{
    
    if(visible){
        if(Boolean(tempId)){
            let findProduct = {...findInArray(cart,'tempId',tempId)}
            dispatch(addCurrentProduct(findProduct))
            setQty(findProduct.qty)
          } else {
            let findProduct = {...findInArray(masterDataSource,'id',productId)};
            if(findProduct && findProduct.id){
              const { option } = findProduct;
              if(smartOption && option.length >0){
                  let newOption = []
                  for(const item of option){
                      let data = {...findInArray(smartOption,'optionId',item)};
                      if(data && data.optionId){
                          newOption.push(data)
                      }
                  }
                  dispatch(addCurrentProduct({...findProduct,option:newOption,addOn:''}))
              } else{
                  dispatch(addCurrentProduct({...findProduct,addOn:''}))
              }
            }
          }
    }
    
      
  },[productId,tempId,visible])

  useEffect(()=>{
    let checkNewOption = option?.some((item)=>{return(item.minChose > filterInArray(item.choice,'chose',true).length)})
    setNext(!checkNewOption)
    if(option){
      setTotalPrice(findTotalPrice(option,mainId,price,qty))
    }
  },[option,qty])

  const CaseOption = ({minChose,maxChose}) => {
    let text = ''
    if(minChose ===0){
        text=`เลือกสูงสุด ${maxChose} ข้อ`
    } else {
        if(maxChose===1){ // แปลว่า minChose ก็เท่ากับ 1 เหมือนกัน
            text='กรุณาเลือก 1 ข้อ'
        } else {
            text=`เลือกได้ ${minChose} ถึง ${maxChose} ข้อ`
        }
    }
    return <p style={{paddingBottom:15,color:colors.absolute}} >{text}</p>
};


  return (
    <Modal show={visible} onHide={onHide} className="modal-slide-from-bottom">
      <Modal.Header closeButton style={{position:'sticky'}} >
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding:0}} >
        <img style={{width:'100%',height:'auto'}} src={imageId||mainImage.reslogo} />
        <div style={{padding:'1rem'}} >
            {/* {detail?.trim() != '' ? <div style={{borderBottom:`1px solid ${colors.lightGray}`,paddingTop:'1rem',paddingBottom:'1rem'}} >{detail}</div>:null} */}
            {detail?.trim() != '' ? <div style={{borderBottom:`1px solid ${colors.lightGray}`,paddingBottom:'1rem'}} >{detail}</div>:null}
                {option?.map((item,index)=>{
                    return(
                        // <div key={index}>
                        <div key={index} style={{borderBottom:`1px solid ${colors.lightGray}`,paddingTop:'1rem',paddingBottom:'1rem'}}>
                            <p style={{padding:0,margin:0}} ><b>{item.optionTopic}</b></p>
                            <CaseOption minChose={item.minChose} maxChose={item.maxChose} />
                            {item.choice.map((a,i)=>{
                                return(
                                    <div key={i} >
                                        {a.status =='available'
                                            ?a.chose=='block'
                                                ?<div  style={{display:'flex',paddingVertical:5,justifyContent:'space-between',alignItems:'center'}}>
                                                    <div  style={{display:'flex'}} >
                                                        <BsCircle style={{color:colors.lightGray, fontSize: '1.5rem'}}  >{a.choiceName}</BsCircle>
                                                        <p style={{paddingLeft:15,color:colors.absolute}} >{a.choiceName}</p>
                                                    </div>
                                                    <p style={{paddingLeft:15,color:colors.absolute}} >฿{findInArray(a.price,'id',mainId)?.price||0}</p>
                                                </div>
                                                // :<div onClick={()=>{choseAction(item.optionId,a.choiceId)}} key={i} style={{display:'flex',paddingVertical:5,justifyContent:'space-between',alignItems:'center',paddingRight:'1rem',paddingLeft:'1rem',cursor:'pointer'}}>
                                                :<div onClick={()=>{dispatch(updateOption({optionId:item.optionId,choiceId:a.choiceId}))}} key={i} style={{display:'flex',paddingVertical:5,justifyContent:'space-between',alignItems:'center',cursor:'pointer'}}>
                                                    <div  style={{display:'flex'}} >
                                                        {a.chose
                                                            ?<BsCheckCircleFill style={{color:colors.redPink, fontSize: '1.5rem'}} />
                                                            :<BsCircle style={{color:colors.lightGray, fontSize: '1.5rem'}}  />
                                                        }
                                                        <p style={{paddingLeft:15}} >{a.choiceName}</p>
                                                    </div>
                                                    <p style={{paddingLeft:15}} >฿{findInArray(a.price,'id',mainId)?.price|| 0}</p>
                                                </div>
                                            :a.status =='out'
                                                ?<div  style={{flexDirection:'row',paddingVertical:5,justifyContent:'space-between',alignItems:'center'}}>
                                                    <div  style={{flexDirection:'row'}} >
                                                        <BsCircle style={{color:colors.lightGray, fontSize: '1.5rem'}}  />
                                                        <p style={{paddingLeft:15,color:colors.absolute}} >{a.choiceName}</p>
                                                    </div>
                                                    <p style={{paddingLeft:15,color:colors.absolute}} >หมด</p>
                                                </div>
                                                :null
                                        }
                                        
                                    </div>
                                )
                            })}
                            <div style={{borderTopWidth:1,borderColor:colors.theme3,marginVertical:15}} />
                        </div>
                    )
                })}
        </div>
        <div style={{padding:'16px'}} >
            <Form.Control 
                as="textarea" 
                rows={3}
                placeholder='รายละเอียดเพิ่มเติม'
                onChange={(event)=>{dispatch(updateCurrentProduct({...currentProduct,addOn:event.target.value}))}}
                value={addOn}
            />

        </div>

      
      </Modal.Body>
      <Modal.Footer>
        <Row style={{display:'flex',justifyContent:'space-around',width:'100%'}} >
            <Col>
                <div style={{display:'flex',justifyContent:'center'}} >
                {qty>1
                    ?<i onClick={()=>{setQty(prev=>prev-1)}} style={{fontSize:'30px',cursor: 'pointer'}} class="bi bi-dash-circle-fill"></i>
                    :<i style={{fontSize:'30px',cursor: 'pointer'}} class="bi bi-dash-circle-fill"></i>
                }
                <div style={{paddingLeft:'1rem',paddingRight:'1rem',fontSize:'20px',paddingTop:'10px'}} >
                {qty}
                
                </div>
                    {maxQty
                        ?qty<maxQty
                            ?<i onClick={()=>{setQty(prev=>prev+1)}} style={{fontSize:'30px',cursor: 'pointer'}}  class="bi bi-plus-circle-fill"></i>
                            :<i  style={{fontSize:'30px',cursor: 'pointer'}}  class="bi bi-plus-circle-fill"></i>
                        :<i onClick={()=>{setQty(prev=>prev+1)}} style={{fontSize:'30px',cursor: 'pointer'}}  class="bi bi-plus-circle-fill"></i>
                    }
                </div>
                {maxQty
                    ?maxQty===qty
                        ?<h6 style={{color:colors.redOrange,textAlign:'center'}} >สั่งได้สูงสุดครั้งละ {maxQty} ถาด</h6>
                        :<h6 style={{color:colors.white}} >.</h6>
                    :<h6 style={{color:colors.white}} >.</h6>
                }
            </Col>

            
            {tempId
                ?qty>0
                    ?next
                        ?<Col onClick={()=>{setQty(1);dispatch(updateProductInCart({...currentProduct,qty,totalPrice}));onRequestClose()}} style={{padding:'16px',backgroundColor:colors.redPink,borderRadius:'20px',color:colors.white,width:'190px',cursor:'pointer'}}   >
                            อัปเดตตะกร้า ฿{totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}
                        </Col>
                        :<Col style={{padding:'16px',backgroundColor:colors.softGray,borderRadius:'20px',color:colors.white,width:'190px',cursor:'pointer'}}  >
                            อัปเดตตะกร้า ฿{totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}
                        </Col>
                    :<Col 
                        onClick={()=>{dispatch(deleteProductInCart(tempId));onRequestClose()}}
                        style={{padding:'16px',backgroundColor:colors.redPink,borderRadius:'20px',color:colors.white,width:'190px',cursor:'pointer'}} 
                      >
                          ลบออก {totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})} {bath}
                      </Col>
                :next
                    ?<Col 
                      onClick={()=>{setQty(1);dispatch(addProduct({product:{...currentProduct,qty,totalPrice}}));onRequestClose()}}  
                      style={{padding:'16px',backgroundColor:colors.redPink,borderRadius:'20px',color:colors.white,width:'190px',cursor:'pointer',}} 
                    >
                        ใส่ตะกร้า {totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})} {bath}
                    </Col>
                    :<Col 
                        style={{padding:'16px',backgroundColor:colors.softGray,borderRadius:'20px',color:colors.white,width:'190px',cursor:'pointer',}} 
                      >
                        ใส่ตะกร้า {totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})} {bath}
                    </Col>
            }
            {/* {screen ==='CartScreen'
                ?<div 
                    onClick={()=>{dispatch(addProduct({product:{...product,qty,totalPrice}}));onRequestClose()}}  
                    style={{padding:'16px',backgroundColor:colors.redPink,borderRadius:'20px',color:colors.white,width:'190px',cursor:'pointer'}} >
                    ใส่ตะกร้า {totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})} {bath}
                </div>
                :<div 
                    onClick={()=>{dispatch(updateCurrentCart({...currentProduct,totalPrice}))}}  
                    style={{padding:'16px',backgroundColor:colors.redPink,borderRadius:'20px',color:colors.white,width:'190px',cursor:'pointer'}} >
                    ใส่ตะกร้า {totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})} {bath}
                </div>
            } */}
            {/* {screen ==='CartScreen'
                ?<div 
                    onClick={()=>{editOrder('add',{...currentProduct,qty:qty,totalPrice})}}  
                    style={{padding:'16px',backgroundColor:colors.redPink,borderRadius:'20px',color:colors.white,width:'190px',cursor:'pointer'}} >
                    ใส่ตะกร้า {totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})} {bath}
                </div>
                :<div 
                    onClick={()=>{dispatch(updateCurrentCart({...currentProduct,totalPrice}))}}  
                    style={{padding:'16px',backgroundColor:colors.redPink,borderRadius:'20px',color:colors.white,width:'190px',cursor:'pointer'}} >
                    ใส่ตะกร้า {totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})} {bath}
                </div>
            } */}
            
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default Modal_Test;
