import {firebaseAuth} from '../db/firestore'
import createDataContext from './createDataContext';

const authReducer = (state,action) => {
    switch(action.type){
        case 'login':
            return{
                ...state,
                currentUser:action.payload,
                loading:false
            };
        case 'logout':
            return{...state};;
        default:
            return state;
    }
};



const checkAuth = (dispatch) => async () => {
    // alert('checAuth')
    // await firebaseAuth.signInWithEmailAndPassword('siripongsrisukha@gmail.com', '123456')

    const unsubscribe = await firebaseAuth.onAuthStateChanged(user => {
        // alert(user.uid)
        dispatch({type:'login',payload:user})
    })
    return unsubscribe    
}

const signup = (dispatch) => async (email, password) =>{
    return await firebaseAuth.createUserWithEmailAndPassword(email, password)
}

const login = (dispatch) => async (email, password) =>{
    let user = firebaseAuth.signInWithEmailAndPassword(email, password)
    dispatch({type:'login',payload:user});
    return user
}

const logout = (dispatch) => async () =>{
    return firebaseAuth.signOut()
}

const resetPassword = (dispatch) => async (email) =>{
    return firebaseAuth.sendPasswordResetEmail(email)
}

const updateEmail = (dispatch) => async (currentUser,email) =>{
    return currentUser.updateEmail(email)
}

const updatePassword = (dispatch) => async (currentUser, password) =>{
    return currentUser.updatePassword(password)
}



export const {Provider,Context} = createDataContext(
    authReducer,
    {checkAuth,signup,login,logout,resetPassword,updateEmail,updatePassword},
    {currentUser:null,loading:true,token:null}
);