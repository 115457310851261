import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db, minusDays } from "../db/firestore";
import initialDailySale from "../configs/initialDailySale";
import { v4 as uuidv4 } from 'uuid';
import { NumberYMD, createDate, totalDateOfMonth, plusDays, stringYMD, stringYMDHMS3 } from "../Utility/dateTime";
import {  createTable, managePayment, orderType, shopTableHeader, summary } from "../Utility/function";
// const initialState = []

const initialState = {
    dailySales:[],
    thisDaySales:[],
    thisMonthSales:[],
    loading_DailySale:false,
    marketSale:[], // ยอดขายเฉพาะตลาด มีแต่ละร้านเป็นหน่วยย่อย
    chart_Daily:[],
    chart_Weekly:[],
    chart_Monthly:[],
    chart_Yearly:[],
    table:[],
    table_Header:[],
    table_Mode:'',
    payment_Header:[],
    startDate:new Date(),
    endDate:new Date()
}

export const fetchDailySale = createAsyncThunk(
    'dailySale/fetchDailySale',
    async ({landlordId,startDate,endDate,marketId})=>{
        let data = []
        await db.collection('dailySale')
          .where('landlordId','==',landlordId)
          .where('date','>=',NumberYMD(startDate))
          .where('date','<',NumberYMD(endDate))
          .get().then((docs)=>{
            docs.forEach((doc)=>{data.push({id: doc.id,...doc.data()})})
        })
        return data;
    }
);

export const fetchThisMonthSale = createAsyncThunk(
  'dailySale/fetchThisMonthSale',
  async (landlordId)=>{
      const today = new Date()
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      let data = []
      await db.collection('dailySale')
        .where('landlordId','==',landlordId)
        .where('date','>=',NumberYMD(firstDayOfMonth))
        .where('date','<',NumberYMD(today))
        .get().then((docs)=>{
          docs.forEach((doc)=>{data.push({id: doc.id,...doc.data(),timestamp:doc.data().timestamp.toDate()})})
      })
      return data;
  }
);

// export const fetchDateSale = createAsyncThunk(
//   'dailySale/fetchDateSale',
//   async ({landlordId, arrayOfTime})=>{
//       const today = new Date()
//       let data = []
//       for(const time of arrayOfTime){
//         await db.collection('dailySale')
//         .where('landlordId','==',landlordId)
//         .where('date','==',NumberYMD(time))
//         .get().then((docs)=>{
//           docs.forEach((doc)=>{data.push({id: doc.id,...doc.data(),timestamp:doc.data().timestamp.toDate()})})
//       })
//       }
//       return data;
//   }
// );

export const fetchDateSale = createAsyncThunk(
  'dailySale/fetchDateSale',
  async ({ landlordId, arrayOfTime }) => {
    const timeQueries = arrayOfTime.map((time) =>
      db
        .collection('dailySale')
        .where('landlordId', '==', landlordId)
        .where('date', '==', NumberYMD(time))
        .get()
    );

    const querySnapshots = await Promise.all(timeQueries);
    const data = [];

    querySnapshots.forEach((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data.push({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp.toDate(),
        });
      });
    });
    console.log(data)
    return data;
  }
);

export const fetchSale_Table = createAsyncThunk(
  'dailySale/fetchSale_Table',
  async ({ marketId, arrayOfTime }) => {
    const timeQueries = arrayOfTime.map((time) =>
      db
        .collection('dailySale')
        .where('marketId', '==', marketId)
        .where('date', '==', NumberYMD(time))
        .get()
    );

    const querySnapshots = await Promise.all(timeQueries);
    const data = [];

    querySnapshots.forEach((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data.push({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp.toDate(),
        });
      });
    });
    return data;
  }
);





export const fetchThisDaySale = createAsyncThunk(
  'dailySale/fetchThisDaySale',
  async (landlordId)=>{
    let data = [];

    const snapshot = await db.collection('bill').where('landlordId', '==', landlordId)
    .where('billDate','==',stringYMDHMS3(new Date()))
    .get();
    
    snapshot.forEach((doc) => {
      const { landlordId, marketId, shopId, scShopId, payment, table, timestamp, net } = doc.data();
      const checkShop = data.find((item) => item.shopId === shopId);
      const type = orderType(table.id);
      if (checkShop && checkShop.shopId) {
        const checkType = checkShop.sales.find((item) => item.type === type);
        checkShop.net += net
        checkShop.orders += 1
        if (checkType && checkType.type) {
          checkType.order += 1;
          let totalPayment = checkType.payment;
  
          payment.forEach((item) => {
            let checkPayment = totalPayment.find((a) => a.type === item.type);
            if (checkPayment && checkPayment.type) {
              checkPayment.amount = Number(checkPayment.amount) + Number(item.amount);
            } else {
              totalPayment.push({
                id: item.id,
                type: item.type,
                amount: Number(item.amount),
                name: item.name
              });
            }
          });
  
          checkType.payment = totalPayment;
        } else {
          const newPayment = payment.map((item) => ({
            id: item.id,
            type: item.type,
            amount: Number(item.amount),
            name: item.name
          }));
  
          checkShop.sales.push({ id: uuidv4(), order: 1, payment: newPayment, type });
        }
      } else {
        const newPayment = payment.map((item) => ({
          id: item.id,
          type: item.type,
          amount: Number(item.amount),
          name: item.name
        }));
  
        let newData = {
          ...initialDailySale,
          landlordId,
          marketId,
          shopId,
          scShopId,
          timestamp:timestamp.toDate(),
          net,
          // type,
          sales: [{ id: uuidv4(), order: 1, payment: newPayment, type }],
          date: NumberYMD(timestamp.toDate()),
          orders:1
        };
  
        data.push(newData);
      }
    });
  
    return data;
  }
);


// fetch books
export const fetchBooks = createAsyncThunk(
    'books/fetchBooks',
    async () => {
      let data = []
      await db.collection('Books').get().then((docs)=>{
        docs.forEach((doc)=>{data.push({id: doc.id,book: doc.data()})})
      })
      return data;
    }
  );

export const dailySaleSlice = createSlice({
    name:'dailySale',
    initialState,
    reducers: {
        createMarketSale: (state,action) => {
          let dailysale = [...state.thisDaySales,...state.thisMonthSales].filter(item => {return( item.marketId == action.payload)})
          let chart_Data = []
          for(let i=1;i<=totalDateOfMonth();i++){
              let data = dailysale.filter(item => item.date == NumberYMD(createDate(i)))
              chart_Data.push({
                name:String(i),
                net:summary(data,'net'),
                store:data.length,
              })
          }
          state.marketSale = dailysale
          state.chart_Daily = chart_Data
        },

          createAllTable: (state,action) => {
            const { type, startDate, endDate} = action.payload
            let raw = state.marketSale.filter((item)=>{return(item.date >= NumberYMD(startDate) && item.date <= NumberYMD(endDate))})
            let {arr,payment_Header} = createTable(raw,type);
            state.table = arr
            state.table_Header = ['No.',type,'TotalOrder','Total Revenue',...payment_Header]
            state.payment_Header = payment_Header
            state.table_Mode = type
            state.startDate = startDate
            state.endDate = endDate
          },
        },
        
        
        
    extraReducers: (builder) => {
        builder
          .addCase(fetchDailySale.pending, (state, action) => {
            state.loading_DailySale = true
          })
          .addCase(fetchDailySale.fulfilled, (state, action) => {
            state.dailySales = action.payload;
            state.loading_DailySale = false
          })
          .addCase(fetchDailySale.rejected, (state, action) => {
            state.loading_DailySale = false
            state.error = action.error.message
          })
          .addCase(fetchThisDaySale.pending, (state, action) => {
            state.loading_DailySale = true
          })
          .addCase(fetchThisDaySale.fulfilled, (state, action) => {
            state.thisDaySales = action.payload;
            state.loading_DailySale = false
          })
          .addCase(fetchThisDaySale.rejected, (state, action) => {
            state.loading_DailySale = false
            state.error = action.error.message
          })
          .addCase(fetchThisMonthSale.pending, (state, action) => {
            state.loading_DailySale = true
          })
          .addCase(fetchThisMonthSale.fulfilled, (state, action) => {
            state.thisMonthSales = action.payload;
            state.loading_DailySale = false
          })
          .addCase(fetchThisMonthSale.rejected, (state, action) => {
            state.loading_DailySale = false
            state.error = action.error.message
          })
          .addCase(fetchDateSale.pending, (state, action) => {
            state.loading_DailySale = true
          })
          .addCase(fetchDateSale.fulfilled, (state, action) => {
            let allSale = state.dailySales
            state.dailySales = [...allSale,...action.payload]
            state.thisMonthSales = action.payload;
            state.loading_DailySale = false
          })
          .addCase(fetchDateSale.rejected, (state, action) => {
            state.loading_DailySale = false
            state.error = action.error.message
          })
          .addCase(fetchSale_Table.pending, (state, action) => {
            state.loading_DailySale = true
          })
          .addCase(fetchSale_Table.fulfilled, (state, action) => {
            state.marketSale.push(...action.payload)
            state.loading_DailySale = false
          })
          .addCase(fetchSale_Table.rejected, (state, action) => {
            state.loading_DailySale = false
            state.error = action.error.message
          })
      }
})

export const  { createMarketSale, createAllTable } = dailySaleSlice.actions;
export default dailySaleSlice.reducer;