export default {
        heading:'Shopchamp connect',
        content:'เลือกดูเนื้อหาที่น่าสนใจ',
        email:'siripongsrisukha@gmail.com',
        campaignName:'shopchamp_Onesignal',
        big_picture:'',
        // big_picture:'https://entertain.teenee.com/thaistar/img5/927933.jpg',
        // url:'',
        // data:'',
    }

  

