import React, { useContext } from "react";
import { Container, Row, Col, Button, Modal, Card, Image } from "react-bootstrap";
import { colors, contact, mainImage } from "../configs";



function Modal_Contact({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
  onClick
}) {


  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
    //   size={size}
    >
      <Modal.Header closeButton>
        <b>Choose some contact type</b>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'transparent'}} >
        <Row>
            {contact.map((item,index)=>{
                return(
                    <Col key={index} sm='4' style={{display:'flex',justifyContent:'center'}} >
                        <Button variant="light" onClick={()=>{onClick(item)}} style={{width:'100%'}}  >
                          <img src={item.icon} style={{width:30,height:30,borderRadius:'100%'}} />
                          {/* &emsp;{item.type}</Button> */}
                          <p>{item.type}</p>
                          </Button>
                    </Col>
                )
            })}
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default Modal_Contact;
