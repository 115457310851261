import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from "../db/firestore";
import firebase from 'firebase/app';


const initialState = {
  renters:[],
  renters_Pending:[],
  renters_Normal:[],
  loading_Renter:false,
  error: "",
  modal_Renter:false,
  renter:{  // ร้านเดี่ยว
    shop:{},
    products:[],
    currentRenter:{}
  },
}

// change Pending To Normal (Status)
export const changePendingToNormal = createAsyncThunk(
    'renter/changePendingToNormal',
    async (pending)=>{
      // alert(JSON.stringify(pending))
        await db.collection('renter').doc(pending.id).update({
          status:"normal",
          entranceManualStatus:true,
          timestamp:firebase.firestore.FieldValue.serverTimestamp()
        })
        return {...pending,status:'normal'};
    }
);

// rejectRenter 
export const rejectRenter = createAsyncThunk(
  'renter/rejectRenter',
  async (pending)=>{
      await db.collection('renter').doc(pending.id).delete()
      return pending.id
  }
);

// fetch renter
export const fetchRenter = createAsyncThunk(
    'renter/fetchRenter',
    async (landlordId) => {
      let renters_Pending = []
      let renters_Normal = []
      await db.collection('renter').where('landlordId','==',landlordId).get().then((docs)=>{
        docs.forEach((doc)=>{
          if(doc.data().status =='pending'){
            renters_Pending.push({id: doc.id,...doc.data(),createDate:doc.data().createDate.toDate(),timestamp:doc.data().timestamp.toDate()})
          } else if(doc.data().status =='normal'){
            renters_Normal.push({id: doc.id,...doc.data(),createDate:doc.data().createDate.toDate(),timestamp:doc.data().timestamp.toDate()})
          } else {}
        }
      )
      })
      return {renters_Pending, renters_Normal};
    }
);

export const fetchShop = createAsyncThunk(
  'renter/fetchShop',
  async ({shopId,currentRenter}) => {
    let shop = {}
    let products = []
    await db.collection('shop').doc(shopId).get().then((doc)=>{
      shop = doc.data()
    })
    await db.collection('product').where('shopId','==',shopId).get().then((docs)=>{
      docs.forEach((doc)=>{products.push({id: doc.id,...doc.data()})})
    })
    return {shop,products,currentRenter};
  }
);

export const renterSlice = createSlice({
    name:'renter',
    initialState,
    reducers: {
      setModal_Renter: (state,action) => {
        state.modal_Renter = action.payload
      },
    },
    extraReducers: (builder) => {
        builder
        //fetchRenter
          .addCase(fetchRenter.pending, (state, action) => {
            state.loading_Renter = true
          })
          .addCase(fetchRenter.fulfilled, (state, action) => {
            const { renters_Normal, renters_Pending} = action.payload
            state.renters = [...renters_Normal,...renters_Pending]
            state.renters_Normal = renters_Normal
            state.renters_Pending = renters_Pending
            state.loading_Renter = false
          })
          .addCase(fetchRenter.rejected, (state, action) => {
            state.loading_Renter = false
            state.error = action.error.message
          })
        // fetchShop
          .addCase(fetchShop.pending, (state, action) => {
            state.loading_Renter = true
          })
          .addCase(fetchShop.fulfilled, (state, action) => {
            state.loading_Renter = false
            state.renter = action.payload
            state.modal_Renter = true
          })
          .addCase(fetchShop.rejected, (state, action) => {
            state.loading_Renter = false
            state.error = action.error.message
          })
        // change pending to normal
        .addCase(changePendingToNormal.pending, (state, action) => {
          state.loading_Renter = true
        })
        .addCase(changePendingToNormal.fulfilled, (state, action) => {
          let pendingRenter = state.renters_Pending
          state.loading_Renter = false
          state.modal_Renter = false
          state.renters_Pending = pendingRenter.filter((item)=>{return(item.id !== action.payload.id)})
          state.renters_Normal.push(action.payload)
        })
        .addCase(changePendingToNormal.rejected, (state, action) => {
          state.loading_Renter = false
          state.error = action.error.message
        })
        .addCase(rejectRenter.pending, (state, action) => {
          state.loading_Renter = true
        })
        .addCase(rejectRenter.fulfilled, (state, action) => {
          let pendingRenter = state.renters_Pending
          state.loading_Renter = false
          state.modal_Renter = false
          state.renters_Pending = pendingRenter.filter((item)=>{return(item.id !== action.payload)})
        })
        .addCase(rejectRenter.rejected, (state, action) => {
          state.loading_Renter = false
          state.error = action.error.message
        })
      }
})

export const  { setModal_Renter } = renterSlice.actions;
export default renterSlice.reducer;