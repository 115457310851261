import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Modal, Card, Image, Table } from "react-bootstrap";
import { colors, contact, mainImage } from "../configs";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useSelector, useDispatch } from "react-redux";
import { FilterTranslator, Translator, findTranslator, paymentToTable } from '../Utility/function';
import { stringDate, stringDateTime3 } from '../Utility/dateTime';
import { createAllTable } from '../redux/dailySaleSlice';
import Modal_Loading from './Modal_Loading';


function Modal_Report({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='xl',
  onClick
}) {

  const today = new Date();
  const dispatch = useDispatch();
    const { table_Header, payment_Header, table, table_Mode, startDate, endDate } = useSelector((state)=> state.dailySale)
    const { currentMarket } = useSelector((state)=> state.market)
    const { renters_Normal } = useSelector((state)=> state.renter)
    const tableOptions = ['shopId','day','month','year']

  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      // size={size}
      fullscreen='xxl-down'  
    >
      <Modal.Header style={{paddingTop:50,position:'relative'}} >
            
            <div style={{display:'flex'}} >
              <h3>{stringDateTime3(new Date(startDate))}</h3>
              <h3 style={{paddingLeft:'1rem',paddingRight:'1rem'}} >-</h3>
              <h3>{stringDateTime3(new Date(endDate))}</h3>
            </div>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-secondary"
                table="table-to-xls"
                // filename="ยอดขายทั้งตลาด"
                filename={`ยอดขาย ${Translator(currentMarket.name,'TH')} ${stringDate(new Date(startDate))} - ${stringDate(new Date(endDate))}`}
                sheet="tablexls"
                buttonText='Export'
            />
            <div onClick={onHide}  style={{position:'absolute',top:0,right:15,borderRadius:100,fontSize:36,borderWidth:0,cursor: 'pointer',color:colors.darkGray}} >
                x
            </div>
            
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'transparent',overflow:'auto',maxHeight:'500px'}} >
        <div style={{display:'flex'}} >
            {tableOptions?.map((item,index)=>{
              return(
                <div onClick={()=>{dispatch(createAllTable({type:item,startDate,endDate}))}} key={index} style={{borderRadius:100,width:100,margin:15,padding:10,backgroundColor:item==table_Mode?colors.darkPurple:colors.lightGray,cursor: 'pointer',color:item==table_Mode?colors.white:'black',display:'flex',justifyContent:'center'}} >{item}</div>
              )})}
          </div>
        <Table   responsive id="table-to-xls" >
          <thead  >
            <tr>
              {table_Header?.map((item,index)=>{
                return(
                  <th key={index} >{item}</th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {table?.map((item,index)=>{
              return(
                <tr key={index} >
                  <td>{index+1}</td>
                  {table_Mode ==='shopId'
                      ?<td>{findTranslator(renters_Normal,'shopId',item.properties).shopName || item.properties}</td>
                      :<td>{item.properties}</td>
                  }
                  <td>{item.orders}</td>
                  <td>{item.net}</td>
                  {payment_Header?.map((a,i)=>{
                    return(
                      <td key={i} >{paymentToTable(a,item.payment)}</td>
                    )
                  })}
                </tr>
              )
            })}
            {/* {table_Shop.map((item,index)=>{
              return(
                <tr key={index} >
                  <td>{index+1}</td>
                  <td>{item.shopId}</td>
                  <td>{item.orders}</td>
                  <td>{item.net}</td>
                  {payment_Header.map((a,i)=>{
                    return(
                      <td key={i} >{paymentToTable(a,item.payment)}</td>
                    )
                  })}
                </tr>
              )
            })} */}
              {/* {Array.from({ length: 500 }).map((_, index) => (
                <tr >
                  <td>{index+1}</td>
                  <td>
                    สมพงษ์
                  </td>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <td  key={index}>Table cell {index}</td>
                  ))}
                </tr>
              ))} */}
          </tbody>
        </Table>
        
      </Modal.Body>
    </Modal>
  );
}

export default Modal_Report;
