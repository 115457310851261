import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { colors, mainImage } from "../configs";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate , Outlet, NavLink } from 'react-router-dom';
import { InputEmail, InputPassword, InputText } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { addProfileToFirebase, setNewProfile } from "../redux/profileSlice";
import DatePicker from "react-datepicker";
import { shopchampRestaurantAPI } from "../Utility/api";
import { signup } from "../redux/authSlice";
import { Modal_Loading } from "../modal";


function RegisterScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { newProfile, loading_Profile, currentProfile } = useSelector((state)=> state.profile)

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [date, setDate] = useState(new Date()); 

    async function checkProfile(){
        if(newProfile.name == ''){
          alert('กรุณาใส่ชือ name')
        } else if(newProfile.tel == ''){
          alert('กรุณาใส่ tel')
        } else if(newProfile.email==''){
            alert('กรุณาใส่ email')
        } else if(newProfile.gender==''){
            alert('กรุณาใส่ gender')
        } else if(newProfile.birthday==''){
            alert('กรุณาใส่ birthday')
        } else if(password==''){
            alert('กรุณาใส่ password')
        } else if(confirmPassword==''){
            alert('กรุณาใส่ confirmPassword')
        } else {alert('กรุณาใส่ข้อมูลให้ครบถ้วน')}
    }

      const checkRegisterUser = async() => {
        if(password == confirmPassword){
            setLoading(true)
            await shopchampRestaurantAPI.get('/users/checkEmailRegister/'+newProfile?.email.trim()).then(objRes=>{
            const emailUserUid = objRes?.data?.uid;
            // alert('emailUserUid:'+emailUserUid);
            // console.log('emailUserUid:'+emailUserUid);
            
            // if(   // App been registered
                
            //     ![undefined,-1].includes(objAppReg?.findIndex( ({appName}) => appName === 'restaurant') )  // undefined = not have customClaims,appReg object, -1 = have the obj but not found key value
            // ){
            if(emailUserUid){
                setLoading(false)
                setTimeout(()=>{alert('ตรวจพบอีเมลซ้ำในระบบ กรุณาใช้อีเมลอื่น')},300)

            }else{ // Register other app, but not registed shopchamp-restaurant
                
                dispatch(signup({email:newProfile?.email.trim(),password})).then((res)=>{ console.log(res.payload)
                    if(!!res.payload?.user?.uid){
                        dispatch(addProfileToFirebase({obj:{...newProfile,timestamp:new Date()},uid:res.payload?.user?.uid})).then(()=>{navigate("/landlord");setLoading(false)})
                    } else {
                        setLoading(false)
                    }
                })
            }  
        
            }).catch(err=>{ // Not found user
                dispatch(signup({email:newProfile?.email.trim(),password})).then((res)=>{ console.log(res.payload)
                    if(!!res.payload?.user?.uid){
                        dispatch(addProfileToFirebase({obj:{...newProfile,timestamp:new Date()},uid:res.payload?.user?.uid})).then(()=>{navigate("/landlord");setLoading(false)})
                    } else {
                        setLoading(false)
                    }
                })
            });
        } else {
            alert('กรุณาระบุรหัสผ่านให้ตรงกัน')
        }
        
    }
   

  return (
    <div  style={{height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:colors.purplePeel,overflow:'auto',paddingTop:'9rem'}} >
        <Modal_Loading show={loading || loading_Profile} />
        <div style={{backgroundColor:'white',borderRadius:50,padding:20,paddingTop:5,width:'40%',minWidth:'400px',marginBottom:50}} >
                <img style={{width:'200px'}} src={mainImage.logo} />
                <h5 style={{color:colors.purpleRed}} ><b>Register</b></h5>
                <InputText
                    name='Name'
                    placeholder="Put your name here..."
                    onChange={(event)=>{dispatch(setNewProfile({...newProfile,name:event.target.value}))}}
                    value={newProfile.name}
                />
                <InputText
                    name='Tel'
                    placeholder="Put your phoneNumber here..."
                    onChange={(event)=>{dispatch(setNewProfile({...newProfile,tel:event.target.value}))}}
                    value={newProfile.tel}
                />
                <InputEmail
                    name='Email'
                    placeholder="Put your email here..."
                    onChange={(event)=>{dispatch(setNewProfile({...newProfile,email:event.target.value}))}}
                    value={newProfile.email}
                />
                <InputPassword
                    name='Password'
                    placeholder='password'
                    onChange={(event)=>{setPassword(event.target.value)}}
                    value={password}
                />
                <InputPassword
                    name='ConfrimPassword'
                    placeholder='confrimPassword'
                    onChange={(event)=>{setConfirmPassword(event.target.value)}}
                    value={confirmPassword}
                />
                 
                <Row style={{marginTop:10}} >
                    <Col sm='3' >Gender</Col>
                    <Col sm='9' >
                    <div onChange={(event)=>{dispatch(setNewProfile({...newProfile,gender:event.target.value}))}}>
                        <Form.Check // prettier-ignore
                            type="radio"
                            value='male'
                            label="ชาย"
                            name='gender'
                        />
                        <Form.Check // prettier-ignore
                            type="radio"
                            value='female'
                            label="หญิง"
                            name='gender'
                        />
                        <Form.Check // prettier-ignore
                            type="radio"
                            value='lgbtq+'
                            label="เพศทางเลือก"
                            name='gender'
                        />
                        <Form.Check // prettier-ignore
                            type="radio"
                            value='none'
                            label="ไม่ระบุ"
                            name='gender'
                        />
                    </div>
                    </Col>
                </Row>
                <Row style={{marginTop:10,marginBottom:10}} >
                    <Col md='3' >
                    Birthday
                    </Col>
                    <Col md='9' >
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={new Date(date)}
                            onChange={(date) => {dispatch(setNewProfile({...newProfile,birthday:date}));setDate(date)}}
                        />
                    </Col>
                </Row>
                <div style={{display:'flex',justifyContent:'center'}} >
                    {newProfile.name=='' || newProfile.tel=='' || newProfile.email=='' || newProfile.gender=='' || newProfile.birthday=='' || password =='' || confirmPassword ==''
                        ?<Button onClick={checkProfile} style={{width:'50%'}} variant="secondary" >Sign in</Button>
                        :<Button onClick={checkRegisterUser} style={{width:'50%'}} variant="success" >Sign in</Button>
                    }
                </div>
        </div>
    </div>
  );
}

export default RegisterScreen;
