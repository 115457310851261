import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { colors, mainImage } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { arrayToNewArray2, endCutoff,  filterInArray,  findInArray,  goToTop,  startCutoff } from "../Utility/function";
import { NumberYMD, minusDays, plusDays, stringYMDHMS, stringYMDHMS3 } from "../Utility/dateTime";
import TablePagination from '@mui/material/TablePagination';
import { Modal_Loading  } from "../modal";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import { fetchBill, updateBills } from "../redux/resBillSlice";
import { CSVLink } from "react-csv";

function ResRawMatScreen() {
  const dispatch = useDispatch();
  const { shop } = useSelector((state)=> state.resShop)
  const { boms, units } = useSelector((state)=> state.resBom)
  const { cutOff, smartOption } = shop;
  const { products } = useSelector((state)=> state.resProduct)
  const { bills, billDates, modal_Bill, normalBill } = useSelector((state)=> state.resBill)
  const [currentDisplay, setCurrentDisplay] = useState([]) // จำนวนที่แสดงในหนึ่งหน้า
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const today = new Date();
  const [startDate, setStartDate] = useState(today); //some many of day array to initial graph
  const [endDate, setEndDate] = useState(today); //  today
  const [display, setDisplay] = useState([]);

  function checkChoice(choice,option){  // สำหรับ reverse choice
    let res = choice.map((item)=>{
      return option.includes(item.choiceId)
          ?{...item,chose:true}
          :item
    })
    return res
}

  function manageOption(product){  // แปลง option แบบย่อ เป็น option แบบเต็ม
      const { id, option} = product;
      let finalOption = []
      let findProduct = findInArray(products,'id',id);
      if(findProduct && findProduct.id){
        if(smartOption && findProduct.option.length >0){
          let newOption = []
          for(const item of findProduct.option){
              let data = {...findInArray(smartOption,'optionId',item)};
              if(data && data.optionId){
                  newOption.push(data)
              }
          }
          finalOption = newOption.map((item)=>{return({...item,choice:checkChoice(item.choice,option)})})
        }
      }
    return {...product,option:finalOption}
  }


  function bomManager(data){   // function สำหรับ ตัดสต๊อก  
    let rawMat = [] // {id:'',qty:''}
    for(const item of data){
        let product = findInArray(products,'id',item.id); // 1. check ว่ายังมีสินค้าอยู่จริง
        if(product && product.id){
            
            let activeChoiceBom = []
            let fullProduct = manageOption(item)
            if(fullProduct.option.length>0){  // ถ้ามี option
                for(const data of fullProduct.option){
                
                activeChoiceBom = [...activeChoiceBom,...arrayToNewArray2(filterInArray(data.choice,'chose',true),'BOM')]
                }
            }
            const { BOM } = product; // 2. check ว่ามี BOM
            let allBom = [...activeChoiceBom,...BOM]
            if(allBom.length>0){ // {id:'',qty:''}
                
                for(const bom of allBom){
                    try{
                        const { id, qty } = bom;
                        let existBOM = findInArray(units,'id',id); // 3. check ว่ายังมี bom อยู่จริง
                        if(existBOM && existBOM.id){
                            const { amount, rawMatId, rawMatName, name } = existBOM;
                            let findRawMat = findInArray(rawMat,'id',rawMatId)
                            let totalQty = Number(amount)*Number(qty)*Number(item.qty+(item?.diffQty||0))
                            if(findRawMat && findRawMat.id){ // มีอยู่แล้วใน rawmat
                                findRawMat.qty += totalQty
                            } else { // ยังไม่มีใน rawMat
                                rawMat.push({id:rawMatId,qty:totalQty,name:rawMatName,unit:name})
                            }
                        }
                    }catch(err){
                        console.log('bomManager_err_allBom');
                    }

                }
            }
        }
    }
    return rawMat
       
}


useEffect(()=>{  
  let rewSearch = []
  let newSearch = []
  let start = minusDays(startDate,1)

  do {
    rewSearch.push(stringYMDHMS3(start))
    start = plusDays(start,1)
  }
  while (NumberYMD(start) < NumberYMD(plusDays(endDate,2)));

  for(const item of rewSearch){
    if(!billDates.includes(item)){
      newSearch.push(item)
    }
  }
  if(newSearch.length >0){
    dispatch(fetchBill({shopId:shop.id,billDate:newSearch,cutOff,startDate,endDate}))
  } else {
      dispatch(updateBills({startDate,endDate,cutOff}))
  }
  
},[startDate,endDate])


 useEffect(()=>{
  setDisplay(bomManager(arrayToNewArray2(bills.filter((item)=>{return(stringYMDHMS(item.timestamp) > stringYMDHMS(startCutoff(startDate,new Date(cutOff))) && stringYMDHMS(item.timestamp) <= stringYMDHMS(endCutoff(endDate,new Date(cutOff))))}),'product')))

},[startDate, endDate, normalBill])

  const handleChangePage = (event, newPage) => {
      setPage(newPage); // start form 0
      goToTop()
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      goToTop()
  };


  useEffect(()=>{
    let fData = display.map((item,index)=>{return({...item,no:index})}).filter((item,index)=>{return(index >=(page*rowsPerPage) && index <= ((page+1)*rowsPerPage)-1)})
      setCurrentDisplay(fData)
  },[page,rowsPerPage,display])

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{borderRadius:20}} onClick={onClick} ref={ref}>
      {value}
    </div>
  ));


  return (
    <div  >
      <Modal_Loading show={modal_Bill} />
      <Row >
          <Col lg='4'  >
              <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
                <img style={{width:30}} src={mainImage.calender} />
                <div style={{paddingLeft:10,paddingRight:10}} >  Start: </div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<ExampleCustomInput />}
                />
              </div>
          </Col>
          <Col lg='4'  >
            <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
              <img style={{width:30}} src={mainImage.calender} />
              <div style={{paddingLeft:10,paddingRight:10}} >  End: </div>
              <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  customInput={<ExampleCustomInput />}
              />
            </div>
          </Col>
          <Col lg='4' style={{padding:10}} >
            <CSVLink 
              style={{backgroundColor:'#292929',textDecoration:'none',color:colors.white,padding:10,borderRadius:20,paddingLeft:30,paddingRight:30}}
              filename={`วัตถุดิบที่ใช้ไป${stringYMDHMS3(startDate)}-${stringYMDHMS3(endDate)}`}
              data={display?.map((item)=>{return({
                ชื่อ:item.name,
                จำนวน:item.qty,
                หน่วย:item.unit,
              })})}
            >
                Export
            </CSVLink>
          </Col>
          
      </Row>
        <Row>
        <Table striped bordered hover responsive  variant="light"   >
            <thead  >
                <tr>
                    <th style={{ width: '10%' }}>No.</th>
                    <th style={{ width: '50%' }}>ชื่อ</th>
                    <th style={{ width: '20%' }}>จำนวน</th>
                    <th style={{ width: '20%' }}>หน่วย</th>
                </tr>
            </thead>
            <tbody  >
            {currentDisplay.map((item, index) => (
                <tr  style={{cursor: 'pointer'}} key={index} >
                <td>{item.no+1}.</td>
                <td >{item.name}</td>
                <td style={{textAlign:'center'}}>{item.qty}</td>
                <td style={{textAlign:'center'}}>{item.unit}</td>
                </tr>
            ))}
            </tbody>
        </Table>
        <TablePagination
            component="div"
            count={display.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Row>
    </div>
  );
}

export default ResRawMatScreen;
