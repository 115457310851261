import React, { useState, useContext } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { clearChoice, updateOption } from "../redux/resChoiceSlice";
import { colors } from "../configs";
import { Modal_ResOptionScreen } from "../modal";


function ResProductOptionScreen() {
  const dispatch = useDispatch();
  const { shop } = useSelector((state)=> state.resShop);
  const { smartOption } = shop;
  const [option_Modal, setOption_Modal] = useState(false)

  return (
    <div  >
        <h1>เมนูย่อย</h1>
        {/* <div onPress={()=>{dispatch(clearChoice()),navigation.navigate('OptionScreen',{optionId:''})}} style={{paddingHorizontal:15,paddingVertical:10,...Style.shadow,backgroundColor:colors.purple,alignSelf:'center',borderRadius:20,margin:10,position:'absolute',bottom:10}} > */}
          <Modal_ResOptionScreen
            show={option_Modal}
          />
          <Button 
            onClick={()=>{dispatch(clearChoice())}} 
            variant="success"
          > 
            เพิ่ม Option
          </Button>
          <div  showsVerticalScrollIndicator={false} contentContainerStyle={{flexGrow:1}} >
            {smartOption?.map((item,index)=>{
                return(
                    // <div onPress={()=>{dispatch(updateOption(item)),navigation.navigate("OptionScreen",{optionId:item.optionId})}}  key={index} style={{padding:10,margin:10,borderRadius:20,backgroundColor:colors.white}} >
                    <div onPress={()=>{dispatch(updateOption(item))}}  key={index} style={{padding:10,margin:10,borderRadius:20,backgroundColor:colors.white}} >
                        <h5 ><b>{index+1}. {item.optionTopic} ({item.backOfficeName})</b></h5>
                        <h6 >ตัวเลือก</h6>
                        {item.choice.map((a,i)=>{
                            return(
                                <div key={i} style={{paddingLeft:15}} >
                                    <h6  >{i+1}) {a.choiceName}</h6>
                                </div>
                            )
                        })}
                    </div>
                )
            })}
          </div>
            
    </div>
  );
}

export default ResProductOptionScreen;
