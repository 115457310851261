import React, { useState, useEffect, useMemo, useRef } from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import { colors, mainImage } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { goToTop } from "../Utility/function";
import { normalSort } from "../Utility/sort";
import { NumberYMD, minusDays, plusDays, stringFullDate, stringYMDHMS3 } from "../Utility/dateTime";
import TablePagination from '@mui/material/TablePagination';
import { Modal_Loading } from "../modal";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import { fetchBill, updateBills } from "../redux/resBillSlice";
import { CSVLink } from "react-csv";
import Dropdown from 'react-bootstrap/Dropdown';
import ChartScreen from "./ChartScreen";
import html2canvas from 'html2canvas';

const options = [
    {id:1,name:'แบบย่อ'},
    {id:2,name:'แบบเต็ม'},
];

function ResReportByCustomer() {
  const dispatch = useDispatch();
  const { shop } = useSelector((state)=> state.resShop);
  const { cutOff, smartOption } = shop;
  const {billDates, modal_Bill, selectedBill } = useSelector((state)=> state.resBill);
  const [currentDisplay, setCurrentDisplay] = useState([]); // จำนวนที่แสดงในหนึ่งหน้า
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const today = new Date();
  const [startDate, setStartDate] = useState(today); //some many of day array to initial graph
  const [endDate, setEndDate] = useState(today); //  today
  const [option, setOption] = useState({id:1,name:'แบบย่อ'});


    useEffect(()=>{  
        let rewSearch = []
        let newSearch = []
        let start = minusDays(startDate,1)

        do {
            rewSearch.push(stringYMDHMS3(start))
            start = plusDays(start,1)
        }
        while (NumberYMD(start) < NumberYMD(plusDays(endDate,2)));

        for(const item of rewSearch){
            if(!billDates.includes(item)){
            newSearch.push(item)
            }
        }
        if(newSearch.length >0){
            dispatch(fetchBill({shopId:shop.id,billDate:newSearch,cutOff,startDate,endDate}))
        } else {
            dispatch(updateBills({startDate,endDate,cutOff}))
        }
    
    },[startDate,endDate])

    const { display, sortMember } = useMemo(()=>{
        let display = []
        const { finalProduct, sortMember } = manageData(selectedBill)
        if(option.id===1){
            display = sortMember
        } else {
            display = finalProduct
        }
        return { display, sortMember }
    },[selectedBill,option,smartOption])


function manageData(bills){
    let memberBill = bills.filter(a=>a.member?.id);
    let allProduct = memberBill.flatMap(a=>a.product.filter(b=>b.process!=='cancel').map(({name,id,qty,totalPrice})=>({name,id,qty,totalPrice,memberId:a.member.id,memberName:a.member.name,tel:a.member.tel})))
    let newMember = []
    for(const item of memberBill){
        let findMember = newMember.find(a=>a.id===item.member.id)
        if(findMember && findMember.id){
            findMember.billQty ++
            findMember.totalPrice += item.net
        } else {
            newMember.push({
                id:item.member.id,
                name:item.member.name,
                billQty:1,
                totalPrice:item.net,
                tel:item.member.tel
            })
        }
    }
    let secondProduct = []

    for(const item of allProduct){
        let thisProduct = secondProduct.find(a=>a.memberId===item.memberId && a.id===item.id)
        if(thisProduct && thisProduct.id){
            thisProduct.totalPrice += item.totalPrice
            thisProduct.qty += item.qty
        } else {
            secondProduct.push(item)
        }
    }
    let sortProduct = normalSort('totalPrice',secondProduct);
    let sortMember = normalSort('totalPrice',newMember);
    let finalProduct = sortMember.flatMap(item => {
        return sortProduct.filter(a => a.memberId === item.id);
    });
    return { finalProduct, sortMember}
}

  const handleChangePage = (event, newPage) => {
      setPage(newPage); // start form 0
      goToTop()
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      goToTop()
  };



  useEffect(()=>{
    let fData = display.map((item,index)=>{return({...item,no:index})}).filter((item,index)=>{return(index >=(page*rowsPerPage) && index <= ((page+1)*rowsPerPage)-1)})
      setCurrentDisplay(fData)
  },[page,rowsPerPage,display])

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{borderRadius:20}} onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  const displayRef = useRef(null);

  const handleCapture = () => {
    if (displayRef.current) {
      html2canvas(displayRef.current)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.href = imgData;
          link.download = `ยอดขายตามสมาชิก ${stringFullDate(startDate)} - ${stringFullDate(endDate)}.png`;
          link.click();
        });
    }
  };


  return (
    <div  >
      <Modal_Loading show={modal_Bill} />
      <h1>ยอดขายตามสมาชิก</h1>
      <Row >
          <Col lg='4'  >
              <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
                <img style={{width:30}} src={mainImage.calender} />
                <div style={{paddingLeft:10,paddingRight:10}} >  Start: </div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<ExampleCustomInput />}
                />
              </div>
          </Col>
          <Col lg='4'  >
            <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
              <img style={{width:30}} src={mainImage.calender} />
              <div style={{paddingLeft:10,paddingRight:10}} >  End: </div>
              <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  customInput={<ExampleCustomInput />}
              />
            </div>
          </Col>
          <Col lg='4' style={{padding:10}} >
          <Button onClick={handleCapture} variant="success" >
                Download Graph
            </Button>
          </Col>
          
      </Row>
      <Row ref={displayRef} style={{backgroundColor:colors.white,borderRadius:20,marginLeft:0,marginRight:0,marginBottom:20}} >
          <Col  lg={12} >
            <div style={{backgroundColor:'white',margin:5,padding:10,borderRadius:20,height:'400px',marginRight:0}} >
              <ChartScreen chart={sortMember} bar='totalPrice' name='' content="ยอดขาย" />
            </div>
          </Col>
      </Row>
      <Row>
        <Col>
            <Dropdown data-bs-theme="dark" style={{marginBottom:10}} >
                <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary" >
                    {option.name}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }} >
                    {options.map((item,index)=>{
                        return(
                            <Dropdown.Item key={index} onClick={()=>{setOption(item)}} >{item.name}</Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </Col>
        <Col>
            <CSVLink 
              style={{backgroundColor:'#292929',textDecoration:'none',color:colors.white,padding:10,borderRadius:20,paddingLeft:30,paddingRight:30}}
              filename={`ยอดขายตามสมาชิก[${option.name}]${stringYMDHMS3(startDate)}-${stringYMDHMS3(endDate)}`}
              data={
                option.id===1
                    ?display.map((item)=>{return({
                        สมาชิก:item.name,
                        จำนวนบิล:item.billQty,
                        ยอดซื้อ:item.totalPrice,
                        เบอร์โทร:item.tel
                      })})
                      
                    :display.map((item)=>{return({
                        สมาชิก:item.memberName,
                        สินค้า:item.name,
                        จำนวน:item.qty,
                        ยอดขาย:item.totalPrice,
                        เบอร์โทร:item.tel
                      })})
              }
            >
                Export CSV
            </CSVLink>
        </Col>
      </Row>
        {option.id===1
              ?<Row>
                <Table striped bordered hover responsive  variant="light"   >
                    <thead  >
                    <tr>
                      <th style={{ width: '10%' }}>No.</th>
                      <th style={{ width: '50%' }}>สมาชิก</th>
                      <th style={{ width: '20%' }}>จำนวนบิล</th>
                      <th style={{ width: '20%' }}>ยอดซื้อ</th>
                      <th style={{ width: '20%' }}>เบอร์โทร</th>
                    </tr>
                  </thead>
                  <tbody  >
                    {currentDisplay.map((item, index) => (
                      <tr  style={{cursor: 'pointer'}} key={index} >
                        <td>{item.no+1}.</td>
                        <td >{item.name}</td>
                        <td style={{textAlign:'center'}}>{item.billQty}</td>
                        <td style={{textAlign:'center'}}>{item.totalPrice}</td>
                        <td style={{textAlign:'center'}}>{item.tel}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <TablePagination
                    component="div"
                    count={display.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Row>
              :<Row>
                <Table striped bordered hover responsive  variant="light"   >
                  <thead  >
                    <tr>
                      <th style={{textAlign:'center', width: '10%'}} >No.</th>
                      <th style={{textAlign:'center'}} >สมาชิก</th>
                      <th style={{textAlign:'center'}}>สินค้า</th>
                      <th style={{ width: '20%' }}>จำนวน</th>
                      <th style={{ width: '20%' }}>ยอดขาย</th>
                      <th style={{ width: '20%' }}>เบอร์โทร</th>
                    </tr>
                  </thead>
                  <tbody  >
                    {currentDisplay.map((item, index) => (
                      <tr  style={{cursor: 'pointer'}} key={index} >
                        <td>{item.no+1}.</td>
                        <td >{item.memberName}</td>
                        <td >{item.name}</td>
                        <td style={{textAlign:'center'}}>{item.qty}</td>
                        <td style={{textAlign:'center'}}>{item.totalPrice}</td>
                        <td style={{textAlign:'center'}}>{item.tel}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <TablePagination
                    component="div"
                    count={display.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Row>
          }
    </div>
  );
}

export default ResReportByCustomer;
