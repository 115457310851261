import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink, useNavigate } from 'react-router-dom';
import { bath, colors, mainImage } from "../configs";
import {
    Form,
    Row,
    Col,
  } from "react-bootstrap";
import { checkCategory2, compareArrays, findTotalPrice, filterInArray,
  leanProduct, manageCategory, someInArray, findInArray } from "../Utility/function";
import '../styles/CartScreen.css';
import { Modal_Test, Modal_Request, Modal_PreMenuOption, Modal_LoadingPanda, Modal_Success, Modal_PreOrder, Modal_PackageBuffet } from "../modal";
import { addOrderToFirebase, fetchHistoryOrder, updateLimitItems, updateMasterDataSource } from "../redux/orderSlice";
import { rapidTranslateMultiTraduction } from "../api/translator";
import { reverseSort } from "../Utility/sort";
import { pushByOneFilter, pushByTwoFilter } from '../api/onesignal';
import LazyLoad from 'react-lazyload';
import {Img} from 'react-image';
import MyLocalLoaderImage from '../assets/image/reslogo.png'


function ProductDisplay({
  placeholder='ค้นหาเมนู แสนอร่อยที่นี่',
}) {
  const dispatch = useDispatch();
  const { tableData, products, orderHistory, masterDataSource, cart } = useSelector((state)=> state.order);

  const { shopName, shopLogo, tableName, smartCategory, smartPreOrder, 
    shopId, scShopId, tableId, mainId, smartOption, smartBuffet, smartStep,
    serviceType, smartRequest, smartTask, smartChef, buffetHeader } = tableData;
  const [categorySetting, setCategorySetting] = useState([])
  const [display, setDisplay] = useState([]);
  const [search, setSearch] = useState('')
  const initialShopProduct = {productId:'',visible:false,tempId:''};
  const [showProduct, setShowProduct] = useState(initialShopProduct)
  const { productId, visible, tempId } = showProduct;
  const [option_Modal, setOption_Modal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableProducts, setAvailableProduct] = useState([]);
  const [freeProduct, setFreeProducts] = useState([]);
  const [chargeProducts, setChargeProducts] = useState([]);
  const [productType, setProductType] = useState('alacarte'); // ถ้า product === 2 แปลว่า เมนูแบบเสียเงิน, default เป็น 2
  const [orderType, setOrderType] = useState('alacarte');
  const [buffet_Modal, setBuffet_Modal] = useState(false);
  const [preOrder_Modal, setPreOrder_Modal] = useState(false);

  // OoYpKchrpJ24H9yaIXmi
    function totalField(arr,field){
        let newArr = []
        arr.forEach((item)=>{
            newArr.push(item[field])
        })
        return newArr
    };

  // useEffect(()=>{
  //   dispatch(fetchHistoryOrder({shopId,tableId}))
  // },[])


  useEffect(()=>{
    let onlyAvailable = [];
    let onlyHavePrice = [];
    let onlyWithOutPreOrder = [];

    // 1. เลือกเฉพาะสินค้าที่ยัง available
    onlyAvailable = filterInArray(products,'status','available');

    // 2. เลือกเฉพาะที่มีราคาตามช่องทางขายนั้น
    for(const item of onlyAvailable){
        if(someInArray(item.price,'id',mainId)){
            onlyHavePrice.push(item)
        }
    }

    if(typeof smartStep === 'object' && !Array.isArray(smartStep)){
        let product = []
        for(const item of smartStep.steps){
            if(item.value.status){  // แปลว่าเปิดใช้งานหมวดเลือกสินค้า
                product = [...product,...item.value.product]
            }
        }
        for(const item of onlyHavePrice){  // เอาเฉพาะสินค้าที่ไม่มีใน preOrder
          if(!product.includes(item.id)){
            onlyWithOutPreOrder.push(item)
          }
        }
    } else {
        onlyWithOutPreOrder = onlyHavePrice
    }

    setAvailableProduct(onlyWithOutPreOrder)
    if(orderHistory.length===0){  // ตอนที่ยังไม่มีออเดอร์
      if(findInArray(serviceType,'name','Buffet').status && someInArray(smartBuffet||[],'status',true)){ // 1. check ว่ามีระบบบุฟเฟ่ต์มั้ย
          setBuffet_Modal(true)
      } 
    }
  },[products])



    useEffect(()=>{
      if(smartStep && smartStep.status){
          if(orderHistory.length===0){
              if(findInArray(serviceType,'name','Buffet').status && someInArray(smartBuffet||[],'status',true)){ // ดูว่าเปิดใช้งานระบบบุฟเฟ่ต์มั้ย
                      // do notting
              } else {
                  setPreOrder_Modal(true)
              }
          }
          if(orderHistory.length===1){
              if(findInArray(serviceType,'name','Buffet').status && someInArray(smartBuffet||[],'status',true)){ // ดูว่าเปิดใช้งานระบบบุฟเฟ่ต์มั้ย
                  setPreOrder_Modal(true)
              } 
          }
      }
    },[orderHistory])

    function priceZero(item){
      let price = item.price.map((a)=>{return({...a,price:0})})
      return {...item,price}
    }

    useEffect(()=>{ // ส่วนนี้เกี่ยวกับการแก้ไขราคารสินค้า  เป็นขั้นตอนต่อจากการทำ filter สินค้า
      if(orderHistory.length>0 && someInArray(orderHistory,'type','buffet')){
        setOrderType('buffet')
        setProductType('buffet')
        let freeProduct = []
        let chargeProduct = []
        let limitProduct = []
        let newOrderHistory = findInArray([...orderHistory],'type','buffet')
        let products = newOrderHistory?.product
        let { product } = findInArray(smartBuffet||[],'id',products[0].id)||{product:products}
        for(const item of product){
            let findProduct = findInArray(availableProducts,'id',item.id)
            if(findProduct && findProduct.id){
                const { charge, maxQty, limit, id } = item
                if(charge){
                    chargeProduct.push({...findProduct,maxQty})
                } else {  // ทำให้ทุกราคาขาย กลายเป็น 0
                    freeProduct.push(priceZero({...findProduct,maxQty}))
                }
                if(limit){
                  limitProduct.push({id,remain:limit*(products[0].qty)}) // จำนวนที่ได้ขึ้นกับคนที่มา
                }
            }
        }
        dispatch(updateLimitItems(limitProduct))
        setFreeProducts(freeProduct)
        setChargeProducts(chargeProduct)
  
    } else {// a la carte
        setChargeProducts(availableProducts)
        setProductType('alacarte')
        setOrderType('alacarte')
    }
    },[orderHistory,availableProducts])

    useEffect(()=>{
      let result = []
      let length = categorySetting.length;
      let selectedProduct = freeProduct
      if(productType==='alacarte'){ 
          selectedProduct = chargeProducts
      }
      for(const item of selectedProduct){
          if(length >=1){
              if(compareArrays(item.category.slice(0,length),totalField(categorySetting,'id'))){
                  result.push(item)
              }
          } else {
              result.push(item)
          }
      }
      if(Boolean(search)){
          searchFilterFunction(result,search)
      } else {
          setDisplay(result)
      }
      dispatch(updateMasterDataSource(result))
    },[categorySetting,freeProduct,chargeProducts,productType])


    const searchFilterFunction = (result,search) => {
      if (search) {
        const newData = result.filter(function (item) {
          const itemData = item.name
            ? item.name.toUpperCase()
            : "".toUpperCase();
          const textData = search.toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      setDisplay(newData);
      } else {
          setDisplay(masterDataSource);
      }
      setSearch(search)
  };

    const [fixedDivHeight, setFixedDivHeight] = useState(0);
    const fixedDivRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // The ResizeObserver callback will be called whenever the size of the observed element changes
      for (const entry of entries) {
        if (entry.target === fixedDivRef.current) {
          // Update the state with the new height of the fixed div
          setFixedDivHeight(entry.contentRect.height);
        }
      }
    });

    if (fixedDivRef.current) {
      // Start observing the fixed div
      resizeObserver.observe(fixedDivRef.current);
    }

    // Cleanup the observer when the component unmounts
    return () => {
      resizeObserver.disconnect();
    };
  }, []);


  async function testTranslate(){
    const translateRes = await rapidTranslateMultiTraduction();
    console.log("translateRes")
    console.log(translateRes)
    alert(JSON.stringify(translateRes,null,4))
  }

  async function testDetectBrowesrLanguage(){
    alert(navigator.language)
    console.log(navigator.language)
  }


  function manageSaleProduct(item){
    if(orderType==='buffet'){
        let findProduct = findInArray(cart,'id',item.id)
        if(findProduct && findProduct.id){
            setShowProduct({...initialShopProduct,visible:true,tempId:findProduct.tempId,productId:item.id})
        } else {
          setShowProduct({...initialShopProduct,visible:true,productId:item.id})
        }
    } else {
      setShowProduct({...initialShopProduct,visible:true,productId:item.id})
    }
}

  return (
    <div>
      <Modal_LoadingPanda show={loading} />
      <Modal_Test
            productId={productId}
            visible={visible}
            onRequestClose={()=>{setShowProduct(initialShopProduct)}}
            tempId={tempId}
      />
      <Modal_Request 
          show={option_Modal} 
          onHide={()=>{setOption_Modal(false)}}
      />

      <Modal_PreOrder
        show={preOrder_Modal}
        success={()=>{setPreOrder_Modal(false)}}
      />
      <Modal_PackageBuffet  
          show={buffet_Modal}
          onHide={()=>{setBuffet_Modal(false)}}
      />
      
      <div className="fixed-div" ref={fixedDivRef}>
      
      
      <div style={{display:'flex',alignItems:'center'}} >
          <img style={{width:'8vw',height:'auto',borderRadius:'100%',minWidth:'80px'}} alt='(image)' src={shopLogo || mainImage.reslogo} />
          <div style={{paddingLeft:'1rem'}} >
            <h2  >{shopName}</h2>
            <h4>{tableName}</h4>
          </div>
          {/* <div style={{display:'flex',flex:1,justifyContent:'flex-end'}} >
              <div onClick={()=>{testTranslate()}} style={{padding:'10px 30px 10px 30px',cursor:'pointer',display:'flex',flexDirection:'column',alignItems:'center'}} >
                <img style={{width:'8vw',height:'auto',borderRadius:'100%',minWidth:'80px'}} src={mainImage.messenger} />
                <h6 style={{textAlign:'center'}} >แปลภาษา</h6>
              </div>
          </div>
          <div style={{display:'flex',flex:1,justifyContent:'flex-end'}} >
              <div onClick={()=>{testDetectBrowesrLanguage()}} style={{padding:'10px 30px 10px 30px',cursor:'pointer',display:'flex',flexDirection:'column',alignItems:'center'}} >
                <img style={{width:'8vw',height:'auto',borderRadius:'100%',minWidth:'80px'}} src={mainImage.messenger} />
                <h6 style={{textAlign:'center'}} >แสดงภาษาของ Browser</h6>
              </div>
          </div> */}
          <div style={{display:'flex',flex:1,justifyContent:'flex-end'}} >
              <div onClick={()=>{setOption_Modal(true)}} style={{padding:'10px 30px 10px 30px',cursor:'pointer',display:'flex',flexDirection:'column',alignItems:'center'}} >
                <img style={{width:'8vw',height:'auto',borderRadius:'100%',minWidth:'80px'}} src={mainImage.request} />
                {/* <h6 style={{textAlign:'center'}} >เรียกพนักงาน</h6> */}
              </div>
          </div>
      </div>
      <div style={{paddingTop:5,paddingBottom:5}} >
          <Form className="d-flex">
              <Form.Control
                  type="search"
                  placeholder={placeholder}
                  className="me-2 rounded-pill"
                  aria-label="Search"
                  onChange={(event)=>{searchFilterFunction(masterDataSource,event.target.value)}}
              />
          </Form>
      </div>
      {chargeProducts.length>0 && orderType==='buffet'
          ?<Row  >
              <Col onClick={()=>{setProductType('buffet')}} 
                style={{padding:5,margin:5,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:20,
                backgroundColor:productType==='buffet'?colors.redPink:colors.softGray,cursor:'pointer',whiteSpace: 'nowrap',
                 textOverflow: 'ellipsis', maxWidth: '100%',overflow: 'hidden'}} >
                  <h5 style={{color:productType==='buffet'?colors.white:colors.dark}} >{buffetHeader.left}</h5>
              </Col>
              <Col onClick={()=>{setProductType('alacarte')}} 
                style={{padding:5,margin:5,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:20,
                backgroundColor:productType==='alacarte'?colors.redPink:colors.softGray,cursor:'pointer',whiteSpace: 'nowrap',
                 textOverflow: 'ellipsis', maxWidth: '100%',overflow: 'hidden'}} >
                  <h5 style={{color:productType==='alacarte'?colors.white:colors.dark}} >{buffetHeader.right}</h5>
              </Col>
          </Row>
          :null
      }
      {smartCategory.map((item,index)=>{
        let length = categorySetting.length===0?true:false
        return(
          <div key={index} style={{ width: '100%', overflowX: 'auto' }} className="custom-scrollbar" >
            <div style={{ display: 'flex' }}>
              {item.level === 1 ? (
                <div
                  onClick={() => {
                    setCategorySetting([]);
                  }}
                  className="category"
                  style={{
                    backgroundColor: length ? colors.redPink : null,
                    color: length ? colors.white : null,
                  }}
                >
                  ทั้งหมด
                </div>
              ) : null}

              {checkCategory2(categorySetting, item).map((a, i) => {
                let status = someInArray(categorySetting, 'id', a.id);
                return (
                  <div
                    onClick={() => {
                      setCategorySetting(manageCategory(item.value, categorySetting, a));
                    }}
                    key={i}
                    className="category"
                    style={{
                      backgroundColor: status ? colors.redPink : null,
                      color: status ? colors.white : null,
                      padding: '5px 10px', // Adjust padding for a smaller bar
                      minWidth: '8rem',
                      maxHeight: '5em',
                      overflow: 'hidden',
                      marginRight: '5px', // Add some spacing between items
                      borderRadius: '16px', // Add rounded corners for a nicer look
                      display:'flex',
                      justifyContent:'center',
                      whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100%'
                    }}
                  >
                    {a.name}
                  </div>
                );
              })}
            </div>
          </div>
        )
      })}
    </div>
        
      <div className="content-below" style={{ marginTop: `${fixedDivHeight}px`,marginLeft:'1rem' }} >
        <Row style={{paddingTop:20,}} >
            {display.map((item,index)=>{
            return(
                <Col key={index} xs='6' sm='6' md='3' lg='2' style={{marginBottom:10,paddingLeft:5,paddingRight:5}} >
                <div onClick={()=>{manageSaleProduct(item)}} style={{backgroundColor:colors.purplePeel,borderRadius:10,height:'100%',marginBottom:0,cursor: 'pointer'}} >
                  <LazyLoad height={200} offset={100} once >
                    {/* <img style={{width:'100%',height:undefined,borderRadius:10,aspecRatio:1}}  alt='(image)' src={item.imageId||mainImage.reslogo} loading="lazy" /> */}
                    <Img
                      src={item.imageId||mainImage.reslogo}
                      alt="My Image"
                      // loader={<div>Loading...</div>}
                      loader={<img src={MyLocalLoaderImage} alt="Loading..." style={{width:'100%',height:undefined,borderRadius:10,aspecRatio:1}}  />}
                      unloader={<div>Error loading image</div>}
                      style={{width:'100%',height:undefined,borderRadius:10,aspecRatio:1}}
                    />
                  </LazyLoad>
                    <div style={{padding:5}} >
                        <p style={{padding:0,margin:0,maxHeight:'3em',overflow:'hidden'}}   >{item.name}</p>
                        <p style={{padding:0,margin:0,color:colors.purpleRed}} ><b>{item.price[0].price}  {bath}</b></p>
                    </div>
                </div>
                </Col>
            )
            })}
        </Row>
      </div>
    </div>
  );
}

export default ProductDisplay;
