import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { colors, mainImage } from "../configs";
import { FloatingText, InputArea, InputText } from "../components";
import Modal_Contact from "./Modal_Contact";
import { v4 as uuidv4 } from 'uuid';
import Modal_Alert from "./Modal_Alert";
import { Translator, manageArray, manageArray_Id, manageObject } from "../Utility/function";
import Modal_CropImage from "./Modal_CropImage";
import { addLandlordToFirebase, setLoading_Landlord, setNewLandlord } from "../redux/landlordSlice";
import { useSelector, useDispatch } from "react-redux";
import { prepareFirebaseImage } from "../db/firestore";


function Modal_HumanResource({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
  search,
  addHuman
}) {

  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      // fullscreen='xxl-down'
      size={size}
    >
      <Modal.Header closeButton>
        <h2><b>เลือกบุคลากร</b></h2>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'transparent'}} >
            {search.map((item,index)=>{
                return(
                    <Button variant="light" onClick={()=>{addHuman(item.id)}} key={index} style={{cursor:'pointer'}} >
                        {index+1}. {item.name}
                    </Button>
                )
            })}
      </Modal.Body>
    </Modal>
  );
}

export default Modal_HumanResource;
