import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { checkCategory2, manageCategory, someInArray } from "../Utility/function";
import { colors } from "../configs";
import Modal_CategorySetting from "../modal/Modal_CategorySetting";


function ResProductCategoryScreen() {
  const dispatch = useDispatch();
  const { shop } = useSelector((state)=> state.resShop);
  const { smartCategory } = shop;
  const [allSelectedCategory, setAllSelectedCategory] = useState([]);
  const [editCategory_Modal, setEditCategory_Modal] = useState(false);

  return (
    <div  >
      <h1>จัดการหมวดหมู่</h1>
      <Button style={{paddingLeft:40,paddingRight:40}} onClick={()=>{setEditCategory_Modal(true)}} variant="success" >แก้ไข</Button>
      <Modal_CategorySetting 
        show={editCategory_Modal} 
        onHide={()=>{setEditCategory_Modal(false)}}
      />
        <div >
      <div>
        <div style={{ overflowY: 'auto', height: '100vh' }}>
          {smartCategory.map((item, index) => {
            return (
              <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ padding: '10px', borderRadius: '20px', margin: '5px',  textAlign: 'center' }}>
                  <h3 >ชั้นที่ {item.level}</h3>
                </div>
                <div style={{ overflowX: 'auto', display: 'flex', flexDirection: 'row' }}>
                  {/* Map over categories and render your components */}
                  {checkCategory2(allSelectedCategory, item).map((a, i) => {
                    const { id, name, aboveId, level } = a;
                    let status = someInArray(allSelectedCategory, 'id', id);
                    return (
                      <div
                        onClick={() => {setAllSelectedCategory(manageCategory(item.value, allSelectedCategory, a))}}
                        key={i}
                        style={{
                          padding: '10px',
                          borderRadius: '20px',
                          margin: '5px',
                          backgroundColor: status ? colors.purple : colors.softGray,
                          minWidth: '150px',
                          textAlign: 'center',
                          cursor:'pointer',
                        }}
                      >
                        <h4 style={{ color: status ? colors.white : colors.darkGray,}} >{name}</h4>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    </div>
  );
}

export default ResProductCategoryScreen;
