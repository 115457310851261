import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { colors, contact, initialMarket, mainImage } from "../configs";
import { FloatingText, ImageInput, InputArea, InputText } from "../components";
import { Modal_Alert, Modal_Contact, Modal_CropImage } from "../modal";
import { v4 as uuidv4 } from 'uuid';
import CropImage from "../components/CropImage";
import { checkSource } from "../Utility/function";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate , Outlet, NavLink } from 'react-router-dom';

function NewMarketScreen() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState(initialMarket)
    const [contact_Modal, setContact_Modal] = useState(false)
    const [selected, setSelected] = useState({})
    const [delete_Modal, setDelete_Modal] = useState(false)
    const [crop_Modal, setCrop_Modal] = useState(false)
  const handleFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...formData };

    switch (fieldName) {
        case 'imageId':
            newFormData.imageId = event.target.files[0]
            // console.log(newFormData.imageId )
            break;
        case 'logo':
            let fIndex = newFormData.imageUrl.findIndex((item)=>{return(item.type =='logo')})
            newFormData.imageUrl[fIndex].imageId = event.target.files[0]
            break;
        case 'latitude':
            newFormData.location.latitude = fieldValue
            break;
        case 'longitude':
            newFormData.location.longitude = fieldValue
            break;
        default:
            newFormData[fieldName] = fieldValue; // dynamic object referent  (eg. equal 'obj.price')
    }
    setFormData(newFormData);
  };
  
  function manageContact(id,value){
        const newFormData = { ...formData }
        let fIndex = formData.contact.findIndex((item)=>{return(item.id==id)})
        newFormData.contact[fIndex].value = value
        setFormData(newFormData);
  };

  function deleteContact(id){
        let newContact = formData.contact.filter((item)=>{return(item.id !==id)})
        setFormData({...formData,contact:newContact})
  }

  function manageImage(value){
    const newFormData = { ...formData }
    newFormData.imageUrl[1].imageId = value
    setFormData(newFormData);
  }

  function submit(){
        if(formData.name ==''){
            alert('กรุณาใส่ชื่อตลาด')
        } else if(formData.location.latitude == '' || formData.location.longitude ==''){
            alert('กรุณาใส่ Location ตลาด')
        } else {
            let imageUrl = formData.imageUrl[1].imageId
            if(formData.imageUrl[1].imageId !== mainImage.logo){

            } else {

            }
        }
  }

  return (
    <div style={{backgroundColor:colors.purplePeel,height:'100vh',overflow:'auto',paddingTop:50,paddingBottom:50}} >
        <Modal_CropImage
            show={crop_Modal}
            onHide={()=>{setCrop_Modal(false)}}
            onClick={(value)=>{setCrop_Modal(false);manageImage(value)}}
        />
        <Modal_Alert
            show={delete_Modal}
            onHide={()=>{setDelete_Modal(false)}}
            onClick={()=>{setDelete_Modal(false);deleteContact(selected.id)}}
            name={selected.type}
        />
        <Modal_Contact 
            show={contact_Modal}
            onHide={()=>{setContact_Modal(false)}}
            onClick={(value)=>{setFormData({...formData,contact:[...formData.contact,{...value,id:uuidv4()}]});setContact_Modal(false)}}
        />
        <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            label="Check this switch"
            onChange={(event)=>{alert(event.target.value)}}
        />
        <Form.Select aria-label="Default select example" onChange={(event)=>{alert(event.target.value)}}>
            <option>Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
        </Form.Select>
        <div style={{backgroundColor:'white',borderRadius:20,width:'50%',minWidth:500,marginLeft:'auto',marginRight:'auto',padding:20}} >
        <h2 style={{alignSelf:'center',marginLeft:'auto',marginRight:'auto',textAlign:'center',marginBottom:'2rem'}} >New Market Profile</h2>
        <InputText placeholder="Put Market'Name Here" name='name' onChange={handleFormChange} value={formData.name} />
        <InputArea placeholder="Put Market Addres Here" name='address' onChange={handleFormChange} value={formData.address} />
        <Row>
            <Col sm='3' >
                Location
            </Col>
            <Col sm='9' >
                <Row>
                    <Col sm='6' >
                        <FloatingText
                            name='latitude'
                            placeholder="Latitude Here"
                            onChange={handleFormChange}
                            value={formData.location.latitude}
                        />
                    </Col>
                    <Col sm='6' >
                        <FloatingText
                            name='longitude'
                            placeholder="Longitude Here"
                            onChange={handleFormChange}
                            value={formData.location.longitude}
                        />
                    </Col>
                </Row>
                
            </Col>
        </Row>
        <Row style={{marginBottom:'1rem'}} >
            <Col sm='3' >
                contact
            </Col>
            <Col sm='9' >
            
                {formData.contact.map((item,index)=>{
                    return(
                        <Col sm='12' key={index} >
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon3" style={{display:'flex',alignItems:'center',justifyContent:'center'}} >
                                    {item.icon}&emsp;{item.type}
                                </InputGroup.Text>
                                <Form.Control 
                                    id="basic-url" 
                                    aria-describedby="basic-addon3" 
                                    placeholder={item.type=='tel'?"Put your phoenumber here":"Put your link here"}
                                    onChange={(event)=>{manageContact(item.id,event.target.value)}}
                                    value={item.value}
                                />
                                <InputGroup.Text id="basic-addon1"  >
                                    <Button onClick={()=>{setDelete_Modal(true);setSelected(item)}} variant="light" style={{padding:10}} ><i class="bi bi-trash"></i></Button>
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    )
                })}
                
                <Button onClick={()=>{setContact_Modal(true)}} style={{backgroundColor:colors.darkPurple,borderColor:colors.darkPurple}} >Add contact</Button>
            
            </Col>
        </Row>
        <Row>
            <Col sm='3' >Cover Photo</Col>
            <Col sm='9' >
                <Button onClick={()=>{setCrop_Modal(true)}} variant="light" >
                    <Image style={{width:'100%'}} src={checkSource(formData.imageUrl[1].imageId)} />
                </Button>
            </Col>
        </Row>
        <Row>
            <Button onClick={()=>{navigate("/landlord")}} style={{backgroundColor:colors.purpleRed,borderColor:colors.purpleRed,width:'70%',marginTop:'1rem',marginLeft:'auto',marginRight:'auto'}}   >Submit</Button>
        </Row>
        </div>
    </div>
  );
}

export default NewMarketScreen;
