import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { chooseMarket, createNewMarket, fetchMarket, setNewMarket_Modal } from "../redux/marketSlice";
import { colors, mainImage } from "../configs";
import { CSVLink } from "react-csv";
import { stringFullTime, stringYMDHMS3 } from "../Utility/dateTime";
import { checkCategory2, compareArrays, findInArray, manageCategory, someInArray, totalField } from "../Utility/function";
import { Modal_Contact,Modal_FlatListTwoColumn,Modal_Confirm, Modal_NewMarket,Modal_NewResProduct, Modal_Success, Modal_Loading } from "../modal";

function ResProductWareHouseScreen() {
  const dispatch = useDispatch();
  const { products,modal_Product } = useSelector((state)=> state.resProduct)
  const { shop } = useSelector((state)=> state.resShop)
  const { units } = useSelector((state)=> state.resBom)
  const { markets, newMarket_Modal, loading_Market } = useSelector((state)=> state.market)
  const { cutOff, channel, smartTable, humanResource, smartCategory, POSs } = shop;
  const [categorySetting, setCategorySetting] = useState([])
  const [display, setDisplay] = useState([])
  const [masterDataSource, setMasterDataSource] = useState([])
  const [search, setSearch] = useState('')
  const [delete_Modal, setDelete_Modal] = useState(false)
  const [success_Modal, setSuccess_Modal] = useState(false)
  const [contact_Modal, setContact_Modal] = useState(false)
  const [channelId, setChannelId] = useState(1)
  const [mainChannel_Modal, setMainChannel_Modal] = useState(false)
  

  useEffect(()=>{
    let res = []
      for(const product of products){
        const { BOM, id, name, price } = product;
        if(BOM.length>0){
          for(const bom of BOM){
            let findFullBom = findInArray(units,'id',bom.id)
            if(findFullBom && findFullBom.id){
              res.push({
                ชื่อสาขา:shop.name,
                วันเวลา:stringFullTime(new Date()),
                รหัสเมนู:id,
                ชื่อเมนู:name,
                ปริมาณ:0,
                ยอดขายทั้งหมด:0,
                BOMId:findFullBom.rawMatId,
                BOMName:findFullBom.rawMatName,
                จำนวนวัตถุดิบที่ใช้:bom.qty,
                unitId:bom.id,
                หน่วยวัตถุดิบ:findFullBom.name,
                ราคาขาย:price[0].price
              })
            }
          }
        } else {
          res.push({
            ชื่อสาขา:shop.name,
            วันเวลา:stringFullTime(new Date()),
            รหัสเมนู:id,
            ชื่อเมนู:name,
            ปริมาณ:0,
            ยอดขายทั้งหมด:0,
            BOMId:0,
            unitId:0,
            จำนวนวัตถุดิบที่ใช้:0,
            หน่วยวัตถุดิบ:0,
          })
        }
      }
      setDisplay(res)
  },[products])


  useEffect(()=>{
    let result = []
    let length = categorySetting.length;
    let remainProducts = products
    
    for(const item of remainProducts){
        if(item?.category?.length >0){
            if(length >=1){
                if(compareArrays(item.category.slice(0,length),totalField(categorySetting,'id'))){
                    result.push(item)
                }
            } else {
                result.push(item)
            }
        }
    }
    setMasterDataSource(result)
    setDisplay(result)
  },[categorySetting,products])

  useEffect(()=>{
    searchFilterFunction(search)
  },[masterDataSource,search])

  const searchFilterFunction = (text) => {
    // let text = event.target.value
    if (text) {
    const newData = masterDataSource.filter(function (item) {
        const itemData = item.name
        ? item.name.toUpperCase()
        : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
    });
    setDisplay(newData);
    } else {
        setDisplay(masterDataSource);
    }
  };

  return (
    <div>
      {/* =========== Add new code =========== */}
      <Modal_FlatListTwoColumn 
          show={mainChannel_Modal}
          header='เปลี่ยนช่องทางการขาย'
          onHide={()=>{setMainChannel_Modal(false)}}
          // onClick={()=>{alert("set_new_channel")}}
          // onPress={(value)=>{setChannelId(value.id),setMainChannel_Modal(false)}}
          onClick={(value)=>{setChannelId(value?.id);setMainChannel_Modal(false)}}
          value={channel}
          selectedId={channelId}
      />
      <Modal_Confirm
          show={delete_Modal}
          // show={true}
          onHide={()=>{setDelete_Modal(false)}}
          content={`ยืนยันการลบ "${'product.name'}" ออกจากคลังสินค้า`}
          // backdrop='static'
          onClick={()=>{
              setDelete_Modal(false);
              // dispatch(deleteProductInCart(product.tempId))
          }}
      />
      <Modal_Success show={success_Modal} />
      <Modal_Loading show={modal_Product} />

        {/* <Modal_NewMarket
        show={newMarket_Modal}
        onHide={()=>{dispatch(setNewMarket_Modal(false))}}
      /> */}
      <Modal_NewResProduct
        show={newMarket_Modal}
        onHide={()=>{dispatch(setNewMarket_Modal(false))}}
      />

      <h1>รายการสินค้า</h1>
      <Col sm='12' onClick={()=>{setMainChannel_Modal(true)}}  style={{display:'flex',justifyContent:'center',alignItems:'center',padding:10}} >
        <Button variant="light" style={{width:'100%',height:'100%',borderRadius:20}}  >
            <h3 style={{color:colors.purpleRed}} >ช่องทางการขาย :{findInArray(channel,'id',channelId)?.name}<i class="bi bi-pencil-square"> </i></h3>
        </Button>
      </Col>
      <div style={{paddingTop:10,paddingBottom:10}} >
          <Form className="d-flex">
              <Form.Control
                  type="search"
                  placeholder={"ค้นหาสินค้าที่นี่"}
                  className="me-2 rounded-pill"
                  aria-label="Search"
                  onChange={(event)=>{setSearch(event.target.value)}}
              />
          </Form>
      </div>
      {smartCategory.map((item,index)=>{
        let length = categorySetting.length===0?true:false
        return(
          <div key={index} style={{ width: '100%', overflowX: 'auto' }} className="custom-scrollbar" >
            <div style={{ display: 'flex' }}>
              {item.level === 1 ? (
                <div
                  onClick={() => {
                    setCategorySetting([]);
                  }}
                  className="category"
                  style={{
                    backgroundColor: length ? colors.redPink : null,
                    color: length ? colors.white : null,
                  }}
                >
                  ทั้งหมด
                </div>
              ) : null}

              {checkCategory2(categorySetting, item).map((a, i) => {
                let status = someInArray(categorySetting, 'id', a.id);
                return (
                  <div
                    onClick={() => {
                      setCategorySetting(manageCategory(item.value, categorySetting, a));
                    }}
                    key={i}
                    className="category"
                    style={{
                      backgroundColor: status ? colors.redPink : null,
                      color: status ? colors.white : null,
                      padding: '5px 10px', // Adjust padding for a smaller bar
                      minWidth: '8rem',
                      maxHeight: '5em',
                      overflow: 'hidden',
                      marginRight: '5px', // Add some spacing between items
                      borderRadius: '16px', // Add rounded corners for a nicer look
                      display:'flex',
                      justifyContent:'center'
                    }}
                  >
                    {a.name}
                  </div>
                );
              })}
            </div>
          </div>
        )
      })}
      
      <Col sm='6' onClick={()=>{dispatch(createNewMarket())}}  style={{display:'flex',justifyContent:'center',alignItems:'center',padding:10}} >
          <Button variant="light" style={{width:'100%',height:'100%',borderRadius:20}}  >
              <i className="bi bi-plus-circle-fill" style={{fontSize:80,color:colors.purpleRed}} ></i>
              <h3 style={{color:colors.purpleRed}} >Add New Product</h3>
          </Button>
      </Col>
      {/* =========== End of Add new code =========== */}
      <CSVLink 
        style={{backgroundColor:'#292929',textDecoration:'none',color:colors.white,padding:10,borderRadius:20,paddingLeft:30,paddingRight:30}}
        filename={`รายการสินค้า${stringYMDHMS3(new Date())}`}
        data={display }
      >
          Export
      </CSVLink>
      <Row style={{margin:0,padding:0}} >
        {products.map((item,index)=>{
          return(
            <Col key={index} xs='6' md='4' lg='3' xl='2' style={{padding:5}}  >
              <div style={{backgroundColor:colors.white,borderRadius:10}} onClick={()=>{alert(JSON.stringify(item.BOM))}} >
                  <img style={{width:'100%',height:undefined,aspectRatio:1,borderRadius:10}} src={item.imageId||mainImage.reslogo} />
                  <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', padding:5 }} >
                    {item.name}
                  </div>
              </div>
            </Col>
          )
        })}
          
      </Row>
    </div>
  );
}

export default ResProductWareHouseScreen;
