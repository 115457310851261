import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { db } from '../db/firestore';
import firebase from 'firebase/app'
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
  Alert
} from "react-bootstrap";


function FileUploader() {
  const [fileData, setFileData] = useState(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);

        // Check if the file is an XLSX file
        if (file.name.endsWith('.xlsx')) {
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          setFileData(jsonData);
        }
        // Check if the file is a CSV file
        else if (file.name.endsWith('.csv')) {
          Papa.parse(file, {
            complete: (result) => {
              setFileData(result.data);
            },
            header: true, // Assuming CSV has a header
          });
        }
        // Unsupported file type
        else {
          alert('Unsupported file type. Please upload a CSV or XLSX file.');
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  console.log('fileData')
  console.log(fileData)

  const addDocumentsToFirestore = async () => {
    const collectionRef = db.collection('csv'); // Replace 'yourCollection' with the name of your collection

    // Split the documents into batches (Firestore allows up to 500 writes in a single batch)
    const batchSize = 500;
    const batches = [];

    for (let i = 0; i < fileData.length; i += batchSize) {
      const batch = collectionRef.firestore.batch();

      // Loop to add documents to the batch
      for (let j = i; j < i + batchSize && j < fileData.length; j++) {
        const documentData = fileData[j];

        const documentRef = collectionRef.doc(); // Auto-generated document ID
        batch.set(documentRef, documentData);
      }

      batches.push(batch);
    }

    // Execute all batches concurrently
    await Promise.all(batches.map((batch) => batch.commit()));
  };



  return (
    <div>
        <Button onClick={addDocumentsToFirestore} >
            addDocumentsToFirestore
        </Button>
      <input type="file" accept=".csv, .xlsx" onChange={handleFileUpload} />
      {fileData && (
        <div>
          <Table    hover  responsive id="table-to-xls" >
        <thead>
          <tr key={"header"}>
            {Object.keys(fileData[0]).map((item) => (
              <th>{item}</th>
            ))}

          </tr>
        </thead>
        <tbody>
        {fileData.map((item) => (
            <tr key={item.id}>
              {Object.values(item).map((val) => (
                <td>{val}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
        </div>
        
      )}
    </div>
  );
}

export default FileUploader;