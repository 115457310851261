import React, { useState, useContext } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Translator } from "../Utility/function";
import { editMarket, setEditMarket_Modal } from "../redux/marketSlice";
import {Modal_EditMarket, Modal_Loading} from "../modal";
import { mainImage } from "../configs";

function MarketProfile() {
  const dispatch = useDispatch();
  const { currentMarket, editMarket_Modal, loading_Market } = useSelector((state)=> state.market);

  return (
    <div className="container" >
      <Modal_EditMarket
        show={editMarket_Modal}
        onHide={()=>{dispatch(setEditMarket_Modal(false))}}
      />
      <Modal_Loading show={loading_Market} />
        <h2>Name : {Translator(currentMarket.name,'TH')}</h2>
        {/* <BorderHorizontal/> */}
        <p>Address : {Translator(currentMarket.address,'TH')}</p>
        {/* <BorderHorizontal/> */}
        <p>Location : {currentMarket.location.latitude},{currentMarket.location.longitude}</p>
        {/* <BorderHorizontal/> */}
        <p>Contact</p>
        {currentMarket?.contact?.map((item,index)=>{
          return(
            <div key={item.id} style={{width:'100%',padding:10}} >
                <img style={{width:30}} src={item.icon} />
                &emsp;{item.value.slice(0,20)}
            </div>
          )
        })}
        {/* <BorderHorizontal/> */}
        <p>CoverPhoto</p>
        <img style={{width:'50%',marginBottom:20}}  src={Translator(currentMarket.imageUrl,'coverPhoto') || mainImage.democoverphoto} />
        {/* <BorderHorizontal/> */}
        {currentMarket.enterprise==='true'
            ?<p>Bussiness : นิติบุคคล</p>
            :<p>Bussiness : บุคคลธรรมดา</p>
        }
        <Button onClick={()=>{dispatch(editMarket())}} variant="success" style={{width:'40%'}} >
            Edit
        </Button>
    </div>
  );
}

export default MarketProfile;
