import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { db } from "../db/firestore";
import { resInitialProfile } from '../configs';

const initialState = {
    profile:resInitialProfile, // 
    modal_Profile:false,
    categorySetting:[],
    selectedKitchen:'order',
    kitchen:0,
}

export const fetchProfile = createAsyncThunk(
  'profile/fetchProfile', async ({token,uniqueId}) => {
    let data = {}
    await db.collection('profile').doc(token).get().then((doc)=>{
      const { timestamp, birthday } = doc.data();
      if(birthday){
        data =  {...doc.data(),timestamp:timestamp.toDate(),id:doc.id,birthday:birthday.toDate(),uniqueId}
      }else{
        data =  {...doc.data(),timestamp:timestamp.toDate(),id:doc.id,birthday:new Date(),uniqueId}
      }
    })
    return data;
});

// updateProfile
export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  async (profile) => {
    await db.collection('profile').doc(profile.id).update(profile)
    return profile
  }
);

export const updateFieldProfile = createAsyncThunk(
  'profile/updateFieldProfile', async ({doc,field}) => {
  await db.collection('profile').doc(doc).update(field)
  return field;
});

export const resProfileSlice = createSlice({
  name: 'resProfile',
  initialState,
  reducers: {
    updatemodal_Profile: state => {
      state.modal_Profile = !state.modal_Profile
    },
    updateCategorySetting: (state, action) => {
      state.categorySetting = action.payload
    },
    updateSelectedKitchen: (state, action) => {
      state.selectedKitchen = action.payload
    },
    updateKitchen: (state, action) => {
      state.kitchen = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProfile.pending, state => {
      state.modal_Profile = true
    })
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.profile = action.payload
      state.modal_Profile = false
    })
    builder.addCase(fetchProfile.rejected, state => {
      state.modal_Profile = false
    })
    builder.addCase(updateProfile.pending, state => {
      state.modal_Profile = true
    })
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.profile = action.payload
      state.modal_Profile = false
    })
    builder.addCase(updateProfile.rejected, state => {
      state.modal_Profile = false
    })
    builder.addCase(updateFieldProfile.pending, state => {
      state.modal_Profile = true
    })
    builder.addCase(updateFieldProfile.fulfilled, (state, action) => {
        state.profile = {...state.profile,...action.payload}
        state.modal_Profile = false
    })
    builder.addCase(updateFieldProfile.rejected, state => {
    state.modal_Profile = false
    })
  }
})

export const { updateCategorySetting, updateSelectedKitchen, updateKitchen,
} = resProfileSlice.actions

export default resProfileSlice.reducer