import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfile, updateFieldProfile } from "../redux/resProfileSlice";
import { AiOutlineFieldTime } from "react-icons/ai";
import { filterDeleteInArray, findInArray, getUniqueArray } from "../Utility/function";
import { colors, mainImage, resInitialCurrentLogin } from "../configs";
import { stringDateTime3 } from "../Utility/dateTime";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink, useNavigate } from 'react-router-dom';
import { fetchShop } from "../redux/resShopSlice";
import { Modal_Loading } from "../modal";
import { fetchProduct } from "../redux/resProductSlice";
import { fetchBom } from "../redux/resBomSlice";
import { db } from "../db/firestore";

function ResAdminScreen() {
  const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentShop, setCurrentShop] = useState([])
    useEffect(()=>{
        db.collection('shop').get().then((docs)=>{
            let res = []
            docs.forEach((doc)=>{res.push({id:doc.id,...doc.data()})})
            setCurrentShop(res)
          })
        
        // alert('ppp')
    },[])

    function backToShop(data){
        console.log('data')
        console.log(data.id)
        Promise.all(
            [
                dispatch(fetchShop(data.id)),
                dispatch(fetchProduct(data.id)),
                dispatch(fetchBom(data.id)),                  
            ]
        )
        .catch(() => {
            alert("Failed to update account")
        })
        .finally(() => {
          navigate("/restaurant")
        })
      }
    
  return (
    <div style={{backgroundColor:colors.white,height:'100vh'}} >
        <div style={{backgroundColor:colors.white,display:'flex',justifyContent:'center'}} >
            <h1>เลือกร้านอาหาร</h1>
        </div>
        <div style={{height:'100vh',marginLeft:20,marginRight:20,borderTopLeftRadius:20,borderTopRightRadius:20}} >
            <Row style={{padding:20,margin:20,backgroundColor:colors.lightGray,borderRadius:20}} >
                {currentShop.map((item,index)=>{
                    return(
                        <Col key={index} xs='12' md='6' lg='4' onClick={()=>{backToShop(item)}} style={{cursor:'pointer'}} >
                            <div style={{borderRadius:30,padding:10,backgroundColor:colors.white,margin:'10px 0px 10px 0px',display:'flex'}} >
                                {/* <img style={{width:100,height:100,borderRadius:20}} src={findInArray(item?.imageUrl||[],'type','logo')?.value || mainImage.reslogo} /> */}
                                <div>
                                    <h6 style={{fontSize:24}} >{item.name}</h6>
                                    <h6 style={{fontSize:24}} >{item.id}</h6>
                                    <div style={{flexDirection:'row',marginTop:17,width:'100%',justifyContent:'flex-start',alignItems:'center'}} >
                                    <AiOutlineFieldTime style={{color:colors.primaryColor, fontSize: '1rem'}} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </div>
        
    </div>
  );
}

export default ResAdminScreen;
