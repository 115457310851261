import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { db } from "../db/firestore";


const initialState = {
    shop:{}, // 
    modal_Shop:false,

}

export const fetchShop = createAsyncThunk(
  'shop/fetchShop', async (token) => {
    let data = {}
    const shopRef = db.collection('shop').doc(token);
    await shopRef.get().then((doc)=>{
      const { smartTable } = doc.data();
      let newSmart = smartTable.map((item)=>{return({...item,timestamp:item.timestamp.toDate()})})
      data = {...doc.data(),id:doc.id,cutOff:doc.data().cutOff.toDate(),smartTable:newSmart}
    })
    return data;
});
export const updateFieldShop = createAsyncThunk(
    'shop/updateFieldShop', async ({doc,field}) => {
    await db.collection('shop').doc(doc).update(field)
    return field;
});


export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    updateModal_Shop: state => {
      state.modal_Shop = !state.modal_Shop
    },

  },
  extraReducers: builder => {
    builder.addCase(fetchShop.pending, state => {
      state.modal_Shop = true
    })
    builder.addCase(fetchShop.fulfilled, (state, action) => {
      state.shop = action.payload
      state.modal_Shop = false
    })
    builder.addCase(fetchShop.rejected, state => {
      state.modal_Shop = false
    })
    builder.addCase(updateFieldShop.pending, state => {
        state.modal_Shop = true
    })
    builder.addCase(updateFieldShop.fulfilled, (state, action) => {
        state.shop = {...state.shop,...action.payload}
        state.modal_Shop = false
    })
    builder.addCase(updateFieldShop.rejected, state => {
    state.modal_Shop = false
    })
  }
})

export const { updateModal_Shop,
} = shopSlice.actions

export default shopSlice.reducer