import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
  Alert
} from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.css";
import '../styles/ShopListScreen.css'
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import { Modal_Shop, Modal_ShopReport } from "../modal";
import { colors } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { allFilter, goToTop } from "../Utility/function";
import { stringFullDate, stringFullTime } from "../Utility/dateTime";
import { normalSort, reverseSort } from "../Utility/sort";
import { fetchRenter, fetchShop, setModal_Renter } from "../redux/renterSlice";


function ShopListScreen() {
  const dispatch = useDispatch();
  const [masterDataSource, setMasterDataSource] = useState([])
  const [store_Modal, setStore_Modal] = useState(false)
  const { renters_Normal, loading_Renter, modal_Renter } = useSelector((state)=> state.renter)
  const { currentMarket } = useSelector((state)=> state.market)
  const [filterDataSoure, setFilterDataSource] = useState([]) // จำนวนข้อมูลทั้งหมดที่ต้องถูกแสดง
  const [currentDisplay, setCurrentDisplay] = useState([]) // จำนวนที่แสดงในหนึ่งหน้า

  const searchFilterFunction = (event) => {
    let text = event.target.value
    if (text) {
      const newData = masterDataSource.filter(function (item) {
        const itemData = item.name
          ? item.name.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setFilterDataSource(newData);
    } else {
        setFilterDataSource(masterDataSource);
    }
    setPage(0)
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
      setPage(newPage); // start form 0
      goToTop()
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      goToTop()
  };

  useEffect(()=>{
    const data = reverseSort('timestamp',allFilter(renters_Normal,'marketId',currentMarket.id));
    setMasterDataSource(data);
    setFilterDataSource(data);
  },[])

  useEffect(()=>{
    let fData = filterDataSoure.filter((item,index)=>{return(index >=(page*rowsPerPage) && index <= ((page+1)*rowsPerPage)-1)})
      setCurrentDisplay(fData)
  },[page,rowsPerPage,filterDataSoure])
  console.log(renters_Normal)
  return (
    <div className="container"  >
      {/* <Link to="/market">Back to market</Link> */}
      <Modal_Shop
        show={modal_Renter}
        onHide={()=>{dispatch(setModal_Renter(false))}}
      />
      <Modal_ShopReport
        show={store_Modal}
        onHide={()=>{setStore_Modal(false)}}
        data={masterDataSource}
      />
      <Row >
        <Col sm='6' >
          <Row>
              <Col md={5} >
                  <h3 style={{display:'inline',color:colors.darkGray}} ><b>ค้นหาร้านค้า</b></h3>
              </Col>
              <Col md={7} >
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2 rounded-pill"
                    aria-label="Search"
                    onChange={searchFilterFunction}
                    
                  />
                  {/* <Button className="rounded-pill" variant="outline-primary">
                    Sort
                  </Button> */}
                </Form>
              </Col>
          </Row>
          <div style={{paddingRight:20}} >
            
          </div>
          
        </Col>
          
        <Col sm='6' style={{display:'flex',justifyContent:'flex-end'}} >
          <Button variant="success" onClick={()=>{setStore_Modal(true)}} >
              DownLoad All Store
          </Button>
        </Col>
      </Row>
      <div  >
      <Table    hover  responsive id="table-to-xls" >
        
        <thead>
          <tr>
            <th>ลำดับ</th>
            <th>Store</th>
            {/* <th style={{textAlign:'center'}} >Status</th> */}
            {/* <th style={{textAlign:'center'}} >ยอดขายวันนี้</th> */}
            <th style={{textAlign:'center'}} >วันที่เข้าร่วม</th>
            {/* <th style={{textAlign:'center'}} >ยอดขายเดือนนี้</th> */}
            <th style={{textAlign:'center'}} >Credit</th>
            <th style={{textAlign:'center'}} >Zone</th>
          </tr>
        </thead>
        <tbody>
        {currentDisplay.map((item, index) => (
              <tr onClick={()=>{dispatch(fetchShop({shopId:item.shopId,currentRenter:item}))}} style={{cursor: 'pointer'}} >
              <td>{index+1}.</td>
              <td  >
                <div style={{display:'flex',alignItems:'center'}} >
                  <img style={{width:60,height:60,objectFit:'cover',borderRadius:'100%'}} alt='image' src={item.shopLogoPhoto}/>
                  <div style={{textAlign:'left',paddingLeft:10}} >
                    <h5><b>{item.shopName}</b></h5>
                    <h6>{item.name}</h6>
                    {/* <h6>{item.shopId}</h6> */}
                  </div>
                </div>
              </td>
              {/* <td style={{position:'relative'}} >
                  <h6 style={{borderRadius:20,alignSelf:'center',position:'absolute',left:'50%',top:'50%',transform:'translate(-50%, -50%)',textAlign:'center',padding:'5px 20px',color:colors.white,backgroundColor:colors.lightGreen}} >OPEN</h6>
              </td> */}
              <td style={{textAlign:'center'}}>
                {stringFullDate(new Date(item.timestamp))}
              </td>
              <td style={{textAlign:'center'}}>
                {item.point}
              </td>
              <td style={{textAlign:'center'}}>
                -
              </td>
            </tr>
            ))}
        </tbody>
      </Table>
      <TablePagination
            component="div"
            count={filterDataSoure.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
        <Outlet/>
        
    </div>
    
  );
}

export default ShopListScreen;
