
import React, { useState, useContext, useEffect } from "react";
import {useParams} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchProductOrder, fetchShopOrder, fetchqrCodeOrder, updateTable } from "../redux/orderSlice";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink, useNavigate } from 'react-router-dom';
import { db } from "../db/firestore";
import { findInArray } from "../Utility/function";
import { mainImage } from "../configs";

function DeliveryScreen() {
  const { docId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector( state => state.auth);

  useEffect(()=>{
    if(currentUser && currentUser.uid){
      try {
        db.collection('shop').doc(docId).get().then((docs)=>{
          if(docs.exists){
              const { name, imageUrl, smartCategory, smartOption } = docs.data();
              dispatch(updateTable({shopName:name,shopLogo:findInArray(imageUrl,'type','logo')?.value||mainImage.logo,tableName:'',smartCategory,mainId:1,smartOption}))
              dispatch(fetchProductOrder(docId))
              navigate(`/${name}`)
          } else {
              alert('ลิงค์หมดอายุ')
          }
        })
      } catch (error){
        alert(error)
      }
    }
  },[currentUser])

  

  return (
    <div style={{display:'flex',justifyContent:'center',alignItem:'center',minHeight:'100vh'}} >
        <img src='https://img.pikbest.com/png-images/20190918/cartoon-snail-loading-loading-gif-animation_2734139.png!bw700' style={{width:300,height:300,aspectRatio:1}} />
    </div>
  );
}

export default DeliveryScreen;

