import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db } from "../db/firestore";
import { initialLandlord } from "../configs";

const initialState = {
  landlords:[],
  loading_Landlord:false,
  error: "",
  newLandlord_Modal:false,
  newLandlord:initialLandlord,
  currentLandlord:{}
}

// add newLandlord to firestore
export const addLandlordToFirebase = createAsyncThunk(
  'landlord/addLandlordToFirebase',
  async (landlord)=>{
      let newLandlord = {}
      await db.collection('landlord').add({...landlord,timestamp:new Date(landlord.timestamp)}).then((doc)=>{
        newLandlord = {id: doc.id, ...landlord}
      })
      return newLandlord;
  }
);

// fetchLandlord
export const fetchLandlord = createAsyncThunk(
    'landlord/fetchLandlord',
    async (email) => {
      let data = []
      await db.collection('landlord').where("humanResourceEmail", "array-contains", email).get().then((docs)=>{
        docs.forEach((doc)=>{data.push({id: doc.id,...doc.data()})})
      })
      return data;
    }
  );

export const landlordSlice = createSlice({
    name:'landlord',
    initialState,
    reducers: {
      setNewLandlord_Modal: (state, action) => {
        state.newLandlord_Modal = action.payload
      },
      createNewLandlord: (state, action) => {
        state.newLandlord = initialLandlord
        state.newLandlord_Modal = true
      },
      setNewLandlord: (state, action) => {
        state.newLandlord = action.payload
      },
      setLoading_Landlord: (state, action) => {
        state.loading_Landlord = action.payload
      },
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchLandlord.pending, (state, action) => {
            state.loading_Landlord = true
          })
          .addCase(fetchLandlord.fulfilled, (state, action) => {
            state.currentLandlord = action.payload[0]
            state.landlords = action.payload
            state.loading_Landlord = false
          })
          .addCase(fetchLandlord.rejected, (state, action) => {
            state.loading_Landlord = false
            state.error = action.error.message
          })
          .addCase(addLandlordToFirebase.pending, (state, action) => {
            // state.loading_Landlord = true
          })
          .addCase(addLandlordToFirebase.fulfilled, (state, action) => {
            state.landlords.push(action.payload)
            state.currentLandlord = action.payload
            state.loading_Landlord = false
            state.newLandlord_Modal = false
          })
          .addCase(addLandlordToFirebase.rejected, (state, action) => {
            state.loading_Landlord = false
            state.error = action.error.message
          })
      }
})

export const  { setNewLandlord_Modal, createNewLandlord, setNewLandlord, setLoading_Landlord } = landlordSlice.actions;
export default landlordSlice.reducer;