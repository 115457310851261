import colors from "./colors";
import mainImage from "./mainImage";

export default {
        ownerId:'',
        name:[
            // {
            //     type:'TH',
            //     value:''
            // }
        ],
        imageUrl:[
            // {
            //     id:1, // จะ fixed เป็น default ให้
            //     type:'logo',
            //     value:mainImage.demologo
            // },
        ], // ใส่ไว้ก่อน แต่ยังไม่ต้องใช้
        address:[
            // {
            //     type:'TH',
            //     value:''
            // }
        ],
        contact:[],
        location:{latitude:'',longitude:''},
        enterprise:true,// เป็นรูปแบบบริษัทหรือไม่
        humanResource:[],
        humanResourceEmail:[], //email1 email2
        payment:[],
        position:[],
        other:[], // ใส่ที่เหลือที่คิดออกทีหลัง
        timestamp:new Date()
    }
  

