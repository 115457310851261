import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { colors, mainImage } from "../configs";
import { FloatingText, InputArea, InputText } from "../components";
import Modal_Contact from "./Modal_Contact";
import { v4 as uuidv4 } from 'uuid';
import Modal_Alert from "./Modal_Alert";
import { Translator, manageArray, manageArray_Id, manageObject } from "../Utility/function";
import Modal_CropImage from "./Modal_CropImage";
import { addLandlordToFirebase, setLoading_Landlord, setNewLandlord } from "../redux/landlordSlice";
import { useSelector, useDispatch } from "react-redux";
import { prepareFirebaseImage } from "../db/firestore";


function Modal_NewLandlord({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
}) {
    const dispatch = useDispatch();
    const { currentProfile } = useSelector((state)=> state.profile)
    const { newLandlord } = useSelector((state)=> state.landlord)
    const [contact_Modal, setContact_Modal] = useState(false)
    const [selected, setSelected] = useState({})
    const [delete_Modal, setDelete_Modal] = useState(false)
    const [crop_Modal, setCrop_Modal] = useState(false)

    async function checkLandlordProfile(){
          
        if(Translator(newLandlord.name,'TH') == null){
          alert('กรุณาใส่ชือ landlord')
        } else if(Translator(newLandlord.address,'TH') == null){
          alert('กรุณาใส่ address')
        } else if(newLandlord.location.latitude == '' || newLandlord.location.longitude == ''){
          alert('กรุณาใส่ location')
        } else if(Translator(newLandlord.imageUrl,'logo') == null){
          alert('กรุณาเลือกรูปภาพ')
        } else {
          dispatch(setLoading_Landlord(true))
          const newFormData = { ...newLandlord }
          newFormData.humanResourceEmail =[currentProfile.email]
          newFormData.humanResource=[{
            email:currentProfile.email,
            name:currentProfile.name,
            notification:true,
            position:['001'],
            status:'active',
            tel:currentProfile.tel
          }]
          newFormData.timestamp = new Date()
          newFormData.ownerId = currentProfile.id
          let newImageUri = []
          for(const image of newFormData.imageUrl){
            if(image.value.slice(0,5) !== 'https'){
                const imageUrl = await prepareFirebaseImage(image.value,'/landlord/')
                newImageUri.push({...image,value:imageUrl})
            } else {
              newImageUri.push(image)
            }
          }
          dispatch(addLandlordToFirebase({...newFormData,imageUrl:newImageUri}))
        }
      }


  function deleteContact(id){
      let newContact = newLandlord.contact.filter((item)=>{return(item.id !==id)})
      dispatch(setNewLandlord({...newLandlord,contact:newContact}))

  }

  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      fullscreen='xxl-down'
    //   size={size}
    >
      <Modal.Header closeButton>
        <h2><b>Create Your Area</b></h2>
        
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'transparent'}} >
          <Modal_Contact 
                show={contact_Modal}
                onHide={()=>{setContact_Modal(false)}}
                onClick={(value)=>{
                    dispatch(setNewLandlord({...newLandlord,contact:[...newLandlord.contact,{...value,id:uuidv4()}]}))
                    setContact_Modal(false)}}
            />
            <Modal_Alert
                show={delete_Modal}
                onHide={()=>{setDelete_Modal(false)}}
                onClick={()=>{setDelete_Modal(false);deleteContact(selected.id)}}
                name={selected.type}
            />
            <Modal_CropImage
              show={crop_Modal}
              onHide={()=>{setCrop_Modal(false)}}
              onClick={(value)=>{
                setCrop_Modal(false);
                manageArray(newLandlord.imageUrl,'logo',value,(imageUrl)=>{dispatch(setNewLandlord({...newLandlord,imageUrl}))})
            }}
              ratio={1/1}
          />
            <Col sm='12'>
                <InputText
                  name='name'
                  placeholder="Area name"
                  onChange={(event)=>{manageArray(newLandlord.name,'TH',event.target.value,(name)=>{dispatch(setNewLandlord({...newLandlord,name}))})}}
                  value={Translator(newLandlord.name,'TH')}
                />
            </Col>
            <Col sm='12'>
                <InputArea
                  name='address'
                  placeholder="Area address"
                  onChange={(event)=>{manageArray(newLandlord.address,'TH',event.target.value,(address)=>{dispatch(setNewLandlord({...newLandlord,address}))})}}
                  value={Translator(newLandlord.address,'TH')}
                />
            </Col>
        <Row>
            <Col sm='3' >
                Location
            </Col>
            <Col sm='9' >
                <Row>
                    <Col sm='6' >
                        <FloatingText
                            name='latitude'
                            placeholder="Latitude Here"
                            onChange={(event)=>{manageObject(newLandlord.location,'latitude',event.target.value,(location)=>{dispatch(setNewLandlord({...newLandlord,location}))})}}
                            value={newLandlord.location.latitude}
                        />
                    </Col>
                    <Col sm='6' >
                        <FloatingText
                            name='longitude'
                            placeholder="Longitude Here"
                            onChange={(event)=>{manageObject(newLandlord.location,'longitude',event.target.value,(location)=>{dispatch(setNewLandlord({...newLandlord,location}))})}}
                            value={newLandlord.location.longitude}
                        />
                    </Col>
                </Row>
                
            </Col>
        </Row>
        <Row style={{marginBottom:'1rem'}} >
            <Col sm='3' >
                contact
            </Col>
            <Col sm='9' >
            
                {newLandlord?.contact?.map((item,index)=>{
                    return(
                        <Col sm='12' key={index} >
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon3" style={{display:'flex',alignItems:'center',justifyContent:'center'}} >
                                    <img src={item.icon} style={{width:30,height:30}} />
                                    &emsp;{item.type}
                                </InputGroup.Text>
                                <Form.Control 
                                    id="basic-url" 
                                    aria-describedby="basic-addon3" 
                                    placeholder={item.type=='tel'?"Put your phoenumber here":"Put your link here"}
                                    onChange={(event)=>{manageArray_Id(newLandlord.contact,item.id,event.target.value,(contact)=>{dispatch(setNewLandlord({...newLandlord,contact}))})}}
                                    value={item.value}
                                />
                                <InputGroup.Text id="basic-addon1"  >
                                    <Button onClick={()=>{setDelete_Modal(true);setSelected(item)}} variant="light" style={{padding:10}} ><i class="bi bi-trash"></i></Button>
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    )
                })}
                
                <Button onClick={()=>{setContact_Modal(true)}} style={{backgroundColor:colors.darkPurple,borderColor:colors.darkPurple}} >Add contact</Button>
            </Col>
        </Row>
        <Row>
            <Col sm='3' >Logo</Col>
            <Col sm='9' >
                <Button onClick={()=>{setCrop_Modal(true)}} variant="light" >
                    <Image style={{width:200,height:200}} roundedCircle src={Translator(newLandlord.imageUrl,'logo')||mainImage.demologo} />
                </Button>
            </Col>
        </Row>
        <Row style={{marginTop:10}} >
            <Col sm='3' >Bussiness Type</Col>
            <Col sm='9' >
            <div onChange={(event)=>{dispatch(setNewLandlord({...newLandlord,enterprise:event.target.value}))}}>
                <Form.Check // prettier-ignore
                    type="radio"
                    value={true}
                    label="นิติบุคคล"
                    name='enterprise'
                    defaultChecked
                />
                <Form.Check // prettier-ignore
                    type="radio"
                    value={false}
                    label="บุคคลธรรมดา"
                    name='enterprise'
                />

            </div>
           
            </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="secondary">Cancel</Button>
        <Button onClick={checkLandlordProfile} variant="primary">Confirm</Button>
    </Modal.Footer>
    </Modal>
  );
}

export default Modal_NewLandlord;
