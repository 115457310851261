import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { db } from "../db/firestore";
import { diffArray, endCutoff, mapInArray, startCutoff, workingDay, findInArray, manageBill, useToDate, convertToInt } from '../Utility/function';
import { NumberYMD, minusDays, stringYMDHMS, stringYMDHMS3 } from '../Utility/dateTime';


const initialState = {
    shops:[],
    modal_Shop:false,
    bills:[],
    billDates:[],
    normalBill:[],
    voidedBill:[],
    graph:[],
    saleByChannel:[],
    payment:[],
    result:[],
    product:[],
    category:[],
    saleMan:[],
    selectedBill:[],
    selectedVoidedBill:[],
    modal_Bill:false,
    products:[],
    shifts:[],
    shiftDates:[],
    modal_Shift:false,
    todayBills:[],
    modal_TodayBill:false,
    selectedShop:{},
    modal_Admin:false,
    profiles:[],
    modal_Profile:false,
}

// fetch bill
export const fetchBill = createAsyncThunk(
  'product/fetchBill',
  async ({ shopId, billDate, cutOff, startDate, endDate }) => {
    let data = [];

    // Split billDate into chunks of 10 elements each
    const chunkSize = 10;
    const billDateChunks = [];
    for (let i = 0; i < billDate.length; i += chunkSize) {
      billDateChunks.push(billDate.slice(i, i + chunkSize));
    }

    // Use Promise.all to make multiple queries
    const promises = billDateChunks.map(async (chunk) => {
      const query = db.collection('bill')
        .where("shopId", "==", shopId)
        .where('billDate', 'in', chunk);

      try {
        const qsnapshot = await query.get();
        if (qsnapshot.docs.length > 0) {
          qsnapshot.forEach((doc) => {
            // data.push({ id: doc.id, ...doc.data(), timestamp: useToDate(doc.data().timestamp) });
            data.push({ id: doc.id, ...doc.data(), timestamp: useToDate(doc.data().timestamp), product:doc.data().product.map((a)=>{return({...a,totalPrice:convertToInt(a.totalPrice)})}) });
          });
        } else {
          console.log('No items found for chunk:', chunk);
        }
      } catch (err) {
        console.error('Error:', err);
      }
    });

    // Wait for all queries to complete
    await Promise.all(promises);
    return { data, billDate, cutOff, startDate, endDate };
  }
);




  // fetch fetchTodayBills
export const fetchTodayBills = createAsyncThunk(
    'admin/fetchTodayBills',
    async () => {
      let data = [];
      await db.collection('bill')
      .where("billDate", "==", stringYMDHMS3(new Date()))
      .get().then((qsnapshot)=>{
        if (qsnapshot.docs.length > 0) {
            qsnapshot.forEach((doc) => {
              data.push({ id: doc.id, ...doc.data(), timestamp: doc.data().timestamp.toDate() });
            });
          } else {
            console.log('No items found ');
          }
      })
      return data;
    }
);

// fetch shops
export const fetchShops = createAsyncThunk(
  'admin/fetchShops',
  async () => {
    let data = [];
      await db.collection('shop')
      .get().then((qsnapshot)=>{
        if (qsnapshot.docs.length > 0) {
            qsnapshot.forEach((doc) => {
              // data.push({  id: doc.id });
              if(doc.data().cutOff){
                data.push({ ...doc.data(), cutOff: doc.data().cutOff.toDate(), id: doc.id });
              }
            });
          } else {
            console.log('No items found ');
          }
      })
      return data;
  }
);



// fetch product
export const fetchProduct = createAsyncThunk(
  'admin/fetchProduct',
  async (id) => {
    let data = []
    await db.collection('product').where("shopId", "==", id).get().then((docs)=>{
      docs.forEach((doc)=>{data.push({id:doc.id,...doc.data(),timestamp:doc.data().timestamp.toDate()})})
    })
    return data;
  }
);


  // fetch shift
export const fetchShift = createAsyncThunk(
  'admin/fetchShift',
  async ({ shopId, shiftDate }) => {
    let data = [];

    // Split shiftDate into chunks of 10 elements each
    const chunkSize = 10;
    const billDateChunks = [];
    for (let i = 0; i < shiftDate.length; i += chunkSize) {
      billDateChunks.push(shiftDate.slice(i, i + chunkSize));
    }

    // Use Promise.all to make multiple queries
    const promises = billDateChunks.map(async (chunk) => {
      const query = db.collection('shiftHistory')
        .where("shopId", "==", shopId)
        .where('openDate', 'in', chunk);

      try {
        const qsnapshot = await query.get();
        if (qsnapshot.docs.length > 0) {
          qsnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data(), openTime: doc.data().openTime.toDate(), closeTime: doc.data().closeTime.toDate()  });
          });
        } else {
          console.log('No items found for chunk:', chunk);
        }
      } catch (err) {
        console.error('Error:', err);
      }
    });

    // Wait for all queries to complete
    await Promise.all(promises);

    return { data, shiftDate };
  }
);




export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    updateModal_Admin: state => {
      state.modal_Admin = !state.modal_Admin
    },
    updateSelectedShop: (state, action) => {
      state.selectedShop = action.payload
      state.bills = []
      state.normalBill = []
      state.voidedBill =[]
      state.billDates = []
      state.shifts = []
    },

    updateDashBoard: (state, action) => {
        const { graph, saleByChannel, payment, result, product , category, saleMan, selectedBill, selectedVoidedBill } = action.payload;
        state.graph = graph
        state.saleByChannel = saleByChannel
        state.payment = payment
        state.result = result
        state.product = product
        state.category = category
        state.saleMan = saleMan
        state.selectedBill = selectedBill
        state.selectedVoidedBill = selectedVoidedBill
    },
    updateBills: (state, action) => {
      const { startDate, endDate, cutOff } = action.payload
      state.selectedBill = state.normalBill.filter((item)=>{return(new Date(item.timestamp) > startCutoff(startDate,new Date(cutOff)) && new Date(item.timestamp) <= endCutoff(endDate,new Date(cutOff)))})
      state.selectedVoidedBill = state.voidedBill.filter((item)=>{return(new Date(item.timestamp) > startCutoff(startDate,new Date(cutOff)) && new Date(item.timestamp) <= endCutoff(endDate,new Date(cutOff)))})
    }, 

  },
  extraReducers: builder => {

    builder.addCase(fetchBill.pending, state => {
      state.modal_Bill = true
    })
    builder.addCase(fetchBill.fulfilled, (state, action) => {
      const { data, billDate, cutOff, startDate, endDate } = action.payload;
        let duplicate = billDate.some((item)=>{return(Number(item)>=NumberYMD(minusDays(new Date(),1)))})
        if(duplicate){
          const { bills, normalBills, voidedBills } = manageBill(state.bills,data)
          state.bills = bills
          state.normalBill = normalBills
          state.voidedBill = voidedBills
        } else {
          state.bills = [...state.bills,...data]
          state.normalBill = [...state.normalBill,...data.filter((item)=>{return(!Boolean(item.void))})]
          state.voidedBill = [...state.voidedBill,...data.filter((item)=>{return(item.void===true )})]
        }
        state.selectedBill = state.normalBill.filter((item)=>{return(new Date(item.timestamp) > startCutoff(startDate,new Date(cutOff)) && new Date(item.timestamp) <= endCutoff(endDate,new Date(cutOff)))})
        state.selectedVoidedBill = state.voidedBill.filter((item)=>{return(new Date(item.timestamp) > startCutoff(startDate,new Date(cutOff)) && new Date(item.timestamp) <= endCutoff(endDate,new Date(cutOff)))})
        state.billDates = [...state.billDates,...billDate.filter((item)=>{return(item < stringYMDHMS3(new Date()))})] 
        state.modal_Bill = false
    })
    builder.addCase(fetchBill.rejected, state => {
        state.modal_Bill = false
    })


    builder.addCase(fetchShops.pending, state => {
      state.modal_Shop = true
    })
    builder.addCase(fetchShops.fulfilled, (state, action) => {
      state.shops = action.payload
      state.modal_Shop = false
    })
    builder.addCase(fetchShops.rejected, state => {
      state.modal_Shop = false
    })
    
    builder.addCase(fetchTodayBills.pending, state => {
        state.modal_TodayBill = true
      })
    builder.addCase(fetchTodayBills.fulfilled, (state, action) => {
      state.todayBills = action.payload
      state.modal_TodayBill = false
    })
    builder.addCase(fetchTodayBills.rejected, state => {
      state.modal_TodayBill = false
    })
    builder.addCase(fetchProduct.pending, state => {
      state.modal_Shop = true
    })
    builder.addCase(fetchProduct.fulfilled, (state, action) => {
      state.products = action.payload
      state.modal_Shop = false
    })
    builder.addCase(fetchProduct.rejected, state => {
      state.modal_Shop = false
    })  
    builder.addCase(fetchShift.pending, state => {
      state.modal_Shift = true
    })
    builder.addCase(fetchShift.fulfilled, (state, action) => {
        const { data, shiftDate } = action.payload;
        let YMD = NumberYMD(minusDays(new Date(),1))
        let findThisDay = shiftDate.some((item)=>{return(Number(item)>=YMD)}) 
        if(findThisDay){
          const thisDayShift = data.filter((item)=>{return(Number(item.openDate )>=YMD)})
          state.shifts = [...diffArray(state.shifts,thisDayShift),...data]
          state.shiftDates = [...state.shiftDates,...shiftDate.filter((item)=>{return(Number(item)<YMD)})]  // เพื่อให้ของวันนี้ เกิดการ refresh เสมอ
        } else {
          state.shifts = [...state.shifts,...data]
          state.shiftDates = [...state.shiftDates,...shiftDate]  
        }
        state.modal_Shift = false
    })
    builder.addCase(fetchShift.rejected, state => {
        state.modal_Shift = false
    })
  }
})

export const { updateModal_Admin, updateDashBoard, 
  updateSelectedShop, updateBills
} = adminSlice.actions

export default adminSlice.reducer