import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { db } from "../db/firestore";
import { NumberYMD, minusDays } from '../Utility/dateTime';
import { diffArray } from '../Utility/function';

const initialState = {
    shifts:[], // 
    modal_Shift:false,
    shiftDates:[],
}

// fetch shift
export const fetchShift = createAsyncThunk(
  'product/fetchShift',
  async ({ shopId, shiftDate }) => {
    let data = [];

    // Split shiftDate into chunks of 10 elements each
    const chunkSize = 10;
    const billDateChunks = [];
    for (let i = 0; i < shiftDate.length; i += chunkSize) {
      billDateChunks.push(shiftDate.slice(i, i + chunkSize));
    }

    // Use Promise.all to make multiple queries
    const promises = billDateChunks.map(async (chunk) => {
      const query = db.collection('shiftHistory')
        .where("shopId", "==", shopId)
        .where('openDate', 'in', chunk);

      try {
        const qsnapshot = await query.get();
        if (qsnapshot.docs.length > 0) {
          qsnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data(), openTime: doc.data().openTime.toDate(), closeTime: doc.data().closeTime.toDate()  });
          });
        } else {
          console.log('No items found for chunk:', chunk);
        }
      } catch (err) {
        console.error('Error:', err);
      }
    });

    // Wait for all queries to complete
    await Promise.all(promises);

    return { data, shiftDate };
  }
);




export const resShiftSlice = createSlice({
  name: 'resBill',
  initialState,
  reducers: {
    updateModal_Shift: state => {
      state.modal_Shift = !state.modal_Shift
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchShift.pending, state => {
      state.modal_Shift = true
    })
    builder.addCase(fetchShift.fulfilled, (state, action) => {
        const { data, shiftDate } = action.payload;
        let YMD = NumberYMD(minusDays(new Date(),1))
        let findThisDay = shiftDate.some((item)=>{return(Number(item)>=YMD)}) 
        if(findThisDay){
          const thisDayShift = data.filter((item)=>{return(Number(item.openDate )>=YMD)})
          state.shifts = [...diffArray(state.shifts,thisDayShift),...data]
          state.shiftDates = [...state.shiftDates,...shiftDate.filter((item)=>{return(Number(item)<YMD)})]  // เพื่อให้ของวันนี้ เกิดการ refresh เสมอ
        } else {
          state.shifts = [...state.shifts,...data]
          state.shiftDates = [...state.shiftDates,...shiftDate]  
        }
        state.modal_Shift = false
    })
    builder.addCase(fetchShift.rejected, state => {
        state.modal_Shift = false
    })
  }
})

export const { updateModal_Shift } = resShiftSlice.actions

export default resShiftSlice.reducer