import React, { useContext } from "react";
import { Container, Row, Col, Button, Modal, Card } from "react-bootstrap";
import { colors } from "../configs";
import '../styles/ShopListScreen.css'
import * as loadingError from "../loading_404Error.json";
import * as loadingData from "../loading.json";

import Lottie from "react-lottie";

function Modal_Loading({
  backdrop=false, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
}) {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      className='loading-screen'
    //   size={size}
    >
      {/* <Modal.Header closeButton>
        Header
      </Modal.Header> */}
      <Modal.Body style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',zIndex:999}}  >
          <Lottie options={defaultOptions} height={140} width={140} />
          Loading...
      </Modal.Body>
      
   
    </Modal>
  );
}

export default Modal_Loading;

