import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { colors, mainImage } from "../configs";
import { FloatingText, InputArea, InputText } from "../components";
import Modal_Contact from "./Modal_Contact";
import { v4 as uuidv4 } from 'uuid';
import Modal_Alert from "./Modal_Alert";
import Modal_CropImage from "./Modal_CropImage";
import { useSelector, useDispatch } from "react-redux";
import { prepareFirebaseImage } from "../db/firestore";
import { addMarketToFirestore, setLoading_Market, setNewMarket, setNewMarket_Modal } from '../redux/marketSlice'
import { Translator, manageArray, manageArray_Id, manageObject } from "../Utility/function";


function Modal_NewMarket({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
}) {
    const dispatch = useDispatch();
    const { newMarket } = useSelector((state)=> state.market)
    const { landlords } = useSelector((state)=> state.landlord)
    const [contact_Modal, setContact_Modal] = useState(false)
    const [selected, setSelected] = useState({})
    const [delete_Modal, setDelete_Modal] = useState(false)
    const [crop_Modal, setCrop_Modal] = useState(false)

  async function checkMarketProfile(){
    
    if(Translator(newMarket.name,'TH') == null){
      alert('กรุณาใส่ชือ market')
    } else if(Translator(newMarket.address,'TH') == null){
      alert('กรุณาใส่ address')
    } else if(newMarket.location.latitude == '' || newMarket.location.longitude == ''){
        alert('กรุณาใส่ location')
    }  else if(Translator(newMarket.imageUrl,'coverPhoto') == null){
      alert('กรุณาเลือกรูปภาพ')
    } else {
      dispatch(setLoading_Market(true))
      const newFormData = { ...newMarket }
      newFormData.timestamp = new Date()
      newFormData.landlordId = landlords[0].id
      let newImageUri = []
      for(const image of newFormData.imageUrl){
        if(image.value.slice(0,5) !== 'https'){
            const imageUrl = await prepareFirebaseImage(image.value,'/market/')
            newImageUri.push({...image,value:imageUrl})
        } else {
          newImageUri.push(image)
        }
      }
      dispatch(addMarketToFirestore({...newFormData,imageUrl:newImageUri}))
    }
  }

  function deleteContact(id){
      let newContact = newMarket.contact.filter((item)=>{return(item.id !==id)})
      dispatch(setNewMarket({...newMarket,contact:newContact}))

  }


  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      fullscreen='xxl-down'
    //   size={size}
    >
      <Modal.Header closeButton>
        <h2><b>Create Your Market</b></h2>
      </Modal.Header>
      <Modal.Body style={{backgroundColor:'transparent'}} >
          <Modal_Contact 
                show={contact_Modal}
                onHide={()=>{setContact_Modal(false)}}
                onClick={(value)=>{
                    dispatch(setNewMarket({...newMarket,contact:[...newMarket.contact,{...value,id:uuidv4()}]}))
                    setContact_Modal(false)}}
            />
            <Modal_Alert
                show={delete_Modal}
                onHide={()=>{setDelete_Modal(false)}}
                onClick={()=>{setDelete_Modal(false);deleteContact(selected.id)}}
                name={selected.type}
            />
            <Modal_CropImage
              show={crop_Modal}
              onHide={()=>{setCrop_Modal(false)}}
              onClick={(value)=>{
                setCrop_Modal(false);
                manageArray(newMarket.imageUrl,'coverPhoto',value,(imageUrl)=>{dispatch(setNewMarket({...newMarket,imageUrl}))})
              }}
              ratio={4/1}
          />
            <Col sm='12'>
                <InputText
                  name='name'
                  placeholder="Market name"
                  onChange={(event)=>{manageArray(newMarket.name,'TH',event.target.value,(name)=>{dispatch(setNewMarket({...newMarket,name}))})}}
                  value={Translator(newMarket.name,'TH')}
                />
            </Col>
            <Col sm='12'>
                <InputArea
                  name='address'
                  placeholder="Market address"
                  onChange={(event)=>{manageArray(newMarket.address,'TH',event.target.value,(address)=>{dispatch(setNewMarket({...newMarket,address}))})}}
                  value={Translator(newMarket.address,'TH')}
                />
            </Col>
        <Row>
            <Col sm='3' >
                Location
            </Col>
            <Col sm='9' >
                <Row>
                    <Col sm='6' >
                        <FloatingText
                            name='latitude'
                            placeholder="Latitude Here"
                            onChange={(event)=>{manageObject(newMarket.location,'latitude',event.target.value,(location)=>{dispatch(setNewMarket({...newMarket,location}))})}}
                            value={newMarket.location.latitude}
                        />
                    </Col>
                    <Col sm='6' >
                        <FloatingText
                            name='longitude'
                            placeholder="Longitude Here"
                            onChange={(event)=>{manageObject(newMarket.location,'longitude',event.target.value,(location)=>{dispatch(setNewMarket({...newMarket,location}))})}}
                            value={newMarket.location.longitude}
                        />
                    </Col>
                </Row>
                
            </Col>
        </Row>
        <Row style={{marginBottom:'1rem'}} >
            <Col sm='3' >
                contact
            </Col>
            <Col sm='9' >
            
                {newMarket?.contact?.map((item,index)=>{
                    return(
                        <Col sm='12' key={index} >
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon3" style={{display:'flex',alignItems:'center',justifyContent:'center'}} >
                                    <img src={item.icon} style={{width:30,height:30}} />
                                    &emsp;{item.type}
                                </InputGroup.Text>
                                <Form.Control 
                                    id="basic-url" 
                                    aria-describedby="basic-addon3" 
                                    placeholder={item.type=='tel'?"Put your phoenumber here":"Put your link here"}
                                    onChange={(event)=>{manageArray_Id(newMarket.contact,item.id,event.target.value,(contact)=>{dispatch(setNewMarket({...newMarket,contact}))})}}
                                    value={item.value}
                                />
                                <InputGroup.Text id="basic-addon1"  >
                                    <Button onClick={()=>{setDelete_Modal(true);setSelected(item)}} variant="light" style={{padding:10}} ><i class="bi bi-trash"></i></Button>
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    )
                })}
                
                <Button onClick={()=>{setContact_Modal(true)}} style={{backgroundColor:colors.darkPurple,borderColor:colors.darkPurple}} >Add contact</Button>
            </Col>
        </Row>
        <Row>
            <Col sm='3' >Cover photo</Col>
            <Col sm='9' >
                <Button onClick={()=>{setCrop_Modal(true)}} variant="light" >
                    <Image style={{width:'100%',maxWidth:400}}  src={Translator(newMarket.imageUrl,'coverPhoto')||mainImage.democoverphoto} />
                </Button>
            </Col>
        </Row>
        <Row style={{marginTop:10}} >
            <Col sm='3' >Bussiness Type</Col>
            <Col sm='9' >
            <div onChange={(event)=>{dispatch(setNewMarket({...newMarket,enterprise:event.target.value}))}}>
                <Form.Check // prettier-ignore
                    type="radio"
                    value={true}
                    label="นิติบุคคล"
                    name='enterprise'
                    defaultChecked
                />
                <Form.Check // prettier-ignore
                    type="radio"
                    value={false}
                    label="บุคคลธรรมดา"
                    name='enterprise'
                />
            </div>
            </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="secondary">Cancel</Button>
        <Button onClick={checkMarketProfile} variant="primary">Confirm</Button>
    </Modal.Footer>
    </Modal>
  );
}

export default Modal_NewMarket;
