import { combineReducers, applyMiddleware } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import logger from 'redux-logger'


import authReducer from './authSlice'
import marketReducer from './marketSlice'
import profileReducer from './profileSlice'
import landLordReducer from './landlordSlice'
import renterSlice from "./renterSlice";
import dailySaleSlice from "./dailySaleSlice";
import orderReducer from './orderSlice';
import resShopReducer from './resShopSlice';
import resBillReducer from './resBillSlice';
import resProfileReducer from './resProfileSlice';
import resProductReducer from './resProductSlice';
import resBomReducer from './resBomSlice';
import resShiftReducer from './resShiftSlice';
import resChoiceReducer from './resChoiceSlice';
import branchReducer from './branchSlice';
import adminReducer from './adminSlice';

  const rootPersistConfig = {
      key: 'root',
      storage,
      // stateReconciler: autoMergeLevel2
      // blacklist: ['counter','market','books','auth','landlord','renter'],
      whitelist:['renter','dailySale']
      
  }

  const marketPersistConfig = {
    key: 'market',
    storage,
    whitelist: ['currentMarket','editMarket']
  };
  const landlordPersistConfig = {
    key: 'landlord',
    storage,
    whitelist: ['currentLandlord']
  };
  const profilePersistConfig = {
    key: 'profile',
    storage,
    whitelist: ['currentProfile']
  };
  const resProfilePersistConfig = {
    key: 'resProfile',
    storage,
    whitelist: ['profile']
  };
  const resShopPersistConfig = {
    key: 'resShop',
    storage,
    whitelist: ['shop']
  };
  const resProductPersistConfig = {
    key: 'resProduct',
    storage,
    whitelist: ['products']
  };
  const resBomPersistConfig = {
    key: 'resBom',
    storage,
    whitelist: ['boms','units']
  };
  const orderPersistConfig = {
    key: 'order',
    storage,
    whitelist: ['products','table','tableData','cart','linkId','orderHistory','type','limitItems']
  };
  const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['currentUser']
  };
  const branchPersistConfig = {
    key: 'branch',
    storage,
    whitelist: ['shops','selectedBranch','selectedShop','profiles']
  };
  const adminPersistConfig = {
    key: 'admin',
    storage,
    whitelist: ['shops','selectedShop']
  };


  const rootReducer = combineReducers({
    // auth:authReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    market: persistReducer(marketPersistConfig, marketReducer),
    profile: persistReducer(profilePersistConfig, profileReducer),
    landlord: persistReducer(landlordPersistConfig, landLordReducer),
    renter: renterSlice,
    dailySale: dailySaleSlice,
    order:persistReducer(orderPersistConfig, orderReducer),
    resShop:persistReducer(resShopPersistConfig, resShopReducer),
    resBill:resBillReducer,
    resProfile: persistReducer(resProfilePersistConfig, resProfileReducer),
    resProduct: persistReducer(resProductPersistConfig, resProductReducer),
    resBom:persistReducer(resBomPersistConfig, resBomReducer),
    resShift:resShiftReducer,
    resChoice:resChoiceReducer,
    // branch:branchReducer,
    branch:persistReducer(branchPersistConfig, branchReducer),
    admin:persistReducer(adminPersistConfig, adminReducer),
    
  })
  const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

  export const store = configureStore({
    reducer: persistedReducer,
    // devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk, logger],
  })
export const persistor = persistStore(store)