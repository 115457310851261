import React from "react";
import { Container, Row, Col, Button, Modal, Card } from "react-bootstrap";
import { FloatingText } from "../components";

function Modal_OneInput({
  backdrop=false, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
  header,
  onChange,
  value,
  placeholder='ตั้งชื่อหมวดหมู่สินค้า',
  onClick
}) {


  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      className='loading-screen'
    //   size={size}
    >
      <Modal.Header closeButton>
        <h3>{header}</h3>
      </Modal.Header>
      <Modal.Body style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',zIndex:999}}  >
        <FloatingText
            name={placeholder}
            placeholder={placeholder}
            onChange={(event)=>{onChange(event.target.value)}}
            value={value}
        />
      </Modal.Body>
      <Modal.Footer>
            <Button onClick={onHide} variant="secondary">Cancel</Button>
            <Button onClick={onClick} variant="success">Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Modal_OneInput;