import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
  Alert
} from "react-bootstrap";
import '../styles/ShopListScreen.css'
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import { colors, mainImage } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { goToTop, searchFilterFunction } from "../Utility/function";
import { stringFullDate, stringFullTime, stringYMDHMS3 } from "../Utility/dateTime";
import { CSVLink } from "react-csv";

function BranchShopListScreen() {
  const dispatch = useDispatch();
  const { shops } = useSelector((state)=> state.branch);

  const [filterDataSoure, setFilterDataSource] = useState(shops) // จำนวนข้อมูลทั้งหมดที่ต้องถูกแสดง
  const [display, setDisplay] = useState([]) // จำนวนที่แสดงในหนึ่งหน้า

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
      setPage(newPage); // start form 0
      goToTop()
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      goToTop()
  };


  useEffect(()=>{
    let fData = filterDataSoure.filter((item,index)=>{return(index >=(page*rowsPerPage) && index <= ((page+1)*rowsPerPage)-1)})
      setDisplay(fData)
  },[page,rowsPerPage,filterDataSoure])

  return (
    <div  >
        <Row >
            <Col sm='6' >
                <Form className="d-flex">
                    <Form.Control
                    type="search"
                    placeholder="ค้นหาร้านค้า"
                    className="me-2 rounded-pill"
                    aria-label="Search"
                    onChange={(event)=>{searchFilterFunction(event.target.value,'name',(value)=>{setFilterDataSource(value);setPage(0)},shops)}}
                    
                    />
                </Form>
            </Col>
          
            <Col sm='6' style={{display:'flex',justifyContent:'flex-end'}} >
                <CSVLink 
                    style={{backgroundColor:'#292929',textDecoration:'none',color:colors.white,padding:10,borderRadius:20,paddingLeft:30,paddingRight:30}}
                    filename={`สาขาทั้งหมด ณ วันที่ ${stringYMDHMS3(new Date())}`}
                    data={
                        shops.map((item)=>{
                            return(
                                {
                                    name:item.name,
                                    registerDate:stringFullTime(item.createdDate),
                                    address:item.address,
                                    tel:item.tel,
                                }
                            )
                        })
                    }
                >
                    Export ร้านทั้งหมด
                </CSVLink>
            </Col>
        </Row>
    <div  >
      <Table    hover  responsive id="table-to-xls" >
        
        <thead>
          <tr>
            <th>ลำดับ</th>
            <th>Store</th>
            <th style={{textAlign:'center'}} >วันที่ลงทะเบียน</th>
            <th style={{textAlign:'center'}} >ที่ตั้ง</th>
            <th style={{textAlign:'center'}} >เบอร์โทร</th>
          </tr>
        </thead>
        <tbody>
        {display.map((item, index) => {
            const { name, address ,tel, createdDate } = item;
            return(
                <tr key={index}  >
                <td>{index+1}.</td>
                <td  >
                  <h6>{name}</h6>
                </td>
                <td style={{textAlign:'center'}}>
                  {stringFullDate(new Date(createdDate))}
                </td>
                <td style={{textAlign:'center'}}>
                  {address}
                </td>
                <td style={{textAlign:'center'}}>
                  {tel}
                </td>
              </tr>
              )
        })}
        </tbody>
      </Table>
      <TablePagination
            component="div"
            count={filterDataSoure.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
        <Outlet/>
        
    </div>
    
  );
}

export default BranchShopListScreen;
