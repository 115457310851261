import React, { useState, useContext } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';

function TookHomeScreen() {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
      alert(selectedIndex)
    };

  return (
    <div style={{height:'100vh'}} >
        {/* <Carousel fade   >
        <Carousel.Item interval={1000} onClick={()=>{alert(1)}} >
        <img
          className="d-block w-100"
          src="https://boxxmusicrecords.com/wp-content/uploads/2016/04/INK_GoldHeart_Web_1920x600.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} onClick={()=>{alert(2)}} >
        <img
          className="d-block w-100"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR-9kAvE73OfjzokSHhWRF-J1MI_HTndjE-g&usqp=CAU"
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={1000} onClick={()=>{alert(3)}} >
        <img
          className="d-block w-100"
          src="https://adaymagazine.com/wp-content/uploads/2021/03/Type-B_Featured-1.jpg"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel> */}
    <div style={{paddingTop:5,paddingBottom:5}} >
          <Form className="d-flex">
              <Form.Control
                  type="search"
                  placeholder='ค้นหาด้วยชื่อสินค้า'
                  className="me-2 rounded-pill"
                  aria-label="Search"
                  onChange={(event)=>{}}
              />
          </Form>
          เรียงสินค้า
          การแสดงผล
      </div>
        TookHomeScreen
    </div>
  );
}

export default TookHomeScreen;
