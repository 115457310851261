export default [
        {id:9,color:'#7750EF'},
        {id:8,color:'#A280EC'},
        {id:7,color:'#D580ED'},
        {id:6,color:'#E2BEff'},
        {id:5,color:'#EAD5FA'},
        {id:4,color:'#FA5B72'},
        {id:3,color:'#FF751F'},
        {id:2,color:'#FFE788'},
        {id:1,color:'#5CE6C1'},
    ]