export const stringDateTime = () => {
    const today = new Date();
    const cDateTime =
        today.getDate().toString().padStart(2,"0") + "/"+
        parseInt(today.getMonth()+1).toString().padStart(2,"0") +"/"+
        today.getFullYear().toString().padStart(4,"0")+" " +
        today.getHours().toString().padStart(2,"0")+":"+
        today.getMinutes().toString().padStart(2,"0")+":"+
        today.getSeconds().toString().padStart(2,"0");

    return cDateTime;  // 27/06/2021 00:25:26
}

export const stringTime = (currentDatetime = new Date()) => {
    const today = currentDatetime;
    const cDateTime =
        // today.getDate().toString().padStart(2,"0") + "/"+
        // parseInt(today.getMonth()+1).toString().padStart(2,"0") +"/"+
        // today.getFullYear().toString().padStart(4,"0");
        today.getHours().toString().padStart(2,"0")+":"+
        today.getMinutes().toString().padStart(2,"0")+":"+
        today.getSeconds().toString().padStart(2,"0");

    return cDateTime;  //00:25:26
}

export const setTimeZero = (date) => {

        let result = date;
        result.setHours(0);
        result.setMinutes(0);
        result.setSeconds(0);
        result.setMilliseconds(0)
    return result;  //for startDate
}
export const setTimeZero2 = (date) => {

        let result = date;
        result.setHours(23);
        result.setMinutes(59);
        result.setSeconds(59);
        result.setMilliseconds(59)
    return result;  //for expireDate
}

export const stringDtNumOnly = () => {
    const today = new Date();
    const cDateTime =
        today.getDate().toString().padStart(2,"0") +
        parseInt(today.getMonth()+1).toString().padStart(2,"0") +
        today.getFullYear().toString().padStart(4,"0")+
        today.getHours().toString().padStart(2,"0")+
        today.getMinutes().toString().padStart(2,"0")+
        today.getSeconds().toString().padStart(2,"0");

    return cDateTime;  // 27062021002526
}

export function getWeek(date = new Date()) {
    var day = date.getDay()
    let startDate = date
    let endDate = date
    if(day == 0){
        endDate = plusDays(date,7)
    } else if(day == 1){
        startDate = minusDays(date,0)
        endDate = plusDays(date,6)
    }else if(day == 2){
        startDate = minusDays(date,1)
        endDate = plusDays(date,5)
    }else if(day == 3){
        startDate = minusDays(date,2)
        endDate = plusDays(date,4)
    }else if(day == 4){
        startDate = minusDays(date,3)
        endDate = plusDays(date,3)
    }else if(day == 5){
        startDate = minusDays(date,4)
        endDate = plusDays(date,2)
    }else {
        startDate = minusDays(date,1)
    }
    let lastDay = getLastDayOfYear(date)
    if(endDate > lastDay){
        endDate = lastDay
    }
    return {startDate:startDate,endDate:endDate}
}

export function getLastDayOfYear(date) {
    const currentYear = date.getFullYear();
    return new Date(currentYear, 11, 31); // 11 represents December (months are 0-indexed)
};

export const stringDateTimeShort = (dateTime) => {
    const today = new Date(dateTime);
    const cDateTime =
        today.getDate().toString().padStart(2,"0") + "/"+
        parseInt(today.getMonth()+1).toString().padStart(2,"0") +"/"+
        today.getFullYear().toString().padStart(4,"0")

    return cDateTime;  // 27/06/2021
}

// export function getWeek() {
//     var day = new Date().getDay()
//     let startDate = new Date()
//     let endDate = new Date()
//     if(day == 0){
//         endDate = plusDays(new Date(),7)
//     } else if(day == 1){
//         startDate = minusDays(new Date(),0)
//         endDate = plusDays(new Date(),6)
//     }else if(day == 2){
//         startDate = minusDays(new Date(),1)
//         endDate = plusDays(new Date(),5)
//     }else if(day == 3){
//         startDate = minusDays(new Date(),2)
//         endDate = plusDays(new Date(),4)
//     }else if(day == 4){
//         startDate = minusDays(new Date(),3)
//         endDate = plusDays(new Date(),3)
//     }else if(day == 5){
//         startDate = minusDays(new Date(),4)
//         endDate = plusDays(new Date(),2)
//     }else {
//         startDate = minusDays(new Date(),1)
//     }
//     return {startDate:startDate,endDate:endDate}
// }


export const stringYMDHMS = (day) => {
    const today = new Date(day);
    const cDateTime =
        today.getFullYear().toString().padStart(4,"0")+
        parseInt(today.getMonth()+1).toString().padStart(2,"0") +
        today.getDate().toString().padStart(2,"0") +
        today.getHours().toString().padStart(2,"0")+
        today.getMinutes().toString().padStart(2,"0")+
        today.getSeconds().toString().padStart(2,"0");

    return cDateTime;  // 20210627002526
}
export const stringYMD = (date) => {
    const cDateTime =
        date.getFullYear().toString().padStart(4,"0")+
        parseInt(date.getMonth()+1).toString().padStart(2,"0") +
        date.getDate().toString().padStart(2,"0") 

    return cDateTime;  // 20210627
};

export const stringYM = (day=new Date()) => {
    let date = new Date(day)
    const cDateTime =
        date.getFullYear().toString().padStart(4,"0")+
        parseInt(date.getMonth()+1).toString().padStart(2,"0")

    return cDateTime;  // 202106
}

export const stringY = (day=new Date()) => {
    let date = new Date(day)
    const cDateTime = date.getFullYear().toString().padStart(4,"0")
    return cDateTime;  // 2021
}

export const NumberYMD = (date) => {
    const cDateTime =
        date.getFullYear().toString().padStart(4,"0")+
        parseInt(date.getMonth()+1).toString().padStart(2,"0") +
        date.getDate().toString().padStart(2,"0") 

    return Number(cDateTime);  // 20210627
}

export const stringYMDHMS3 = (day) => {
    const cDateTime =
        day.getFullYear().toString().padStart(4,"0")+
        parseInt(day.getMonth()+1).toString().padStart(2,"0") +
        day.getDate().toString().padStart(2,"0")

    return String(cDateTime);  // 20210627
}

export function minusDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
}   

export const stringYMDHMS2 = (day) => {
    const cDateTime =
        day.getFullYear().toString().padStart(4,"0")+
        parseInt(day.getMonth()+1).toString().padStart(2,"0") +
        day.getDate().toString().padStart(2,"0")

    return cDateTime;  // 20210627
}

export const stringYMDHMS4 = (day) => {
    const cDateTime =
        day.getFullYear().toString().padStart(4,"0")+"-"+
        parseInt(day.getMonth()+1).toString().padStart(2,"0") +"-"+
        day.getDate().toString().padStart(2,"0")

    return cDateTime;  // 2021-06-27
}

export function plusDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}   //plusDays(new Date(), 14)




export const getMonth = (date) => {
    console.log((date|| new Date()).getMonth())
    return parseInt(new Date().getMonth()+1)
};


export const stringDate = () => {
    const today = new Date();
    const cDate =
        today.getDate() + "/"+
        parseInt(today.getMonth()+1) +"/"+
        today.getFullYear();

    return cDate; // 27/06/2021 
}

export const stringYMD2 = () => {
    const today = new Date();
    const Y = today.getFullYear().toString();
    const preM = parseInt(today.getMonth()+1).toString();
    const M = preM.length === 1 ? '0' + preM :preM;
    const D = today.getDate().toString();

    const YMD = Y + M + D;

    return YMD;
}

export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  export const stringDateTime3 = (today) => {
    // const today = new Date();
    let xx = 10
    if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '01'){
        xx = 'ม.ค.'
    } else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '02'){
        xx = 'ก.พ.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '03'){
        xx = 'มี.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '04'){
        xx = 'เม.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '05'){
        xx = 'พ.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '06'){
        xx = 'มิ.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '07'){
        xx = 'ก.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '08'){
        xx = 'ส.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '09'){
        xx = 'ก.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '10'){
        xx = 'ต.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '11'){
        xx = 'พ.ย.'
    }else{
        xx = 'ธ.ค.'
    }

    let year = Number(today.getFullYear().toString().padStart(4,"0")) + 543
    const cDateTime =
        today.getDate().toString().padStart(2,"0") + "  "+
        xx +" "+
        year+"   " 
    return cDateTime;  // 27/06/2021 
}

export const getHoursMinute = (today) => {
    const day = new Date(today)
    const cDateTime =
        day.getHours().toString().padStart(2,"0")+":"+
        day.getMinutes().toString().padStart(2,"0") 

    return cDateTime;  //00:25 น.
}

export const stringFullTime = (date) => {
    const today = new Date(date);
    let xx = 10
    if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '01'){
        xx = 'ม.ค.'
    } else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '02'){
        xx = 'ก.พ.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '03'){
        xx = 'มี.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '04'){
        xx = 'เม.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '05'){
        xx = 'พ.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '06'){
        xx = 'มิ.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '07'){
        xx = 'ก.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '08'){
        xx = 'ส.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '09'){
        xx = 'ก.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '10'){
        xx = 'ต.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '11'){
        xx = 'พ.ย.'
    }else{
        xx = 'ธ.ค.'
    }

    let year = Number(today.getFullYear().toString().padStart(4,"0")) + 543
    const cDateTime =
        today.getDate().toString().padStart(2,"0") + "  "+
        xx +" "+
        year+"   " +
        today.getHours().toString().padStart(2,"0")+":"+
        today.getMinutes().toString().padStart(2,"0")+":"+
        today.getSeconds().toString().padStart(2,"0");
    return cDateTime;  // 12 /ม.ค. 66 18:35:23
}



export const stringDayMonth = (date) => {
    const today = new Date(date);
    let xx = 10
    if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '01'){
        xx = 'ม.ค.'
    } else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '02'){
        xx = 'ก.พ.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '03'){
        xx = 'มี.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '04'){
        xx = 'เม.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '05'){
        xx = 'พ.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '06'){
        xx = 'มิ.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '07'){
        xx = 'ก.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '08'){
        xx = 'ส.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '09'){
        xx = 'ก.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '10'){
        xx = 'ต.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '11'){
        xx = 'พ.ย.'
    }else{
        xx = 'ธ.ค.'
    }

    const cDateTime = today.getDate().toString().padStart(2,"0") + "  "+xx 
    return cDateTime;  // 12 ม.ค.
}


export const stringFullDate = (today) => {
    // const today = new Date();
    let xx = 10
    if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '01'){
        xx = 'ม.ค.'
    } else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '02'){
        xx = 'ก.พ.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '03'){
        xx = 'มี.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '04'){
        xx = 'เม.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '05'){
        xx = 'พ.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '06'){
        xx = 'มิ.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '07'){
        xx = 'ก.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '08'){
        xx = 'ส.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '09'){
        xx = 'ก.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '10'){
        xx = 'ต.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '11'){
        xx = 'พ.ย.'
    }else{
        xx = 'ธ.ค.'
    }

    let year = Number(today.getFullYear().toString().padStart(4,"0")) + 543
    const cDateTime =
        today.getDate().toString().padStart(2,"0") + "  "+
        xx +" "+
        year+"   " 
    return cDateTime;  // 12/ม.ค./2021 
}

export const daysInMonth_direct = (month, year) => {
    return new Date(parseInt(year), parseInt(month), 0).getDate();
}  // daysInMonth_direct("2000","2") = 29

export const daysInMonth_js = (month, year) => {
    return new Date(parseInt(year), parseInt(month) + 1, 0).getDate();
}   // daysInMonth_direct("2000","1") = 29 , January from javascript = 0


export const dateTodaysInMonth_js = (today) => {
    return new Date(parseInt(today.getYear()), parseInt(today.getMonth()) + 1, 0).getDate();
}  


 
export const stringGetYearMonth = (today) => {
    // const today = new Date();
    let xx = 10
    if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '01'){
        xx = 'ม.ค.'
    } else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '02'){
        xx = 'ก.พ.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '03'){
        xx = 'มี.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '04'){
        xx = 'เม.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '05'){
        xx = 'พ.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '06'){
        xx = 'มิ.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '07'){
        xx = 'ก.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '08'){
        xx = 'ส.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '09'){
        xx = 'ก.ย.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '10'){
        xx = 'ต.ค.'
    }else if(parseInt(today.getMonth()+1).toString().padStart(2,"0") == '11'){
        xx = 'พ.ย.'
    }else{
        xx = 'ธ.ค.'
    }

    let year = Number(today.getFullYear().toString().padStart(4,"0")) + 543
    const cDateTime =
        xx +" "+
        year+"   " 
    return cDateTime;  // 12/ม.ค./2021 
}

export const stringGetYear = (today) => {
    return Number(today.getFullYear().toString().padStart(4,"0")) + 543  // 2021 
}


export const totalDateOfMonth = (today=new Date()) => {
    return new Date(parseInt(today.getYear()), parseInt(today.getMonth()) + 1, 0).getDate();
};

export const firstDayOfMonth = (today=new Date()) =>{
    return new Date(today.getFullYear(), today.getMonth(), 1);
};

export const lastDayOfMonth = (today=new Date()) =>{
    return new Date(today.getFullYear(), today.getMonth()+1, 0);
};


export function createDate(i){
    return new Date(new Date().getFullYear(), new Date().getMonth(), i)
}

export const numberHMS = (date) => {
    const today = new Date(date);
    const cDateTime =
        today.getHours().toString().padStart(2,"0")+
        today.getMinutes().toString().padStart(2,"0")+
        today.getSeconds().toString().padStart(2,"0");

    return Number(cDateTime);  // 002526
};


export function plusMinute(date, minutes) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() + minutes);
    return result;
}   //plusDays(new Date(), 14)
