import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container, Table } from "react-bootstrap";
import ChartScreen from "./ChartScreen";
import { bath, colors, ink, mainImage } from "../configs";
import Modal_Report from "../modal/Modal_Report";
import { useSelector, useDispatch } from "react-redux";
import { endCutoff,  findInArray,  goToTop,  manageDashboard, startCutoff, summary } from "../Utility/function";
import { normalSort, reverseSort } from "../Utility/sort";
import { NumberYMD, firstDayOfMonth, lastDayOfMonth, minusDays, plusDays, stringFullTime, stringYMDHMS, stringYMDHMS3 } from "../Utility/dateTime";
import TablePagination from '@mui/material/TablePagination';
import { Modal_Loading, Modal_ReportReceipt } from "../modal";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { fetchShift } from "../redux/resShiftSlice";
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image, Font  } from '@react-pdf/renderer';
import Sarabun from '../assets/fonts/Sarabun/Sarabun-Regular.ttf';
import Bold from "../assets/fonts/Sarabun/Sarabun-Bold.ttf";

function ResInvoiceScreen() {
  const dispatch = useDispatch();
  const { shop } = useSelector((state)=> state.resShop)
  const { cutOff, channel, smartTable, humanResource, smartCategory, POSs } = shop;
  const { shifts, shiftDates, modal_Shift } = useSelector((state)=> state.resShift)

  const [currentDisplay, setCurrentDisplay] = useState([]) // จำนวนที่แสดงในหนึ่งหน้า
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const today = new Date();
  const [startDate, setStartDate] = useState(today); //some many of day array to initial graph
  const [endDate, setEndDate] = useState(today); //  today
  const [display, setDisplay] = useState([]);
  Font.register({
    family: "Sarabun",
    src: Sarabun,
  });
  Font.register(

    {
      family: "Bold",
      src: Bold,
    }
    );
  
  console.log("shifts")
  console.log(shifts)

  useEffect(()=>{  
    let rewSearch = []
    let newSearch = []
    let start = minusDays(startDate,1)

    do {
      rewSearch.push(stringYMDHMS3(start))
      start = plusDays(start,1)
    }
    while (NumberYMD(start) < NumberYMD(plusDays(endDate,2)));

    for(const item of rewSearch){
      if(!shiftDates.includes(item)){
        newSearch.push(item)
      }
    }
    if(newSearch.length >0){
      dispatch(fetchShift({shopId:shop.id,shiftDate:newSearch}))
    }
 },[startDate,endDate])


 useEffect(()=>{
  setDisplay(shifts.filter((item)=>{return(stringYMDHMS(item.openTime) > stringYMDHMS(startCutoff(startDate,new Date(cutOff))) && stringYMDHMS(item.openTime) <= stringYMDHMS(endCutoff(endDate,new Date(cutOff))))}))
},[startDate, endDate, shifts])

  const handleChangePage = (event, newPage) => {
      setPage(newPage); // start form 0
      goToTop()
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      goToTop()
  };


  useEffect(()=>{
    let fData = display.map((item,index)=>{return({...item,no:index})}).filter((item,index)=>{return(index >=(page*rowsPerPage) && index <= ((page+1)*rowsPerPage)-1)})
      setCurrentDisplay(fData)
  },[page,rowsPerPage,display])

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{borderRadius:20}} onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  // Create Document Component
const MyDocument = () => (
  <Document style={{ fontFamily: "Sarabun" }} >
    {/* Page 1 */}
    <Page size="A4" style={styles.page}>
        <View style={{flexDirection:'row',justifyContent:'space-between',padding:10}} >
            <Image style={{width:100,height:100}} source={ink} />
            <Text style={{fontSize:24}} >ใบเสร็จรับเงิน/ใบกำกับภาษี </Text>
        </View>
        <View style={{flexDirection:'row',justifyContent:'space-between'}} >
            <Text>14/9/2023</Text>
            <View style={{flexDirection:'row',justifyContent:'space-between'}}  >
              <Text>เล่มที่ 003</Text>
              <Text>เลขที่ RC928</Text>
            </View>
        </View>
        <View>
            <Text>ชื่อผู้ขาย</Text>
            <Text>ที่อยู่</Text>
            <View style={{flexDirection:'row',justifyContent:'space-between'}}  >
              <Text>เลขประจำตัวผู้เสียภาษี </Text>
              <Text>โทรศัพท์</Text>
            </View>
        </View>
        <View>
            <Text>ชื่อผู้ซื้อ</Text>
            <Text>ที่อยู่</Text>
            <View style={{flexDirection:'row',justifyContent:'space-between'}}  >
              <Text>เลขประจำตัวผู้เสียภาษี </Text>
              <Text>โทรศัพท์</Text>
            </View>
        </View>
        
        <View style={{borderTopWidth:1,borderLeftWidth:1}} >
            <View style={{flexDirection:'row'}} >
                  <View style={{borderRightWidth:1,borderBottomWidth:1,padding:10,width:'10%'}} >
                      <Text>ลำดับ </Text>
                  </View>
                  <View style={{borderRightWidth:1,borderBottomWidth:1,padding:10,width:'45%',justifyContent:'center',alignItems:'center'}} >
                      <Text>รายการ </Text>
                  </View>
                  <View style={{borderRightWidth:1,borderBottomWidth:1,padding:10,width:'10%',justifyContent:'center',alignItems:'center'}} >
                      <Text>จำนวน </Text>
                  </View>
                  <View style={{borderRightWidth:1,borderBottomWidth:1,padding:10,width:'15%',justifyContent:'center',alignItems:'center'}} >
                      <Text>หน่วยละ </Text>
                  </View>
                  <View style={{borderRightWidth:1,borderBottomWidth:1,padding:10,width:'20%',justifyContent:'center',alignItems:'center'}} >
                      <Text>จำนวนเงิน </Text>
                  </View>
            </View>
            
        </View>
            <View style={{borderLeftWidth:1,borderRightWidth:1,flex:1}} >
              {[1,2,3,4,5].map((item,index)=>{
                return(
                  <View style={{flexDirection:'row'}} key={index} >
                    <Text style={{width:'10%',textAlign:'center'}} >1</Text>
                    <Text style={{width:'45%',paddingLeft:10}} >ส้มตำปลาร้า </Text>
                    <Text style={{width:'10%',textAlign:'center'}} >1 </Text>
                    <Text style={{width:'15%',textAlign:'center'}} >359.00 </Text>
                    <Text style={{width:'20%',textAlign:'center'}} >718.00 </Text>
                  </View>
                )
              })}
              

            </View>
            <View style={{flexDirection:'row',borderTopWidth:1,borderLeftWidth:1}} >
                  <View style={{borderRightWidth:1,borderBottomWidth:1,padding:10,width:'80%',alignItems:'flex-end'}} >
                      <Text>มูลค่ารวมก่อนเสียภาษี</Text>
                      <Text>ภาษีมูลค่าเพิ่ม(VAT)</Text>
                      <Text style={{ fontFamily: "Bold" ,marginTop:10}} >ยอดรวม</Text>
                  </View>
                  <View style={{borderRightWidth:1,borderBottomWidth:1,padding:10,width:'20%',alignItem:'center'}} >
                      <Text style={{alignSelf:'center'}} >934.58</Text>
                      <Text style={{alignSelf:'center'}} >65.42</Text>
                      <Text style={{alignSelf:'center', marginTop:10, fontFamily: "Bold" }} >1,000</Text>
                  </View>
            </View>
      </Page>
  </Document>
);


  return (
    // <div className="background"  >
    <div  >
      <Modal_Loading show={modal_Shift} />
      
      <h1>PDF Generation Example</h1>
      <PDFDownloadLink document={<MyDocument />} fileName="example.pdf">
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Download PDF'
        }
      </PDFDownloadLink>
      <PDFViewer style={{width:'1200px',height:'1000px'}} >
        <MyDocument />
      </PDFViewer>
      {/* <Row >
          <Col lg='4'  >
              <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
                <img style={{width:30}} src={mainImage.calender} />
                <div style={{paddingLeft:10,paddingRight:10}} >  Start: </div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<ExampleCustomInput />}
                />
              </div>
          </Col>
          <Col lg='4'  >
            <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
              <img style={{width:30}} src={mainImage.calender} />
              <div style={{paddingLeft:10,paddingRight:10}} >  End: </div>
              <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  customInput={<ExampleCustomInput />}
              />
            </div>
          </Col>
          <Col lg='4' style={{padding:10}} >
            <CSVLink 
              style={{backgroundColor:'#292929',textDecoration:'none',color:colors.white,padding:10,borderRadius:20,paddingLeft:30,paddingRight:30}}
              filename={`รายงานกะ${stringYMDHMS3(startDate)}-${stringYMDHMS3(endDate)}`}
              data={
                display.map((item)=>{return({
                      POS:findInArray(POSs,'id',item.posId)?.name||item.posId,
                      เวลาเปิดกะ:stringFullTime(item.openTime),
                      เวลาปิดกะ:stringFullTime(item.closeTime),
                      คนเปิดกะ:findInArray(humanResource,'id',item.openBy)?.name||'ไม่พบข้อมูล',
                      คนปิดกะ:findInArray(humanResource,'id',item.closeBy)?.name||'ไม่พบข้อมูล',
                      ยอดเปิดกะ:item.openCash,
                      ยอดปิดกะ:item.closeCash,
                      ยอดถอนออก:item.closeWithDraw,
                      เงินขาดเกิน:item.closeDiff,
                    })})
              }
            >
                Export
            </CSVLink>
          </Col>
          
      </Row> */}
              {/* <Row>
                <Table striped bordered hover responsive  variant="light"   >
                  <thead  >
                    <tr>
                      <th style={{ width: '5%' }}>No.</th>
                      <th style={{ width: '15%' }}>POS</th>
                      <th style={{ width: '10%' }}>เวลาเปิดกะ</th>
                      <th style={{ width: '10%' }}>เวลาปิดกะ</th>
                      <th style={{ width: '10%' }}>ยอดเปิดกะ</th>
                      <th style={{ width: '10%' }}>ยอดปิดกะ</th>
                      <th style={{ width: '10%' }}>ยอดถอนออก</th>
                      <th style={{ width: '10%' }}>เงินขาด/เกิน</th>
                    </tr>
                  </thead>
                  <tbody  >
                    {currentDisplay.map((item, index) => (
                      <tr  style={{cursor: 'pointer'}} key={index} >
                        <td>{item.no+1}.</td>
                        <td >{findInArray(POSs,'id',item.posId)?.name||item.posId}</td>
                        <td >{stringFullTime(item.openTime)}</td>
                        <td >{stringFullTime(item.closeTime)}</td>
                        <td >{Number(item.openCash).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                        <td >{Number(item.closeCash).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                        <td >{Number(item.closeWithDraw).toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                        <td >{item.closeDiff.toLocaleString(undefined, {minimumFractionDigits: 2,maximumFractionDigits: 2})}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <TablePagination
                    component="div"
                    count={display.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Row> */}
    </div>
  );
}

// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: "Sarabun",
        fontSize: 12,
        flexDirection: 'column',
        padding: 30,
        width:'1000px',
        height:'1500px'
      },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

export default ResInvoiceScreen;
