import React, { useContext } from "react";
import { Container, Row, Col, Button, Modal, Card } from "react-bootstrap";
import '../styles/ShopListScreen.css'
import * as loadingpanda from '../loadingpanda.json'
import { ink, colors, mainImage } from "../configs";
import { pushByOneFilter, pushByTwoFilter } from '../api/onesignal';
import { useSelector, useDispatch } from "react-redux";
import { addRequestTofirebase } from "../redux/orderSlice";



function Modal_Request({
  backdrop=true, // true/false/static
  animation=true,
  show,
  onHide,
  centered=true,
  size='lg',
}) {
  const dispatch = useDispatch();

  const { tableData, products, orderHistory, preOrder_Modal } = useSelector((state)=> state.order);

  const { shopName, shopLogo, tableName, smartCategory, smartPreOrder, 
    shopId, scShopId, tableId, mainId, smartOption, smartBuffet, smartStep,
    serviceType, smartRequest, smartTask, smartChef } = tableData;


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingpanda.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };



  // function sentRequest({taskId,name,tableName,tableId}){
  function sentRequest({taskId,name,tableName,tableId}){

    dispatch(addRequestTofirebase({
      shopId,
      taskId,
      name,
      tableId,
      staffId:'',
      tableName,
      timestamp:new Date()
    })).then(()=>{
        if(smartTask){
            for(const item of smartTask){
                pushByTwoFilter({id:item,shopId,content:name,heading:tableName})
            }
        }
    })
  }

  return (
    <Modal
      backdrop={backdrop}
      animation={animation}
      show={show}
      onHide={onHide}
      centered={centered}
      className='loading-screen'
    //   size={size}
    >

      <Modal.Body 
      style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',zIndex:999,background:colors.transparent,borderRadius:30}}  
      >
          <Row >
              {smartRequest.map((item,index)=>{
                const { name, imageId, id } = item;
                return(
                  <Col key={index} 
                  onClick={()=>{onHide();sentRequest({taskId:id,name,tableName,tableId})}}  lg='6' xs='6'  >
                      <div style={{display:'flex',flexDirection:'column',alignItems:'center',cursor:'pointer',padding:10,margin:10}} >
                          <img style={{width:70,height:70,borderRadius:20}} src={imageId||mainImage.reslogo} />
                          <h6>{name}</h6>
                      </div>
                  </Col>
                )
              })}
          </Row>

      </Modal.Body>
      
   
    </Modal>
  );
}

export default Modal_Request;

