import React, { useState, useEffect, useMemo, useRef } from "react";
import { Row, Col, Table, Button } from "react-bootstrap";
import { colors, mainImage } from "../configs";
import { useSelector, useDispatch } from "react-redux";
import { createLabel, findInArray,  goToTop, haveCommonElement, summary } from "../Utility/function";
import { normalSort } from "../Utility/sort";
import { NumberYMD, minusDays, plusDays, stringFullDate, stringYMDHMS3 } from "../Utility/dateTime";
import TablePagination from '@mui/material/TablePagination';
import { Modal_Loading } from "../modal";
import DatePicker from "react-datepicker";
import { forwardRef } from "react";
import { fetchBill, updateBills } from "../redux/resBillSlice";
import { CSVLink } from "react-csv";
import Dropdown from 'react-bootstrap/Dropdown';
import html2canvas from 'html2canvas';
import DoubleChart from "../components/DoubleChart";

const options = [
    {id:1,name:'แบบย่อ'},
    {id:2,name:'แบบเต็ม'},
];

const translates = {
    '1':'01:00 น.',
    '2':'02:00 น.',
    '3':'03:00 น.',
    '4':'04:00 น.',
    '5':'05:00 น.',
    '6':'06:00 น.',
    '7':'07:00 น.',
    '8':'08:00 น.',
    '9':'09:00 น.',
    '10':'10:00 น.',
    '11':'11:00 น.',
    '12':'12:00 น.',
    '13':'13:00 น.',
    '14':'14:00 น.',
    '15':'15:00 น.',
    '16':'16:00 น.',
    '17':'17:00 น.',
    '18':'18:00 น.',
    '19':'19:00 น.',
    '20':'20:00 น.',
    '21':'21:00 น.',
    '22':'22:00 น.',
    '23':'23:00 น.',
    '0':'00:00 น.',
}
function ResReportByReceipt() {
  const dispatch = useDispatch();
  const { shop } = useSelector((state)=> state.resShop)
  const { cutOff, smartReceipt } = shop;
  const {billDates, modal_Bill, selectedBill } = useSelector((state)=> state.resBill)
  const { products } = useSelector((state)=> state.resProduct)
  const [currentDisplay, setCurrentDisplay] = useState([]) // จำนวนที่แสดงในหนึ่งหน้า
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const today = new Date();
  const [startDate, setStartDate] = useState(today); //some many of day array to initial graph
  const [endDate, setEndDate] = useState(today); //  today
  const [option, setOption] = useState({id:1,name:'แบบย่อ'})


    useEffect(()=>{  
    let rewSearch = []
    let newSearch = []
    let start = minusDays(startDate,1)

    do {
        rewSearch.push(stringYMDHMS3(start))
        start = plusDays(start,1)
    }
    while (NumberYMD(start) < NumberYMD(plusDays(endDate,2)));

    for(const item of rewSearch){
        if(!billDates.includes(item)){
        newSearch.push(item)
        }
    }
    if(newSearch.length >0){
        dispatch(fetchBill({shopId:shop.id,billDate:newSearch,cutOff,startDate,endDate}))
    } else {
        dispatch(updateBills({startDate,endDate,cutOff}))
    }
    
    },[startDate,endDate])

    const { display, datasets, bar } = useMemo(()=>{
        let display = []
        const { datasets, finalProduct } = manageData(selectedBill)
        if(option.id===1){
            display = datasets
        } else {
            display = finalProduct
        }
        let bar = smartReceipt.value.map(a=>a.name)
        return { display, datasets, bar }
    },[selectedBill,option,smartReceipt])

    function saperateProduct(bills){
        let allProduct = bills.flatMap(a=>a.product).filter(b=>b.precess!=='cancel')
        let display = smartReceipt.value.map((item)=>{
            let newProduct = []
            for(const a of allProduct){
                const { category } = findInArray(products,'id',a.id)||{category:[]};
                if(haveCommonElement(item.category,category)){
                    newProduct.push(a)
                }
            }
            return{
                id:item.id,
                name:item.name,
                totalPrice:summary(newProduct,'totalPrice'),
                product:newProduct
            }
        })

        const result = display.reduce((acc, item) => {
            acc[item.name] = item.totalPrice;
            return acc;
        }, {});
        return result
    }


function manageData(bills){
    
    const productWithHour = bills.flatMap(item => 
        item.product.filter(b=>b.process!=='cancel').map(({ id, name, qty, totalPrice}) => ({
            id,
            name,
            qty,
            totalPrice,
            hour: new Date(item.timestamp).getHours().toString(),
            hourName:translates[new Date(item.timestamp).getHours().toString()]
        }))
    );
    let secondProduct = []

    for(const item of productWithHour){
        let thisProduct = secondProduct.find(a=>a.hour===item.hour && a.id===item.id)
        if(thisProduct && thisProduct.id){
            thisProduct.totalPrice += item.totalPrice
            thisProduct.qty += item.qty
        } else {
            secondProduct.push(item)
        }
    }
    let sortProduct = normalSort('totalPrice',secondProduct)
    let finalProduct = []
    let datasets = [] // ยอดขายตามหมวดหมู่ใบเสร็จ
      let labels = createLabel(new Date(cutOff).getHours())
      for (const item of labels) {
        let arr = selectedBill.filter((a)=>{return(new Date(a.timestamp).getHours()==item)})
        datasets.push({name:translates[item],...saperateProduct(arr)})
        finalProduct.push(...sortProduct.filter(a=>a.hour===item))
    }
    return { datasets, finalProduct }
}

  const handleChangePage = (event, newPage) => {
      setPage(newPage); // start form 0
      goToTop()
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      goToTop()
  };


  useEffect(()=>{
    let fData = display.map((item,index)=>{return({...item,no:index})}).filter((item,index)=>{return(index >=(page*rowsPerPage) && index <= ((page+1)*rowsPerPage)-1)})
      setCurrentDisplay(fData)
  },[page,rowsPerPage,display])

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div style={{borderRadius:20}} onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  const displayRef = useRef(null);

  const handleCapture = () => {
    if (displayRef.current) {
      html2canvas(displayRef.current)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.href = imgData;
          link.download = `ยอดขายตามหมวดหมู่ใบเสร็จ ${stringFullDate(startDate)} - ${stringFullDate(endDate)}.png`;
          link.click();
        });
    }
  };


  return (
    <div  >
      <Modal_Loading show={modal_Bill} />

      <h1>ยอดขายตามหมวดหมู่ใบเสร็จ</h1>
      <Row >
          <Col lg='4'  >
              <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
                <img style={{width:30}} src={mainImage.calender} />
                <div style={{paddingLeft:10,paddingRight:10}} >  Start: </div>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<ExampleCustomInput />}
                />
              </div>
          </Col>
          <Col lg='4'  >
            <div style={{display:'flex',padding:5,borderRadius:10,border: '1px solid grey',backgroundColor:colors.white,marginBottom:10}} >
              <img style={{width:30}} src={mainImage.calender} />
              <div style={{paddingLeft:10,paddingRight:10}} >  End: </div>
              <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  customInput={<ExampleCustomInput />}
              />
            </div>
          </Col>
          <Col lg='4' style={{padding:10}} >
          <Button onClick={handleCapture} variant="success" >
                Download Graph
            </Button>
          </Col>
          
      </Row>
      <Row ref={displayRef} style={{backgroundColor:colors.white,borderRadius:20,marginLeft:0,marginRight:0,marginBottom:20}} >
          <Col  lg={12} >
            <div style={{backgroundColor:'white',margin:5,padding:10,borderRadius:20,height:'400px',marginRight:0}} >
              <DoubleChart data={datasets} bar={bar} />
            </div>
          </Col>
      </Row>
      <Row>
        <Col>
            <CSVLink 
              style={{backgroundColor:'#292929',textDecoration:'none',color:colors.white,padding:10,borderRadius:20,paddingLeft:30,paddingRight:30}}
              filename={`ยอดขายตามหมวดหมู่ใบเสร็จ[${option.name}]${stringYMDHMS3(startDate)}-${stringYMDHMS3(endDate)}`}
              data={
                option.id===1
                    ?display.map((item)=>{return({
                      เวลา:item.name,
                        จำนวนบิล:item.billQty,
                        ยอดขาย:item.ยอดขาย,
                        จำนวนลูกค้า:item.customerQty,
                        ยอดขายต่อหัว:item.billQty>0?item.ยอดขาย/item.customerQty:0,
                      })})
                      
                    :display.map((item)=>{return({
                        เวลา:item.hourName,
                        ชื่อ:item.name,
                        จำนวน:item.qty,
                        ยอดขาย:item.totalPrice,
                      })})
              }
            >
                Export CSV
            </CSVLink>
        </Col>
      </Row>
        
            
        {option.id===1
              ?<Row>
                <Table striped bordered hover responsive  variant="light"   >
                    <thead  >
                    <tr>
                      <th style={{ width: '10%' }}>No.</th>
                      <th style={{ width: '50%' }}>เวลา</th>
                      {smartReceipt.value.map((item,index)=>{
                        return(
                            <th key={index} style={{ width: '20%' }}>{item.name}</th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody  >
                    {currentDisplay.map((item, index) => (
                      <tr  style={{cursor: 'pointer'}} key={index} >
                        <td>{item.no+1}.</td>
                        <td >{item.name}</td>
                        {smartReceipt.value.map((a,index)=>{
                            return(
                                <th key={index} style={{ width: '20%' }}>{item[a.name]}</th>
                            )
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <TablePagination
                    component="div"
                    count={display.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Row>
              :<Row>
                <Table striped bordered hover responsive  variant="light"   >
                  
                  <thead  >
                    <tr>
                      <th style={{textAlign:'center', width: '10%'}} >No.</th>
                      <th style={{textAlign:'center'}} >เวลา</th>
                      <th style={{textAlign:'center'}}>ชื่อ</th>
                      <th style={{ width: '20%' }}>จำนวน</th>
                      <th style={{ width: '20%' }}>ยอดขาย</th>
                    </tr>
                  </thead>
                  <tbody  >
                    {currentDisplay.map((item, index) => (
                      <tr  style={{cursor: 'pointer'}} key={index} >
                        <td>{item.no+1}.</td>
                        <td >{item.hourName}</td>
                        <td style={{textAlign:'center'}}>{item.name}</td>
                        <td style={{textAlign:'center'}}>{item.qty}</td>
                        <td style={{textAlign:'center'}}>{item.totalPrice}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <TablePagination
                    component="div"
                    count={display.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Row>
          }
    </div>
  );
}

export default ResReportByReceipt;
