import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfile, updateFieldProfile } from "../redux/resProfileSlice";
import { AiOutlineFieldTime } from "react-icons/ai";
import { filterDeleteInArray, findInArray, getUniqueArray } from "../Utility/function";
import { colors, mainImage, resInitialCurrentLogin } from "../configs";
import { stringDateTime3 } from "../Utility/dateTime";
import { BrowserRouter as Router, Routes, Route, Link, Navigate, Outlet, NavLink, useNavigate } from 'react-router-dom';
import { fetchShop } from "../redux/resShopSlice";
import { Modal_Loading } from "../modal";
import { fetchProduct } from "../redux/resProductSlice";
import { fetchBom } from "../redux/resBomSlice";

function ResHomeScreen() {
  const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state)=> state.auth);
    const { profile, modal_Profile } = useSelector((state)=> state.resProfile);
    const { modal_Shop } = useSelector((state)=> state.resShop);
    const { port } = profile
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        dispatch(fetchProfile({token:currentUser.uid,uniqueId:'444'})).then((res)=>{
            if(res.payload){
            const { port } = res.payload;
            // console.log(port)
            // console.log(currentUser.uid)
            }
        })
    },[])

    function backToShop(data){
      setLoading(true)
        dispatch(fetchShop(data.shopId)).then((res)=>{
          console.log(res.payload)
          const { humanResource, position, name, id, branch } = res.payload;
          let stillHaveRight = humanResource.find((a)=>{return(a.id == profile.id && a.status === 'active')})
          if(stillHaveRight && stillHaveRight.id){
            let rawRights = []
            for(const positionId of stillHaveRight.position){
              let findPosition = findInArray(position,'id',positionId);
              if(findPosition && findPosition.id){
                rawRights = rawRights.concat(findPosition.rights)
              }
            }
            let newRights = getUniqueArray(rawRights)
            if(newRights.includes('800')){
              let newCurrentLogin = {
                shopId:id,
                printer:data.printer,
                rights:newRights
              }
              let updatePort = {
                ...data,
                shopName:name,
                position:stillHaveRight.position,
                lastLogin:new Date()
              }
              let newPort = port.map((a)=>{
                return a.shopId == id
                          ?updatePort
                          :a
              })
              Promise.all(
                [
                    dispatch(updateFieldProfile({doc:profile.id,field:{port:newPort,currentLogin:newCurrentLogin}})),
                    dispatch(fetchProduct(id)),
                    dispatch(fetchBom(id)),                  
                ]
            )
            .catch(() => {
                setLoading(false)
                alert("Failed to update account")
            })
            .finally(() => {
              setLoading(false)
              navigate("/restaurant")
            }) 
            } else {
              alert('คุณไม่ได้รับสิทธิ์เข้าถึงรายงาน\nเข้าถึงได้โดยเพิ่มสิทธิ์ในแอป หัวข้อ dashboard')
            }
            
          } else {
            dispatch(updateFieldProfile({
              doc:profile.id,field:{
                // port:filterDeleteInArray([...port,'shopId',id]),
                port:filterDeleteInArray([...port],'shopId',id),
                currentLogin:resInitialCurrentLogin
              }
            })).then(()=>{
              alert('คุณไม่ได้รับสิทธิ์เข้าถึงร้านค้านี้')
            })
          }
        })
      }
    
  return (
    <div style={{backgroundColor:colors.white,height:'100vh'}} >
        <Modal_Loading show={loading} />
        <div style={{backgroundColor:colors.white,display:'flex',justifyContent:'center'}} >
            <h1>เลือกร้านอาหาร</h1>
        </div>
        <div style={{height:'100vh',marginLeft:20,marginRight:20,borderTopLeftRadius:20,borderTopRightRadius:20}} >
            <Row style={{padding:20,margin:20,backgroundColor:colors.lightGray,borderRadius:20}} >
                {port.map((item,index)=>{
                    return(
                        <Col key={index} xs='12' md='6' lg='4' onClick={()=>{backToShop(item)}} style={{cursor:'pointer'}} >
                            <div style={{borderRadius:30,padding:10,backgroundColor:colors.white,margin:'10px 0px 10px 0px',display:'flex'}} >
                                <img style={{width:100,height:100,borderRadius:20}} src={findInArray(item?.imageUrl||[],'type','logo')?.value || mainImage.reslogo} />
                                <div>
                                    <h6 style={{fontSize:24}} >{item.shopName}</h6>
                                    <h6 >สาขา : {item.branch} </h6>
                                    <div style={{flexDirection:'row',marginTop:17,width:'100%',justifyContent:'flex-start',alignItems:'center'}} >
                                    <AiOutlineFieldTime style={{color:colors.primaryColor, fontSize: '1rem'}} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </div>
        
    </div>
  );
}

export default ResHomeScreen;
