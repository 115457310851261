import firebase from 'firebase/app'
import 'firebase/firestore'
import  'firebase/auth'
import 'firebase/storage'
import { getStorage, ref, uploadBytes } from "firebase/storage";
// Note! : firebase V9 are more change syntax and not different in feature.so,use V8 and change to V9 later .....
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
// import 'firebase/compat/storage';


import {stringYMDHMS}from '../Utility/dateTime'

// // old, shopcham-brand
// const firebaseConfig = {
//     apiKey: "AIzaSyAQDCXX7p-vOMHVEAlUpDgQmh382ulvfMA",
//     authDomain: "shopcham-24b0b.firebaseapp.com",
//     projectId: "shopcham-24b0b",
//     storageBucket: "shopcham-24b0b.appspot.com",
//     messagingSenderId: "955470064375",
//     appId: "1:955470064375:web:46d333dd232ee016af01f2",
//     measurementId: "G-DS6JW927RY"
// };

// shopchamp-erp
const firebaseConfig = {
  apiKey: "AIzaSyDIrSUcUCUrdgCZ6xRTiw9kd4KGG5J609Q",
  authDomain: "shopchamp-restaurant.firebaseapp.com",
  projectId: "shopchamp-restaurant",
  storageBucket: "shopchamp-restaurant.appspot.com",
  messagingSenderId: "845480287943",
  appId: "1:845480287943:web:821115f96c08d7406428e2",
  measurementId: "G-4GEN2V2CEL"
};
  
  //hash for void error, load firebase only once
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  
  const db = firebase.firestore()
  const firebaseAuth = firebase.auth()
  const firebaseStorage = firebase.storage()

  export {db,firebaseAuth,firebaseStorage}

  export const fetchProfile = (token) => {
    const promise = new Promise((resolve,reject)=>{
      db
      .collection('profile')
      .doc(token)
      .get()
      .then((doc)=>{
        let data = doc.data()
        return {...data,timestamp:data.timestamp.toDate(),id:doc.id}
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
    });
    
    return promise
  }

  

  export const updateProfile = (id,data) =>{
    const promise = new Promise(async (resolve,reject)=>{
      let copyData = {...data}
      delete copyData.id
      db.collection('profile').doc(id).update(copyData)
      .then(()=>{
        resolve(data)
      })
      .catch(err=>{reject(err);console.log('Error updateProfile',err);})
    })
    return promise
  }

  export const addProfile = (object) =>{
    const promise = new Promise(async(resolve,reject)=>{
      db.collection('profile').add(object)
      .then(function(doc){ 
        return {...object,id:doc.id}
      })
      .then(res => resolve(res))
      .catch(err=>{reject(err);console.log('Error addProfile',err);})
    })
  
    return promise
  }

  export const fetchLandlord = (email) => {
    const promise = new Promise((resolve,reject)=>{
      db
      .collection('landlord')
      .where("humanResourceEmail", "array-contains", email)
      .get()
      .then((docs)=>{
        let res = []
        docs.forEach((doc)=>{
          res.push({...doc.data(),timestamp:doc.data().timestamp.toDate(),id:doc.id})
        })
        return res
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
    });
    
    return promise
  }
  
  
  
  export const updateLandlord = (id,data) =>{
    const promise = new Promise(async (resolve,reject)=>{
      let copyData = {...data}
      delete copyData.id
      db.collection('landlord').doc(id).update(copyData)
      .then(()=>{
        resolve(data)
      })
      .catch(err=>{reject(err);console.log('Error updateLandlord',err);})
    })
    return promise
  }

  export const addLandlord = (object) =>{
    const promise = new Promise(async(resolve,reject)=>{
      db.collection('landlord').add(object)
      .then(function(doc){ 
        return {...object,id:doc.id}
      })
      .then(res => resolve(res))
      .catch(err=>{reject(err);console.log('Error addLandlord',err);})
    })
  
    return promise
  }
  

  export const fetchMarket = (token) => {
    const promise = new Promise((resolve,reject)=>{
      db
      .collection('market')
      .where("landlordId", "==", token)
      .get()
      .then((docs)=>{
        let res = []
        docs.forEach((doc)=>{
          res.push({...doc.data(),timestamp:doc.data().timestamp.toDate(),id:doc.id})
        })
        return res
      })
      .then(res => resolve(res))
      .catch(err => reject(err))
    });
    
    return promise
  }
  
  
  
  export const updateMarket = (id,data) =>{
    const promise = new Promise(async (resolve,reject)=>{
      let copyData = {...data}
      delete copyData.id
      db.collection('market').doc(id).update(copyData)
      .then(()=>{
        resolve(data)
      })
      .catch(err=>{reject(err);console.log('Error updateMarket',err);})
    })
    return promise
  }

  export const addMarket = (object) =>{
    const promise = new Promise(async(resolve,reject)=>{
      db.collection('market').add(object)
      .then(function(doc){ 
        return {...object,id:doc.id}
      })
      .then(res => resolve(res))
      .catch(err=>{reject(err);console.log('Error addMarket',err);})
    })
  
    return promise
  }
  

export const prepareFirebaseImage = async (imageData,storageRef,oldUrltoDelete=null) => { // auto check format and prepare URL to send image file and get URL
  // Example to use ...
  // 'add file only':
  //    const imageIdUrl = await prepareFirebaseImage(editBrandProfile.imageId,'/brandProfile/')
  // 'update file (add & delete old file) :
  //    const oldUrltoDelete = await db.collection('productCobrand').doc(docId).get().then(doc => doc.data().imageId) //get one object pattern
  //    const imageIdUrl = await prepareFirebaseImage(editBrandProfile.imageId,'/brandProfile/',oldUrltoDelete)
  
  // alert('prepareFirebaseImage')

  let imageUrl = ''
  // if(imageData?.name == undefined){ // detect "URL" format
  //     imageUrl = imageData
  // }else{ // detect "BLOB" format
      // alert('else prepareFirebaseImage')
      // const fileName = stringYMDHMS()+imageData.name // event.target.files[0].name ,add datetime to prevent instead old file in firebase storage
      const fileName = stringYMDHMS() // event.target.files[0].name ,add datetime to prevent instead old file in firebase storage
      // await firebaseStorage.ref(storageRef+fileName).put(imageData)  //eg. ref = 'brandProfile/pepsiCanPicture';
      // firebaseStorage.ref(storageRef+fileName).putString(imageData, 'data_url', { contentType: 'image/jpeg' });
      await firebaseStorage.ref(storageRef+fileName+'.png').putString(imageData, 'data_url', { contentType: 'image/png' });
      imageUrl = await firebaseStorage.ref(storageRef+fileName+'.png').getDownloadURL();

      // imageUrl = await firebaseStorage.ref(storageRef+fileName).getDownloadURL()

      // if(oldUrltoDelete){   // delete old file in firebase storage
      // const oldFileRef = await firebaseStorage.refFromURL(oldUrltoDelete).fullPath
      // firebaseStorage.ref(oldFileRef).delete()
      // }
  // }

  // console.log('refFromURL()',await firebaseStorage.refFromURL(imageUrl).fullPath)
  return imageUrl
}


export const webImageDelete = async (oldUrltoDelete=null) => {
  const oldFileRef = await firebaseStorage.refFromURL(oldUrltoDelete).fullPath;
  // alert(oldFileRef);
  
  firebaseStorage.ref(oldFileRef).getDownloadURL()
    .then(()=>{
      firebaseStorage.ref(oldFileRef).delete();
      // alert('success delete file in firebaseStorage!')
    })
    .catch((err)=>{
      console.log('oldUrltoDelete file does not exits!')
      // alert('oldUrltoDelete file does not exits!')
      console.log(err);
    }); 

}

// const deleteFirebaseStorage = async (oldUrltoDelete='') => {
//   const oldFileRef = await firebaseStorage.refFromURL(oldUrltoDelete).fullPath;
//   // alert(oldFileRef);
  
//   firebaseStorage.ref(oldFileRef).getDownloadURL()
//     .then(()=>{
//       firebaseStorage.ref(oldFileRef).delete();
//       // alert('success delete file in firebaseStorage!')
//     })
//     .catch((err)=>{
//       console.log('oldUrltoDelete file does not exits!')
//       // alert('oldUrltoDelete file does not exits!')
//       console.log(err);
//     });   
// }





export const stringDateTime = (dateTime) => {
  const today = dateTime;
  const cDateTime =
      today.getDate().toString().padStart(2,"0") + "/"+
      parseInt(today.getMonth()+1).toString().padStart(2,"0") +"/"+
      today.getFullYear().toString().padStart(4,"0")+" " +
      today.getHours().toString().padStart(2,"0")+":"+
      today.getMinutes().toString().padStart(2,"0")+":"+
      today.getSeconds().toString().padStart(2,"0");

  return cDateTime;  // 27/06/2021 00:25:26
}

  export function minusDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() - days);
      return result;
    }   //minusDays(new Date(), 14)

  export const setTimeStart = (result) => {

      result.setHours(0);
      result.setMinutes(0);
      result.setSeconds(0);
      result.setMilliseconds(0)
      return result;  //for startDate
  }





// export const billToSQLCsv = async () => {
// const promise = await new Promise(async (resolve,reject)=>{

//   console.log('billToSQLCsv()')

//   // const billRef = db.collection('bill').where('shopId','==',token).where("dateTime",">",setTimeStart(minusDays(new Date(), 30)));
//   const billRef = db.collection('bill').where("dateTime",">",setTimeStart(minusDays(new Date(), 1)));
//   // const billRef = db.collection('stock').where('barcode','==','50230112');


//   await billRef
//   .get()
//   .then(async (qsnapshot) => { // try get from local first to safe cost
//     let arrObject = [];

//     console.log('snapshot.docs.length:'+qsnapshot.docs.length);

//     if (qsnapshot.docs.length > 0) {
//       qsnapshot.forEach(doc=>
//         arrObject.push({
//           ...doc.data(),
//           id:doc.id,
//           // dateTime:'xxxxxxx'
//           // dateTime:new Date(),
//           // dateTime:doc.data().dateTime.toDate(),
//           dateTime:stringDateTime(doc.data().dateTime.toDate()),
//           // dateTime:stringDateTime(),
//           // dateTime:'xxxx',
          
//         })
//       )

//       // console.log(qsnapshot.docs.length)
//       return arrObject
//     }
//   })
//   .then(bills => {
//     const jsonFields = [
//       "storeName","Pos Name","Invoice No","Receipt Datetime","Product ID",
//       "Product Barcode","barcode type","Product Name","Quantity","Unit Price",
//       "Unit","Discount","promotionStatus","Total Sales","Customer ID",
//       "Customer Name","customer_Tel"
//     ];

//     let csvStr = jsonFields.join(",")+"\n";
//     bills.forEach((bill,i) => { 
//       // 1 st JSON layer
//       const storeName = bill.shopId;
//       const posName = "shopcham";
//       const invoiceNo = bill.id;
//       const receiptDatetime = bill.dateTime;
//       // const productId = bill.product[i].id;
//       // const productBarcode = bill.product[i].barcode;
//       // const barcodeType = bill.product[i].barcodeStatus;
//       // const productName = bill.product[i].name;
//       // const quantity = bill.product[i].name;
//       // const unitPrice = bill.product[i].price;
//       // const unit = bill.product[i].unit;
//       const discount = 0;
//       // const promotionStatus = bill.product[i].promotion.status;
//       const totalSales = bill.totalPrice;
//       const customerId = bill.customerId;
//       const customerName = bill.customerName;
//       const customer_Tel = bill.customerTel;

//       bill.product.forEach(product => {
//         // 2 nd JSON layer
//         const productId = product.id;
//         const productBarcode = product.barcode;
//         const barcodeType = product.barcodeStatus;
//         const productName = product.name;
//         const quantity = product.name;
//         const unitPrice = product.price;
//         const unit = product.unit;
//         const promotionStatus = product.promotion.status;
        
//         csvStr += 
//           storeName + ','+
//           posName + ','+
//           invoiceNo + ','+
//           receiptDatetime + ','+
//           productId + ','+
//           productBarcode + ','+
//           barcodeType + ','+
//           productName + ','+
//           quantity + ','+
//           unitPrice + ','+
//           unit + ','+
//           discount + ','+
//           promotionStatus + ','+
//           totalSales + ','+
//           customerId + ','+
//           customerName + ','+
//           customer_Tel + "\n";
//       })
      

      
//     })

//     return csvStr;
//   })
//   .then(res => {console.log('return csv');resolve(res) })  // .then(res => {resolve(res);console.log('local fetchBill:',res)})
//   .catch(err => reject(err))  // .catch(err => {reject(err);console.log('local fetchBill err:',err)})
// });

// return promise
// }



export const billToSQLCsv = async (starDate = new Date(),endDate = new Date()) => {
  const promise = await new Promise(async (resolve,reject)=>{

    console.log('billToSQLCsv()')
  
    // const billRef = db.collection('bill').where('shopId','==',token).where("dateTime",">",setTimeStart(minusDays(new Date(), 30)));
    // const billRef = db.collection('stock').where('barcode','==','50230112');
    // const billRef = db.collection('bill').where("dateTime",">",setTimeStart(minusDays(new Date(), 1)));
    // const billRef = db.collection('bill')
    //   .where("dateTime",">=",new Date("2022-09-04"))
    //   .where("dateTime","<=",new Date("2022-09-05"));
    const billRef = db.collection('bill')
      .where("dateTime",">=",new Date(starDate))
      .where("dateTime","<=",new Date(endDate));
  
    await billRef
    .get()
    .then(async (qsnapshot) => { // try get from local first to safe cost
      let arrObject = [];
  
      console.log('snapshot.docs.length:'+qsnapshot.docs.length);
  
      if (qsnapshot.docs.length > 0) {
        qsnapshot.forEach(doc=>
          arrObject.push({
            ...doc.data(),
            id:doc.id,
            // dateTime:'xxxxxxx'
            // dateTime:new Date(),
            // dateTime:doc.data().dateTime.toDate(),
            dateTime:stringDateTime(doc.data().dateTime.toDate()),
            // dateTime:stringDateTime(),
            // dateTime:'xxxx',
            
          })
        )
  
        // console.log(qsnapshot.docs.length)
        return arrObject
      }
    })
    .then(bills => {
      let aCsvBill = [];

      const jsonFields = [
        "storeName","Pos Name","Invoice No","Receipt Datetime","Product ID",
        "Product Barcode","barcode type","Product Name","Quantity","Unit Price",
        "Unit","Discount","promotionStatus","Total Sales","Customer ID",
        "Customer Name","customer_Tel"
      ];

      aCsvBill.push(jsonFields)
  
      // let csvStr = jsonFields.join(",")+"\n";
      
      bills.forEach((bill,i) => { 
        // 1 st JSON layer
        const storeName = bill.shopId;
        const posName = "shopcham";
        const invoiceNo = bill.id;
        const receiptDatetime = bill.dateTime;
        // const productId = bill.product[i].id;
        // const productBarcode = bill.product[i].barcode;
        // const barcodeType = bill.product[i].barcodeStatus;
        // const productName = bill.product[i].name;
        // const quantity = bill.product[i].name;
        // const unitPrice = bill.product[i].price;
        // const unit = bill.product[i].unit;
        const discount = 0;
        // const promotionStatus = bill.product[i].promotion.status;
        const totalSales = bill.totalPrice;
        const customerId = bill.customerId;
        const customerName = bill.customerName.length >= 5 ? bill.customerName.substring(0,5)+'xxxxx' : bill.customerName ;
        const customer_Tel = bill.customerTel.length >= 9 ? '+66' + bill.customerTel.substring(1,bill.customerTel.length -4) + 'xxxx' : '';
  
        bill.product.forEach(product => {
          // 2 nd JSON layer
          const productId = product.id;
          const productBarcode = String(product.barcode);
          const barcodeType = product.barcodeStatus;
          const productName = product.name;
          const quantity = product.qty;
          const unitPrice = product.price;
          const unit = product.unit;
          const promotionStatus = product.promotion.status;


            aCsvBill.push([
              storeName ,
              posName ,
              invoiceNo ,
              receiptDatetime ,
              productId ,
              productBarcode ,
              barcodeType ,
              productName ,
              quantity ,
              unitPrice ,
              unit ,
              discount ,
              promotionStatus ,
              totalSales ,
              customerId ,
              customerName ,
              customer_Tel               
            ]);
              

        })
        
  
        
      })
  
      return aCsvBill;
    })
    .then(res => {console.log(res);resolve(res) })  // .then(res => {resolve(res);console.log('local fetchBill:',res)})
    .catch(err => reject(err))  // .catch(err => {reject(err);console.log('local fetchBill err:',err)})
  });
  
  return promise
  }

export  const updateAndFetchTimestamp = async () => {
      const serverTimeRef = db.collection('admin').doc('serverTime');

      return serverTimeRef
        .update({ timestamp: firebase.firestore.FieldValue.serverTimestamp() })
        .then(() => {
          return serverTimeRef.get();
        })
        .then(documentSnapshot => {
          const timestamp = documentSnapshot.data().timestamp;
          return timestamp;
        })
        .catch(error => {
          console.error('Error updating and retrieving server timestamp:', error);
          return null;
        });
  };