import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { db, firebaseAuth } from "../db/firestore";
import { anonymous_Id } from "../configs";

const authDefault = {currentUser:null,Loading_Auth:true,token:null,path:''};
const initialState = authDefault



export const signup = createAsyncThunk(
  'auth/signup',
  async ({email,password}) => {
    let user ={}
    try{
      user =  await firebaseAuth.createUserWithEmailAndPassword(email.trim(), password);
      
    }catch(err){
      console.log("Error Code:"+err?.code+"\nMessage:"+err?.message)
      // alert("Error Code:"+err?.code+"\nMessage:"+err?.message)
        switch(err?.code){
            case 'auth/email-already-in-use':
                alert('อีเมลนี้ใช้สมัครบริการแล้ว กรุณาใช้อีเมลอื่น')
                break;
            case 'auth/invalid-email':
                alert('อีเมลนี้ไม่พบในเครื่อข่ายอินเตอร์เน็ต กรุณาใช้อีเมลอื่น')
                break;
            case 'auth/operation-not-allowed':
                alert('ผู้ให้บริการปิดการทำงานของวิธีล็อกอินนี้')
                break;
            case 'auth/weak-password':
                alert('โปรดกรอกรหัสผ่านอย่างน้อย 6 หลัก')
                break;
        }
    }
    
    return user
  }
);

export const signin = createAsyncThunk( 
  'auth/signin',
  async ({email,password}) => {
    // let user = firebaseAuth.signInWithEmailAndPassword(email, password).then(us)
    // return user;

    // alert('email:'+email +'\n'+
    //       'password:'+JSON.stringify(password))



    console.log("email"+email+"password"+password)
    let user = {}
    try{
      user = await firebaseAuth.signInWithEmailAndPassword(email.trim(), password);
      // user = await firebaseAuth.signInWithEmailAndPassword('siripongsrisukha@gmail.com', '123456');
      // await onesignalHandleSignInUser(objRes.user.uid);
      // dispatch({type:'signIn',payload:{token:objRes.user.uid,currentUser:objRes.user}});

      // alert('authSlice signin:'+JSON.stringify(user,null,4))
      
      // console.log('authSlice signin:'+JSON.stringify(user,null,4))
      
    }catch(err){
        console.log("Error Code:"+err.code+"\nMessage:"+err.message)
        switch(err.code){
            case 'auth/invalid-email':
                alert('อีเมลนี้ไม่พบในเครื่อข่ายอินเตอร์เน็ต')
                break;
            case 'auth/user-disabled':
                alert('บัญชีผู้ใช้งานนี้ถูกระงับ')
                break;
            case 'auth/user-not-found':
                alert('ไม่พบบัญชีผู้ใช้งานในระบบ')
                break;
            case 'auth/wrong-password': //เกิดเฉพาะเมื่ออะไรที่ใช้อีเมลล์ซ้ำ
                alert('รหัสผ่านผิด กรุณาลองใหม่อีกครั้ง')
                break;
        }
    }

    return user
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async () => {
    return firebaseAuth.signOut();
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (email) => {
    return firebaseAuth.sendPasswordResetEmail(email);
  }
);

export const updateEmail = createAsyncThunk(
  'auth/updateEmail',
  async (currentUser,email) => {
    return currentUser.updateEmail(email);
  }
);

export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async (currentUser, password) => {
    return currentUser.updatePassword(password);
  }
);


export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers: {
      login: (state, action) => {
        state.currentUser = action.payload;
        state.loading_Auth = false
      },
      updatePath: (state, action) => {
        state.path = action.payload;
      },
      clearCurrentLogin: (state, action) => {
        // state.currentUser = {uid:anonymous_Id};
        state.currentUser = null;
      },
    },
    extraReducers: (builder) => {
      builder
      .addCase(logout.fulfilled, (state, action) => {
        // state = {...authDefault,uid:anonymous_Id}
        state.currentUser = null;
        state.Loading_Auth = true;
        state.token = null;
        state.path = '';
      })
      .addCase(signin.pending, (state, action) => {
        state.currentUser = action.payload
      })
      // .addCase(fetchMarket.fulfilled, (state, action) => {
      //   state.markets = action.payload
      //   state.loading_Market = false
      // })
      // .addCase(signup.rejected, (state, action) => {
      //   alert(action.payload)
      // })
    }
})


export const  { login, updatePath, clearCurrentLogin } = authSlice.actions;
export default authSlice.reducer;