export default [
    {
        id:1,
        name:'A La Carte',
        status:true
    },
    {
        id:2,
        name:'Buffet',
        status:false
    },
]