import React, { useState, useContext } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Table,
  Modal,
  Card,
  Image,
  Collapse,
} from "react-bootstrap";
import { colors, mainImage } from "../configs";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate , Outlet, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signin } from '../redux/authSlice';
import { shopchampRestaurantAPI } from "../Utility/api";
import { Modal_Loading } from "../modal";
import { fetchProfile } from "../redux/profileSlice";

function LoginScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading_Profile, path } = useSelector((state)=> state.auth)

  const [formData, setFormData] = useState({email:'',password:''})
  const [loading, setLoading] = useState(false)
  // console.log(formData)

  const handleFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...formData };

    switch (fieldName) {
      default:
        newFormData[fieldName] = fieldValue; // dynamic object referent  (eg. equal 'obj.price')
    }

    setFormData(newFormData);
  };

  console.log('formData:'+JSON.stringify(formData,null,4))

  const checkRegisterUser = async() => {
      setLoading(true)
    await shopchampRestaurantAPI.get('/users/checkEmailRegister/'+formData?.email.trim()).then(objRes=>{
      const emailUserUid = objRes?.data?.uid;

      if(emailUserUid){
        dispatch(signin(formData)).then(res => {
          // alert('!!res.payload?.uid:'+JSON.stringify(res.payload,null,4))
          if(!!res.payload?.user?.uid){
            dispatch(fetchProfile(res.payload?.user?.uid)).then(()=>{navigate(path);setLoading(false)})
          } else {
            setLoading(false)
          }
        })
      }else{ // Register other app, but not registed vender
        setLoading(false)
        alert('กรุณาสมัครสมาชิกเพื่อเข้าใช้งานระบบ');
      }  

    }).catch(err=>{ // Not found user
        setLoading(false)
        alert('กรุณาสมัครสมาชิกเพื่อเข้าใช้งานระบบ');
      console.log(err);
    });
}




  return (
    <div  style={{height:'100vh',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:colors.purplePeel}} >
        <Modal_Loading show={loading || loading_Profile} />
        <div style={{backgroundColor:'white',borderRadius:50,padding:20,paddingTop:5,display:'flex',flexDirection:'column',alignItems:'center',width:'25%',minWidth:'300px'}} >
                <img style={{width:'200px'}} src={mainImage.logo} />
                <h5 style={{color:colors.purpleRed}} ><b>Hi, Welcome Back</b></h5>
                <h6 style={{fontSize:14,color:colors.darkGray}} >Enter your credentials to continue</h6>
                <Form.Floating className="mb-3" style={{width:'100%'}} >
                    <Form.Control
                      name="email"
                      type="email"
                      placeholder="name@example.com"
                      onChange={handleFormChange}
                    />
                    <label >Email address</label>
                </Form.Floating>
                <Form.Floating className="mb-3" style={{width:'100%'}} >
                    <Form.Control
                      name="password"
                      type="password"
                      placeholder="Password"
                      onChange={handleFormChange}
                    />
                    <label >Password</label>
                </Form.Floating>
                {/* <div style={{display:'flex',justifyContent:'space-between',width:'100%',paddingTop:20}} >
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group>
                    <p style={{color:colors.purpleRed}} >Forgot Password?</p>
                </div> */}
                {/* <Button onClick={()=>{navigate("/landlord")}} style={{width:'100%',backgroundColor:colors.darkPurple,borderRadius:20,marginTop:10}} >Sign in</Button> */}
                {!!formData.email && !!formData.password
                    ?<Button onClick={()=>{checkRegisterUser()}} variant="success" style={{width:'100%',borderRadius:20,marginTop:10}} >Sign in</Button>
                    :<Button onClick={()=>{alert('กรุณาใส่ข้อมูลให้ครบถ้วน')}} variant="secondary" style={{width:'100%',borderRadius:20,marginTop:10}} >Sign in</Button>
                }
                
                <Link to='/register' style={{marginTop:'1rem'}} >
                  Don't have an account?
                </Link>
        </div>
    </div>
  );
}

export default LoginScreen;
